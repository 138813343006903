/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-10 14:01:54
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-05-06 15:56:33
 */

const baseUrl = process.env.REACT_APP_BASE_URL;
const adminBffBaseURL = process.env.REACT_APP_ERL_ADMIN_BFF_BASE_URL;
// const authServiceClient = "erl-web-user-manage";
const authServiceClient = process.env.REACT_APP_AUTH_SERVICE_CLIENT;
// const authServiceClientSecret = "c65d54f4-0e27-40f8-a565-f9eb376c6f77";
const authServiceClientSecret =
  process.env.REACT_APP_AUTH_SERVICE_CLIENT_SECRET;

const mockApiBaseURL = "http://localhost:4000/";

const authTokenStorageKey = "@webAuthKey";
const userProfileStorageKey = "@userProfileKey";

const apiBaseURL = process.env.REACT_APP_ERL_ADMIN_BFF_BASE_URL;
// const apiBaseURL = "https://dev.erl2.lgcc.gov.lk/";
// const apiBaseURL = "https://qa.erl2.lgcc.gov.lk/";
// const apiBaseURL = "https://staging.erl2.lgcc.gov.lk/";
// const apiBaseURL = "https://web.erl2.gov.lk/"

const uploadComponentConfig = {
  txt: "file-txt.png",
  pdf: "file-pdf.png",
  docx: "file-word.png",
  xlsx: "file-excel.png",
};

const validImageTypes = {
  png: "image/png",
  jpg: "image/jpg",
  jpeg: "image/jpeg",
};

const validFileTypes = {
  txt: "text/plain",
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export {
  mockApiBaseURL,
  authTokenStorageKey,
  baseUrl,
  apiBaseURL,
  uploadComponentConfig,
  validImageTypes,
  validFileTypes,
  authServiceClient,
  authServiceClientSecret,
  adminBffBaseURL,
  userProfileStorageKey,
};
