/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:09:37
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-15 16:09:16
 */
import React, { useEffect, useState } from "react";
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import {
  applicationStatus,
  DataTable,
} from "../../../ui-components/ui-elements/data-table/DataTable";
import StatusCheckFilterComponent from "./includes/SearchComponent";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import { RoundedIconButton } from "../../../ui-components/ui-elements/form/Button";
import OnlineApplicationStatusPopUp from "../pages/includes/OnlineApplicationStatusPopUp";
import { useStatusCheckHook } from "../../../hooks/useStatusCheckHook";
import { useRecoilValue, useRecoilState } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { VehicleNumber, ChassisNumber } from "../../../../atom";

import moment from "moment";

const StatusCheckTableRows = [
  {
    requestedAt: "10/08/2020 14:28",
    vehicleRegNo: "CAD-5555",
    referenceNo: "1254657687",
    badge: applicationStatus.PENDING,
  },
  {
    requestedAt: "01/05/2020 14:28",
    vehicleRegNo: "BAA-0978",
    referenceNo: "1212409458",
    badge: applicationStatus.ISSUED,
  },
];

const StatusCheck = () => {
  const locale = useRecoilValue(LanguageFilter);
  const [open2, setOpen2] = useState(false);

  const { statusInfo, getStatusByID, getAllStatus } = useStatusCheckHook();

  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState("");
  const [Error, setError] = useState("");
  const [requestType, setRequestType] = useState("vehicle_no");
  const [defaultText, setDefaultText] = useState("");
  const [vehicleNumberState, setVehicleNumber] = useRecoilState(VehicleNumber);
  const [chassisNumberState, setChassisNumber] = useRecoilState(ChassisNumber);

  useEffect(() => {
    setVehicleNumber("");
    setChassisNumber("");
  }, []);

  // const [errorDisplayName, setErrorDisplayName] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const notify = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    statusInfo?.map((data) => {
      data.requested_at = moment(data.requested_at).format("DD/MM/YYYY HH:mm");
    });

    statusInfo?.map((data) => {
      data.status = data.status.charAt(0).toUpperCase() + data.status.slice(1);
    });

    setTableData(statusInfo);
  }, [statusInfo]);

  const StatusCheckTableHead = [
    {
      displayName:
        locale === "si"
          ? "..... දී ඉල්ලා ඇත"
          : locale === "en"
          ? "Requested at"
          : "கோரிக்கை விடுக்கப்பட்டது.",
      key: "requested_at",
    },
    {
      displayName:
        locale === "si"
          ? "වාහනයේ ලියාපදිංචි අංකය"
          : locale === "en"
          ? "Vehicle Reg. Number"
          : "வாகனப் பதிவு இலக்கம்",
      key: "vehicle_no",
    },
    {
      displayName:
        locale === "si"
          ? "යොමු අංකය"
          : locale === "en"
          ? "Reference Number"
          : "குறிப்பு இலக்கம்.",
      key: "licence_no",
    },
    {
      displayName:
        locale === "si"
          ? "අයදුම්පතේ වර්තමාන තත්ත්වය"
          : locale === "en"
          ? "Application Status"
          : "விண்ணப்பத் தகுதிநிலை",
      key: "status",  
    },
    // {
    //   displayName: "",
    //   key: "badge",
    // },
  ];

  const handleSelectedType = (obj) => {
    setRequestType(obj.value);
  };

  const handleVehicleInput = (text) => {
    document
      .getElementById("txtVehicle")
      .addEventListener("keyup", function () {
        if (this.value.length > 0) {
          setError("");
        }
        if (this.value.length <= 35) {
          setData(this.value);
        } else {
          this.value = this.value.slice(0, 35);
        }
      });
  };

  const onSearchClicked = () => {
    if (statusInfo === undefined) {
      notify("No record(s) found.");
      // setErrorDisplayName("No record(s) found.")
    } else {
      getStatusByID(requestType, data);

      if (statusInfo.length === 0) {
        notify("No record(s) found.");
        // setErrorDisplayName("No record(s) found.")
      }
    }
    // }
  };

  const onResetClicked = () => {
    setRequestType("vehicle_no");
    setData("");
    setDefaultText("");
    document.getElementById("StstusCheckForm").reset();
    getAllStatus();
  };

  return (
    <Template activeHeader="revenueLicence" selectedLan={locale}>
      <UICard
        title={
          locale === "en"
            ? "Check Online Application Status"
            : locale === "si"
            ? "අයදුම්පත්‍රය මාර්ගතව පරීක්ෂා කරන්න"
            : "இணையவழி மூலமான விண்ணப்பத்தின் தகுதிநிலையினை செவ்வை பார்த்தல்"
        }
        titlePlacement={cardTitlePlacement.LEFT}
        cardSize={cardSize.LARGE}
        isContentCenter={true}
        titleStyle="mainTxtHeading "
      >
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <DataTable
          dataTableWrapperStyle={"mt-5 mb-5 ml-1 mr-1"}
          filterComponent={
            <form id="StstusCheckForm">
              <StatusCheckFilterComponent
                onTypeSelected={handleSelectedType}
                onVehicleNumberEntered={handleVehicleInput}
                inputId="txtVehicle"
                Error={Error}
                maxCharLength={35}
                onResetClicked={() => onResetClicked()}
                defaultText={defaultText}
                labelText={
                  <div className="">
                    {locale === "si"
                      ? "තෝරන්න"
                      : locale === "ta"
                      ? "நிரப்பு"
                      : "Filter by"}
                  </div>
                }
                inputBoxText={
                  <div className="">
                    {locale === "si"
                      ? "අගය"
                      : locale === "ta"
                      ? "மதிப்பு"
                      : "Value"}
                  </div>
                }
              />
            </form>
          }
          isSetSearch={true}
          isSetReset={true}
          tableHeaderList={StatusCheckTableHead}
          tableBodyList={tableData ? tableData : []}
          onSearchClicked={() => onSearchClicked()}
          onResetClicked={() => onResetClicked()}
          isSetStatus={true}
          statusIcon={
            <RoundedIconButton  
              icon={"?"}
              tooltip={locale === "si"
              ? "තත්ත්වය"
              : locale === "ta"
              ? "நிலை"
              : "Status"}
              onClickFn={() => setOpen2(true)}
            />
          }
        />

        {open2 && (
          <Popup
            title={
              locale === "si"
                ? "මාර්ගගත අයදුම්පතේ වර්තමාන තත්ත්වය "
                : locale === "ta"
                ? "இணையவழி மூலமான விண்ணப்பத்தின் தகுதிநிலை"
                : "Online Application Status"
            }
            titlePlacement={cardTitlePlacement.CENTER}
            content={<OnlineApplicationStatusPopUp />}
            isOpen={open2}
            handleClose={() => setOpen2(false)}
          />
        )}
      </UICard>
    </Template>
  );
};

export default StatusCheck;
