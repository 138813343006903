/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-19 14:19:53
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-05 17:06:25
 */
import React, { memo } from "react";
import { formCacheLevel } from "../../../../config/template.config";
import PropTypes from "prop-types";
import { useBasicButton } from "../../../hooks/common-hooks/useFromButtonState.hook";

const emptyFn = () => undefined;

const toolTipPositions = {
  top: "top",
  bottom: "bottom",
  right: "right",
  left: "left",
};

const CloseButton = ({ onClickFn = emptyFn, btnStyle = "" }) => {
  return <Button btnStyle={btnStyle} onClick={onClickFn} btnText="X" />;
};

const RoundedIconButton = ({
  icon = "",
  tooltip = "",
  onClickFn = emptyFn,
  btnType = "button",
}) => {
  return (
    <button
      className="roundedIconButton"
      title={tooltip}
      onClick={onClickFn}
      type={btnType}
    >
      {icon}
    </button>
  );
};

const Button = ({
  onClick = emptyFn,
  btnType = "button",
  btnStyle = "btnPrimary",
  btnName = "",
  btnText = "",
  isFullWidth = false,
  isActive = false,
  isDisabled = false,
  startIcon = null,
  endIcon = null,
  setToolTip = false,
  toolTipPosition = toolTipPositions.bottom,
  toolTipTitle = "",
  
}) => {
  const dataToggleType = setToolTip ? "tooltip" : "";

  const disabledStyle =
    btnStyle === "btnPrimary"
      ? "disabledPrimary"
      : btnStyle === "btnOutlinePrimary"
      ? "disabledOutline"
      : "";

  return (
    <div className="button">
      <button
      // style={{minWidth:100}}
        type={btnType}
        name={btnName}
        className={`${btnStyle} ${isDisabled ? disabledStyle : ""} ${
          isFullWidth ? " btn-block" : ""
        } ${isActive ? " active " : ""}`}
        onClick={onClick}
        disabled={isDisabled}
        datatoggle={dataToggleType}
        dataplacement={toolTipPosition}
        title={toolTipTitle}
      >
        {startIcon ? startIcon : ""}
        {` ${btnText} `}
        {endIcon ? endIcon : ""}
      </button>
    </div>
  );
};

Button.propTypes = {
  /** Button text */
  btnText: PropTypes.string,
  /** Button name */
  btnName: PropTypes.string,
  /** Button size */
  btnSize: PropTypes.oneOf(["small", "medium", "large"]),
  /** Button color */
  btnColor: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  /** Is full width set or not */
  isFullWidth: PropTypes.bool,
  /** Is button disable or not */
  isDisabled: PropTypes.bool,
  /** Button left icon */
  startIcon: PropTypes.object,
  /** Button right icon */
  endIcon: PropTypes.object,
  /** Tooltip text */
  tooltip: PropTypes.string,
  /** Onclick button function */
  onClick: PropTypes.func,
  /** Button type */
  btnType: PropTypes.string,
  /** Button style */
  btnStyle: PropTypes.string,
  /** Is active button */
  isActive: PropTypes.bool,
  /** Is tooltip available */
  setToolTip: PropTypes.bool,
  /** Tooltip position */
  toolTipPosition: PropTypes.string,
  /** Tooltip title */
  toolTipTitle: PropTypes.string,
};

/**
 * memo render
 * @param {Object} prevProps
 * @param {Object} nextProps
 */
const areEqual = (prevProps, nextProps) => {
  if (nextProps.cacheLevel === formCacheLevel.none) {
    return false;
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnFormGroupChange) {
    return (
      prevProps.isFullWidth === nextProps.isFullWidth &&
      prevProps.btnText === nextProps.btnText &&
      prevProps.btnName === nextProps.btnName &&
      prevProps.isBtnDisabled === nextProps.isBtnDisabled &&
      prevProps.tooltip === nextProps.tooltip &&
      prevProps.updateStatus === nextProps.updateStatus &&
      prevProps.startIcon === nextProps.startIcon &&
      prevProps.startIcon === nextProps.endIcon &&
      prevProps.formGroupName === nextProps.formGroupName &&
      prevProps.mergeToForm === nextProps.mergeToForm &&
      prevProps.btnStyle === nextProps.btnStyle &&
      prevProps.isActive === nextProps.isActive
    );
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnIndividual) {
    return (
      prevProps.isFullWidth === nextProps.isFullWidth &&
      prevProps.btnText === nextProps.btnText &&
      prevProps.tooltip === nextProps.tooltip &&
      prevProps.isBtnDisabled === nextProps.isBtnDisabled &&
      prevProps.startIcon === nextProps.startIcon &&
      prevProps.startIcon === nextProps.endIcon &&
      prevProps.formGroupName === nextProps.formGroupName &&
      prevProps.mergeToForm === nextProps.mergeToForm &&
      prevProps.btnStyle === nextProps.btnStyle &&
      prevProps.isActive === nextProps.isActive
    );
  }
};

const ButtonMemo = memo(Button, areEqual);

const ButtonWithState = ({
  btnText = "",
  btnName = "",
  isFullWidth = false,
  isDisabled = false,
  startIcon = null,
  endIcon = null,
  tooltip = "",
  formGroupName = "",
  mergeToForm = null,
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  onClick = emptyFn,
  btnType = "button",
  btnStyle = "btnPrimary",
  isActive = false,
  setToolTip = false,
  toolTipPosition = toolTipPositions.bottom,
  toolTipTitle = "",
}) => {
  const [handleOnClickFn, updateStatus] = useBasicButton(
    onClick,
    formGroupName,
    mergeToForm
  );

  return (
    <ButtonMemo
      btnText={btnText}
      btnName={btnName}
      isFullWidth={isFullWidth}
      isDisabled={isDisabled}
      startIcon={startIcon}
      endIcon={endIcon}
      tooltip={tooltip}
      cacheLevel={cacheLevel}
      mergeToForm={mergeToForm}
      updateStatus={updateStatus}
      onClick={(eventObj) => handleOnClickFn(eventObj)}
      btnStyle={btnStyle}
      btnType={btnType}
      isActive={isActive}
      setToolTip={setToolTip}
      toolTipPosition={toolTipPosition}
      toolTipTitle={toolTipTitle}
    />
  );
};

export {
  Button,
  CloseButton,
  toolTipPositions,
  RoundedIconButton,
  ButtonWithState,
};
