/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-13 11:50:35
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-01-29 11:52:17
 */
import React, { Fragment } from "react";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import Home from "./pages/Home";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { AnimatePresence, motion } from "framer-motion";

const HomeRoutes = () => {
  return (
    <AnimatePresence>
      <Fragment>
        <Navigate
          exact={true}
          path="/"
          routeKey={"home"}
          component={guest(Home)}
          routePermissions={permissions.NONE.permissions}
        />
      </Fragment>
    </AnimatePresence>
  );
};

export default HomeRoutes;
