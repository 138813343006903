/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-22 16:48:53
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-02 15:26:02
 */

import { MenuHeader } from "../common-includes/MenuHeader";
import { TopHeader } from "../common-includes/TopHeader";

const TemplateHeaderWrapper = ({ activeHeader = "", selectedLan = {} }) => {
  return (
    <div className="erlWebHeaderMainWrapper">
      <TopHeader selectedLan={selectedLan} />
      <MenuHeader activeHeader={activeHeader} />
    </div>
  );
};

export { TemplateHeaderWrapper };
