/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-27 00:04:21
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:33:25
 */
import React from "react";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import { Link } from "react-router-dom";
import Template from "../../../ui-components/templates/Template";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";

const ForgotPassword = () => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <Template selectedLan={locale}>
      <UICard
        title={"Forgot Password"}
        titlePlacement={cardTitlePlacement.CENTER}
        cardSize={cardSize.MEDIUM}
        isCardCenter={true}
      >
        <div className="row authFormWrapper">
          <div className="col-md-6 columnBoder">
            <InputBox
              labelText="Email *"
              inputId="email"
              inputName="password"
              inputType="email"
              labelPlacement={labelPositions.TOP}
              inputError=""
              isRequired={true}
              isDisabled={false}
            />
            <div className="contentCenter">
              <div className="floatRight">
                <Button btnText={"Submit"} btnType={"submit"} />
              </div>
            </div>
          </div>
          <div className="col-md-6 contentCenter">
            <p>
              Enter your email address and we will send you instructions on how
              to reset your password
            </p>
            <div className="textCenter">
              <p>OR</p>
              <Link to="login">
                <Button
                  btnName={"login"}
                  btnText={"Back to Login Page"}
                  btnType={"button"}
                  btnStyle={"emailSignin"}
                />
              </Link>
            </div>
          </div>
        </div>
      </UICard>
    </Template>
  );
};

export default ForgotPassword;
