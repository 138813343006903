/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-01 13:49:14
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-15 14:30:00
 */
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import {
  Accordion,
  AccordionWrapper,
} from "../../../ui-components/ui-elements/accordion/Accordion";
import { DataTable } from "../../../ui-components/ui-elements/data-table/DataTable";
import VehicleFitnessTestingCompaniesFilterComponent from "./includes/SearchComponent";
import { AnimatePresence, motion } from "framer-motion";
import {
  supInsCompaniesAPI,
  vetCompaniesAPI,
  vftComapniesAPI,
} from "../../../../config/apiUrl.config";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";

import CompanyListTable from "../../../ui-components/ui-elements/table/CompanyListTable";

import { useSupportOrganizationHook } from "../../../hooks/useSupportOrganizationHook";
import { IntlProvider, FormattedMessage } from "react-intl";
import { proviceCode } from "../../../../config/ProvinceCode.config";
import { useLocateUsHook } from "../../../hooks/useLocateUsHook";
import { VehicleNumber, ChassisNumber } from "../../../../atom";

const messages = {
  en: {
    title: "Support Organizations",
    InsuranceCompanies: "Insurance Companies",
    VehicleEmissionTestingCompanies: "Vehicle Emission Testing Companies",
    VehicleFitnessTestingCompanies: "Vehicle Fitness Testing Centres",
  },
  si: {
    title: "සහායක ආයතන",
    InsuranceCompanies: "රක්ෂණ සමාගම්",
    VehicleEmissionTestingCompanies: "වාහන දුම් පරීක්ෂා කිරීමේ සමාගම්",
    VehicleFitnessTestingCompanies:
      "වාහන යෝග්‍යතා පරීක්ෂාව සිදු කරනු ලබන සමාගම්",
  },
  ta: {
    title: "ஆதரவு நிறுவனங்கள்",
    InsuranceCompanies: "காப்புறுதி கம்பனிகள்",
    VehicleEmissionTestingCompanies: "வாகன புகை பரிசோதனை செய்யும்கம்பனிகள்",
    VehicleFitnessTestingCompanies:
      "வாகன உடனல் தகுதியினை பரிசோதனை செய்யும் கம்பனிகள்",
  },
};

const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

const pageTransition = {
  duration: 2,
};

//insurance
const InsuranceCompaniesTableHead = [
  {
    displayName: "Insurance Company Name",
    key: "name",
    width: "col col-lg-1",
  },
  {
    displayName: "Contact Number",
    key: "phone",
    width: "col col-lg-1",
  },
  {
    displayName: "Email",
    key: "email",
    width: "col col-lg-5",
  },
];

const InsuranceCompaniesTableHeadSinhala = [
  {
    displayName: "රක්ෂණ සමාගමේ නම",
    key: "name",
  },
  {
    displayName: "සම්බන්ධ කර ගත හැකි දුරකථන අංකය",
    key: "phone",
  },
  {
    displayName: "විද්‍යුත් ලිපිනය",
    key: "email",
  },
];

const InsuranceCompaniesTableHeadTamil = [
  {
    displayName: "காப்புறுதி கம்பனியின் பெயர்",
    key: "name",
  },
  {
    displayName: "தொடர்பு இலக்கம்",
    key: "phone",
  },
  {
    displayName: "மின்னஞ்சல்",
    key: "email",
  },
];

const InsuranceCompaniesTableRows = [
  {
    companyName: "Amana Takaful PLC",
    contactNumber: "94117000001",
    email: "info@ama.com",
  },
  {
    companyName: "AIA General Lanka Limited",
    contactNumber: "94117000002",
    email: "info@aia.lk",
  },
  {
    companyName: "Ceylinco Insurance PLC",
    contactNumber: "94117000003",
    email: "info@cey.lk",
  },
  {
    companyName: "Fairfirst Insurance Limited",
    contactNumber: "94117000004",
    email: "info@faixddfsdfsdfsdfsdfsfsdfsfsdv.lk",
  },
];

//emiosion
const VehicleEmissionTestingCompaniesTableHead = [
  {
    displayName: "VET Company Name",
    key: "name",
  },
  {
    displayName: "Contact Number",
    key: "phone",
  },
  {
    displayName: "Email",
    key: "email",
  },
];

const VehicleEmissionTestingCompaniesTableHeadSinhala = [
  {
    displayName: " වාහන දුම් පරීක්ෂා කිරීමේ සමාගමේ නම",
    key: "name",
  },
  {
    displayName: "සම්බන්ධ කර ගත හැකි දුරකථන අංකය",
    key: "phone",
  },
  {
    displayName: "විද්‍යුත් ලිපිනය",
    key: "email",
  },
];

const VehicleEmissionTestingCompaniesTableHeadTamil = [
  {
    displayName: "வாகன புகை பரிசோதனை செய்யும்கம்பனியின் பெயர்",
    key: "name",
  },
  {
    displayName: "தொடர்பு இலக்கம்",
    key: "phone",
  },
  {
    displayName: "மின்னஞ்சல்",
    key: "email",
  },
];

const VehicleEmissionTestingCompaniesTableRows = [
  {
    companyName: "LAUGFS Eco Sri (PVT) Ltd",
    contactNumber: "94117000001",
    email: "info@let.com",
  },
  {
    companyName: "Clean Co Lanka (PVT) Ltd",
    contactNumber: "94117000002",
    email: "info@gglk.lk",
  },
];

//fittness
const VehicleFitnessTestingCompaniesTableHead = [
  {
    displayName: "VFT Company Name",
    key: "name",
  },
  {
    displayName: "Contact Number",
    key: "phone",
  },
  {
    displayName: "Email",
    key: "email",
  },
];

const VehicleFitnessTestingCompaniesTableHeadSinhala = [
  {
    displayName: "වාහන යෝග්‍යතා පරීක්ෂාව සිදු කරනු ලබන සමාගමේ නම",
    key: "name",
  },
  {
    displayName: "සම්බන්ධ කර ගත හැකි දුරකථන අංකය",
    key: "phone",
  },
  {
    displayName: "විද්‍යුත් ලිපිනය",
    key: "email",
  },
];

const VehicleFitnessTestingCompaniesTableHeadTamil = [
  {
    displayName: "வாகன உடனல் தகுதியினை பரிசோதனை செய்யும் கம்பனியின் பெயர்",
    key: "name",
  },
  {
    displayName: "தொடர்ப இலக்கம்",
    key: "phone",
  },
  {
    displayName: "மின்னஞ்சல்",
    key: "email",
  },
];

const VehicleFitnessTestingCompaniesTableRows = [
  {
    companyName: "GSK Motors (PVT) Ltd",
    contactNumber: "94117000001",
    email: "gsk@gmail.com",
  },
  {
    companyName: "Amarasinghe Motor Way (PVT) Ltd",
    contactNumber: "94117000002",
    email: "info@amw.lk",
  },
  {
    companyName: "Remo Motors",
    contactNumber: "94117000003",
    email: "remomoter@yahoo.com",
  },
  {
    companyName: "Pride Motors Company",
    contactNumber: "94117000004",
    email: "info@amw.lk",
  },
];

const SupportOrganizations = () => {
  const locale = useRecoilValue(LanguageFilter);

  const { insCompanies, vetCompanies, vftCompanies, setProvinceCode } =
    useSupportOrganizationHook();
  const { provinceList } = useLocateUsHook();
  const [vehicleNumberState, setVehicleNumber] = useRecoilState(VehicleNumber);
  const [chassisNumberState, setChassisNumber] = useRecoilState(ChassisNumber);

  useEffect(() => {
    setVehicleNumber("");
    setChassisNumber("");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    
    <Template activeHeader="supportedCompanies" selectedLan={locale}>
     
    <MetaTags>
      
      <meta name="description" content="Support Organizations provide required services, which essentially required to obtain the Revenue Licence and to run the vehicle on roads as well.
1. Insurance Companies: Insurance companies facilitate to issue general insurance covers to your vehicles, which is mandatory as suited for your requirements.
2. VET Companies: Vehicle Emmission Test (VET) Companies facilitate to issue emmissione test certificates to your vehicles, which required to obtain the Revenue Licence.
3. VFT Issuance Centres:Vehicle Fitness Test (VFT) Issuance Centres facilitate to issue fitness test certificate to your vehicle (only for the required vehicle categorues)." />
       <meta name="keywords" content="insurance, insurance companies, vehicle insurance, emmission test, VET, fitness certificate, vehicle fitness" />
    </MetaTags>
    
      <IntlProvider locale={locale} messages={messages[locale]}>
        <motion.div
          // initial="out"
          // animate="in"
          // exit="out"
          // variants={pageVariants}
          // transition={pageTransition}
        >
          <UICard
            title={
              <div className="pl-3">
                <FormattedMessage
                id="title"
                value={{ locale }}
              ></FormattedMessage>
              </div>
              
            }
            titlePlacement={cardTitlePlacement.LEFT}
            cardSize={cardSize.LARGE}
            titleStyle="mainTxtHeading "
          >
            <AccordionWrapper accordionWrapperStyle={"mt-5 mb-5 ml-3 mr-4"}>
              <Accordion
                heading={
                  <FormattedMessage
                    id="InsuranceCompanies"
                    value={{ locale }}
                  ></FormattedMessage>
                }
                content={
                  <div>
                    {insCompanies ? (
                      <DataTable
                        tableHeaderList={
                          locale === "en"
                            ? InsuranceCompaniesTableHead
                            : locale === "si"
                            ? InsuranceCompaniesTableHeadSinhala
                            : InsuranceCompaniesTableHeadTamil
                        }
                        // tableBodyList={insCompanies.data.data}
                        tableBodyList={insCompanies.data.data.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )}
                        
                      />
                    ) : null}
                  </div>
                }
              />

              <Accordion
                heading={
                  <FormattedMessage
                    id="VehicleEmissionTestingCompanies"
                    value={{ locale }}
                  ></FormattedMessage>
                }
                content={
                  <div>
                    {vetCompanies ? (
                      <DataTable
                        tableHeaderList={
                          locale === "en"
                            ? VehicleEmissionTestingCompaniesTableHead
                            : locale === "si"
                            ? VehicleEmissionTestingCompaniesTableHeadSinhala
                            : VehicleEmissionTestingCompaniesTableHeadTamil
                        }
                        tableBodyList={vetCompanies.data.data.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )}
                      />
                    ) : null}
                  </div>
                }
              />
              <Accordion
                heading={
                  <FormattedMessage
                    id="VehicleFitnessTestingCompanies"
                    value={{ locale }}
                  ></FormattedMessage>
                }
                content={
                  <div>
                    <DataTable
                      filterComponent={
                        <VehicleFitnessTestingCompaniesFilterComponent
                          inputData={provinceList}
                          onProviceChanged={setProvinceCode}
                        />
                      }
                      tableHeaderList={
                        locale === "en"
                          ? VehicleFitnessTestingCompaniesTableHead
                          : locale === "si"
                          ? VehicleFitnessTestingCompaniesTableHeadSinhala
                          : VehicleFitnessTestingCompaniesTableHeadTamil
                      }
                      tableBodyList={
                        vftCompanies &&
                        vftCompanies?.data?.data?.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )
                      }
                    />
                  </div>
                }
              />
            </AccordionWrapper>
          </UICard>
        </motion.div>
      </IntlProvider>
    </Template>
  );
};

export default SupportOrganizations;
