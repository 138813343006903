/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-22 13:57:14
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-22 13:59:05
 */
const TemplateBodyWrapper = ({ children = null }) => {
  return <div className="erlWebBodyMainWrapper">{children}</div>;
};

export { TemplateBodyWrapper };
