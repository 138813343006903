/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-19 14:20:02
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-05 14:57:59
 */
import React, { memo } from "react";
import { FormControlWrapper } from "./form-includes/FormCoreIncludes";
import { labelPositions } from "./InputLabel";
import { Input } from "./InputBox";
import { CheckLabelWrapper } from "./CheckLabel";
import { toBoolean } from "../../../../helpers/common-helpers/common.helpers";
import PropTypes from "prop-types";
import {
  defaultTemplateType,
  templateTypes,
  formCacheLevel,
} from "../../../../config/template.config";
import { useBasicInput } from "../../../hooks/common-hooks/useFormInputState.hook";

const emptyFn = () => undefined;

const CheckBox = ({
  inputId = "",
  inputName = "",
  inputError = "",
  helperText = "",
  inputValue = true,
  isRequired = false,
  isDisabled = false,
  labelText = "",
  labelPlacement = labelPositions.START,
  toolTip = "",
  onChangeFn = emptyFn,
  screenSize,
}) => {
  return (
    <FormControlWrapper
      inputError={inputError}
      helperText={helperText}
      className="form-check"
    >
      <CheckLabelWrapper
        inputName={inputName}
        isRequired={isRequired}
        labelPlacement={labelPlacement}
        labelText={labelText}
      >
        <Input
          inputId={inputId}
          inputName={inputName}
          className={"form-check-input"}
          inputType={"checkbox"}
          onChangeTxtFn={(event) => {
            onChangeFn({
              name: inputName,
              value: !toBoolean(inputValue),
              eventInfo: event,
            });
          }}
          inputValue={inputValue}
          isRequired={isRequired}
          isDisabled={isDisabled}
        />
      </CheckLabelWrapper>
    </FormControlWrapper>
  );
};

CheckBox.propTypes = {
  /** Input value name */
  inputName: PropTypes.string,
  /** Input field error message */
  inputError: PropTypes.string,
  /** In out value */
  inputValue: PropTypes.bool,
  /** Lable text */
  labelText: PropTypes.string,
  /** Helper text */
  helperText: PropTypes.string,
  /** Tooltip text */
  toolTip: PropTypes.string,
  /** Onclick event function */
  onChangeFn: PropTypes.func,
  /** Input id */
  inputId: PropTypes.string,
  /** Is required */
  isRequired: PropTypes.bool,
  /** Is disabled */
  isDisabled: PropTypes.bool,
  /** Label placement */
  labelPlacement: PropTypes.string,
  /** Screen size */
  screenSize: PropTypes.string,
};

/**
 * memo render
 * @param {Object} prevProps
 * @param {Object} nextProps
 */
const areEqual = (prevProps, nextProps) => {
  if (nextProps.cacheLevel === formCacheLevel.none) {
    return false;
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnFormGroupChange) {
    return (
      prevProps.labelText === nextProps.labelText &&
      prevProps.inputError === nextProps.inputError &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isRequired === nextProps.isRequired &&
      prevProps.inputValue === nextProps.inputValue &&
      prevProps.updateStatus === nextProps.updateStatus &&
      prevProps.toolTip === nextProps.toolTip &&
      prevProps.inputId === nextProps.inputId &&
      prevProps.labelPlacement === nextProps.labelPlacement &&
      prevProps.screenSize === nextProps.screenSize
    );
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnIndividual) {
    return (
      prevProps.labelText === nextProps.labelText &&
      prevProps.inputError === nextProps.inputError &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isRequired === nextProps.isRequired &&
      prevProps.inputValue === nextProps.inputValue &&
      prevProps.toolTip === nextProps.toolTip &&
      prevProps.inputId === nextProps.inputId &&
      prevProps.labelPlacement === nextProps.labelPlacement &&
      prevProps.screenSize === nextProps.screenSize
    );
  }
};

const CheckBoxMemo = memo(CheckBox, areEqual);

const CheckBoxWithState = ({
  inputId = "",
  inputName = "",
  inputError = "",
  inputValue = false,
  isDisabled = false,
  labelText = "",
  helperText = "",
  toolTip = "",
  inputStatePath = "",
  formGroupName = "",
  labelPlacement = labelPositions.START,
  onChangeFn = emptyFn,
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  screenSize,
}) => {
  const [currentValue, currentError, updateStatus, handleOnChangeFn] =
    useBasicInput(
      inputStatePath,
      formGroupName,
      inputName,
      inputValue,
      onChangeFn
    );

  return (
    <CheckBoxMemo
      inputName={inputName}
      inputError={currentError}
      inputValue={toBoolean(currentValue)}
      labelText={labelText}
      helperText={helperText}
      toolTip={toolTip}
      isDisabled={isDisabled}
      onChangeFn={(eventData) => {
        handleOnChangeFn(eventData);
        onChangeFn(eventData);
      }}
      updateStatus={updateStatus}
      cacheLevel={cacheLevel}
      labelPlacement={labelPlacement}
      inputId={inputId}
      screenSize={screenSize}
    />
  );
};

CheckBoxWithState.propTypes = {
  /** In out value name */
  inputName: PropTypes.string,
  /** Input field error message */
  inputError: PropTypes.string,
  /** In out value */
  inputValue: PropTypes.bool,
  /** Lable text */
  lableText: PropTypes.string,
  /** Helper text */
  helperText: PropTypes.string,
  /** Tooltip text */
  toolTip: PropTypes.string,
  /** Form group name */
  formGroupName: PropTypes.string,
  /** Input element state avilable path(use for manage complex objects) */
  inputStatePath: PropTypes.string,
  /** Onclick event function */
  onChangeFn: PropTypes.func,
  /** Input id */
  inputId: PropTypes.string,
  /** Is required */
  isRequired: PropTypes.bool,
  /** Is disabled */
  isDisabled: PropTypes.bool,
  /** Label placement */
  labelPlacement: PropTypes.string,
  /** Screen size */
  screenSize: PropTypes.string,
};

export { CheckBox, CheckBoxWithState };
