import { useState, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { LanguageFilter } from "../../atom";

import { callApi } from "../../helpers/common-helpers/callApi.helpers";
import {
  authorityAPI,
  districtsAPI,
  provinceAPI,
} from "../../config/apiUrl.config";

const initProvice = {
  id: "0",
  value: "select",
  name: "Select",
};

const initDistrict = {
  value: "select",
};

export const useLocateUsHook = () => {
  const locale = useRecoilValue(LanguageFilter);

  const [province, setProvince] = useState(initProvice);
  const [district, setDistrict] = useState(initDistrict);

  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [authorities, setAuthorities] = useState([]);

  const getProvinceData = useCallback(async () => {
    callApi(`${provinceAPI.url}?sort_by=name|Casc&language=${locale}`)
      .method("get")
      .send((error, result) => {
        if (result) {
          setProvinceList(result.data.data);

        } else {
          setProvinceList([]);
        }
      });
  }, [locale]);

  const getDistrictsData = useCallback(async () => {
    callApi(
      `${districtsAPI.url}?sort_by=name|asc&province_code=${province.value}&language=${locale}`
    )
      .method("get")
      .send((error, result) => {
        if (result) {
          setDistrictList(result.data.data);
        } else {
          setDistrictList([]);
        }
      });
  }, [locale, province]);

  const getAuthoritiesData = useCallback(async () => {
    callApi(
      `${authorityAPI.url}/filter-by-location?province_code=${province.value}&district_code=${district.value}&name=${locale}`
    )
      .method("get")
      .send((error, result) => {
        if (result) {
          setAuthorities(result.data.data);
          console.log(result.data.data);
        } else {
          setAuthorities([]);
        }
      });
  }, [locale, district, province]);

  useEffect(() => {
   
      setDistrictList([]);
      setAuthorities([]);
    

    // if (districtList.length !== 0) {
    //   setDistrict({ value: districtList[0].code });
    // }
  }, [province]);

  useEffect(() => {
    getProvinceData();
    getDistrictsData();
    getAuthoritiesData();
  }, [getAuthoritiesData, getDistrictsData, getProvinceData, locale]);

  // useEffect(() => {
  //   getProvinceData();
  // }, [locale]);

  // useEffect(() => {
  //   getDistrictsData();
  // }, [province, locale]);

  // useEffect(() => {
  //   getAuthoritiesData();
  // }, [province, district, locale]);

  return {
    province,
    district,
    setProvince,
    districtList,
    provinceList,
    setDistrict,
    authorities,
    getAuthoritiesData,
  };
};
