import React, { Fragment, useEffect, useState } from "react";
import { Base64 } from "js-base64";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import {
  Button,
  RoundedIconButton,
} from "../../../ui-components/ui-elements/form/Button";
import { Badge } from "../../../ui-components/ui-elements/form/Badge";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import CurrentAddressPopUp from "./includes/CurrentAddressPopUp";
import CurrentLicenceStatusPopUp from "./includes/CurrentLicenceStatusPopUp";
import { Link, useHistory } from "react-router-dom";
import { Label } from "../../../ui-components/ui-elements/form/Label";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { toast, ToastContainer } from "react-toastify";
import dateFormat, { masks } from "dateformat";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { useScreenType } from "../../../hooks/common-hooks/useScreenStatus.hook";

const ConfirmDetails = ({ vehicleInfo, onCancelClicked }) => {
  const [openAddress, setOpenAddress] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const locale = useRecoilValue(LanguageFilter);
  const history = useHistory();

  const notify = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onConfirmedClick = () => {
    toast.dismiss();

    if (vehicleInfo?.current_licence_status === "VA") {
      notify(
        "You cannot proceed. The vehicle has a valid Revenue Licence Currently"
      );
    } else {
      if (vehicleInfo?.documents_validated === true) {
        history.push("/payment-licence");
      } else {
        if (vehicleInfo?.insurance_status === false) {
          notify("You are unable to proceed. Insurance Certificate : Invalid");
        }
        if (vehicleInfo?.vet_status === false) {
          notify("You are unable to proceed. VET Certificate : Invalid");
        }
        if (vehicleInfo?.vft_status === false) {
          notify("You are unable to proceed. VFT Certificate : Invalid");
        }
      }
    }
  };
  // const onCancelClick = () => {
  //   window.location.reload(false);
  //   window.scrollTo(0, 0);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let dateTo = new Date(vehicleInfo?.new_lcn_valida_to);
  let dateTonew = `${dateTo.getDate()}/${dateTo.getMonth() + 1
    }/${dateTo.getFullYear()}`;

  let dateFrom = new Date(vehicleInfo?.new_lcn_valida_from);
  let dateFromnew = `${dateFrom.getDate()}/${dateFrom.getMonth() + 1
    }/${dateFrom.getFullYear()}`;

  let screenType = useScreenType()
  return (
    <div>
      <div className="uiCardWrapper-new">
        <UICard
          title={
            locale === "en"
              ? "Confirm Vehicle & Ownership Details"
              : locale === "si"
                ? "වාහනයේ සහ හිමිකාරීත්වයේ විස්තර තහවුරු කරන්න "
                : "வாகனம் மற்றும் வாகனத்தின் உரித்துடமை விபரங்களை உறுதிப்படுத்துக."
          }
          borderLess={true}
          titleStyle="mainTxtHeading "
        >
          <div className="col-md-9 col-sm-12 mt-5">
            <div className="row mb-3">
              <div className="col-md-5 ">
                <Label
                  className="floatRight"
                  labelText={
                    locale === "en"
                      ? "Vehicle is Registered to "
                      : locale === "si"
                        ? "වාහනය ලියාපදිංචි කර ඇත්තේ ................ නමිනි"
                        : "வாகனம் பதிவு செய்யப்பட்டுள்ளது"
                  }
                />
              </div>
              <div className="col-md-7 ">
                <div className="row mt-1 " style={{marginLeft:"15px"}}>
                  {vehicleInfo?.documents_validated === true
                    ? vehicleInfo ? Base64.decode(vehicleInfo.owner_name) : "-"
                    : "-"}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-5">
                <Label
                  className="floatRight"
                  labelText={
                    locale === "en"
                      ? "Current Address of the above Owner"
                      : locale === "si"
                        ? "ඉහත අයිතිකරුගේ වර්තමාන ලිපිනය"
                        : "மேற்குறித்தஉரிமையாளரின் தற்போதைய முகவரி"
                  }
                />
              </div>
              <div className=" col-md-7  ">
                <div className="row mt-1 " style={{marginLeft:"15px"}}>
                  <div className="pl-1">
                    {vehicleInfo?.documents_validated === true
                      ? vehicleInfo ? Base64.decode(vehicleInfo.owner_address_line_1) : "-" +
                        ", " +
                        vehicleInfo ? Base64.decode(vehicleInfo.owner_address_line_2) : "-"
                      : "-"}
                  </div>
                  <div className="pl-2">
                    <RoundedIconButton
                      icon={"?"}
                      tooltip={"Details"}
                      onClickFn={() => setOpenAddress(true)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {openAddress && (
              <Popup
                content={<CurrentAddressPopUp />}
                handleClose={() => setOpenAddress(false)}
                title={
                  locale === "en"
                    ? "Current Address of the Owner"
                    : locale === "si"
                      ? "හිමිකරුගේ වර්තමාන ලිපිනය"
                      : "உரிமையாளரின் தற்போதைய முகவரி"
                }
              />
            )}

            <div className="row mb-3">
              <div className="col-md-5">
                <Label
                  className="floatRight"
                  labelText={
                    locale === "en"
                      ? "Vehicle Registered Province"
                      : locale === "si"
                        ? "වාහනය ලියාපදිංචි කර ඇති පළාත"
                        : "வாகனம் பதிவு செய்யப்பட்ட மாகாணம்"
                  }
                />
              </div>
              <div className="col-md-7  ">
                <div className="row mt-1"  style={{marginLeft:"15px"}}>
                  {vehicleInfo?.documents_validated === true
                    ? vehicleInfo?.province
                    : "-"}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-5">
                <Label
                  className="floatRight"
                  labelText={
                    locale === "en"
                      ? "Current Licence Status"
                      : locale === "si"
                        ? "වර්තමාන බලපත්‍රයේ තත්ත්වය"
                        : "தற்போதைய அனுமதிப் பத்திரத்தின் தகுதிநிலை"
                  }
                />
              </div>

              <div className=" col-7 ">
                <div className="row mt-1"  style={{marginLeft:"15px"}}>
                  <div className="pl-1">
                    <Badge
                      text={vehicleInfo?.current_licence_status === "RE"
                        ? "Renewable"
                        : vehicleInfo?.current_licence_status === "EX"
                          ? "Expired"
                          : vehicleInfo?.current_licence_status === "VA"
                            ? "Valid"
                            : vehicleInfo?.current_licence_status === "NL"
                              ? "No Current Licence"
                              : "N/A"
                      }
                      style={
                        vehicleInfo?.current_licence_status === "RE"
                          ? "primaryBadge"
                          : vehicleInfo?.current_licence_status === "EX"
                            ? "warningBadge"
                            : vehicleInfo?.current_licence_status === "VA"
                              ? "successBadge"
                              : vehicleInfo?.current_licence_status === "NL"
                                ? "NoBadge"
                                : "defaultBadge"
                      }
                    />
                  </div>
                  <div className="pl-2">
                    <RoundedIconButton
                      icon={"?"}
                      tooltip={"Details"}
                      onClickFn={() => setOpenStatus(true)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {openStatus && (
              <Popup
                content={<CurrentLicenceStatusPopUp />}
                handleClose={() => setOpenStatus(false)}
                title={
                  locale === "en"
                    ? "Current Licence Status"
                    : locale === "si"
                      ? "වර්තමාන බලපත්‍රයේ තත්ත්වය"
                      : "தற்போதைய அனுமதிப் பத்திரத்தின் தகுதி நிலை"
                }
              />
            )}

            <div className="row mb-3">
              <div className="col-md-5">
                <Label
                  className="floatRight"
                  labelText={
                    locale === "en"
                      ? "Current Licence Expiry Date"
                      : locale === "si"
                        ? "වර්තමාන බලපත්‍රය කල් ඉකුත්වීමේ දිනය"
                        : "தற்போதைய அனுமதிப் பத்திரம் காலாவதியாகும் திகதி"
                  }
                />
              </div>
              <div className="col-7 ">
                <div className="row mt-1"  style={{marginLeft:"15px"}}>
                  {vehicleInfo?.current_licence_expiry_date
                    ? dateFormat(
                      vehicleInfo?.current_licence_expiry_date,
                      "dd/mm/yyyy"
                    )
                    : "N/A"}
                </div>
              </div>
            </div>

            {vehicleInfo?.insurance_company_name !== undefined &&
              vehicleInfo?.insurance_status !== undefined ? (
              <div className="row mb-3">
                <div className="col-md-5">
                  <Label
                    className="floatRight"
                    labelText={
                      locale === "en"
                        ? "Insurance Certificate"
                        : locale === "si"
                          ? "රක්ෂණ සහතිකය"
                          : "காப்புறுதி சான்றிதழ்"
                    }
                  />
                </div>
                <div className=" col-md-7 ">
                  <div className="row mt-1"  style={{marginLeft:"15px"}}>
                    <div className="pl-1">
                      <Badge
                        text={
                          vehicleInfo?.insurance_status === true
                            ? "Valid"
                            : "Invalid"
                        }
                        style={
                          vehicleInfo?.insurance_status === true
                            ? "successBadge"
                            : "warningBadge"
                        }
                      />
                    </div>
                    <div className="pl-2">
                      {vehicleInfo?.insurance_status === true ? (
                        vehicleInfo?.insurance_company_name
                      ) : (
                        <p style={{ color: "red" }}>No valid insurance found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicleInfo?.vet_company_name !== undefined &&
              vehicleInfo?.vet_status !== undefined ? (
              <div className="row mb-3">
                <div className="col-md-5">
                  <Label
                    className="floatRight"
                    labelText={
                      locale === "en"
                        ? "Vehicle Emission Test Certificate"
                        : locale === "si"
                          ? "වාහන දුම් පරීක්ෂණ සහතිකය"
                          : "வாகன புகை பரிசோதனைச் சான்றிதழ்"
                    }
                  />
                </div>
                <div className=" col-md-7 ">
                  <div className="row mt-1"  style={{marginLeft:"15px"}}>
                    <div className="pl-1">
                      <Badge
                        text={
                          vehicleInfo?.vet_status === true ? "Valid" : "Invalid"
                        }
                        style={
                          vehicleInfo?.vet_status === true
                            ? "successBadge"
                            : "warningBadge"
                        }
                      />
                    </div>
                    <div className="pl-2">
                      {vehicleInfo?.vet_status === true ? (
                        vehicleInfo?.vet_company_name
                      ) : (
                        <p style={{ color: "red" }}>No valid VET found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {vehicleInfo?.vft_company_name !== undefined &&
              vehicleInfo?.vft_status !== undefined ? (
              <div className="row mb-3">
                <div className="col-md-5">
                  <Label
                    className="floatRight"
                    labelText={
                      locale === "en"
                        ? "Vehicle Fitness Certificate"
                        : locale === "si"
                          ? "වාහන යෝග්‍යතා සහතිකය"
                          : "வாகனத்தின் உறுதிநிலைச் சான்றிதழ்"
                    }
                  />
                </div>
                <div className=" col-md-7 ">
                  <div className="row mt-1"  style={{marginLeft:"15px"}}>
                    <div className="pl-1">
                      <Badge
                        text={
                          vehicleInfo?.vft_status === true ? "Valid" : "Invalid"
                        }
                        style={
                          vehicleInfo?.vft_status === true
                            ? "successBadge"
                            : "warningBadge"
                        }
                      />
                    </div>
                    <div className="pl-2">
                      {vehicleInfo?.vft_status === true ? (
                        vehicleInfo?.vft_company_name
                      ) : (
                        <p style={{ color: "red" }}>No valid VFT found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </UICard>
      </div>
      <div className="uiCardWrapper-new">
        <UICard
          title={
            vehicleInfo?.documents_validated === true &&
              vehicleInfo?.current_licence_status !== "VA"
              ? locale === "en"
                ? "New Revenue Licence Details"
                : locale === "si"
                  ? "නව ආදායම් බලපත්‍ර තොරතුරු"
                  : "புதிய வருமான அனுமதிப் பத்திர விபரங்கள்"
              : ""
          }
          cardStyle="mb-5"
          borderLess={true}
          titleStyle="mainTxtHeading "
        >
          <div className="col-md-9 mt-5">
            <div className="row">
              {vehicleInfo?.documents_validated === true &&
                vehicleInfo?.current_licence_status !== "VA" ? (
                <div className="col-md-6">
                  <Label
                    className="floatRight"
                    labelText={
                      locale === "en"
                        ? "New Revenue Licence Validity Period"
                        : locale === "si"
                          ? "නව ආදායම් බලපත්‍රයේ වලංගු කාලය"
                          : "புதிய வருமான அனுமதிப் பத்திரத்தின்  செல்லுபடிக் காலம்"
                    }
                  />
                </div>
              ) : (
                <div></div>
              )}
              {vehicleInfo?.documents_validated === true &&
                vehicleInfo?.current_licence_status !== "VA" ? (
                <div className="col-md-6 " style={{ paddingTop: "5px" }}>
                  <div className="row " style={screenType[0]==="MOBILE"?{marginLeft: "8px"}:{marginRight:"15px"}}>
                    <div className="col-3" >
                      <div className="row">
                        {locale === "en"
                          ? "From "
                          : locale === "si"
                            ? "සිට "
                            : "இலிருந்து"}
                      </div>
                    </div>
                    <div className="pl-3">
                      {dateFormat(
                        vehicleInfo?.new_lcn_valida_from,
                        "dd/mm/yyyy"
                      )}
                    </div>
                  </div>
                  <div className="row   mt-1" style={screenType[0]==="MOBILE"?{marginLeft: "8px"}:{marginRight:"15px"}}>
                    <div className="col-3">
                      <div className="row">
                        {locale === "en"
                          ? "To "
                          : locale === "si"
                            ? "දක්වා"
                            : "வரை"}
                      </div>

                    </div>

                    <div className="pl-3">
                      {dateFormat(vehicleInfo?.new_lcn_valida_to, "dd/mm/yyyy")}
                    </div>
                  </div>

                </div>
              ) : (
                <div></div>
              )}
            </div>
            {/* <div className="row">
              <div className="col-6"></div>
              {vehicleInfo?.documents_validated === true &&
                vehicleInfo?.current_licence_status !== "VA" ? (
                <div className="row">
                  <div className="col-3">
                    <div className="row">
                      {locale === "en"
                        ? "To "
                        : locale === "si"
                          ? "දක්වා"
                          : "வரை"}
                    </div>

                  </div>

                  <div className="pl-3">
                    {dateFormat(vehicleInfo?.new_lcn_valida_to, "dd/mm/yyyy")}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div> */}

          </div>



          <div className=" d-flex justify-content-center" >

            {/* <Link to="/payment-licence"> */}
            {vehicleInfo?.documents_validated === true &&
              vehicleInfo?.current_licence_status !== "VA" ? (
              <div className="paddingTop">
                <Button
                  btnStyle="btnPrimary marginRight"
                  btnName="confirm"
                  btnText={
                    locale === "en"
                      ? "Confirm"
                      : locale === "si"
                        ? "තහවුරු කරන්න"
                        : "உறுதிப்படுத்துதல்"
                  }
                  onClick={() => onConfirmedClick()}
                />
              </div>
            ) : (
              <div className="paddingTop">
                <Button
                  btnStyle="disabledPrimary marginRight paddingTop"
                  btnName="confirm"
                  btnText={
                    locale === "en"
                      ? "Confirm"
                      : locale === "si"
                        ? "තහවුරු කරන්න"
                        : "உறுதிப்படுத்துதல்"
                  }
                  onClick={() => { }}
                />
              </div>
            )}
            {/* </Link> */}
            <div className="paddingTop">
              <Button
                btnStyle={`btnOutlinePrimary ${screenType[0] !== "MOBILE" ? "marginRight" : ""}  paddingTop`}
                btnName="cancel"
                btnText={
                  locale === "en"
                    ? "Cancel"
                    : locale === "si"
                      ? "අවලංගු කරන්න"
                      : "இரத்துச் செய்தல்."
                }
                onClick={() => onCancelClicked()}
              />
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>


        </UICard>
      </div>
    </div>
  );
};

export { ConfirmDetails };
