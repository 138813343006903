/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-26 15:07:57
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-26 15:15:07
 */

const Badge = ({ text = "", style = "defaultBadge" }) => {
  return <span className={"badge " + style}>{text}</span>;
};

export { Badge };
