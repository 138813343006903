/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-13 11:52:01
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:56:01
 */

import React, { Fragment } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

//import routers
import ErrorBoundary from "../components/ui-components/ui-elements/common/ErrorBoundary";
import DisplayError from "../components/modules/error-boundary/pages/DisplayError";
import ErrorBoundaryRoutes from "../components/modules/error-boundary/ErrorBoundary.routes";
import HomeRoutes from "../components/modules/home/Home.routes";
import TestComponentRoutes from "../components/modules/test-components/TestComponent.routes";
import UserProfileRoutes from "../components/modules/user-profile/UserProfile.routes";
import StatusCheckRoutes from "../components/modules/status-check/StatusCheck.routes";
import RevenueLicenceRoutes from "../components/modules/revenue-licence/RevenueLicence.routes";
import AuthRoutes from "../components/modules/auth/Auth.routes";
import QuickLinkRoutes from "../components/modules/quick-links/QuickLinkRoutes"

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Fragment>
          <ErrorBoundary displayComponent={DisplayError}>
            <HomeRoutes />
            <AuthRoutes />
            <QuickLinkRoutes />
            <ErrorBoundaryRoutes />
            <TestComponentRoutes />
            <UserProfileRoutes />
            <StatusCheckRoutes />
            <RevenueLicenceRoutes />
          </ErrorBoundary>
        </Fragment>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
