/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-27 12:39:33
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:35:24
 */
import React, { useState } from "react";
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import {
  Button,
} from "../../../ui-components/ui-elements/form/Button";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import ConfirmYourEmailPopUp from "./includes/ConfirmYourEmailPopUp";
import PasswordPolicyPopUp from "../reset-password/includes/PasswordPolicyPopUp";
import { Link } from "react-router-dom";

const Register = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  return (
    <Template>
      <UICard
        title={"Register"}
        titlePlacement={cardTitlePlacement.CENTER}
        cardSize={cardSize.MEDIUM}
        isCardCenter={true}
      >
        <div className="row authFormWrapper">
          <div className="col-md-6 columnBoder">
            <InputBox
              labelText="Email"
              inputId="email"
              inputName="email"
              inputType="text"
              labelPlacement={labelPositions.TOP}
              inputError=""
              isRequired={true}
              isDisabled={false}
            />
            <InputBox
              labelText="Password"
              inputId="password"
              inputName="password"
              inputType="password"
              labelPlacement={labelPositions.TOP}
              inputError=""
              isRequired={true}
              isDisabled={false}
              endIcon={<i className="mdi mdi-help" />}
              endIconOnClickFn={() => setOpen(true)}
            />
            {open && (
              <Popup
                title={"Password Policy"}
                content={<PasswordPolicyPopUp />}
                isOpen={open}
                handleClose={() => setOpen(false)}
              />
            )}
            <InputBox
              labelText="Confirm Password"
              inputId="confirm"
              inputName="confirm"
              inputType="password"
              labelPlacement={labelPositions.TOP}
              inputError=""
              isRequired={true}
              isDisabled={false}
            />
            <div className="col-md-12 contentCenter">
              <div className="floatRight">
                <Button
                  btnText={"Proceed"}
                  btnType={"submit"}
                  onClick={() => setOpen2(true)}
                />
                {open2 && (
                  <Popup
                    title={"Confirm Your Email"}
                    content={<ConfirmYourEmailPopUp />}
                    isOpen={open2}
                    handleClose={() => setOpen2(false)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 contentCenter">
            <div className="textCenter">
              <p>Create an account using your email address</p>
              <p>OR</p>
              <Link to="">
                <Button
                  btnName={"facebook"}
                  btnText={"Login with Facebook"}
                  btnType={"button"}
                  btnStyle={"facebookSignin"}
                />
              </Link>
              <Link to="">
                <Button
                  btnName={"google"}
                  btnText={"Login with Google"}
                  btnType={"button"}
                  btnStyle={"googleSignin"}
                />
              </Link>
              <p>Already have an account?</p>
              <Link to="login">
                <Button
                  btnName={"signup"}
                  btnText={"Register with your email"}
                  btnType={"button"}
                  btnStyle={"emailSignin"}
                />
              </Link>
            </div>
          </div>
        </div>
      </UICard>
    </Template>
  );
};

export default Register;
