/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-01 16:38:14
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-05 12:18:06
 */

const TableHead = ({
  tableHeaderList = [],
  isSetStatus = false,
  statusIcon = "",
}) => {
  const tableHead = (
    <tr>
      {tableHeaderList.map((data) => {
        if (isSetStatus && (data.key === "badge" || data.key === "status"))
          return (
            <th style={{ textAlign: "left", width: "20%" }}>
              {data.displayName} {statusIcon}
            </th>
          );
        else
          return (
            <th style={{ width: "20%", textAlign: "left" }}>
              {data.displayName}
            </th>
          );
      })}
    </tr>
  );

  return <thead>{tableHead}</thead>;
};

export { TableHead };
