/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2021-01-18 11:28:45
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-02 15:26:24
 */

import React from "react";
import { Footer } from "./common-includes/Footer";
import { TemplateBodyWrapper } from "./template-includes/TemplateBodyWrapper";
import { TemplateHeaderWrapper } from "./template-includes/TemplateHeaderWrapper";

const Template = ({ activeHeader = "", children = null, selectedLan = "" }) => {
  return (
    <div className="erlWebMainWrapper">
      <TemplateHeaderWrapper
        activeHeader={activeHeader}
        selectedLan={selectedLan}
      />
      <TemplateBodyWrapper children={children} />
      <Footer />
    </div>
  );
};

export default Template;
