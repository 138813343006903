/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:23:37
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:50:46
 */
import { SelectBox } from "../../../../ui-components/ui-elements/form/SelectBox";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../../atom";
import { labelPositions } from "../../../../ui-components/ui-elements/form/InputLabel";

const inputData = [
  {
    id: 1,
    value: "option 1",
  },
  {
    id: 2,
    value: "option 2",
  },
  {
    id: 3,
    value: "option 3",
  },
];

const LocateUsFilterComponent = ({
  provinceData,
  districtData,
  onProvinceSelected,
  onDistrictSelected,
  isDistrictDisabled,
}) => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <div className="row">
      <div className="col-6">
        <SelectBox
          labelText={
            locale === "en" ? "Province" : locale === "si" ? "පළාත" : "மாகாணம்"
          }
          labelPlacement={labelPositions.START}
          dataList={provinceData}
          isRightAlignLabel={false}
          labelColSize={3}
          emptySelectOptionTxt={
            locale === "en"
              ? "Select Province"
              : locale === "si"
              ? "පළාත තෝරන්න"
              : "மாகாணத்தைத் தேர்ந்தெடுக்கவும்"
          }
          keyName="code"
          valueName="name"
          onChangeFn={
            onProvinceSelected
           
          }
        />
      </div>

      <div className="col-6">
        <SelectBox
          labelPlacement={labelPositions.START}
          inputName={"District"}
          isRightAlignLabel={false}
          labelColSize={3}
          labelText={
            locale === "en"
              ? "District"
              : locale === "si"
              ? "දිස්ත්‍රික්කය"
              : "மாவட்டம்"
          }
          dataList={districtData}
          emptySelectOptionTxt={
            locale === "en"
              ? "Select District"
              : locale === "si"
              ? "දිස්ත්‍රික්කය තෝරන්න"
              : "மாவட்டத்தைத் தேர்ந்தெடுக்கவும்"
          }
          //emptySelectOptionTxt=""
          isDisabled={isDistrictDisabled}
          valueName="name"
          keyName="code"
          onChangeFn={onDistrictSelected}
        />
      </div>
    </div>
  );
};

export default LocateUsFilterComponent;
