import { useState, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { callApi } from "../../helpers/common-helpers/callApi.helpers";
import { contactUsAPI } from "../../config/apiUrl.config";
import { LanguageFilter } from "../../atom";

export const useContactUsHook = () => {
  const locale = useRecoilValue(LanguageFilter);

  const [contactUsData, setContactUsData] = useState();

  const getContactUs = useCallback(() => {
    callApi(`${contactUsAPI.url}?language=${locale}`)
      .method("get")
      .send((error, result) => {
        if (result) {
          // setContactUsData((prev) => ({
          //   ...result,
          //   prev: result,
          // }));
          setContactUsData(result.data.data);
          console.log("contactUsData", contactUsData[0].addresses[0]);
        }
      });
  }, [locale]);

  useEffect(() => {
    getContactUs();
  }, [getContactUs, locale]);

  return { contactUsData };
};
