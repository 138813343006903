/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2021-01-18 11:28:45
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-10 15:14:04
 */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UICard } from "../../ui-elements/common/BaseElements";
import { MenuPopup } from "../../ui-elements/Popup/Popup";
import { IntlProvider, FormattedMessage } from "react-intl";

import GovLogo from "../../../../style/img/logo/logo.png";
import ERLLogo from "../../../../style/img/logo/logo_8.png";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  Container,
  Row,
  Col,
  NavDropdown,
} from "react-bootstrap";

import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { authTokenStorageKey } from "../../../../config/core.config";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";
import {
  getFromLocalStorage,
  reomveFromLocalStorage,
} from "../../../../helpers/common-helpers/manageStorage.helpers";
import { useProfileInfoHook } from "../../../hooks/useProfileHook";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { logoutAPI } from "../../../../config/apiUrl.config";
import { useLogOutHook } from "../../../hooks/useLogOutHook";

const messages = {
  en: {
    home: "Home",
    revenueLicenceHead: "Revenue Licence",
    revenueLicence: "First/ Renew Licence",
    revenueLicenceTitle: "Revenue Licence",
    supportedCompanies: "Support Organizations",
    contactUs: "Contact Us",
    locateUs: "Locate Us",
    FAQs: "FAQs",
    login: "Login/Sign Up",
    checkStatus: "Check Status",
    logout: "Logout",
    changePassword: "Change Password",
    profile: "K",
  },

  si: {
    home: "මුල් පිටුව",
    revenueLicenceHead: "ආදායම් බලපත්‍රය",
    revenueLicence: "ප්‍රථම බලපත්‍රය /ආදායම් බලපත්‍රය අලුත් කිරීම",
    supportedCompanies: "සහායක ආයතන",
    contactUs: "අපව අමතන්න",
    locateUs: "අප ආයතනයේ පිහිටීම",
    FAQs: "නිතර යොමු කරන ප්‍රශ්න",
    login: "පිවිසෙන්න/පූරණය වන්න",
    checkStatus: "වර්තමාන තත්ත්වය පරීක්ෂා කරන්න",
    changePassword: "මුරපදය වෙනස් කරන්න",
    logout: "ඉවත් වන්න",
    profile: "K",
  },
  ta: {
    home: "முகப்பு பக்கம்",
    revenueLicenceHead: "வருமான அனுமதி பத்திரம்",
    revenueLicence: "முதலாவது / புதிய வருமான உத்தரவுப் பத்திரம்",
    supportedCompanies: "துணை நிறுவனங்கள்",
    contactUs: "எம்முடன் தொடர்பு கொள்ள",
    locateUs: "எங்களைக் கண்டறிய",
    FAQs: "அடிக்கடி கேட்கப்படும் வினாக்கள்",
    login: "உள் நுழைதல் / பதிவு செய்தல்",
    checkStatus: "தகுதிநிலை செவ்வை பார்த்தல்",
    changePassword: "கடவுச்சொல் மாற்றம்",
    logout: "வெளியேறல்",
    profile: "K",
  },
};

const MenuHeader = ({ activeHeader = "" }) => {
  const locale = useRecoilValue(LanguageFilter);
  const authStatus = useContext(AuthContext);

  const { logoutUser } = useLogOutHook();

  const handleLogout = () => {
    logoutUser({
      refresh_token: getFromLocalStorage(authTokenStorageKey)?.refreshToken,
    });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  return (
    <div className="menuHeaderWrapper" style={{ padding: 0 }}>
      <div className="erlContent">
        <div className="row">
          <div className="col-md-1 col-sm-3 logoWrapper">
            <img src={GovLogo} alt="Logo" />
          </div>
          <IntlProvider locale={locale} messages={messages[locale]}>
            <Navbar bg="" variant="dark" expand="xl">
              <Navbar.Brand href="#home"></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav " className="d-flex justify-content-center" >
                <Nav className="mr-4 d-flex justify-content-md-between">
                  <Nav.Link className= {`${activeHeader === "home"? "menuWrapper li active" :"menuWrapper li"}`} href="/">
                   
                      <span className="linkText">
                        <FormattedMessage
                          id="home"
                          value={{ locale }}
                        ></FormattedMessage>
                      </span>
                   
                  </Nav.Link>

                  <Dropdown>
                    <Nav.Link className="menuWrapper li">
                      <Dropdown.Toggle
                        className="toggle-div"
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <Link
                        // className={`${
                        //   activeHeader === "revenueLicence" && "active"
                        // }`}
                        >
                          <span className="linkText">
                            <FormattedMessage
                              id="revenueLicenceHead"
                              value={{ locale }}
                            />
                          </span>
                        </Link>
                      </Dropdown.Toggle>
                    </Nav.Link>

                    <Dropdown.Menu
                      style={{
                        backgroundColor: "darkblue",
                      }}
                    >
                      <Dropdown.Item className="erlWebHeaderMainWrapperText" href="revenue-licence">
                        {/* <Link
                          // className={`${
                          //   activeHeader === "revenueLicence" && "active"
                          // }`}
                          to="revenue-licence"
                        > */}
                          <span className="linkText">
                            <FormattedMessage
                              id="revenueLicence"
                              value={{ locale }}
                            />
                          </span>
                        {/* </Link> */}
                      </Dropdown.Item>
                      <Dropdown.Item className="erlWebHeaderMainWrapperText" href="status-check">
                        {/* <Link
                          // className={`${
                          //   activeHeader === "checkStatus" && "active"
                          // }`}
                          to="status-check"
                        > */}
                          <span className="linkText">
                            <FormattedMessage
                              id="checkStatus"
                              value={{ locale }}
                            />
                          </span>
                        {/* </Link> */}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Nav.Link className={`${activeHeader === "supportedCompanies"? "menuWrapper li active" :"menuWrapper li"}`} href="supported-companies">
                    {/* <Link
                      to="supported-companies"
                      // className={`${activeHeader === "supportedCompanies" && "active"  }` }
                    > */}
                      <span className="linkText">
                        <FormattedMessage
                          id="supportedCompanies"
                          value={{ locale }}
                        ></FormattedMessage>
                      </span>
                    {/* </Link> */}
                  </Nav.Link>
                  <Nav.Link className={`${activeHeader === "contactUs"? "menuWrapper li active" :"menuWrapper li"}`} href="contact-us">
                    {/* <Link
                      to="contact-us"
                      // className={`${activeHeader === "contactUs" && "active"}`}
                    > */}
                      <span className="linkText">
                        <FormattedMessage
                          id="contactUs"
                          value={{ locale }}
                        ></FormattedMessage>
                      </span>
                    {/* </Link> */}
                  </Nav.Link>
                  <Nav.Link className={`${activeHeader === "locateUs"? "menuWrapper li active" :"menuWrapper li"}`} href="locate-us">
                    {/* <Link
                      to="locate-us"
                      // className={`${activeHeader === "locateUs" && "active"}`}
                    > */}
                      <span className="linkText">
                        <FormattedMessage
                          id="locateUs"
                          value={{ locale }}
                        ></FormattedMessage>
                      </span>
                    {/* </Link> */}
                  </Nav.Link>
                  <Nav.Link className={`${activeHeader === "faq"? "menuWrapper li active" :"menuWrapper li"}`} href="Faq">
                    {/* <Link
                      to="Faq"
                      // className={`${activeHeader === "faq" && "active"}`}
                    > */}
                      <span className="linkText">
                        <FormattedMessage
                          id="FAQs"
                          value={{ locale }}
                        ></FormattedMessage>
                      </span>
                    {/* </Link> */}
                  </Nav.Link>
                  <div className="col-md-1 col-sm-1">
                    {authStatus[0].isAuthenticated === false ? (
                      <Nav.Link className="">
                        <Link to="/login">
                          <li
                            className={`loginBtnLi ${
                              activeHeader === "login" && "active"
                            }`}
                          >
                            {" "}
                            <div className="loginBtn">
                              <FormattedMessage
                                id="login"
                                value={{ locale }}
                              ></FormattedMessage>
                            </div>{" "}
                          </li>
                        </Link>
                      </Nav.Link>
                    ) : (
                      <div className="profileBtn">
                        <div className="logoWrapper">
                          <Dropdown className="">
                            <Dropdown.Toggle id="dropdown-autoclose-true">
                              {getFromLocalStorage(authTokenStorageKey) === null
                                ? ""
                                : getFromLocalStorage(authTokenStorageKey)
                                    .display_name.charAt(0)
                                    .toUpperCase()}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="profile-div">
                              <Dropdown.Header className="profile-header">
                                <Link to="profile">
                                  <div className="d-flex flex-row">
                                    
                                      <div className="profile-div-in">
                                        <Nav.Link className="menuWrapper ">
                                          <li
                                            className={`loginBtnLi ${
                                              activeHeader === "login" &&
                                              "active"
                                            }`}
                                          >
                                            <div className="loginBtn-new">
                                              {getFromLocalStorage(
                                                authTokenStorageKey
                                              ) === null
                                                ? ""
                                                : getFromLocalStorage(
                                                    authTokenStorageKey
                                                  )
                                                    .display_name.charAt(0)
                                                    .toUpperCase()}
                                            </div>
                                          </li>
                                        </Nav.Link>
                                      </div>
                                    
                                    <div className="floatRight profile-div-out">
                                      <div>
                                        {getFromLocalStorage(
                                          authTokenStorageKey
                                        ) === null
                                          ? ""
                                          : getFromLocalStorage(
                                              authTokenStorageKey
                                            ).display_name}
                                      </div>
                                      <div>
                                        {getFromLocalStorage(
                                          authTokenStorageKey
                                        ) === null
                                          ? ""
                                          : getFromLocalStorage(
                                              authTokenStorageKey
                                            ).email}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </Dropdown.Header>
                              <Dropdown.Divider />
                              {getFromLocalStorage(authTokenStorageKey)
                                ?.social_login === "0" ? (
                                <Dropdown.Item
                                  // href="/change-password"
                                  className="erlWebHeaderMainWrapperText"
                                >
                                  <Link
                                    to="change-password"
                                    className={`${
                                      activeHeader === "profile"
                                        ? "active"
                                        : "profile"
                                    }`}
                                  >
                                    <FormattedMessage
                                      id="changePassword"
                                      value={{ locale }}
                                    ></FormattedMessage>
                                  </Link>
                                </Dropdown.Item>
                              ) : null}
                              <Dropdown.Item
                                // href="/status-check"
                                className="erlWebHeaderMainWrapperText"
                              >
                                <Link
                                  to="status-check"
                                  className={`${
                                    activeHeader === "profile"
                                      ? "active"
                                      : "profile"
                                  }`}
                                >
                                  <FormattedMessage
                                    id="checkStatus"
                                    value={{ locale }}
                                  />
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => handleLogout()}
                                className="erlWebHeaderMainWrapperText"
                              >
                                <FormattedMessage
                                  id="logout"
                                  value={{ locale }}
                                ></FormattedMessage>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </IntlProvider>

          <div
            className="col-md-1 col-sm-3 logoWrapper"
            // style={{ padding: 10 }}
          >
            <img
              src={ERLLogo}
              alt="Logo"
              style={{
                paddingTop: 20,
                paddingRight: 20,
                paddingBottom: 20,
                height: 80,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { MenuHeader };
