/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-19 14:20:15
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-05 17:05:53
 */

const FormControlWrapper = ({
  elementWrapperStyle = "",
  isFullWidth = true,
  inputError = "",
  helperText = "",
  isMultiline = false,
  children = null,
  className = "",
}) => {
  return <div className={className}>{children}</div>;
};

const FormHelperTextWrapper = ({
  id = "",
  elementStyle = "",
  inputError = "",
  helperText = "",
}) => {
  return (
    <small
      id={id}
      className={
        "form-text " + inputError !== "" ? ".text-danger" : "text-muted"
      }
    >
      {inputError !== "" ? inputError : helperText}
    </small>
  );
};

export { FormHelperTextWrapper, FormControlWrapper };
