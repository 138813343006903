/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:23:37
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-02 15:20:07
 */
import { SelectBox } from "../../../../ui-components/ui-elements/form/SelectBox";
import { InputBox } from "../../../../ui-components/ui-elements/form/InputBox";

const inputData = [
  {
    id: 1,
    value: "Vehicle Registration Number",
    type: "vehicle_no",
  },
  {
    id: 2,
    value: "Reference Number",
    type: "licence_no",
  },
];

const StatusCheckFilterComponent = ({
  onTypeSelected,
  onVehicleNumberEntered,
  defaultText,
  inputId,
  labelText,
  inputBoxText,
  Error,
  maxCharLength,
}) => {
  return (
    <div
      // className="row"
      style={{
        // alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div className="checkStatusDropdown">
        {/* <div className="col-md-6" > */}
        <SelectBox
          labelText={labelText}
          dataList={inputData}
          // onChangeFn={(obj) => handleSelectedType(obj)}
          onChangeFn={onTypeSelected}
          // helperText={"Select"}
          keyName="type"
          isEmptySelection = {false}
          // emptySelectOptionTxt="Vehicle Registration Number"
        />
        {/* </div> */}
      </div>
      <div className="checkStatusInputbox">
        <InputBox
          inputId={inputId}
          labelText={inputBoxText}
          placeholder={defaultText}
          inputName="searchText"
          maxCharLength={maxCharLength}
          inputError={Error}
          // onChange={(text) => handleVehicleInput(text)}
          onChange={onVehicleNumberEntered}
        />
      </div>
    </div>
  );
};

export default StatusCheckFilterComponent;
