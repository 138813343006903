/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-01 16:37:38
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-01 17:08:03
 */
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { SelectBox } from "../../../ui-components/ui-elements/form/SelectBox";

const inputData = [
  {
    id: 1,
    value: "option 1",
  },
  {
    id: 2,
    value: "option 2",
  },
  {
    id: 3,
    value: "option 3",
  },
];

export const OnlineAppStatusFilterComponent = () => {
  return (
    <div className="row">
      <div className="col-md-7">
        <SelectBox
          labelText="Filter By"
          dataList={inputData}
          emptySelectOptionTxt="Vehicle Registration Number"
        />
      </div>
      <div className="col-md-5">
        <InputBox inputName="searchText" />
      </div>
      {/* <div className="col-md-2">
        <div className="row">
          <Button btnName="search" btnText="Search" />
          <Button btnName="reset" btnText="Reset" />
        </div>
      </div> */}
    </div>
  );
};

export const VftFilterComponent = () => {
  return (
    <div className="row">
      <div className="col-md-7">
        <SelectBox
          labelText="Province"
          dataList={inputData}
          emptySelectOptionTxt="All"
        />
      </div>
    </div>
  );
};

export const LocateUsFilterComponent = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <SelectBox
          labelText="Province"
          dataList={inputData}
          emptySelectOptionTxt="All"
        />
      </div>
      <div className="col-md-6">
        <SelectBox
          labelText="District"
          dataList={inputData}
          emptySelectOptionTxt="All"
        />
      </div>
    </div>
  );
};
