import React from "react";
import { Dropdown } from "react-bootstrap";
// import Carousel from "react-elastic-carousel";
import homeImage from "../../../../style/img/home.jpeg";
import * as _ from "lodash";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const NewsBanner = ({ data }) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  console.log("News",data.data);

  return (
    <div>
      <div>
        <div className="row marginNone">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="introCardWrapperNews">
              {/* <Carousel
                // breakPoints={breakPoints}
                showArrows={false}
                focusOnSelect={false}
                enableAutoPlay={true}
                autoPlaySpeed={5000}
              >
                {data.data.map((item) => (
                  <div>
                    <h6>
                      <b>{item.heading}</b>
                    </h6>
                    <hr color="#ffffff"></hr>
                    <h6>{item.content}</h6>
                  </div>
                ))}
              </Carousel> */}
              {data.data.length != 0 ? <Carousel
              className="carousel-style"
              showArrows={false}
              autoPlay={true}
              infiniteLoop={true}
              interval={5000}
             showStatus={false}
             selectedItem={0}
             showIndicators={true}
            //  dynamicHeight={true}
             stopOnHover={true}            
              >
                {data.data.map((item) => (
                  <div className="slider-item-div">
                    <h6>
                      <b>{item.heading}</b>
                    </h6>                  
                    <hr color="#ffffff"></hr>
                    <h6>{item.content}</h6>
                  </div>
                  ))
                }
              </Carousel>: <div className="slider-item-div"><h6>No news to display</h6></div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div>
    //   <Dropdown>
    //     <Dropdown.Toggle variant="danger" id="dropdown-basic" className="col-sm-8" >
    //     {/*<button class="button"><span>Hover </span></button>*/}
    //     News
    //     </Dropdown.Toggle>
    //     <Dropdown.Menu>
    //       {props.data.map((item) => (
    //             <div style={{ marginTop: 1 }}>
    //               <div className="erlContent erlMainIntroWrapper">
    //                 <div className="row marginNone">
    //                   <div className="col-md-12 col-sm-6 marginBottom">
    //                     <div className="introCardWrapperNews whiteBG">
    //                       <h6><b>{item.content}</b></h6>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           ))}
    //     </Dropdown.Menu>
    //   </Dropdown>
    // </div>
  );
};

export default NewsBanner;

