/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-29 16:15:37
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:50:09
 */
import React, { useState } from "react";
import { SelectBox } from "../../../ui-components/ui-elements/form/SelectBox";
import { CheckBox } from "../../../ui-components/ui-elements/form/CheckBox";
import { Form } from "../../../ui-components/ui-elements/form/FormWrapper";
import {
  Button,
  RoundedIconButton,
  toolTipPositions,
} from "../../../ui-components/ui-elements/form/Button";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { RadioButton } from "../../../ui-components/ui-elements/form/RadioButton";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import { Badge } from "../../../ui-components/ui-elements/form/Badge";
import {
  Accordion,
  AccordionWrapper,
} from "../../../ui-components/ui-elements/accordion/Accordion";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
  Image,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { TermsAndConditionsContent } from "../../quick-links/terms-and-conditions/TermsAndConditions";

const PopUpComponent = () => {
  const [collapse, setCollapse] = useState(false);
  return (
    <div>
      <UICard
        title={"Verify Your Mobile"}
        titlePlacement={cardTitlePlacement.LEFT}
        cardSize={cardSize.SMALL}
        isCardCenter={true}
      >
        <Form className="">
          <p>
            Verification code has been sent to the entered Email address. Plese
            check enter the sent verification code to continue.
          </p>
          <br></br>
          <div className="row">
            <div className="col-md-8">
              <InputBox
                inputId="otp"
                inputName="otp"
                labelText="OTP"
                helperText=""
                labelPlacement={labelPositions.START}
                isDisabled={false}
              />
            </div>
            <div className="col-md-4">
              <Button
                btnText={"Verify"}
                btnType={"submit"}
                isFullWidth={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p>Havent received OTP yet?</p>
            </div>
            <div className="col-md-4">
              <Button
                btnText={"Resend"}
                btnType={"button"}
                isFullWidth={true}
              />
            </div>
          </div>
        </Form>
      </UICard>
      
    <UICard
      title="Are you sure you want to cancel"
      titlePlacement={cardTitlePlacement.LEFT}
      cardSize={cardSize.SMALL}
      isCardCenter={true}
    >
       
      <Form>
       
        <div className="row ml-2">
          <Button
          btnText={"Yes"}
          btnType={"button"}
          />
            <div className="mr-2 pr-2">
              <Button
               btnText={"No"}
               btnType={"button"}
              />
            </div>

        </div>
       
      </Form>
      
    </UICard>

      <UICard
        title={"Password Policy"}
        titlePlacement={cardTitlePlacement.LEFT}
        cardSize={cardSize.SMALL}
        isCardCenter={true}
      >
        <Form className="">
          <p>
            Please make sure that your password is a strong password. It should
            meet the following requirements.
          </p>
          <br></br>
          <ul>
            <li>Password should be at least 8 characters long</li>
            <li>
              Should be a combination of alphanumeric (A-Z, a-z, 0-9)
              characters.
            </li>
            <li>Should include at least one special character.</li>
          </ul>
        </Form>
      </UICard>
      <UICard
        title={"Confirm Your Email"}
        titlePlacement={cardTitlePlacement.LEFT}
        cardSize={cardSize.SMALL}
        isCardCenter={true}
      >
        <Form className="">
          <p>
            An onetime password(OTP) has been sent to your mobile. Plese enter
            it below
          </p>
          <br></br>
          <div className="row">
            <div className="col-md-8">
              <InputBox
                inputId="otp"
                inputName="otp"
                labelText="OTP"
                helperText=""
                labelPlacement={labelPositions.START}
                isDisabled={false}
              />
            </div>
            <div className="col-md-4">
              <Button
                btnText={"Verify"}
                btnType={"submit"}
                isFullWidth={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p>Havent received OTP yet?</p>
            </div>
            <div className="col-md-4">
              <Button
                btnText={"Resend"}
                btnType={"button"}
                isFullWidth={true}
              />
            </div>
          </div>
        </Form>
      </UICard>
      <UICard
        title={"How to find the chassis number"}
        titlePlacement={cardTitlePlacement.LEFT}
        cardSize={cardSize.SMALL}
        isCardCenter={true}
      >
        <Form className="">
          <ul>
            <li>
              You can find the chassis number in the vehicle registration
              book/form
            </li>
            <li>
              Chassis number is required to ensure that you are the owner/valid
              representative of this vehicle.{" "}
            </li>
          </ul>
          <div>
            <Image src="images/Capture.PNG" />
          </div>
          <br></br>
        </Form>
      </UICard>
      <UICard
        title={"Current address of the owner"}
        titlePlacement={cardTitlePlacement.LEFT}
        cardSize={cardSize.SMALL}
        isCardCenter={true}
      >
        <Form className="">
          <ul>
            <li>
              Revenue Licence will be posted to the displayed address which is
              currently registered with the Department of Motor Traffic. (DMT)
            </li>
            <li>
              If the mentioned address is not your postal address, please visit
              the nearest Revenue Licence Issuing Center to obtain the revenue
              licence.
            </li>
          </ul>
        </Form>
      </UICard>
      <div>
        <TermsAndConditionsContent />
      </div>
      <UICard
        title={"Online application status"}
        titlePlacement={cardTitlePlacement.LEFT}
        cardSize={cardSize.SMALL}
        isCardCenter={true}
      >
        <div className="row mt-4">
          <div className="col-md-3">
            <Badge text="Issued" style={"successBadge float-right"} />
          </div>
          <div className="col-md-9">
            <p>Revenue Licence is printed and posted</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <Badge text="Pending" style={"defaultBadge float-right"} />
          </div>
          <div className="col-md-9">
            <p>Revenue Licence is printing is still in progress</p>
          </div>
        </div>
      </UICard>
      <UICard
        title={"Current licence status"}
        titlePlacement={cardTitlePlacement.LEFT}
        cardSize={cardSize.SMALL}
        isCardCenter={true}
      >
        <div className="row mt-4">
          <div className="col-md-3">
            <Badge text="Expired" style={"warningBadge float-right"} />
          </div>
          <div className="col-md-9">
            <p>Revenue Licence is expired</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <Badge text="Renewable" style={"successBadge float-right"} />
          </div>
          <div className="col-md-9">
            <p>The Licence is within the renewable period</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <Badge text="Valid" style={"primaryBadge float-right"} />
          </div>
          <div className="col-md-9">
            <p>Valid Revenue Licence</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <Badge text="No current Licence" style={"defaultBadge float-right"} />
          </div>
          <div className="col-md-9">
            <p>No current Licence record exists</p>
          </div>
        </div>
      </UICard>
    </div>
  );
};

export default PopUpComponent;
