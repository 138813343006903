import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { CheckBox } from "../../../ui-components/ui-elements/form/CheckBox";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { SelectBox } from "../../../ui-components/ui-elements/form/SelectBox";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import Template from "../../../ui-components/templates/Template";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import { TermsAndConditionsPopUp } from "../../quick-links/terms-and-conditions/TermsAndConditions";
import { Link } from "react-router-dom";
import { Label } from "../../../ui-components/ui-elements/form/Label";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  ChassisNumber,
  VehicleNumber,
  VehicleInfo,
  paymentCompleteUrl,
  LanguageFilter,
  MobileNumber,
} from "../../../../atom";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../../../helpers/common-helpers/manageStorage.helpers";
import { useRevenueLicenseHook } from "../../../hooks/useRevenueLicenseHook";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormattedMessage, IntlProvider } from "react-intl";
import _ from "lodash";
import {
  authTokenStorageKey,
  userProfileStorageKey,
} from "../../../../config/core.config";
import { useScreenType } from "../../../hooks/common-hooks/useScreenStatus.hook";

const messages = {
  en: {
    title: "Contact Information",
    contactNumber: "Contact Number ",
    sendSMS: "Send SMS alerts",
    deliveryInfo: "Delivery Information",
    deliveryMethod: "Delivery Method",
    deliveryFee: "Delivery Fee (LKR)",
    paymentDetails: "Payment Details",
    licenseFee: "Licence Fee (LKR)",
    licenseFine: "Licence Fine (LKR)",
    arrearsFee: "Arrears Fee (LKR)",
    arrearsFine: "Arrears Fine (LKR)",
    additions: "Additions (LKR)",
    deductions: "Deductions (LKR)",
    total: "Total (LKR)",
    convenienceMsg: "* Convenience fee will be charged for the online payment",
    paymentMethod: "Payment Method",
    paymentFee: "Payment Fee (LKR)",
    continueToPayMsg:
      "Upon clicking the ‘Continue to Pay’ button, you agree to our Terms and Conditions",
    terms: "Terms & Conditions",
    continueToPay: "Continue to Pay",
    back: "Back",
  },
  si: {
    title: "සම්බන්ධ කර ගැනීමේ තොරතුරු",
    contactNumber: "සම්බන්ධ කර ගත හැකි දුරකථන අංකය ",
    sendSMS: "කෙටි පණිවිඩ ඇඟවීමේ යවන්න",
    deliveryInfo: "බෙදාහැරීමේ තොරතුරු",
    deliveryMethod: "බෙදාහැරීමේ ක්‍රමය",
    deliveryFee: "බෙදාහැරීමේ ගාස්තු (ලංකා රුපියල්)",
    paymentDetails: "ගෙවීම් සිදු කිරීමේ තොරතුරු",
    licenseFee: "බලපත්‍ර ගාස්තුව (ලංකා රුපියල්)",
    licenseFine: "බලපත්‍ර දඩ (ලංකා රුපියල්)",
    arrearsFee: "හිඟ මුදල් (ලංකා රුපියල්)",
    arrearsFine: "හිඟ දඩ මුදල් (ලංකා රුපියල්)",
    additions: "එකතුකිරීම් (ලංකා රුපියල්)",
    deductions: "අඩුකිරීම් (ලංකා රුපියල්)",
    total: "එකතුව (ලංකා රුපියල්)",
    convenienceMsg: "* මාර්ගගත ගෙවීම් සඳහා පහසුකම් ගාස්තුවක් අයකරනු ලැබේ.",
    paymentMethod: "ගෙවීම් සිදු කිරීමේ ක්‍රමය",
    paymentFee: "ගෙවීම් ගාස්තුව (ලංකා රුපියල්)",
    continueToPayMsg:
      "‘ගෙවීම ඉදිරියට සිදු කරන්න’ බොත්තම ක්ලික් කිරීම තුලින් අපගේ නියමයන් හා කොන්දේසිවලට ඔබ එකඟ වේ.",
    terms: "නියමයන් හා කොන්දේසි",
    continueToPay: "ගෙවීම ඉදිරියට සිදු කරන්න",
    back: "ආපසු",
  },
  ta: {
    title: "தொடர்பு தகவல்",
    contactNumber: "தொடர்பு இலக்கம்",
    sendSMS: "குறுந் தகவல் விழிப்பூட்டலடகளை அனுப்புக",
    deliveryInfo: "வழங்கல் தகவல்",
    deliveryMethod: "வழங்கல் முறை",
    deliveryFee: "வழங்கல் கட்டணம் (ரூபா.)",
    paymentDetails: "கொடுப்பனவு விபரங்கள்",
    licenseFee: "அனுமதிப் பத்திர கட்டணம் (ரூபா.)",
    licenseFine: "அனுமதிப் பத்திர தண்டப்பணம் (ரூபா.)",
    arrearsFee: "நிலுவைக் கட்டணம் (ரூபா.)",
    arrearsFine: "நிலுவை தண்டப்பணம் (ரூபா.)",
    additions: "சேர்க்கைகள் (ரூபா.)",
    deductions: "கழிப்பனவுகள் (ரூபா.)",
    total: "மொத்தம் (ரூபா)",
    convenienceMsg:
      "* இணையவழி மூலமான கொடுப்பனவுகளுக்கு சௌகரியக் கட்டணம்அறவீடு செய்யப்படும்.",
    paymentMethod: "கொடுப்பனவு முறை",
    paymentFee: "கொடுப்பனவு கட்டணம் (ரூபா.)",
    continueToPayMsg:
      "“செலுத்துவதற்குத் தொடரவும்” பொத்தானை கிளிக் செய்வதனூடாக எமது நியதிகள் மற்றும் நிபந்தனைகளினை நீங்கள் ஏற்றுக் கொள்கின்றீர்கள்.",
    continueToPay: "செலுத்துவதற்குத் தொடரவும்",
    back: "திரும்ப செல்",
  },
};

const PaymentInformation = () => {
  const locale = useRecoilValue(LanguageFilter);
  const history = useHistory();

  const vehicleNum = useRecoilValue(VehicleNumber);
  const chassiNum = useRecoilValue(ChassisNumber);
  const vehicleDetails = useRecoilValue(VehicleInfo);
  const [contactNumberState, setContactNumberState] = useRecoilState(MobileNumber);
  const [open, setOpen] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [selectedDelivery, setSelectedDelivery] = useState({});
  const [selectedPayment, setSelectedPayment] = useState({});
  const { chargeInfo, getChargeInfo, deliveryInfo, paymentInfo, makePayment } =
    useRevenueLicenseHook();

  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-z A-Z]/;

  useEffect(() => {
    setContactNumber(getFromLocalStorage(userProfileStorageKey)?.phone);
  }, [getFromLocalStorage(userProfileStorageKey)?.phone]);

  useEffect(() => {
    if (vehicleDetails !== undefined) {
      getChargeInfo(vehicleNum, vehicleDetails.temp_licence_reference);
    }
  }, [vehicleDetails]);

  const notify = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      //closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const handleMobileNumber = (text) => {
    document.getElementById("txtMobile").addEventListener("keyup", function () {
      if (this.value !== 0 && this.value.charAt(0) === "0") {
        this.value = this.value.slice(1);
        setContactNumber(this.value);
        setContactNumberState(this.value);
      } else {
        setContactNumber(this.value);
        setContactNumberState(this.value);
      }
    });
  };

  const handlePayment = () => {
    if (_.isEmpty(contactNumber)) {
      notify("Mobile Number is required.");
    } else if (contactNumber.length !== 9) {
      notify("Invalid Mobile Number. Please enter a valid Mobile Number.");
      // notify("Mobile Number is required.");
    } else if (_.isEmpty(selectedDelivery)) {
      notify(
        locale === "en"
          ? "Delivery Method is Required"
          : locale === "si"
            ? "බෙදා හැරීමේ ක්‍රමය අවශ්‍ය වේ"
            : "விநியோக முறை தேவை"
      );
    } else if (_.isEmpty(selectedPayment)) {
      notify(
        locale === "en"
          ? "Payment Method is Required"
          : locale === "si"
            ? "ගෙවීමේ ක්‍රමය අවශ්‍ය වේ"
            : "கட்டண முறை தேவை"
      );
    } else {
      if (!format.test(contactNumber)) {
        makePayment(
          {
            vehicle_no: vehicleNum,
            temp_licence_reference: vehicleDetails.temp_licence_reference,
            delivery_method_id: selectedDelivery.id,
            payment_method_code: selectedPayment.code,
            // payment_method_code: "SMV",
            contact_number: contactNumber,
            send_sms: true,
          },
          selectedPayment.code
        );

        history.push("/payment-complete");
      } else {
        notify("Invalid Mobile Number. Please enter a valid Mobile Number.");
        // notify("Mobile Number is required.");
      }
    }
  };

  const handleDeliverySelect = (id) => {
    deliveryInfo.map((data) => {
      if (data.id === id) {
        setSelectedDelivery(data);
      }
    });
  };

  const handlePaymentSelect = (id) => {
    paymentInfo.map((data) => {
      if (data.id === id) {
        setSelectedPayment(data);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let screenType = useScreenType()
  return (
    <Fragment>
      <Template activeHeader="paymentInformation" selectedLan={locale}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <div className="uiCardWrapper-new payment-info">
            <UICard
              borderLess={true}
              title={<FormattedMessage id="title" value={{ locale }} />}
              titleStyle="mainTxtHeading"
            >
              {/* <div className="row pl-3">
                <div className="col-md-9 col-sm-12 text-danger"> */}
              {/* <InputBox
                    inputId="txtMobile"
                    labelText={
                      <div className="text-dark pl-2 ml-1 mr-1">
                        <FormattedMessage
                          id="contactNumber"
                          value={{ locale }}
                        />
                      </div>
                    }
                    labelColSize={5}
                    startText={"0094"}
                    onChange={(text) => handleMobileNumber(text)}
                    isRequired={true}
                    isRightAlignLabel={false}
                    placeholder={contactNum?contactNum:""}
                  /> */}
              <div className="row">
                <div className="col-md-2 col-sm-12">
                  <div
                    className="row"
                    style={{ marginLeft: "1.5%" }}
                  >

                    <div>
                      <Label
                        labelText={
                          <div
                            className="text-dark pl-2 ml-1 mr-1"
                            style={{ marginTop: "7.25%" }}
                          >
                            <FormattedMessage
                              id="contactNumber"
                              value={{ locale }}
                            />
                          </div>
                        }
                      />
                    </div>

                    <div
                      className="text-danger"
                      style={{ marginTop: "2.5%", marginLeft: "5%" }}
                    >
                      *
                    </div>
                  </div>
                </div>
                <div className="col-md-10 col-sm-12">
                  <div
                    className="input-group-prepend"
                    style={{ marginTop: "1%" }}
                  >
                    <span
                      class="input-group-text "
                      style={screenType[0] !== "MOBILE" ? { height: 38, marginLeft: "18%" } : { height: 38, marginLeft: "0%" }}
                    >
                      {"0094"}
                    </span>
                    <input
                      maxLength={9}
                      style={screenType[0] !== "MOBILE" ? { width: "43%" } : { width: "100%" }}
                      className="form-control form-group"
                      id="txtMobile"
                      name={
                        <div className="text-dark">
                          {locale === "en"
                            ? "Mobile Number "
                            : locale === "si"
                              ? "ජංගම දුරකථන අංකය"
                              : "கையடக்க தொலைபேசி இலக்கம்"}{" "}
                        </div>
                      }
                      defaultValue={contactNumber ? contactNumber : ""}
                      // value={contactNumber}
                      onChange={(text) => handleMobileNumber(text)}
                    />
                  </div>
                  <CheckBox
                    labelPlacement={labelPositions.END}
                    labelText={
                      <div className="text-dark "
                      >
                        <FormattedMessage id="sendSMS" value={{ locale }} />
                      </div>
                    }
                  />
                </div>



              </div>

              {/* </div>
              </div> */}

             
            </UICard>
          </div>
          <div className="uiCardWrapper-new">
            <UICard
              borderLess={true}
              title={<FormattedMessage id="deliveryInfo" value={{ locale }} />}
              titleStyle="mainTxtHeading "
            >
              <div className="row">
                <div className="col-md-9 col-sm-12 text-danger">
                  <SelectBox
                    labelText={
                      <div className="text-dark pl-2 ml-1 mr-1">
                        <FormattedMessage
                          id="deliveryMethod"
                          value={{ locale }}
                        />
                      </div>
                    }
                    labelColSize={5}
                    isRightAlignLabel={false}
                    keyName="id"
                    dataList={
                      deliveryInfo
                        ? deliveryInfo.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )
                        : []
                    }
                    onChangeFn={(obj) => handleDeliverySelect(obj.value)}
                    valueName={"name"}
                    isRequired={true}
                  />
                </div>
              </div>

              <div className="col-md-11 ml-1 pl-2">
                <div className="row">
                  <div className={" col-md-4"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage
                            id="deliveryFee"
                            value={{ locale }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-md-7 ">
                    {selectedDelivery?.fee !== undefined
                      ? selectedDelivery?.fee
                      : "0.00"}
                  </div>
                </div>
              </div>
            </UICard>
          </div>
          <div className="uiCardWrapper-new">
            <UICard
              borderLess={true}
              title={
                <FormattedMessage id="paymentDetails" value={{ locale }} />
              }
              titleStyle="mainTxtHeading "
            >
              {/* <div className="row pl-2 ml-1"> */}
              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-8"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage
                            id="licenseFee"
                            value={{ locale }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Label
                      className="textFloat"
                      labelText={
                        chargeInfo !== undefined
                          ? numberWithCommas(chargeInfo?.lcn_fee)
                          : "0.00"
                      }
                    />
                    {/* {chargeInfo !== undefined ? chargeInfo?.lcn_fee : "0.00"} */}

                    {/* <Label
                        className="textFloat"
                        labelText= {chargeInfo !== undefined ? chargeInfo?.lcn_fee : "0.00"}
                      /> */}
                  </div>
                </div>
              </div>
              {/* </div> */}

              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-8"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage
                            id="licenseFine"
                            value={{ locale }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Label
                      className="textFloat"
                      labelText={
                        chargeInfo !== undefined
                          ? numberWithCommas(chargeInfo?.lcn_fine)
                          : "0.00"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-8"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage
                            id="arrearsFee"
                            value={{ locale }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Label
                      className="textFloat"
                      labelText={
                        chargeInfo !== undefined
                          ? numberWithCommas(chargeInfo?.arrears_fee)
                          : "0.00"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-8"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage
                            id="arrearsFine"
                            value={{ locale }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Label
                      className="textFloat"
                      labelText={
                        chargeInfo !== undefined
                          ? numberWithCommas(chargeInfo?.arrears_fine)
                          : "0.00"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-8"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage
                            id="deliveryFee"
                            value={{ locale }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Label
                      className="textFloat"
                      labelText={
                        selectedDelivery?.fee !== undefined
                          ? numberWithCommas(selectedDelivery?.fee)
                          : "0.00"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-8"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage id="additions" value={{ locale }} />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Label
                      className="textFloat"
                      labelText={
                        chargeInfo?.additions !== undefined
                          ? numberWithCommas(chargeInfo?.additions)
                          : "0.00"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-8"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage
                            id="deductions"
                            value={{ locale }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Label
                      className="textFloat"
                      labelText={
                        chargeInfo?.deductions !== undefined
                          ? numberWithCommas(chargeInfo?.deductions)
                          : "0.00"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-8"}>
                    <Label
                      className="floatRight"
                      isBold={true}
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage id="total" value={{ locale }} />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-3">
                    {/* {chargeInfo !== undefined ? chargeInfo.total : "N/A"} */}
                    <Label
                      className="textFloat"
                      labelText={
                        selectedDelivery?.fee !== undefined &&
                          chargeInfo !== undefined
                          ? numberWithCommas(
                            (
                              parseFloat(chargeInfo.total) +
                              parseFloat(selectedDelivery?.fee)
                            ).toFixed(2)
                          )
                          : selectedDelivery?.fee === undefined &&
                            chargeInfo !== undefined
                            ? numberWithCommas(chargeInfo.total)
                            : "0.00"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={"col-md-11 ml-1 pl-2"}>
                <div className="row">
                  <div className={"col-md-4"}></div>
                  <div className="col-md-7">
                    <div className="redLabel">
                      <FormattedMessage
                        id="convenienceMsg"
                        value={{ locale }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </UICard>
          </div>
          <div className="uiCardWrapper-new">
            <UICard
              borderLess={true}
              title={<FormattedMessage id="paymentMethod" value={{ locale }} />}
              titleStyle="mainTxtHeading "
              cardStyle={"mb-5"}
            >
              <div className="row">
                <div className={"col-md-9 col-sm-12 text-danger"}>
                  <SelectBox
                    labelText={
                      <div className="text-dark pl-2 ml-1 mr-1">
                        <FormattedMessage
                          id="paymentMethod"
                          value={{ locale }}
                        />
                      </div>
                    }
                    isRequired={true}
                    labelColSize={5}
                    isRightAlignLabel={false}
                    onChangeFn={(obj) => handlePaymentSelect(obj.value)}
                    keyName="id"
                    dataList={
                      paymentInfo
                        ? paymentInfo.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )
                        : []
                    }
                    valueName={"name"}
                  />
                </div>
              </div>

              {/* <div className="row">
              <div className={"col-md-9 col-sm-12"}>
                <div className="row">
                  <div className={"col-5"}>
                    <Label
                      className="floatRight"
                      labelText={
                        <div className="text-dark">
                          <FormattedMessage
                            id="paymentFee"
                            value={{ locale }}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="col-7">
                    {selectedPayment.fee !== undefined
                      ? selectedPayment.fee
                      : "0.00"}
                  </div>
                </div>
              </div>
            </div> */}

              <div className="row">
                <div className={"col-md-11 col-sm-12"}>
                  <div className="row floatRight">
                    <div className="col-md-9 col-sm-12">
                      <FormattedMessage
                        id="continueToPayMsg"
                        value={{ locale }}
                      />
                      <Link
                        className="text-primary"
                        onClick={() => setOpen(true)}
                      >
                        <FormattedMessage id="terms" value={{ locale }} />
                      </Link>
                    </div>
                  </div>
                </div>

                {open && (
                  <Popup
                    content={<TermsAndConditionsPopUp />}
                    handleClose={() => setOpen(false)}
                    title={<FormattedMessage id="terms" value={{ locale }} />}
                  />
                )}

                <div className="col-md-7 col-sm-10 ml-4">
                  <div className="row floatRight">
                    <div className="paddingTop">
                      <Button
                        btnText={
                          locale === "en"
                            ? "Continue to Pay"
                            : locale === "si"
                              ? "ගෙවීම ඉදිරියට සිදු කරන්න"
                              : "செலுத்துவதற்குத் தொடரவும்"
                        }
                        btnType={"submit"}
                        onClick={() => handlePayment()}
                        btnStyle={"btnPrimary marginRight"}
                      />
                    </div>
                    <div className="paddingTop">
                      <Button
                        btnText={
                          locale === "en"
                            ? "Back"
                            : locale === "si"
                              ? "ආපසු"
                              : "திரும்ப செல்"
                        }
                        btnType={"button"}
                        onClick={() => history.goBack()}
                        // onClick={() => history.push("revenue-licence")}
                        //isActive={true}
                        btnStyle={"btnOutlinePrimary"}
                      />
                    </div>
                  </div>

                  <br />
                  <br />

                  <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    //closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </div>
              </div>
            </UICard>
          </div>
        </IntlProvider>
      </Template>
    </Fragment>
  );
};

export { PaymentInformation };
