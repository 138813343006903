/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-27 13:10:53
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 17:47:17
 */
import CircularProgress from "@material-ui/core/CircularProgress";

const cardTitlePlacement = {
  LEFT: "titleAlignLeft",
  CENTER: "titleAlignCenter",
  RIGHT: "titleAlignRight",
};

const cardSize = {
  XSMALL: "cardSizeExtraSmall",
  SMALL: "cardSizeSmall",
  MEDIUM: "cardSizeMedium",
  LARGE: "cardSizeLarge",
};

const CircleLoaderElement = ({
  loaderStyle = "",
  loaderSize = 40,
  loaderThickness = 2,
}) => {
  return (
    <CircularProgress
      className={loaderStyle}
      size={loaderSize}
      thickness={loaderThickness}
    />
  );
};

const UICard = ({
  title = "",
  children = null,
  titlePlacement = cardTitlePlacement.LEFT,
  cardSize = "",
  imageAlt = null,
  imageSrc = null,
  isCardCenter = false, // do not use this if the card height is more than the screen size
  isContentCenter = false,
  borderLess = false,
  imageTitle = "",
  cardStyle = "",
  titleStyle = "",
  bodyStyle = "",
}) => {
  return (
    <div className={isCardCenter ? "cardAlignCenter" : ""}>
      <div
        className={`card ${cardStyle} ${cardSize} ${
          isContentCenter && "text-center"
        } ${borderLess ? "borderLess" : ""}`}
      >
        <div className="card-body">
          <h3 className={`card-title ${titleStyle} ${titlePlacement}`}>
            {title}
          </h3>
          {imageAlt || imageSrc ? (
            <Image
              title={imageTitle}
              type="card-img-top"
              alt={imageAlt}
              src={imageSrc}
            />
          ) : null}
          <div className={`${bodyStyle}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const InitialLoader = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100vh",
      }}
    >
      <CircleLoaderElement
        loaderStyle={"loader"}
        loaderSize={50}
        loaderThickness={2}
      />
    </div>
  );
};

const Image = ({
  isCenter = false,
  type = "",
  src = "",
  alt = "",
  title = "",
}) => {
  return (
    <div className={`${isCenter && "titleAlignCenter"}`}>
      <h7>{title}</h7>
      <img class={type} src={src} alt={alt} />
    </div>
  );
};

const emptyFun = () => undefined;

export {
  UICard,
  cardTitlePlacement,
  cardSize,
  emptyFun,
  Image,
  InitialLoader,
  CircleLoaderElement,
};
