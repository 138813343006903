import { useContext } from "react";
import { logoutAPI } from "../../config/apiUrl.config";
import { authTokenStorageKey } from "../../config/core.config";
import { callApi } from "../../helpers/common-helpers/callApi.helpers";
import { reomveFromLocalStorage } from "../../helpers/common-helpers/manageStorage.helpers";
import { AuthContext } from "../modules/core/context-providers/AuthContext.provider";

export const useLogOutHook = () => {
  const [authStatuse, authAction] = useContext(AuthContext);

  const logoutUser = async (body) => {
    callApi(`${logoutAPI.url}/logout`)
      .method("post")
      .headers(false)
      .body(body)
      .send((error, result) => {
        if (result) {
          if (result.data === true) {
            authAction.unauthorisedUserFn();

            reomveFromLocalStorage(authTokenStorageKey);
            emptyCache();
          }
        }
      });
  };

  const emptyCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      window.location.reload(true);
    }
  };

  return {
    logoutUser,
  };
};
