import React, { Fragment } from "react";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import { RevenueLicence } from "./pages/RevenueLicence";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { PaymentInformation } from "./pages/PaymentInformation";
import { ReferenceDetails } from "./pages/ReferenceDetails";
import authorized from "../../hoc-middlewares/Authorized";
import PaymentComplete from "./pages/PaymentComplete";

const RevenueLicenceRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/revenue-licence"
        routeKey={"revenue"}
        component={authorized(RevenueLicence)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/payment-licence"
        routeKey={"payment"}
        component={authorized(PaymentInformation)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/payment-complete"
        routeKey={"payment_complete"}
        component={authorized(PaymentComplete)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/reference-details"
        routeKey={"reference"}
        component={guest(ReferenceDetails)}
        routePermissions={permissions.NONE.permissions}
      />
    </Fragment>
  );
};

export default RevenueLicenceRoutes;
