/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 15:28:02
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-09 10:02:42
 */
import { Badge } from "../../../../ui-components/ui-elements/form/Badge";
import {
  cardSize,
  UICard,
} from "../../../../ui-components/ui-elements/common/BaseElements";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../../atom";

const OnlineApplicationStatusPopUp = () => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <UICard cardSize={cardSize.LARGE} borderLess={true}>
      {/* <div className="badgeAlign"> */}
      <ul>
        <div className="row">
          <div className="col-md-1">
            <Badge text={
          locale === "en"
            ? "Issued"
            : locale === "si"
            ? "නිකුත් කරන ලදි"
            : "வழங்கப்பட்டது"
        } style={"successBadge float-right"} />
            {/* <li><p className="floatLeft bg-success">Issued </p></li> */}
          </div>
          <div>
            <p className="floatLeft">- </p>
          </div>
          <div className="col-md-9">
            <p className="floatLeft">{
          locale === "en"
            ? "Revenue Licence is printed and posted"
            : locale === "si"
            ? "ආදායම් බලපත්‍රය මුද්‍රණය කර තැපැල් කරන ලදි."
            : "வருமான அனுமதிப்பத்திரம் அச்சிடப்பட்டு தபாலில் அனுப்பட்டுள்ளது."
        }</p>
          </div>
        </div>
      </ul>
      <ul>
        <div className="row">
          <div className="col-md-1">
            <Badge text={
          locale === "en"
            ? "Pending"
            : locale === "si"
            ? "ක්‍රියා කරමින් පවතී"
            : "நிலுவையிலுள்ளது"
        } style={"defaultBadge float-right"} />
            {/* <li>
              <p className="floatLeft">Pending </p>
            </li> */}
          </div>
          <div>
            <p className="floatLeft">- </p>
          </div>
          <div className="col-md-10">
            <p className="floatLeft">
            {
          locale === "en"
            ? "Revenue Licence printing is still in progress"
            : locale === "si"
            ? "ආදායම් බලපත්‍රය මුද්‍රණය කිරීමේ කටයුතු තවමත් සිදුවෙමින් පවතී. "
            : "வருமான அனுமதிப் பத்திரம் அச்சிடும் செயல்முறையானது இடம்பெற்று வருகின்றது."
        } 
            </p>
          </div>
        </div>
      </ul>
      {/* </div> */}
    </UICard>
  );
};

export default OnlineApplicationStatusPopUp;
