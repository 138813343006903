/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-29 13:21:51
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:51:47
 */
import React from "react";
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { FormattedMessage, IntlProvider } from "react-intl";

const messages = {
  en: {
    definitions: "Definitions",
    heading: "Terms and Conditions",
    lastUpdate: "Last updated: 12-04-2022.",
    des1: `Welcome to the Online Vehicle Revenue License Service System (Website). Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the Website that enables issuance and generation of vehicle revenue license online (“Service”) operated by the Office of the Provincial Department of Motor Traffic (“Owner”, "us", "we", or "our").`,
    des2: "For the purpose of these Terms, User, You or Your shall mean the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
    des3: "Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. By accessing and using any part of this website and/ or using the online services, you shall be deemed to have accepted to be legally bound by these Terms of Use. These Terms apply to all visitors, users and others who access and/ or use the Service.",
    h1: "1.	ACCESS INFORMATION",
    des101:
      "Following registration for the Services provided in this Website, you may be issued with Access Information that may be used to access the Website and/ or the Services and any pages for which registration is required.",
    des102:
      "Your account is to be used by a single user only and you will not allow simultaneous access using the same Access Information. You acknowledge that you are responsible for ensuring that no unauthorized access to the Website and the Services is obtained using your Access Information and that you are liable for all such activities conducted through your account whether authorized or not. When choosing Access Information, you must not use words that are obscene, abusive or likely to cause offence.",
    des103: "You as the registered user of the account will:",
    des10301:
      "keep your Access Information secure and not let it become public knowledge;",
    des10302:
      "provide true, accurate, current and complete information in all fields indicated as compulsory when registering on the Website for the Services and notify us immediately of any changes or mistakes;",
    des10303:
      "if your Access Information becomes known to any other unauthorized user you must take subsequent measures to change your access codes and inform the Owner immediately.",
    des104:
      "Once you have ‘signed-in’ to any secure areas of the Website, where you are requested to provide your Access Information, you must not at any time leave the Internet terminal or device from which you have accessed the Website and the Services or let anyone else use the Internet terminal or device until you have logged out of the secure area of the Website and the Services.",
    des105:
      "You will be responsible for ensuring that you have logged out of the secure area at the end of any session.",
    des106:
      "You must not access the Website and the Services from any computer connected to a local area network (LAN) or any public Internet access device or access point without first making sure that no-one else will be able to observe or copy your access or get access to the Sites and the Services pretending to be you.",
    h2: "2.	PROPRIETARY RIGHTS",
    des201:
      "This website is owned and operated by the Office of the Provincial Department of Motor Traffic (“Owner”).",
    des202: `The materials located in this website including the information, trade and service marks and software programs (the "Contents"), are protected by copyright, trademark and other forms of proprietary rights. All rights, title and interest in the Contents are owned by, licensed to or controlled by the Owner.`,
    h3: "3.	PRIVACY AND PROTECTION OF DATA ",
    des301: "Please refer the Privacy Policy on the Website",
    h4: "4.	RESTRICTIONS ON USE OF CONTENTS",
    des401:
      "Except as otherwise provided, the Contents of this website shall not be reproduced, republished, uploaded, posted, transmitted, communicated or otherwise distributed in any way, without the prior written permission of the Owner. To use the Contents of this website or any part thereof, the user must obtain permission in writing, stating the Contents for use; intent of use; manner of use; time frame of use and identity of the user. The owner reserves the right to refuse permission without stating any reason(s); and/ or if information submitted by user is deemed insufficient.",
    des402:
      "Modification of any of the Contents or use of the Contents for any other purpose will be a violation of copyright and other intellectual property rights of the Owner. Graphics and images on this website are protected by copyright and may not be reproduced or appropriated in any manner without written permission of the Owner and/or their respective copyright owners.",
    h5: "5.	DISCLAIMER OF WARRANTIES AND LIABILITY",
    des501: `The Contents of this website are provided on an "as is" basis without warranties of any kind.`,
    des502:
      "To the fullest extent permitted by law, the Owner does not warrant and hereby disclaims any warranty: ",
    des50201:
      "as to the accuracy, correctness, fullness, reliability, timeliness, non-infringement, title, merchantability, fitness for any particular purpose of the Contents of this website;",
    des50202:
      "that the Contents available through this website or any functions associated therewith will be uninterrupted or error-free, or that defects will be corrected or that this website and the server is and will be free of all viruses or other malicious, destructive or corrupting code, agent, program or macros and/ or other harmful elements.",
    des503:
      "The Owner shall also not be liable for any damage or loss of any kind caused as a result (whether direct or indirect) of the use of this website or non-access thereof, including but not limited to any damage or loss suffered as a result of reliance on the Contents contained or available from this website.",
    des504:
      "The Owner shall not be liable for any full or partial damage or loss of information of any kind caused as a result (whether direct or indirect) of network interruptions or any other hindrance owing to a third party service provider other than this Website and/ or the Owner of this Website. ",
    des505:
      "The information provided herein is to give a better understanding of the user's obligations and do not constitute professional or legal advice. While every effort is made to ensure that the Contents herein are consistent with existing law and practice, members of the public are advised to refer to the relevant legislation or to professional advice to be certain of their legal obligations.",
    h6: "6.	RIGHT OF ACCESS",
    des601:
      "The Owner reserves all rights to deny or restrict access to this website to any particular person, or to block access from a particular Internet address to this website, at any time, without ascribing any reasons and/or notification whatsoever.",
    h7: "7.	THIRD PARTY WEBSITES",
    des701: "Links from this Website to other Websites",
    des70101:
      "This website contains hyperlinks to websites which are not maintained by the Owner. The Owner is not responsible for the contents of those website and shall not be liable for any damages or loss arising from use of and/or access to those websites. Use of the hyperlinks and access to such websites are entirely at your own risk.",
    des70102:
      "Hyperlinks to other websites are provided as a convenience to the user. In no circumstances shall the Owner be considered to be endorsing or verifying such websites, nor shall the Owner be considered to be associated or affiliated in whatever manner with any trade or service marks, logos, insignia or other devices used or appearing on websites to which this website is linked.",
    des702: "Links to this Website from other Websites",
    des70201:
      "Under no circumstance shall the Owner be considered to be endorsing or verifying the contents of such websites that hyperlink to this Website or any of the Contents, nor shall the Owner be considered to be associated or affiliated in whatever manner with any trade or service marks, logos, insignia or other devices used or appearing on websites that hyperlink to this website or any of the Contents.",
    des70202:
      "The Owner reserves all rights to disable any hyperlinks to, or frames of any website containing inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topics, names, material or information, or material or information that violates any written law, any applicable intellectual property, proprietary, privacy or publicity rights.",
    des70203:
      "The Owner reserves the right to disable any unauthorized hyperlinks or frames and disclaims any responsibility for the content available on any other website reached by hyperlinks to or from this website or any of the Contents.",
    h8: "8.	GENERAL",
    des801: "Updates and Amendments",
    des80101: `These Terms of Use may change from time to time at the absolute discretion of the Owner. Such updates shall be posted on this website as and when such update occurs. If any changes are made to these Terms of Use, we will notify you by including a "NEWLY UPDATED" label with the "Terms of Use" link on our Services after material changes are made.  Your use of this Site after such changes have been posted will constitute your agreement to the modified Terms of Use and of all the changes.`,
    des802: "Governing Law and Jurisdiction ",
    des80201:
      "These Terms and Conditions are governed by and construed in accordance with English law and you hereby submit to the non-exclusive jurisdiction of the Sri Lankan courts.",
    des803: "Termination",
    des80301:
      "We may terminate or suspend access to our Website and/ or Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation to breach of the Terms by You. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability",
    des804: "Severability",
    des80401:
      "These Terms and Conditions are severable in that if any provision is determined to be illegal or unenforceable by any court of competent jurisdiction such provision shall be deemed to have been deleted without affecting the remaining provisions of these Terms and Conditions.",
    des805: "Waiver",
    des80501:
      "Our failure to exercise any particular right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing.",
    des806: "Representations",
    des80601:
      "You acknowledge and agree that in entering into these Terms and Conditions you do not rely on, and shall have no remedy in respect of, any statement, representation, warranty or understanding (whether negligently or innocently made) of any person (whether party to these Terms and Conditions or not) other than as expressly set out in these Terms and Conditions as a warranty. Nothing in this Clause shall, however, operate to limit or exclude any liability for fraud.",
    des807: "Force Majeure",
    des80701:
      "The Owner shall not be liable in respect of any breach of these Terms and Conditions due to any cause beyond its reasonable control of the Owner including but not limited to, Act of God, inclement weather, any act or omission by Internet Service Providers, act or omission of Government or other competent authority.",
    des808: "Contact",
    des80801:
      "Any questions you may have about these Terms and Conditions can be referred to us via the following email address; ",
  },
  si: {
    definitions: "Definitions Sinhala",
    heading: "පරිශීලක කොන්දේසි (කොන්දේසි)",
    lastUpdate: "අවසන් වරට යාවත් කාලීන කරන ලද්දේ: 12-04-2022.",
    des1: `මාර්ගගත වාහන ආදායම් බලපත්‍ර සේවා පද්ධතිය (වෙබ් අඩවිය) වෙත ඔබ සාදරයෙන් පිළිගනිමු. කරුණාකර වෙබ් අඩවිය භාවිතා කිරීමට ප්‍රථමයෙන් මෙම පරිශීලක කොන්දේසි ("කොන්දේසි", "පරිශීලක කොන්දේසි") සැලකිලිමත්ව කියවන්න. පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තු කාර්යාලය (“හිමිකරු”, "අප", "අපි", හෝ "අපගේ") මගින් ක්‍රියාත්මක කරනු ලබන මෙම සේවාව ඔස්සේ  වාහන ආදායම් බලපත්‍රය මාර්ගගතව නිකුත් කිරීම හා සකස් කිරීම සඳහා එමඟින් ඔබට හැකියාව සැලසෙනු ඇත.`,
    des2: "මෙම කොන්දේසිවලට අදාළ කාර්යය සඳහා  පරිශීලකයා, ඔබ හෝ ඔබගේ  යනුවෙන් අදහස් කරන්නේ සේවාව වෙත  ප්‍රවේශවන නැහතහොත් සේවාව භාවිතා කරනු ලබන පුද්ගලයා හෝ එවැනි පුද්ගලයින් වෙනුවෙන් වෙබ් අඩවිය වෙත ප්‍රවේශ වන හෝ වෙබ් අඩවිය පරිශීලනය කරනු ලබන සමාගමක් හෝ  වෙනත් නෛතික ආයතනයක් විය හැකිය.",
    des3: "මෙම නියමයන් ඔබ විසින් පිළිගනු ලැබීම සහ ඒවාට අනුකූලවීම අනුව මෙම සේවාව වෙත ඔබගේ ප්‍රවේශවීම හා සේවාව භාවිතා කිරීම සම්බන්ධයෙන් කොන්දේසි පනවා තිබේ. මෙම වෙබ් අඩවියේ කිසියම් කොටසක් වෙත ප්‍රවේශවීම හෝ එය භාවිතා කිරීම සහ/හෝ මාර්ගගත සේවාව භාවිතා කිරීම මගින් මෙම පරිශීලක කොන්දේසිවලට ඔබ නීතිමය වශයෙන් බැඳී සිටින බව ඔබ පිළිගෙන ඇති බවට සලකනු ලැබේ.  මෙම නියමයන් වෙබ් අඩවියේ සියලුම නරඹන්නන්ට, පරිශීලකයින්ට සහ සේවාවට ප්‍රවේශ වන සහ/හෝ භාවිතා කරන අනෙකුත් අයට අදාළ වේ.",
    h1: "1.	ප්‍රවේශවීමේ  තොරතුරු",
    des101:
      "මෙම වෙබ් අඩවියේ සපයා ඇති සේවාවන් සඳහා ලියාපදිංචි වීමෙන් පසුව, ලියාපදිංචිය  අත්‍යවශ්‍ය වන වෙබ් අඩවියට සහ/හෝ සේවාවන්ට සහ ඕනෑම පිටුවකට ප්‍රවේශ වීම සඳහා  භාවිතා කළ හැකි ප්‍රවේශ තොරතුරු ඔබ වෙත  නිකුත් කරනු ලැබේ.",
    des102:
      "ඔබගේ ගිණුම තනි පුද්ගලයකු විසින් පමණක් පරිශීලනය කළ යුතු අතර එකම ප්‍රවේශ තොරතුරු භාවිතා කරමින් සමගාමී ප්‍රවේශයක් සඳහා ඔබට අවසර නොලැබේ.  ඔබගේ ප්‍රවේශ තොරතුරු භාවිතා කරමින් වෙබ් අඩවිය හා සේවාව වෙත අනවසර ප්‍රවේශයක් සිදු නොකරන බව සහතික කිරීම සඳහා ඔබ වගකිව යුතු බවත් ඔබගේ අවසරය ඇතිව හෝ අවසරයකින් තොරව හෝ  ඔබගේ ගිණුම හරහා සිදු කරනු ලබන එවැනි කටයුතු සම්බන්ධයෙන් ඔබ වගකිය යුතු බවත්  ඔබ විසින් පිළිගනු  ලැබේ. ප්‍රවේශ තොරතුරු තෝරා ගැනීමේ දී,  අසභ්‍ය, අපවාදාත්මක හෝ වරදක් සිදු කිරීමට ඉඩකඩ ඇති වචන ඔබ විසින් භාවිතා නොකළ යුතු ය.",
    des103: "ගිණුමේ ලියාපදංචි පරිශීලකයා ලෙස ඔබ:",
    des10301:
      "ඔබගේ ප්‍රවේශ තොරතුරු රහසිගතව තබා ගත යුතු අතර එම තොරතුරු අන් අයට දැන ගැනීමට ඉඩ නොතැබිය යුතු ය;",
    des10302:
      "සේවා  ලබා ගැනීම සඳහා වෙබ් අඩවියේ ලියාපදිංචි වීමේ දී අනිවාර්යයෙන් ඇතුළත් කළ  යුතු යැයි දක්වා ඇති සියලු ක්ෂේත්‍ර සඳහා සත්‍ය, නිවැරදි, වර්තමාන හා සම්පූර්ණ තොරතුරු  ඇතුළත් කළ යුතු අතර එම තොරතුරුවල කිසියම් වෙනසක් හෝ වැරැද්දක් සිදු වී ඇත් නම් ඒ පිළිබඳව වහාම දැනුවත් කළ යුතු ය.",
    des10303:
      "ඔබගේ ප්‍රවේශ තොරතුරු වෙනත් අවසරය නොලත් පරිශීලකයකුහට  දැන ගැනීමට ලැබුණහොත්, ඔබගේ ප්‍රවේශ කේත වෙනස් කිරීම සඳහා අවශ්‍ය පියවර ගත යුතු අතර හිමිකරුට ඒ පිළිබඳව වහාම දැනුම් දිය යුතු ය.",
    des104:
      "ඔබේ ප්‍රවේශ තොරතුරු ලබා දෙන ලෙස ඉල්ලා සිටින වෙබ් අඩවියේ ඕනෑම ආරක්‍ෂිත ක්ෂේත්‍රයක් වෙත ඔබ 'පුරණය' වූ පසු, ඔබ කිසිම අවස්ථාවක ඔබ වෙබ් අඩවියට සහ සේවාවන්ට ප්‍රවේශ වූ අන්තර්ජාල පර්යන්තයෙන් හෝ උපාංගයෙන් ඉවත්වීම හෝ  ඔබ වෙබ් අඩවියේ සහ සේවාවන්හි ආරක්ෂිත ක්ෂේත්‍රයෙන් ඉවත් වන තුරු වෙනත් ඕනෑම කෙනෙකුට අන්තර්ජාල පර්යන්තය හෝ උපාංගය භාවිතා කිරීමට ඉඩ ලබා දීම හෝ නොකළ  යුතුය.",
    des105:
      "සැසිය අවසානයේ ආරක්ෂිත ක්ෂේත්‍රයෙන් ඔබ ඉවත් වී ඇති බව තහවුරු කිරීමේ වගකීම ඔබ විසින් දැරිය යුතුයි.",
    des106:
      "ඔබ ලෙස පෙනී සිටින වෙනත් කිසිදු පුද්ගලයකුට ඔබගේ ප්‍රවේශය නිරීක්ෂණය කිරීමට හෝ පිටපත් කිරීමට නොහැකිබව හෝ  වෙබ් අඩවියට සහ සේවාවට ප්‍රවේශ විය නොහැකි බව පළමුවෙන් තහවුරු කර ගැනීමෙන් තොරව  ප්‍රාදේශීය ජාලයකට (LAN) සම්බන්ධ පරිගණකයකින් හෝ කිසියම් පොදු අන්තර්ජාල ප්‍රවේශ උපාංගයකින්  හෝ පිවිසුම්  ලක්ෂයකින්   මෙම වෙබ් අඩවියට සහ සේවාවට ඔබ ප්‍රවේශ නොවිය යුතු ය.",
    h2: "2.	හිමිකාරීත්වය සතු අයිතිවාසිකම්",
    des201:
      "මෙම වෙබ් අඩවිය පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තු කාර්යාලය (“හිමිකරු”)   සතු වන අතර එම පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තු කාර්යාලය  මගින් එය ක්‍රියාත්මක කරනු ලැබේ.",
    des202: `මෙම වෙබි අඩවියේ දක්වා ඇති තොරතුරු,  වෙළඳ හා සේවා සලකුණු සහ මෘදුකාංග වැඩසටහන් ("අන්තර්ගතයන්") ඇතුළු  ලේඛනවල ප්‍රකාශන අයිතිය, වෙළඳ ලාංඡනය සහ වෙනත් ආකාරයේ  හිමිකාරත්ව අයිතිවාසිකම් මගින් ආරක්ෂා කරනු ලැබේ. අන්තර්ගතයේ සියළුම හිමිකම්, හිමිකම් අයිතිය සහ භුක්තියේ හිමිකාරත්වය සම්බන්ධයෙන් බලපත්‍ර අයිතිය දරනු ලබන්නේ හෝ පාලනය කරනු  ලබන්නේ හිමිකරු විසිනි.`,
    h3: "3.	රහස්‍යතාව සහ දත්ත ආරක්ෂා කර ගැනීම",
    des301: "කරුණාකර වෙබ් අඩවියේ දක්වා ඇති රහස්‍යතා ප්‍රතිපත්තිය කියවන්න.",
    h4: "4.	අන්තර්ගතය පරිශීලනය කිරීම සම්බන්ධ සීමාවන්",
    des401:
      "මෙහි වෙනත් ආකාරයකින් දක්වා ඇත්තේ නම් මිස, හිමිකරුගේ පූර්ව ලිඛිත අවසරයකින් තොරව වෙබ් අඩවියේ අන්තර්ගය කිසිදු ආකාරයකින් ප්‍රතිනිෂ්පාදනය, ප්‍රතිප්‍රකාශනය, උඩුගත කිරීම, පළ කිරීම, සම්ප්‍රේෂණය කිරීම, සන්නිවේදනය කිරීම හෝ වෙනත් ආකාරයකින් බෙදා හැරීම   නොකළ යුතුය. මෙම වෙබ් අඩවියේ අන්තර්ගතයන් හෝ එහි කිසියම් කොටසක් පරිශීලනය කිරීම සඳහා භාවිතා කරනු ලබන අන්තර්ගතය,  භාවිතා කරනු ලබන කාරණය, භාවිතා කරනු ලබන ආකාරය, භාවිතා කරනු ලබන කාලසීමාව සහ පරිශීලකයාගේ අනන්‍යතාව සඳහන් කරමින් පරිශීලකයා විසින් හිමිකරුගේ ලිඛිත අවසරය ලබා ගත යුතුය. පරිශීලක විසින් සපයනු ලබන තොරතුරු ප්‍රමාණවත් නොවන්නේ නම් කිසිදු හේතුවක් සහ /හෝ  හේතු දැක්වීමකින් තොරව අවසරය අවලංගු  කිරීමේ  අයිතිය  හිමිකරු සතුය.",
    des402:
      "කිසිදු අන්තර්ගතයක් වෙනස් කිරීම හෝ වෙනත් අරමුණක් සඳහා අන්තර්ගතය භාවිතා කිරීම හිමිකරුගේ ප්‍රකාශන අයිතිය සහ අනෙකුත් බුද්ධිමය දේපළ අයිතිවාසිකම් උල්ලංඝනය කිරීමක් වනු ඇත. මෙම වෙබ් අඩවියේ ඇති චිත්‍ර සහ රූපවල ප්‍රකාශන අයිතිය මගින් ආරක්ෂා කර ඇති අතර හිමිකරුගේ සහ/හෝ ඔවුන්ගේ අදාළ ප්‍රකාශන හිමිකරුවන්ගේ ලිඛිත අවසරයකින් තොරව කිසිදු ආකාරයකින් ප්‍රතිනිෂ්පාදනය කිරීම හෝ පවරා ගැනීම නොකළ හැකිය.",
    h5: "5.	 වගකීම් සහ බැරකම් අත්හැරීම",
    des501: `මෙම වෙබ් අඩවියේ අන්තර්ගතය කිසිදු ආකාරයක වගකීමක් නොමැතිව "පවතින පරිදි" පදනම මත සපයනු ලැබේ.`,
    des502:
      "නීතියෙන් අවසර දී ඇති උපරිම ප්‍රමාණයට, හිමිකරු විසින් පහත කරුණු සම්බන්ධයෙන්  වගකීමක් නොදරන අතර මෙයින් ඕනෑම වගකීමක් ප්‍රතික්ෂේප කරනු ලැබේ:",
    des50201:
      "කිසියම් සුවිශේෂී අරමුණක් සඳහා මෙම වෙබ් අඩවියේ අන්තර්ගතයේ නිරවද්‍යතාව, පූර්ණ නිරවද්‍යතාව, පූර්ණත්වය, විශ්වසනීයත්වය, කාලානුරූපී බව, උල්ලංඝනය නොවනබව, හිමිකම, වාණිජ්‍ය හැකියාව, යෝග්‍යතාව;",
    des50202:
      "මෙම වෙබ් අඩවිය හරහා ලබා ගත හැකි අන්තර්ගතයන් හෝ ඒ හා සම්බන්ධ ඕනෑම කාර්යයක් බාධාවකින් තොරව හෝ දෝෂ රහිතව ලබා ගත හැකි බව, නැතහොත් දෝෂ නිවැරදි කරනු ඇති බව හෝ මෙම වෙබ් අඩවිය සහ ප්‍රධාන පරිගණකය සියලුම වෛරස් හෝ වෙනත් උපද්‍රවකාරී, විනාශකාරී හෝ දූෂිත කේත, කාරක, වැඩසටහන් හෝ මැක්‍රෝ සහ/ හෝ වෙනත් හානිකර අංගවලින් තොර බව.",
    des503:
      "තවද මෙම වෙබ් අඩවිය භාවිතා කිරීමේ හෝ එයට ප්‍රවේශ නොවීමේ ප්‍රතිඵලයක් ලෙස (සෘජුව හෝ වක්‍රව) ඇති වන ඕනෑම ආකාරයේ හානියක් හෝ අලාභයක් ඇතුළුව එහෙත් එයට පමණක් සීමා නොවන  මෙම වෙබ් අඩවියෙහි අඩංගු හෝ ලබා ගත හැකි අන්තර්ගතය සම්බන්ධයෙන් විශ්වාසය තැබීමේ ප්‍රතිඵලයක්  හේතුවෙන් ඇති වන හානියක් හෝ අහිමිවීමක් සඳහා හිමිකරු වගකිව යුතු නොවේ.",
    des504:
      "මෙම වෙබ් අඩවිය සහ /හෝ මෙම වෙබ් අඩවියේ හිමිකරු හැර  තුන්වැනි පාර්ශ්වයක සේවා සපයන්නකු හේතුවෙන් ඇති වන  ජාල විසන්ධිවීම් හෝ බාධාවක් හේතුවෙන්  තොරතුරුවලට සිදුවන  (සෘජු හෝ වක්‍ර)  කිසියම් ආකාරයක පූර්ණ හෝ අර්ධ  හානියක් හෝ තොරතුරු නැතිවීම  සම්බන්ධයෙන් හිමිකරු වගකිය යුතු නොවේ.",
    des505:
      "මෙහි සපයා ඇති තොරතුරු පරිශීලකයාගේ වගකීම් පිළිබඳ වඩා හොඳ අවබෝධයක් ලබා දෙන අතර  සහ වෘත්තීය හෝ නීති උපදෙස් ඊට ඇතුළත් නොවේ. මෙහි අන්තර්ගතය පවතින නීතියට සහ භාවිතයට අනුකූල බව සහතික කිරීමට සෑම උත්සාහයක්ම දරන අතර, මහජන සාමාජිකයින්ට ඔවුන්ගේ නීතිමය බැඳීම් පිළිබඳ සහතික වීමට අදාළ නීති හෝ වෘත්තීය උපදෙස් වෙත යොමු වන ලෙස උපදෙස් දෙනු ලැබේ.",
    h6: "6.	ප්‍රවේශවිමේ අයිතිය",
    des601:
      "ඕනෑම අවස්ථාවක, කිසිදු හේතුවක් සහ/හෝ දැනුම් දීමකින් තොරව ඕනෑම නිශ්චිත පුද්ගලයෙකු විසින් මෙම වෙබ් අඩවියට ප්‍රවේශවීම ප්‍රතික්ෂේප කිරීමට හෝ සීමා කිරීමට හෝ විශේෂිත අන්තර්ජාල ලිපිනයකින් මෙම වෙබ් අඩවියට පිවිසීම අවහිර කිරීමට හිමිකරුට සියලු හිමිකම් ඇත,",
    h7: "7.	තෙවන පාර්ශ්වයේ වෙබ් අඩවි ",
    des701: "මෙම වෙබ් අඩවියෙන් වෙනත් අඩවි වෙත ඇති සබැඳි",
    des70101:
      "අමෙම වෙබ් අඩවියේ හිමිකරු විසින් නඩත්තු නොකරන වෙබ් අඩවි වෙත අධි සබැඳි මෙම වෙබ් අඩවියෙහි අඩංගු වේ. එම වෙබ් අඩවියේ අන්තර්ගතය සඳහා හිමිකරු වගකිව යුතු නොවන අතර එම වෙබ් අඩවි භාවිතා කිරීමෙන් සහ/හෝ ප්‍රවේශ වීමෙන් ඇතිවන හානි හෝ අලාභයන්ට හිමිකරු වගකියනු නොලැබේ. අධි සබැඳි භාවිතා කිරීම සහ එවැනි වෙබ් අඩවිවලට ප්‍රවේශ වීම සම්බන්ධ අවදානම සම්පූර්ණයෙන් ම දැරිය යුතු ය.",
    des70102:
      "වෙනත් වෙබ් අඩවි වෙත අධි සබැඳි ලබා දී ඇත්තේ පරිශීලකයාගේ පහසුව සඳහාය. කිසිම අවස්ථාවක හිමිකරු විසින් එවැනි වෙබ් අඩවි අනුමත කරනු ලබන බවට හෝ සත්‍යාපනය කරනු ලබන බවට  නොසැලකිය යුතු අතර, මෙම වෙබ් අඩවිය හා සබැඳි වෙබ් අඩවිවල භාවිතා කරන හෝ දිස්වන ඕනෑම වෙළඳ හෝ සේවා ලකුණු, ලාංඡන, ලාංඡන හෝ වෙනත් උපාංග සමඟ කුමන ආකාරයකින් හෝ හිමිකරු  සම්බන්ධ වී හෝ අනුබද්ධ වි ඇති  ලෙසට නොසැලකිය යුතු ය.",
    des702: "වෙනත් වෙබ් අඩවි මගින් මෙම වෙඩ් අඩවිය වෙත ඇති සබැඳි",
    des70201:
      "මෙම වෙබ් අඩවියට අධි සම්බන්ධිත එවැනි වෙබ් අඩවි හෝ ඕනෑම අන්තර්ගයක් කිසිදු අවස්ථාවක දී හිමිකරු විසින් පිටසන් කරනු ලබන බව හෝ සත්‍යාපනය කරනු ලබන බව නොසැලකිය යුතුය. තවද මෙම වෙබ් අඩවියට හෝ ඕනෑම අන්තර්ගතයකට අධි සබැඳි වෙබ් අඩවිවල භාවිතා කරන හෝ දිස්වන ඕනෑම වෙළඳ හෝ සේවා ලකුණු, ලාංඡන, සලකුණු හෝ වෙනත් උපාංග සමඟ හිමිකරු සම්බන්ධ වී හෝ අනුබද්ධ වී ඇති  බවට  නොසැලකිය යුතුය.",
    des70202:
      "අදාළ ඕනෑම ලිඛිත නීතියක්, අදාළ ඕනෑම බුද්ධිමය දේපලක්, හිමිකාරීත්වය, පෞද්ගලිකත්වය හෝ ප්‍රචාරණ අයිතිවාසිකම් උල්ලංඝනය කරන්නා වූ, නාමයන්, ලේඛන හෝ තොරතුරු, අඩංගු නුසුදුසු, අසභ්‍ය, අපහාසාත්මක, උල්ලංඝනය කරන, අශෝභන, හෝ නීති විරෝධී මාතෘකා අඩංගු ඕනෑම වෙබ් අඩවියක අධි සම්බන්ධතාවන් හෝ රාමු අක්‍රිය කිරීමට හිමිකරුට සියලු හිමිකම් ඇත.",
    des70203:
      "ඕනෑම අනවසර අධි සබැඳියක් හෝ රාමුවක්  අක්‍රිය  කිරීමට හා මෙම වෙබ් අඩවියට හෝ   වෙබ් අඩවියෙන් හෝ ඕනෑම අන්තර්ගතයකට අධි සබැඳි මගින් ළඟා වූ වෙනත් ඕනෑම වෙබ් අඩවියක ඇති අන්තර්ගතය සඳහා වන වගකීම  ප්‍රතික්ෂේප කිරීමට අයිතියක් හිමිකරුට පවතියි.",
    h8: "8.	සාමාන්‍ය",
    des801: "යාවත්කාලීන කිරීම් සහ සංශෝධන",
    des80101: `හිමිකරු ගේ පූර්ණ අභිමතය පරිදි මෙම පරිශීලක කොන්දේසි කලින් කල වෙනස් විය හැකි ය. එවැනි යාවත්කාලීන කිරීම් සිදු කරනු ලබන අවස්ථාවේ දී ඒවා මෙම වෙබි අඩවියෙහි පළ කරනු ඇත. මෙම පරිශීලක කොන්දේසිවල කිසියම් වෙනසක් සිදු කරන්නේ නම්, භෞතික වෙනස්කම් සිදු කිරීමෙන් අනතුරුව  අපගේ සේවාවෙහි   "පරිශීලක කොන්දේසි" සම්බන්ධකය සමග " අලුතින්ම යාවත්කාලීන කරන ලද " ලේබලයක් ඇතුළත් කරමින් අප විසින් ඔබ වෙත දැනුම් දෙනු ලැබේ. එවැනි වෙනස්කම් පිළිබඳව පළ කිරීමෙන් අනතුරුව ඔබ විසින් මෙම වෙබ් අඩවිය භාවිතා කිරීම  යනුවෙන් අදහස් කරනුයේ වෙනස් කරන ලද පරිශීලක කොන්දේසි සහ සිදු කරන මෙම සියලු වෙනස්කම්වලට ඔබ එකඟතාවය පළ කර ඇති බවයි.`,
    des802: "පාලන නීතිය හා අධිකරණ බලය",
    des80201:
      "මෙම නියමයන් හා කොන්දේසි ඉංග්‍රීසි නීතිය අනුව බලපැවැත්වෙන්නා  සේ ම ඒ අනුව සකස් වී ඇති අතර ශ්‍රී ලංකාවේ අධිකරණ බලයට ඇතුළත් නොවන නියමයන් හා කොන්දේසි මෙමඟින් දක්වනු ලැබේ.",
    des803: "අවසන් කිරීම",
    des80301:
      "ඔබ විසින් නියමයන් උල්ලංඝසය කිරීම ඇතුළත්ව කුමන ආකාරයේ හෝ හේතුවක් පදනම් කරගෙන පූර්ව දැනුම්දීමකින් හෝ බැඳීමකින් තොරව අපගේ වෙබ් අඩවියට  සහ හෝ සේවාවට  ප්‍රවේශ වීම අවසන් කිරීම හෝ අත්හිටුවීම සිදු  කළ හැකි ය. සීමාසහිතභාවය නොමැතිවීම, අයිතිය පිළිබඳ විධිවිධාන, වගකීම් අත්හැරීම, වන්දි පූර්ණය හා වගකීම් සඳහා වන සීමාව ඇතුළත්ව මෙම නියමයන්හි අතුළත් විධිවිධානවල  අවසන් කිරීම ස්වභාවයෙන් ම  පවතින අතර අතර ඒවායේ  එසේ අවසන් කිරීම පැවතිය යුතු ය.",
    des804: "ඉවත් කිරීම සම්බන්ධයෙන්  දැඩි ප්‍රතිතපත්තිය",
    des80401:
      "යම් විධිවිධානයක් නීතිවිරෝධී හෝ නිසි අධිකරණ බලය තුළ යම් අධිකරණයක් මඟින් බලාත්මක කළ නොහැකි බව නිශ්චය කරගන්නේ නම් ඒ සම්බන්ධයෙන් මෙම නියමයන් හා කොන්දේසි ඉවත් කළ හැකි වන අතර නියමයන් සහ කොන්දේසිවල ඉතිරි විධිවිධානවලට බලපෑමක් නොවන ආකාරයෙන් එබදු විධිවිධානයක් ඉවත් කරනු ලබන බව සැලකිය හැකි ය.",
    des805: "අත්හැරීම",
    des80501:
      "අප විසින් ලිඛිතව පිළිගෙන එකඟ වන්නේ නම් මිස මෙම නියමයන් සහ කොන්දේසිවල යම් සුවිශේෂී අයිතියක් හෝ විධිවිධානයක් ක්‍රියාත්මක කිරීමට අප අපොහොසත්වීම යනුවෙන් අදහස් වන්නේ එවැනි අයිතියක් හෝ ප්‍රතිපාදනයක් අත්හැරීමක් සිදු නොවේ.",
    des806: "නියෝජනයන්",
    des80601:
      "වගකීමක් ලෙස මෙම නියමයන් සහ කොන්දේසිවල පැහැදිලිව දක්වා ඇති පරිදි හැර මෙම නියමයන් සහ කොන්දේසිවලට ඇතුල් වීමේදී ඔබ කිසිදු පුද්ගලයෙකුගේ (මෙම නියමයන් සහ කොන්දේසිවල පාර්ශවකරුවෙකු වන හෝ නොවන) කිසියම් ප්‍රකාශයක්, නියෝජනයක්, වගකීමක් හෝ අවබෝධයක් (නොසැලකිලිමත්ව  හෝ නොදැනුවත්ව සිදු කළ ද) සම්බන්ධයෙන් විශ්වාසය නොතබන බවත්, එයට පිළියමක් නොමැති බවත් ඔබ  විසින් පිළිගනිමින් එකඟ වේ.  කෙසේ වෙතත්, මෙම වගන්තියේ සඳහන් කිසිවක් වංචා සම්බන්ධ වගකීම් සීමා කිරීම සඳහා  හෝ බැහැර කිරීම සඳහා ක්‍රියාත්මක  නොවිය යුතු ය.",
    des807: "උත්තරීතර බලය",
    des80701:
      "ස්වභාවධර්මයේ බලපෑම්, අයහපත් කාලගුණය, අන්තර්ජාල සේවා සපයන්නන්ගේ කිසියම් ක්‍රියාවක් හෝ පැහැර හැරීමක්, රජයේ හෝ වෙනත් නිසි බලධාරියකු විසින් සිදු කරන ක්‍රියාවක් හෝ පැහැර හැරීමක් ඇතුළුව ඒවාට සීමා නොවී හිමිකරු ගේ සාධාරණ පාලනයේ බැහැර කිසිදු හේතුවක් නිසා මෙම නියමයන් හා කොන්දේසි උල්ලංඝනය කිරීම සම්බන්ධයෙන් හිමිකරු වගකිය යුතු නොවේ.",
    des808: "අපව සම්බන්ධ කර ගන්න",
    des80801:
      "මෙම නියමයන් සහ කොන්දේසි පිළිබඳව ඔබට ඇති ඕනෑම ගැටළුවක්  පහත විද්‍යුත් තැපැල් ලිපිනය හරහා අප වෙත යොමු කළ හැක; ",
  },
  ta: {
    definitions: "Definitions Tamil",
    heading: "பயன்பாட்டு நியதிகள் (நியதிகள்)",
    lastUpdate: "இறுதியாக இற்றைப்படுத்தப்பட்டது: 12-04-2022.",
    des1: `இணையவழி மூலமான வாகன அனுமதிப் பத்திர சேவை முறைமைக்கு (இணையத்தளம்)  உங்களை அன்புடன் வரவேற்கின்றோம். தயவு செய்து மாகாண மோட்டார் போக்குவரத்துத் திணைக்கள அலுவலகத்தினால்  (“உரிமையாளர்”, “எங்களுக்கு”, “நாம்”, அல்லது “எமக்கு”) தொழிற்படுத்தப்படும் இணைய வழி மூலமான வாகன வருமான அனுமதிப் பத்திரத்தின் (“சேவை”) வழங்குகை மற்றும் தயாரித்தல்  செயல்முறையினை செயற்படுத்துகின்ற இணையத் தளத்தினைப் பயன்படுத்துவதற்கு முன்பாக அத்தகைய பயன்பாட்டு நியதிகளினை (“நியதிகள்”, “பயன்பாட்டு நியதிகள்”) தயவு செயது கவனமாக வாசிக்கவும்.`,
    des2: "இத்தகைய நியதிகளின் நோக்கத்திற்காக, பயனர், நீங்கள் அல்லது உங்களுடையது என்பது சேவையை அணுகும் அல்லது பயன்படுத்தும் தனிநபர், அல்லது நிறுவனம் அல்லது பொருத்தமான வகையில், அத்தகைய தனிநபரின் சார்பிலான ஏனைய சட்ட ஆளுமையானது  அணுகுகின்ற அல்லது பயன்படுத்துகின்றமையினைக் குறிப்பிடும்.",
    des3: "சேவையினைப் பயன்படுத்துகின்றமைக்கான உங்களின் அணுகுமுறையானது இத்தகைய நியதிகளுடன் நீங்கள் இணங்குதல் மற்றும் அவற்றினை ஏற்றுக் கொள்ளுதல் ஆகியவற்றின் அடிப்படையில் நிபந்தனை விதிக்கப்படுகின்றது. இணையத் தளத்தின் ஏதேனும் பகுதியினை அணுகிப் பயன்படுத்துவதன் மூலம் மற்றும் / அல்லது இணைய வழி சேவைகளினைப் பயன்படுத்துவதன் மூலம் இந்த பயன்பாட்டு நியதிகளுக்கு நீங்கள் சட்ட ரீதியாக கட்டுப்பட்டுள்ளதாக கருதப்படுவீர்கள். இத்தகைய நியதிகள் அனைத்து வருகையாளர்கள், பயனர்கள் மற்றும்  இச்சேவையினை அணுகுகின்ற மற்றும் / அல்லது பயன்படுத்துகின்ற ஏனையவர்களுக்க்கும் ஏற்புடையதாகும்.",
    h1: "1.	தகவல்களை அணுகுதல்",
    des101:
      "இந்த இணையதளத்தில் வழங்கப்பட்ட சேவைகளுக்கான பதிவினைத் தொடர்ந்து, இணையதளம் மற்றும்/அல்லது சேவைகள் மற்றும் பதிவு செய்யப்பட வேணடுமென தேவைப்படுத்தப்படுகின்ற ஏதேனும் பக்கங்களையும் அணுகுவதற்குப் பயன்படுத்தப்படக்கூடிய அணுகு தகவலானது உங்களுக்கு வழங்கப்படலாம்.",
    des102:
      "உங்களின் கணக்கானது தனியொருவரினால் மட்டுமே பயன்படுத்தப்படுதல் வேண்டும் என்பதுடன் ஒரே அணுகுமுறைத் தகவலினைப் பயன்படுத்தி சமாந்தரமாக அணுகுவதற்கு நீங்கள் அனுமதிக்கப்பட மாட்டீர்கள். உங்கள் அணுகுமுறையிலான தகவலைப் பயன்படுத்தி இணையதளம் மற்றும் சேவைகளுக்கான அங்கீகரிக்கப்படாத அணுகுதல் எதுவும் பெறப்படவில்லை என்பதையும், அங்கீகரிக்கப்பட்டாலும் இல்லாவிட்டாலும் உங்கள் கணக்கு மூலம் நடாத்தப்படும் அனைத்து நடவடிக்கைகளுக்கும் நீங்கள் பொறுப்பாவீர்கள் என்பதை உறுதிப்படுத்துவதற்கு நீங்கள் பொறுப்பு என்பதை நீங்கள் ஏற்றுக்கொள்கின்றீர்கள்.",
    des103: "கணக்கின் பதிவு செய்யப்பட்ட பயனராக இருக்கும் நீங்கள்:",
    des10301:
      "உங்கள் அணுகுத் தகவலைப் பாதுகாப்பாக வைத்திருங்கள். அதனை எல்லோருக்கும் தெரிய வருவதற்கு அனுமதியளிக்காதீர்கள்;",
    des10302:
      "சேவைகளுக்கான இணையத்தளத்தினைப் பதிவு செய்யும் போது கட்டாயமானதாக குறிப்பிடப்பட வேண்டிய அனைத்து கூடுகளிலும் உண்மையானதும் சரியானதும் நடைமுறையிலுள்ளதும் பூரணமானதுமான தகவல்களை வழங்குங்கள். ஏதேனும் மாற்றங்கள் அல்லது தவறுகள் காணப்படின் உடனடியாக அறியத்தரவும்;",
    des10303:
      "உங்களின் அணுகுதல் தகவலானது வேறு எவரேனும் அதிகாரமளிக்கப்படாத பயனரினால் தெரிந்து கொள்ளுமிடத்து உடனடியாக உங்களது அணுகுமுறை குறியீடுகளினை மாற்றுவதற்கு நடவடிக்கை எடுப்பதுடன் உடனடியாக உரிமையாளருக்கும் அறியத்தரவும்.",
    des104:
      "இணையத்தளத்தின் ஏதேனுமொரு பாதுகாப்பான பகுதியில் நீங்கள் 'உள் நுழைந்திருந்தால்', உங்கள் அணுகுதல் தகவலை வழங்குமாறு கேட்க்கப்பட்டால், நீங்கள் எந்த நேரத்திலும் இணைய முனையம் அல்லது இணையதளம் மற்றும் சேவைகளை அணுகிய சாதனத்தை விட்டு வெளியேறக் கூடாது அல்லது இணையதளம் மற்றும் சேவைகளின் பாதுகாப்பான பகுதியிலிருந்து நீங்கள் வெளியேறும் வரை வேறு எவரும் இணைய முனையம் அல்லது சாதனத்தைப் பயன்படுத்துவதற்கு அனுமதியுங்கள்.",
    des105:
      "எந்தவொரு அமர்வின் முடிவிலும் நீங்கள் பாதுகாப்பான பகுதியிலிருந்து வெளியேறிவிட்டீர்கள் என்பதை உறுதிப்படுத்துவதற்கு நீங்களே பொறுப்பாளிகள் ஆவீர்கள்.",
    des106:
      "நீங்கள் உள்ளூர் வலையமைப்பு உங்களைப் போன்று இயங்கும் தளங்கள் மற்றும் சேவைகளுக்கான அணுகுதலினை பெற்றுக் கொள்ளுதல் அல்லது ஏதேனும் பொது வலையமைப்பு (LAN) அல்லது ஏதேனும் பொது வலையமைப்பினை அணுகும் சாதனம் அல்லது அஅணுகல் புள்ளியுடன் இணைக்கப்பட்டுள்ள எந்தவொரு கணனியிலிருந்தும் இணையத்தள மற்றும் சேவைகளினைப் பெற்றுக் கொள்ளக் கூடாது.",
    h2: "2.	சொத்துவ முறையிலான உரிமைகள்",
    des201:
      "இந்த இணையத்தளமானது மாகாண மோட்டார் போக்குவரத்துத் திணைக்களத்திற்குச் (‘உரிமயாளர்’) சொந்தமானதாக இருப்பதுடன் அதனால் இயக்கப்படுகின்றது.",
    des202: `தகவல், வர்த்தகம் மற்றும் சேவை குறிகள் மற்றும் மென்பொருள் நிகழ்ச்சித்திட்டங்கள் ("உள்ளடக்கங்கள்") உள்ளடங்கலான இந்த இணையதளத்தில் உள்ள பொருட்கள் பதிப்புரிமை, வியாபாரக் குறி மற்றும் சொத்துவ உரிமைகளின் ஏனைய வடிவங்களினால் பாதுகாக்கப்படுகின்றன. உள்ளடக்கத்தில் உள்ள அனைத்து உரிமைகள், தலைப்புக்கள்  மற்றும் ஆர்வங்கள் ஆகியன  ஆகியவை உரிமையாளருக்கு சொந்தமானவை, உரிமம்  பெற்றவை அல்லது உரிமையாளரினால் கட்டுப்படுத்தப்படுகின்றன.`,
    h3: "3.	தரவுகளின் பிரதிதியேக தன்மையும் பாதுகாப்பும்",
    des301: "தயவு செய்து இணையத்தளத்தில் பிரத்தியேக கொள்கையினைப பார்க்கவும்.",
    h4: "4.	உள்ளடக்கங்களினைப் பயன்படுத்துவது தொடர்பான கட்டுப்ப்பாடுகள்",
    des401:
      "வேறுவிதமாக வழங்கப்பட்டுள்ளதைத் தவிர, இந்த இணையத்தளத்தின் உள்ளடக்கங்கள் உரிமையாளரின் எழுத்து மூல முன் அனுமதியின்றி மீண்டும் உருவாக்கப்படவோ, மீண்டும் வெளியிடவோ, பதிவேற்றப்படவோ, இடுகையிடவோ, அனுப்பவோ, தொடர்பு கொள்ளவோ அல்லது விநியோகிக்கப்படவோ கூடாது. இந்த இணையத்தளத்தின் உள்ளடக்கங்களை அல்லது அதன் எந்தப் பகுதியையும் பயன்படுத்த, பயனர் பயன்பாட்டிற்கான உள்ளடக்கங்கள், பயன்பாட்டின் நோக்கம், பயன்படுத்தும் முறை, பயன்பாட்டிற்கான கால அளவு  மற்றும் பயனரின் அடையாளம் என்பவற்றினைகுறிப்பிட்டு   எழுத்து மூலமான அனுமதியைப் பெறுதல் வேண்டும். பயனரினால் சமர்ப்பிக்கப்பட்ட தகவல்கள் போதுமானதாக இல்லையெனக் கருதினால் மற்றும் / அல்லது ஏதேனும் காரணங்களினைக் குறிப்பிடாமல் அனுமதியினை மறுப்பதற்கான உரிமை உரிமையாளருக்குள்ளது.",
    des402:
      "எந்தவொரு உள்ளடக்கத்தையும் மாற்றியமைப்பது அல்லது வேறு எந்த நோக்கத்திற்காகவும் உள்ளடக்கத்தைப் பயன்படுத்துவதும் உரிமையாளரின் பதிப்புரிமை மற்றும் ஏனைய புலமைச் சொத்து உரிமைகளினை மீறும் செயலாகும். இந்த இணையதளத்தில் உள்ள கிராபிக்ஸ் மற்றும் உருவப் படங்கள் பதிப்புரிமை மூலம் பாதுகாக்கப்படுகின்றன என்பதுடன் உரிமையாளர் மற்றும்/அல்லது உரிய பதிப்புரிமை உரிமையாளர்களின் எழுத்துப்பூர்வ அனுமதியின்றி எந்த வகையிலும் மீண்டும் உருவாக்கப்படவோ அல்லது கையகப்படுத்தப்படவோ கூடாது.",
    h5: "5.	உத்தரவாதங்கள் மற்றும் பொறுப்பு தொடர்பான பொறுப்புத்துறப்பு",
    des501: `இந்த இணையதளத்தின் உள்ளடக்கங்கள் எந்தவிதமான உத்தரவாதங்களும் இல்லாமல் "உள்ளபடியே" வழங்கப்படுகின்றன.`,
    des502:
      "சட்டத்தால் அனுமதிக்கப்பட்ட முழு அளவிலான உத்தரவாதத்தினை உரிமையாளர் வழங்குவதில்லை என்பதுடன் இதன் மூலம் எந்தவொரு உத்தரவாதத்தினையும் மறுதலிக்கின்றார்:",
    des50201:
      "இந்த இணையத்தளத்தின் உள்ளடக்கங்களின் ஏதேனுமொரு குறிப்பிட்ட நோக்கத்திற்கான, துல்லியமான, சரியான, நிறைவான, நம்பகமான, நேரமின்மை , மீறல் இல்லாத, தலைப்பு, வர்த்தக திறன், உறுதிநிலை;",
    des50202:
      "ஆ. இந்த இணையத்தளம் மூலம் கிடைக்கும் உள்ளடக்கங்கள்அல்லது அதனுடன் தொடர்புடைய செயற்பாடுகள் தடையின்றி அல்லது பிழையின்றி இருக்கும் அல்லது அந்த குறைபாடுகள் சரி செய்யப்படும் அல்லது இந்த இணையத்தளம் மற்றும் கணனி தகவல் சேமிப்பகம் (Server) என்பன, அனைத்து வைரசுக்கள் அல்லது ஏனைய தீங்கு விளைவிக்கும், அழிவுகரமான அல்லது சிதைக்கும்  குறியீடுகள் இல்லாத, முகவர், நிகழ்ச்சித்திட்டம் அல்லது நுண்பாகங்கள் மற்றும் / அல்லது ஏனைய தீங்கு விளைவிக்கும் பாகங்கள்.",
    des503:
      "உரிமையாளரானவர், இந்த இணையத் தளத்திலிருந்து கிடைக்கக்கூடிய அல்லது இணையத்தளத்திலுள்ள உள்ளடக்கங்கள் மீதான நம்பகமான பெறுபேறாக பாதிப்புக்குள்ளான ஏதேனும் சேதம் அல்லது நட்டத்திற்கு மட்டுப்படுத்தப்படாத ஆனால் உள்ளடங்குகின்ற இந்த இணையத்தள பயன்பாடு அல்லது அணுக முடியாமையின் (நேரடியாகவோ அல்லது மறைமுகமாகவோ) விளைவாக ஏற்படக் கூடிய ஏதேனும் சேதம் அல்லது நட்டத்திற்கும் பொறுப்பேற்க மாட்டார்.",
    des504:
      "உரிமையாளரானவர், இந்த இணைய தளம் மற்றும் / அல்லது இந்த இணைய தளத்தின் உரிமையாளர் தவிர்ந்த மூன்றாம் தரப்பு சேவை வழங்குனரினால் ஏற்படும் ஏதேனும் வலையமைப்பு இடையூறுகள் அல்லது வேறு ஏதேனும் இடையூறுகளின் விளைவாக (நேரடியாகவோ அல்லது மறைமுகமாகவோ) ஏற்படுகின்ற ஏதேனும் முழுமையான அல்லது பகுதியளவிலான தகவல் சேதம் அல்லது  நட்டத்திற்குப் பொறுப்பேற்க மாட்டார். ",
    des505:
      "இதில் வழங்கப்பட்டுள்ள தகவலானது பயனரின் கடப்பாடுகளின் சிறந்த புரிதலுக்காக வழங்கப்பைட்டுள்ளதுடன் தொழில்சார்  அல்லது சட்ட மதியுரைகளினை நிறைவேற்றவில்லை. இதிலுள்ள உள்ளடக்கங்கள் தற்போதிருக்கும் சட்டம் மற்றும் செயற்பாடுகளுக்கு இசைவானதாக உள்ளைமையினை உறுதி செய்வதற்காக ஒவ்வொரு முயற்சியும்  மேற்கொள்ளப்படும் அதேவேளையில், பொதுமக்கள் தமது சட்டரீதியான நடவடிக்கைகளில் உறுதியாக இருக்க தொடர்புடைய சட்டவாக்கத்தினை அல்லது தொழில்சார் கடப்பாடுகளினை நிலையானதாகவே உள்ளன. பொதுமக்கள் உரிய சட்டவாக்கம் அல்லது அவற்றின் சட்ட கடப்பாடுகளினைப் பார்க்குமாறு  ஆலோசனை வழங்கப்படுகின்றார்கள்.",
    h6: "6.	அணுகும் உரிமை",
    des601:
      "உரிமையாளருக்கு, எவ்வித காரணங்களையும் குறிப்பிடாது மற்றும் / அல்லது எத்தகைய அறிவித்தல் எதனையும்  விடுக்ந, இந்த இணையத் தளத்தினை குறிப்பிட்டதொரு இணைய முகவரியிலிருந்து அணுகுவதனைத் தடுக்கலாம் அல்லது எவரேனும் குறிப்பிட்ட ஆள் இந்த இணையத் தளத்தினை அணுகுவதனை கட்டுப்படுத்துவதற்கும் மறுப்பதற்குமான சகல உரிமைகளும் உள்ளன.",
    h7: "7.	மூன்றாம் தரப்பு இணையத் தளங்கள்",
    des701: "இந்த இணையத்தளத்திலிருந்து ஏனைய இணையத்தளங்களுக்கான இணைப்புக்கள்.",
    des70101:
      "இந்த இணையத்தளமானது உரிமையாளரினால் பேணப்படுகின்ற இணையத்தளங்களுக்கான உயர் இணைப்புக்களைக் கொண்டுள்ளது. உரிமையாளர் அத்தகைய இணையத்தளங்களின் உள்ளடக்கங்களுக்கு பொறுப்பு வாய்ந்தவரல்ல என்பதுடன் அத்தகைய இணையத்தளங்களினை பயன்படுத்துதல் மற்றும் / அல்லது அணுகுவதனால் எழுகின்ற ஏதேனும் சேதங்கள் அல்லது இழப்புக்களுக்கும் பொறுப்பாளியாக மாட்டார்.  உயர் இணைப்புக்களைப் பயன்படுத்துவதும் அத்தகைய இணையத்தளங்களை அணுகுவதும் முற்றுமுழுதாக உங்களின் பொறுப்பாகும்.",
    des70102:
      "ஏனைய இணையத்தளங்களுக்கான உயர் இணைப்புக்கள் பயனருக்கு வசதியாகவே வழங்கப்படுகின்றன. எந்தவொரு சந்தர்ப்பத்திலும் உரிமையாளர் அத்தகைய வலைத்தளங்களை ஆதரப்பதாகவோ அல்லது சரி பார்ப்பதாகவோ கருதப்பட மாட்டார். அல்லது எந்தவொரு வர்த்தக அல்லது சேவைக் குறிகள், இலச்சனைகள், சின்னங்கள் அல்லது இந்த இணையத் தளம் இணைக்கட்டுள்ள வலைத்தளங்களில் பயன்படுத்தப்படும் அல்லது தோன்றும் ஏனைய சாதனங்களுடன் உரிமையாளர் தொடர்புடையவராகவோ அல்லது இணைக்கப்பட்டவராகவோ கருதப்பட மாட்டார்.",
    des702: "ஏனைய இணையத்தளங்களிலிருந்து இந்த இணையத் தளத்துக்கான இணைப்புக்கள்.",
    des70201:
      "எந்தச் சூழ்நிலையிலும், இந்த இணையதளம் அல்லது உள்ளடக்கத்தில் உயர் இணைப்புச் செய்யும் அத்தகைய இணையதளங்களின் உள்ளடக்கங்களை உரிமையாளர் ஆதரிப்பதாகவோ அல்லது சரிபார்ப்பதாகவோ கருதப்பட மாட்டார், மேலும் உரிமையாளர் எந்தவொரு வர்த்தகம் அல்லது சேவைக் குறிகளுடன்  எந்த வகையிலும் தொடர்புடையதாகவோ அல்லது இணைக்கப்பட்டவராகவோ கருதப்பட மாட்டார். இலச்சினைகள், சின்னங்கள் அல்லது இந்த இணையதளம் அல்லது உள்ளடக்கங்களில் ஏதேனும் ஒன்றை உயர் இணைப்புச் செய்யும் இணையதளங்களில் பயன்படுத்தப்படும் அல்லது தோன்றும் ஏனைய  சாதனங்கள்.",
    des70202:
      "உரிமையாளருக்கு, ஏதேனும் எழுத்து மூலமான சட்டம், ஏதேனும் பொருத்தமான புலமைச்சொத்து, சொத்துவம், பிரத்தியேக அல்லது பிரசித்தப்படுத்தல் உரிமைகள் என்பவற்றினை மீறுகின்ற பொருத்தமற்ற, அவதூறான, அளவுக்கு மீறய, ஆபாசமான, அநாகரிகமான அல்லது சட்டவிரோத தலைப்புக்கள், பெயர்கள் பொருள் அல்லது தகவலினைக் கொண்டுள்ள ஏதேனும் இணைய தளத் திரைகளினை முடக்குவதற்கு அனைத்து உரிமைகளும் உள்ளன.",
    des70203:
      "எந்தவொரு அங்கீகரிக்கப்படாத உயர் இணைப்புக்கள் அல்லது திரைகளினை   முடக்குவதற்கு உரிமையாளருக்கு உரிமை உள்ளது மற்றும் இந்த வலைத்தளம் அல்லது உள்ளடக்கங்களில் ஏதேனும் உயர் இணைப்பு மூலம் அடையப் பெற்ற வேறு ஏதேனும் வலைத்தளத்தில் கிடைக்கப் பெறும் உள்ளடக்கத்திற்கான எந்தவொரு பொறுப்பையும் மறுதலிக்கின்றது.",
    h8: "8.	பொதுவானவை",
    des801: "இற்றைப்படுத்தல்களும் திருத்தங்களும்",
    des80101: `இத்தகைய பயன்பாட்டு நியதிகள் உரிமையாளரின் முற்றுமுழுதான தற்துணிபின் அடிப்படையில் காலத்திற்குக் காலம் மாறலாம். அத்தகைய இற்றைப்படுத்தல்களானவை அத்தகைய இற்றைப்படுத்தல் நிகழுகின்ற போது உள்ளவாறு இந்த இணையத்தளத்தில் இடுகை இடப்படும். இத்தகைய பயன்பாட்டு நியதிகளில் ஏதேனும் மாற்றங்கள் மேற்கொள்ளப்படின், உண்மையான மாற்றங்கள் மேற்கொண்டதன் பின்னர் எமது சேவைகளிலுள்ள “பயன்பாட்டு நியதிகள்” எனும் இணைப்புடன் “புதிதாக இற்றைப்படுத்தப்பட்டது” எனும் லேபலினை உள்ளடக்குவதன் மூலம் நாம் உங்களுக்கு அறியத்தருவோம்.`,
    des802: "ஆளும் சட்டம் மற்றும் நியாயாதிக்கம்",
    des80201:
      "இத்தகைய நியதிகள் மற்றும் நிபந்தனைகள் ஆங்கில சட்டத்திற்கமைவாக நிருவகிக்கப்படுவதாக பொருள் கொள்ளப்படுவதுடன் நீங்கள் இலங்கை நீதிமன்றங்களின் பிரத்தியேகமல்லாத நியாயாதிக்கத்திற்கு இத்தால் சமர்ப்பிக்கின்றீர்கள்.",
    des803: "முடிவுறுத்தல்",
    des80301:
      "நாம், உங்களினால் நியதிகள் மட்டுப்பாடின்றி மீறப்படுவது உள்ளடங்கலான ஏதேனும் அது போன்ற காரணங்களினால் எவ்வித முன்னறிவித்தலுமின்றி அல்லது பதில் ஏதும் கூறாமல் எமது இணையத்தளம் மற்றும் / அல்லது சேவைக்கான அணுகலினை நாம் உடனடியாக நிறுத்தலாம் அல்லது  இடைநிறுத்தலாம். மட்டுப்படுத்தலின்றி, உரித்துடமை ஏற்பாடுகள், உத்தரவாத பொறுப்புத் துறப்புக்கள், இழப்பீடு மற்றும் பொறுப்பு மட்டுப்படுத்தல்கள் உள்ளடங்கலான முடிவுறுத்தலானது அவற்றின் தன்மைக்கேற்ப நியதிகளின் அனைத்து ஏற்பாடுகளும் முடிவுறுத்தலினைத் தக்க வைத்துக் கொள்ளும்.",
    des804: "வெவ்வேறாக்கப்படக்கூடிய தன்மை",
    des80401:
      "இந்த விதிமுறைகள் மற்றும் நிபந்தனைகள் வெவ்வேறாக்கப்படக் கூடியவை, இதில் ஏதேனும் ஒரு விதி சட்டவிரோதமானது அல்லது செயல்படுத்த முடியாதது என ஏதேனும் நீதிமன்ற நியாயாதிக்கத்தினால் தீர்மானிக்கப்பட்டால், அத்தகைய ஏற்பாடானது இந்த நியதிகள் மற்றும் நிபந்தனைகளின் எஞ்சியுள்ள ஏற்பாடுகளினைப் பாதிக்காத வகையில் நீக்கப்பட்டதாகக் கருதப்படுதல் வேண்டும்.",
    des805: "தள்ளுபடி",
    des80501:
      "இந்த விதிமுறைகள் மற்றும் நிபந்தனைகளின் எந்தவொரு குறிப்பிட்ட உரிமையையும் அல்லது விதியையும் நாம் பயன்படுத்தத் தவறினால், எழுத்துப்பூர்வமாக எங்களால் ஒப்புக்கொள்ளப்பட்டாலன்றி, அத்தகைய உரிமை அல்லது விதியை தள்ளுபடி செய்தலாகாது.",
    des806: "பிரதிநிதித்துவங்கள் ",
    des80601:
      "இந்த நியதிகள் மற்றும் நிபந்தனைகளுக்குள் நுழைவதில் நீங்கள் எவரேனும் நபரின் (இந்த நியதிகள் மற்றும் நிபந்தனைகளுக்கு உட்பட்டவராக இருந்தாலும் இல்லாவிட்டாலும்) எந்த அறிக்கை, பிரதிநிதித்துவம், உத்தரவாதம் அல்லது புரிதல் (அலட்சியமாக அல்லது அப்பாவித்தனமாக செய்யப்பட்டதாக இருந்தாலும்) தொடர்பாக எந்தப் பரிகாரமும் கிடைக்க மாட்டாது என்பதை நீங்கள் ஒப்புக் கொள்கிறீர்கள். நியதிகள்கள் மற்றும் நிபந்தனைகள் அல்லது இல்லை) உத்தரவாதமாக இத்தகைய நியதிகள் மற்றும் நிபந்தனைகளில் வெளிப்படையாக அமைக்கப்பட்டுள்ளதைத் தவிர, மோசடிக்கான எந்தவொரு பொறுப்பையும் கட்டுப்படுத்தவோ அல்லது விலக்கவோ இந்த உட்பிரிவில் உள்ள எதுவும் தொழிற்படாது.",
    des807: "Force Majeure",
    des80701:
      "உரிமையாளரானவர் அவரின் நியாயமான கட்டுப்பாட்டுக்கு அப்பாற்பட்ட எந்தவொரு காரணத்திற்காகவும், கடவுளின் செயல், சீரற்ற வானிலை, இணைய சேவை வழங்குனர்களின் ஏதேனுமொரு செயல் அல்லது புறக்கணிப்பு, அரசாங்கம் மற்றும் ஏனைய தகைமை வாய்ந்த அதிகாரியின் நடவடிக்கை அல்லது புறக்கணிப்பு காரணமாக இத்தகைய நியதிகள் மற்றும் நிபந்தனைகளின் மீறுகின்ற ஏதேனுதொரு நடவடிக்கை தொடர்பில் பொறுப்பேற்க மாட்டார்.",
    des808: "தொடர்புகளுக்கு",
    des80801:
      "இத்தகைய நியதிகள் மற்றும் நிபந்தனைகள் பற்றி ஏதேனும் விளக்கங்கள் உங்களுக்குத் தேவைப்படின், நீங்கள் பின்வரும் மின்னஞ்சல் முகவரியினூடாக தொடர்பு கொள்ளலாம்; ",
  },
};

const TermsAndConditionsContent = ({ locale }) => {
  window.scrollTo(0, 0);
  return (
    <div className="termsAndConditionsPageWrapper">
      <div
        className="erlContent erlIntroduction"
        style={{ textAlign: "justify" }}
      >
        <p>
          <FormattedMessage id="lastUpdate" value={{ locale }} />
        </p>
        <br></br>
        <p>
          <FormattedMessage id="des1" value={{ locale }} />
        </p>
        <br></br>
        <p>
          <FormattedMessage id="des2" value={{ locale }} />
        </p>
        <br></br>
        <p>
          <FormattedMessage id="des3" value={{ locale }} />
        </p>
        <br></br>
        <p>
          <h5>
            <b>
              <FormattedMessage id="h1" value={{ locale }} />
            </b>
          </h5>
        </p>
        <br></br>
        <ol>
          <li>
            <FormattedMessage id="des101" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des102" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des103" value={{ locale }} />
            <ol>
              <li>
                <FormattedMessage id="des10301" value={{ locale }} />
              </li>
              <li>
                <FormattedMessage id="des10302" value={{ locale }} />
              </li>
              <li>
                <FormattedMessage id="des10303" value={{ locale }} />
              </li>
            </ol>
          </li>
          <li>
            <FormattedMessage id="des104" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des105" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des106" value={{ locale }} />
          </li>
        </ol>
        <br></br>
        <p>
          <h5>
            <b>
              <FormattedMessage id="h2" value={{ locale }} />
            </b>
          </h5>
        </p>
        <br></br>
        <ol>
          <li>
            <FormattedMessage id="des201" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des202" value={{ locale }} />
          </li>
        </ol>
        <br></br>
        <p>
          <h5>
            <b>
              <FormattedMessage id="h3" value={{ locale }} />
            </b>
          </h5>
        </p>
        <br></br>
        <ol>
          <li>
            <FormattedMessage id="des301" value={{ locale }} />
          </li>
        </ol>
        <br></br>
        <p>
          <h5>
            <b>
              <FormattedMessage id="h4" value={{ locale }} />
            </b>
          </h5>
        </p>
        <br></br>
        <ol>
          <li>
            <FormattedMessage id="des401" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des402" value={{ locale }} />
          </li>
        </ol>
        <br></br>
        <p>
          <h5>
            <b>
              <FormattedMessage id="h5" value={{ locale }} />
            </b>
          </h5>
        </p>
        <br></br>
        <ol>
          <li>
            <FormattedMessage id="des501" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des502" value={{ locale }} />
            <ol>
              <li>
                <FormattedMessage id="des50201" value={{ locale }} />
              </li>
              <li>
                <FormattedMessage id="des50202" value={{ locale }} />
              </li>
            </ol>
          </li>
          <li>
            <FormattedMessage id="des503" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des504" value={{ locale }} />
          </li>
          <li>
            <FormattedMessage id="des505" value={{ locale }} />
          </li>
        </ol>
        <br></br>
        <p>
          <h5>
            <b>
              <FormattedMessage id="h6" value={{ locale }} />
            </b>
          </h5>
        </p>
        <br></br>
        <ol>
          <li>
            <FormattedMessage id="des601" value={{ locale }} />
          </li>
        </ol>
        <br></br>
        <p>
          <h5>
            <b>
              <FormattedMessage id="h7" value={{ locale }} />
            </b>
          </h5>
        </p>
        <br></br>
        <ol>
          <li>
            <FormattedMessage id="des701" value={{ locale }} />
            <ol>
              <li>
                <FormattedMessage id="des70101" value={{ locale }} />
              </li>
              <li>
                <FormattedMessage id="des70102" value={{ locale }} />
              </li>
            </ol>
          </li>
          <li>
            <FormattedMessage id="des702" value={{ locale }} />
            <ol>
              <li>
                <FormattedMessage id="des70201" value={{ locale }} />
              </li>
              <li>
                <FormattedMessage id="des70202" value={{ locale }} />
              </li>
              <li>
                <FormattedMessage id="des70203" value={{ locale }} />
              </li>
            </ol>
          </li>
        </ol>
        <br></br>
        <p>
          <h5>
            <b>
              <FormattedMessage id="h8" value={{ locale }} />
            </b>
          </h5>
        </p>
        <br></br>
        <ol>
          <li>
            <FormattedMessage id="des801" value={{ locale }} />
            <p>
              <FormattedMessage id="des80101" value={{ locale }} />
            </p>
          </li>
          <li>
            <FormattedMessage id="des802" value={{ locale }} />
            <p>
              <FormattedMessage id="des80201" value={{ locale }} />
            </p>
          </li>
          <li>
            <FormattedMessage id="des803" value={{ locale }} />
            <p>
              <FormattedMessage id="des80301" value={{ locale }} />
            </p>
          </li>
          <li>
            <FormattedMessage id="des804" value={{ locale }} />
            <p>
              <FormattedMessage id="des80401" value={{ locale }} />
            </p>
          </li>
          <li>
            <FormattedMessage id="des805" value={{ locale }} />
            <p>
              <FormattedMessage id="des80501" value={{ locale }} />
            </p>
          </li>
          <li>
            <FormattedMessage id="des806" value={{ locale }} />
            <p>
              <FormattedMessage id="des80601" value={{ locale }} />
            </p>
          </li>
          <li>
            <FormattedMessage id="des807" value={{ locale }} />
            <p>
              <FormattedMessage id="des80701" value={{ locale }} />
            </p>
          </li>
          <li>
            <FormattedMessage id="des808" value={{ locale }} />
            <p>
              <FormattedMessage id="des80801" value={{ locale }} />
              <a href={"mailto:" + "info@erl2.gov.lk"}>info@erl2.gov.lk</a>
            </p>
          </li>
        </ol>
        <br></br>
      </div>
    </div>
  );
};

const TermsAndConditions = () => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <Template selectedLan={locale}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <UICard
          borderLess={true}
          title={<FormattedMessage id="heading" value={{ locale }} />}
          titlePlacement={cardTitlePlacement.LEFT}
          titleStyle="mainTxtHeading"
          cardSize={cardSize.LARGE}
        >
          <br></br>
          <TermsAndConditionsContent locale={locale} />
        </UICard>
      </IntlProvider>
    </Template>
  );
};

const TermsAndConditionsPopUp = () => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <TermsAndConditionsContent locale={locale} />
    </IntlProvider>
  );
};

export {
  TermsAndConditions,
  TermsAndConditionsContent,
  TermsAndConditionsPopUp,
};
