/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:54:15
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-15 14:36:02
 */
import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { DataTable } from "../../../ui-components/ui-elements/data-table/DataTable";
import { useContactUsHook } from "../../../hooks/useContactUsHook";
import { AnimatePresence, motion } from "framer-motion";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { useState } from "react";
import { VehicleNumber, ChassisNumber } from "../../../../atom";

const messages = {
  en: {
    title: "Contact Us",
  },
  si: {
    title: "අපව අමතන්න",
  },
  ta: {
    title: "எம்முடன் தொடர்பு கொள்வதற்கு",
  },
};

const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

const pageTransition = {
  duration: 2,
};

const ContactUsTableHeadEnglish = [
  {
    displayName: "PDMT Office Name",
    key: "name",
  },
  {
    displayName: "Address",
    key: "addresses",
  },
  {
    displayName: "Contact Number",
    key: "phone",
  },
  {
    displayName: "Email",
    key: "email",
  },
];
const ContactUsTableHeadSinhala = [
  {
    displayName: "අදායම් බලපත්‍ර නිකුත් කිරීමේ මධ්‍යස්ථානය",
    key: "name",
  },
  {
    displayName: "ලිපිනය",
    key: "addresses",
  },
  {
    displayName: "සම්බන්ධ කර ගත හැකි දුරකථන අංකය",
    key: "phone",
  },
  {
    displayName: "විද්‍යුත් ලිපිනය",
    key: "email",
  },
];
const ContactUsTableHeadTamil = [
  {
    displayName: "வருமான அனுமதிப் பத்திரம் வழங்கும் நிலையம்",
    key: "name",
  },
  {
    displayName: "முகவரி",
    key: "addresses",
  },
  {
    displayName: "தொடர்பு இலக்கம்",
    key: "phone",
  },
  {
    displayName: "மின்னஞ்சல் ",
    key: "email",
  },
];

const ContactUsTableRows = [
  {
    issuingCentre: "Department of Motor Traffic - Province A",
    address: "Department of Motor Traffic, Province A",
    contactNumber: "94117000001",
    email: "info@a.province.com",
  },
  {
    issuingCentre: "Department of Motor Traffic - Province B",
    address: "Department of Motor Traffic, Province B",
    contactNumber: "94117000002",
    email: "info@b.province.lk",
  },
  {
    issuingCentre: "Department of Motor Traffic - Province C",
    address: "Department of Motor Traffic, Province C",
    contactNumber: "94117000003",
    email: "info@c.province.com",
  },
  {
    issuingCentre: "Department of Motor Traffic - Province D",
    address: "Department of Motor Traffic, Province D",
    contactNumber: "94117000004",
    email: "info@d.province.lk",
  },
];

const ContactUs = () => {
  const locale = useRecoilValue(LanguageFilter);
  const [contactDataList, setcontactDataList] = useState([]);
  const { contactUsData } = useContactUsHook();

  const [vehicleNumberState, setVehicleNumber] = useRecoilState(VehicleNumber);
  const [chassisNumberState, setChassisNumber] = useRecoilState(ChassisNumber);

  useEffect(() => {
    setVehicleNumber("");
    setChassisNumber("");
  }, []);

  useEffect(() => {
    const arry = [];
    let obj = {};

    setcontactDataList(arry);

    contactUsData &&
    contactUsData.map((data) => {
        obj = {
          name: data.name,
          addresses:
            data.addresses.length > 0
              ? data?.addresses[0]
              : "N/A",
          phone: data.phone[0],
          email: data.email,
        };

        arry.push(obj);
      });

    setcontactDataList(arry);
  }, [contactUsData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Template activeHeader="contactUs" selectedLan={locale}>
      <MetaTags>
        <meta
          name="description"
          content="Provincial Department of Motor Traffic (PDMT) is the authorized body to manage operations related in; issuing Vehicle Revenue Licenses for registered vehicles and regulation of passenger carriage and goods carriage service within the Provinces. There are 9 PDMTs in all 9 Provinces."
        />
        <meta
          name="keywords"
          content="how to contact, contact us, PDMT, provincial department of motor traffic"
        />
      </MetaTags>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <motion.div
          // initial="out"
          // animate="in"
          // exit="out"
          // variants={pageVariants}
          // transition={pageTransition}
        >
          <div className="">
            <UICard
              title={
                <div className="ml-3 pl-1">
                  <FormattedMessage
                    id="title"
                    value={{ locale }}
                  ></FormattedMessage>
                </div>
              }
              titlePlacement={cardTitlePlacement.LEFT}
              cardSize={cardSize.LARGE}
              isContentCenter={true}
              titleStyle="mainTxtHeading "
            >
              <br></br>
              {/* <DataTable
          dataTableWrapperStyle={"mt-5 mb-5"}
          tableHeaderList={ContactUsTableHead}
          tableBodyList={contactUsData.value.value}
        /> */}
              <div className=" ml-3 pl-1 mr-4 pr-1">
                {contactUsData ? (
                  <DataTable
                    tableHeaderList={
                      locale === "en"
                        ? ContactUsTableHeadEnglish
                        : locale === "si"
                        ? ContactUsTableHeadSinhala
                        : ContactUsTableHeadTamil
                    }
                    // tableBodyList={contactUsData.data.data.sort((a, b) =>
                    //   a.name.localeCompare(b.name)
                    // )}
                    tableBodyList={contactDataList}
                  />
                ) : // <CompanyListTable
                //   label={"Insurance Companies"}
                //   headerData={InsuranceCompaniesTableHead}
                //   bodyData={insCompanies}
                // />
                null}
              </div>
            </UICard>
          </div>
        </motion.div>
      </IntlProvider>
    </Template>
  );
};

export default ContactUs;
