/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-29 15:34:33
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-19 13:26:37
 */
import React from "react";
import { Link } from "react-router-dom";
import { emptyFun } from "../../../../helpers/common-helpers/common.helpers";
import { Image } from "../common/BaseElements";
import { CloseButton } from "../form/Button";

const Popup = ({
  handleClose = emptyFun,
  content = null,
  title = "",
  isSetHeader = true,
  isSetCloseButton = true,
}) => {
  return (
    <div className="popupBox">
      <div className="box">
        <div className="popup">
          {isSetHeader && (
            <div className="popupHeader">
              <div className="row">
                <div className="col-md-10 col-sm-6">
                  <div className="popupTitle">{title}</div>
                </div>
                <div className="col-md-2 col-sm-6">
                  {isSetCloseButton && (
                    <button
                      className="popupCloseButton"
                      onClick={handleClose}
                    >
                      <i className="mdi mdi-close"></i>
                    </button>
                   
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="popupContent">{content}</div>
        </div>
      </div>
    </div>
  );
};

const MenuPopup = ({
  content = null,
  title = "",
  subtitle = "",
  imageSrc = "",
  imageAlt = "",
  isSetHeader = true,
  children = null,
}) => {
  return (
    <div className="menuPopupBox">
      <div className="box">
        <div className="popup">
          {isSetHeader && (
            <Link to="user-profile">
              <div class="card menuPopupHeader paddingNone">
                <div class="row no-gutters">
                  <div class="col-md-3 imagePadding">
                    <Image src={imageSrc} type="card-img" alt={imageAlt} />
                  </div>
                  <div class="col-md-9">
                    <div class="card-body menuHeaderBody">
                      <h5 class="card-title">{title}</h5>
                      <p class="card-text">{subtitle}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
          <div className="popupContent">
            {content}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Popup, MenuPopup };
