/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2021-01-18 11:28:45
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-10 15:07:43
 */

import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";

const TopHeader = ({ selectedLan = "" }) => {
  const [language, setSelectedLanguage] = useRecoilState(LanguageFilter);

  return (
    <div className="topHeaderWrapper">
      <div className="erlContent">
        <div className="row">
          <div className="col topBarContactWrapper">
            
            <div className="emailWrapper" style={{paddingLeft:"5%"}}>
              <i className="mdi mdi-email-outline" />
              info@erl2.gov.lk
            </div>
            
            <div className="contactWrapper">
              {/* <i className="mdi mdi-phone" /> */}
            </div>
          </div>
          
          <div className="col-md-4" style={{marginRight:"25%",paddingLeft:"8%"}}>
          <div  >
         <b>Online Vehicle Revenue Licence Service</b> 
          </div>
          </div>
          <div className="col-md-1 topBarLanguageWrapper alignRight ml-auto" style={{paddingRight:"4%"}}>
           
            {selectedLan === "en" ? (
              <>
                <div
                  className="langIIWrapper"
                  onClick={() => setSelectedLanguage("si")}
                  style={{ cursor: "pointer" }}
                >
                  සිංහල
                </div>

                <span>|</span>

                <div
                  className="langIIWrapper"
                  onClick={() => setSelectedLanguage("ta")}
                  style={{ cursor: "pointer" }}
                >
                  தமிழ்
                </div>
              </>
            ) : (
              <></>
            )}

            {selectedLan === "si" ? (
              <>
                <div
                  className="langIIWrapper"
                  onClick={() => setSelectedLanguage("ta")}
                  style={{ cursor: "pointer" }}
                >
                  தமிழ்
                </div>

                <span>|</span>

                <div
                  className="langIIWrapper"
                  onClick={() => setSelectedLanguage("en")}
                  style={{ cursor: "pointer" }}
                >
                  English
                </div>
              </>
            ) : (
              <></>
            )}

            {selectedLan === "ta" ? (
              <>
                <div
                  className="langIIWrapper"
                  onClick={() => setSelectedLanguage("si")}
                  style={{ cursor: "pointer" }}
                >
                  සිංහල
                </div>

                <span>|</span>

                <div
                  className="langIIWrapper"
                  onClick={() => setSelectedLanguage("en")}
                  style={{ cursor: "pointer" }}
                >
                  English
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
         
          
        </div>
       
      </div>
    </div>
  );
};

export { TopHeader };
