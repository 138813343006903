import { useState } from "react";
import { vehicleInfoAPI } from "../../config/apiUrl.config";
import { callApi } from "../../helpers/common-helpers/callApi.helpers";

export const usePaymentCompleteHook = () => {
  const [licenseData, setLicenseData] = useState();
  const [errorInfo, setErrorInfo] = useState();

  const printLicense = async (body) => {
    console.log('Payment Request',body);
    callApi(`${vehicleInfoAPI.url}/complete-pay`)
      .method("put")
      .headers(true)
      .body(body)
      .send((error, result) => {
        if (result) {
          setLicenseData(result.data.data);
          console.log("Licence Data",result.data.data);
        } else {
          console.log("Error Data",error);
          setErrorInfo(error);
        }
      });
  };

  return {
    printLicense,
    licenseData,
    errorInfo,
  };
};
