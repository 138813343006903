/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-27 12:52:11
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 14:26:03
 */
import { useContext, useEffect, useState } from "react";

import { checkUserINLocalStorage } from "../../helpers/common-helpers/manageStorage.helpers";
//import {isMobile} from "../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../modules/core/context-providers/AuthContext.provider";
import { UIContext } from "../ui-components/context-providers/UIContext.provider";
import { isAuthorizedINGlobalState } from "./helper/init.helpers";
/**
 *
 * @param {String} routeKey
 * @param {String} type auth|guess
 * @returns
 */
const useKeycloak = (routeKey, type = "auth") => {
  const [initState, setState] = useState(false);
  const [authStatus, authAction] = useContext(AuthContext);
  const [, uiAction] = useContext(UIContext);

  useEffect(() => {
    uiAction.setCurrentRouteFn(routeKey);

    if (
      // authStatus.isAuthenticated === false ||
      authStatus.isAuthenticated === true
    ) {
      setState(true);
    } else {
      const localStoreData = checkUserINLocalStorage();
      if (localStoreData.status === true) {
        authAction.setTokensFn(localStoreData.result);
        setState(true);
      } else {
        isAuthorizedINGlobalState(authStatus, authAction, (error, status) => {
          setState(true);
        });
      }
    }
  }, []);

  return [initState, authStatus.isAuthenticated];
};

export default useKeycloak;
