/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-01 16:38:31
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-05 14:57:31
 */

import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";

const TableWrapper = ({
  tableHeaderList = [],
  tableBodyList = [],
  isSetStatus = false,
  statusIcon = "",
}) => {
  return (
    <div className="overflowX">
      <table className="table table-striped">
        <TableHead
          tableHeaderList={tableHeaderList}
          isSetStatus={isSetStatus}
          statusIcon={statusIcon}
        />
        <TableBody
          tableHeaderList={tableHeaderList}
          tableBodyList={tableBodyList}
          isSetStatus={isSetStatus}
        />
      </table>
    </div>
  );
};

export { TableWrapper };
