/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-19 14:20:05
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 17:48:32
 */
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import {
  CoreContext,
  apiResponseStoringType,
} from "../../../modules/core/context-providers/CoreContext.provider";
import { Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { CircleLoaderElement } from "../circle-loader-element/CircleLoaderElement";

const emptyFun = (...para) => undefined;

const Form = ({
  elementStyle = "",
  isBackProsess = false,
  setDescription = "",
  setGroupName = "",
  formGroupLinkWith = "",
  setFormObject = {},
  apiUrl = "",
  onRebuildResponseFn = { emptyFun },
  onDestroyUnsetFormObject = true,
  children = null,
}) => {
  const [formState, formAction] = useContext(FormContext);
  const [, coreAction] = useContext(CoreContext);

  useEffect(() => {
    formAction
      .initFromObjectFn(setGroupName)
      .isBackProsess(isBackProsess)
      .setDescription(setDescription || setGroupName)
      .setGroupName(setGroupName)
      .setLinkWithOthers(formGroupLinkWith || setGroupName)
      .setFormObject(setFormObject)
      .setOnLoad(apiUrl ? true : false)
      .generate();

    if (apiUrl) {
      coreAction
        .sendRequestFn(apiUrl)
        .method("get")
        .setInitStoring(
          apiResponseStoringType.apiResponseToFormState,
          setGroupName
        )
        .setOnRebuildResponseFn((response) => onRebuildResponseFn(response))
        .send((error, result) => {});
    }

    return () => {
      if (onDestroyUnsetFormObject) {
        formAction.removeFromGroupFn(setGroupName);
      }
    };
  }, []);

  return (
    <div className={`fullWidthDiv ${elementStyle} marginBottom`}>
      {_get(formState, `${setGroupName}._onLoad`, false) === false ? (
        <Fragment>{children}</Fragment>
      ) : (
        <div>
          <br />
          <center>
            <CircleLoaderElement />
          </center>
        </div>
      )}
    </div>
  );
};

Form.propTypes = {
  /** FormWrapper element css class */
  elementStyle: PropTypes.string,
  /** set back process or not */
  isBackProsess: PropTypes.bool,
  /** set description form group */
  setDescription: PropTypes.string,
  /** set form group name*/
  setGroupName: PropTypes.string,
  /** link form group with other forms */
  formGroupLinkWith: PropTypes.string,
  /** set init form object */
  setFormObject: PropTypes.object,
  /** set init form object */
  children: PropTypes.node,
};

export { Form };
