/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-11 16:52:07
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 17:48:18
 */

const CircleLoaderElement = () => {
  return (
    <div className="loaderBox">
      <div className="circleLoader">
        <div class={`spinner-border spinner`} role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export { CircleLoaderElement };
