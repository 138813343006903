import React, { Fragment } from "react";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import Template from "../../../ui-components/templates/Template";
import { Label } from "../../../ui-components/ui-elements/form/Label";

const ReferenceDetails = () => {
  return (
    <Fragment>
      <Template>
        <UICard borderLess={true} title="Licence Details" titleStyle="mainTxtHeading ">
          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Reference No" />
            </div>
            <div className="col-md-3 col-sm-12">32423434</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Vehicle No" />
            </div>
            <div className="col-md-3 col-sm-12">WP-34343</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Revenue Licence No" />
            </div>
            <div className="col-md-3 col-sm-12">ED-23212</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Licence Duration" />
            </div>
            <div className="col-md-3 col-sm-12">2/12/2018 to 2/12/2012</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Validity Period" />
            </div>
            <div className="col-md-3 col-sm-12">14 Days</div>
          </div>
        </UICard>

        <UICard borderLess={true} title="Owner Details" titleStyle="mainTxtHeading ">
          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Name of the owner" />
            </div>
            <div className="col-md-3 col-sm-12">Asanka de silva</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Delivery address of the owner" />
            </div>
            <div className="col-md-3 col-sm-12">188/23,Colombo 10</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Contact No" />
            </div>
            <div className="col-md-3 col-sm-12">0725112443</div>
          </div>
        </UICard>

        <UICard borderLess={true} title="Payments Details" titleStyle="mainTxtHeading ">
          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Total payment amount" />
            </div>
            <div className="col-md-3 col-sm-12">32423434</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Approval code" />
            </div>
            <div className="col-md-3 col-sm-12">0000</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Payment Type" />
            </div>
            <div className="col-md-3 col-sm-12">Credit Card</div>
          </div>

          <div className="row">
            <div className={"col-md-3 col-sm-12"}>
              <Label labelText="Transaction date" />
            </div>
            <div className="col-md-3 col-sm-12">10/08/2020</div>
          </div>

          <div className="col-md-8 floatRight">
            <div className="row">
              <Button
                btnText={"Print temp licence"}
                btnType={"submit"}
                btnStyle={"btnPrimary"}
              />
            </div>
          </div>
        </UICard>
      </Template>
    </Fragment>
  );
};

export { ReferenceDetails };
