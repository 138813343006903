/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-29 14:19:05
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-15 15:56:07
 */
import React, { useEffect } from "react";
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import {
  Accordion,
  AccordionWrapper,
} from "../../../ui-components/ui-elements/accordion/Accordion";
import { AnimatePresence, motion } from "framer-motion";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { VehicleNumber, ChassisNumber } from "../../../../atom";
import MetaTags from "react-meta-tags";
const messages = {
  en: {
    title: "Frequently Asked Questions",
    general: "General",
    login: "Login/ Sign up",
    eRLService: "eRL Service",
    payment: "Payment",
    assistance: "Assistance",
    q101: "What is eRevenue Licence Service (eRL)?",
    a101: "eRL Service facilitates you to;\n1). Obtain the very first Revenue Licence after registration is done at the Department of Motor Traffic \n2). Renew existing Revenue Licence and \n3). Track the status of requested Revenue Licence Online",
    q102: "Who can obtain the Revenue Licence through eRL Service?",
    a102: "Anyone, who owns a vehicle (of defined vehicle Categories*) registered at the Department of Motor Traffic can obtain the Revenue Licence through eRL Service from anywhere in the world.",
    q103: "Can all the Vehicles get its Revenue Licence Online?",
    a103: "Following vehicle Categories* can obtain the Revenue Licence Online; Motor Cars, Motor Cars A-Z, Motor Cycles, Dual Purpose Vehicles, Land Vehicles, Land Vehicle Tailor, Three Wheelers, Motor Lorry, Motor Lorry Tailor, Motor Tricycle Van, Private Coach, Motor Home, Special Purpose Vehicle, Quadric-cycle, Ambulance and Hearse.",
    q104: "Do I need any documents to be submitted, to get the Revenue Licence Online?",
    a104: "No, you do not require to submit any documents to get the Revenue Licence Online.",
    q105: "Is there a Mobile App available for eRL Service?",
    a105: "No, but you can access the service through your Mobile device as well.",
    q106: "Does the Online Temporary Revenue Licence recognize as a valid service and how to prove its validity? ",
    a106: "Yes, the Online Temporary Revenue Licence is valid as per the Government Laws and the Temporary Receipt is a valid document for the time indicated on the receipt.You should use following options as proofs of validity of the Revenue Licence; \n1).Refer ‘Check Status’ option on eRL Service.\n2).Type ‘DMT RLS <vehicle no.>’ and SMS to 1919.\n3).Check the ‘Revenue Licence Status’ on Motor Vehicle Information App of the Department of Motor Traffic.\n4).Contact the relevant Provincial Department of Motor Traffic, as relevant to your address, which the vehicle is registered.",
    q201: "What is eRL user Login/ Sign up?",
    a201: "Login: Facilitates you to access the eRL Service with an existing Google or Facebook account.\nSign up: Facilitates you to access the eRL Service with your specific email ID and Password, if you do not have either Google or Facebook account.",
    q202: "What could be the reason, if I cannot Login/ Sign up?",
    a202: "You should check your internet connectivity and if it works fine, then check the validity of your Google/ Facebook account logins or Sign up email ID/ Password.",
    q203: "Can I change my Password or what if I forgot my Password?",
    a203: "Yes, you can reset your Password, if you have forgotten or need to change. Use the ‘Forget Password’ in the Login/ Sign up menu and click on the ‘Reset Password’ link in the email you get after the above step.",
    q204: "Can I have my User profile in the eRL Service?",
    a204: "Yes, you should click on your Login name on top of the Home page to access the User Profile. You can submit your Mobile phone no. to get the service notifications.",
    q301: "What facilities do I need to have to start with the eRL Service?",
    a301: "You need to have followings facilities to get the eRL Service;\n1).Google/ Facebook account or Sign up with your email ID/ Password.\n2).Valid Credit Card or QR App.\n3).Mobile phone, to get the OTP.",
    q302: "What are the requirements to obtain the Revenue Licence?",
    a302: "You need to have valid Insurance, Emission test (VET) and Fitness (VFT) certificate, as applicable to your vehicle Category.",
    q303: "How do I request for my Revenue License?",
    a303: "1).	Login using your Google/ Facebook account or Sign up with your User name/ Password, if you do not have either Google or Facebook account.\n2).	Submit the Vehicle No. and the Chassis No. (Last 6 digits) of your vehicle.\n3).	Confirm the vehicle details - Ownership, Insurance/ Emission (VET)/ Fitness (VFT) certification and Licence Fee.\n4).	Choose the Delivery method and Payment method.\n5).	Complete the payment.\n6).	Print the receipt for Temporary Revenue Licence from your email or by download on your device",
    q304: "What is a Temporary Revenue Licence?",
    a304: "Temporary Revenue Licence is generated by the eRL Service for you to keep with you till you get the Original Revenue Licence by post and valid for a duration as indicated on your temporary receipt.",
    q305: "How long does it take to receive the Original Revenue License by post?",
    a305: "You will receive the Original Revenue Licence within the time as indicated on the temporary receipt.",
    q306: "What can I do, if the vehicle Insurance/ Emission (VET)/ Fitness (VFT) are shown as ‘Expired’ or ‘Invalid’?",
    a306: "You should contact the respective service provider and renew your Insurance/ VET/ VFT certificate by visiting the nearest location of the service.",
    q307: "Can I get the Original Revenue Licence to my residential address, if it is different from the registered address of the vehicle?",
    a307: "Original Revenue Licence will be posted only at the given address in the Vehicle Registration System of the Department of Motor Traffic. You should visit the respective Provincial Department of Motor Traffic to collect your Revenue Licence (with Temporary receipt/ original CR/ NIC/ Driving licence), if you are at a different address.It is recommended to change the correct address by visiting the Department of Motor Traffic.",
    q308: "Do I need an Emission (VET) or Fitness (VFT) certificate to get the very First Revenue Licence of a vehicle?",
    a308: "No, VET or VFT certificate is not required to get the First Revenue License for a Brand New vehicle.",
    q309: "Can I renew the First Revenue License of a transferred vehicle through eRL Service Online?",
    a309: "Yes, you can renew the first Revenue Licence of a transferred vehicle through eRL Service.",
    q401: "What are the Payment Methods available to make Online payments?",
    a401: "You can use any Credit/ Debit card (VISA/ Master/ AMEX) or QR App.",
    q402: "How is the Revenue Licence Amount/ Fee calculated?",
    a402: "Licence Amount = Fee + Fine + Arrears + Additions -  Deductions\nFee – Rate decided as per the vehicle Category, by the Government.\nFine – Penalty charged as per the Licence Type and delayed period in renewing the Licence.\nArrears – Overdue amount (if any) that has to be paid.\nAdditions – Additional charges (if any) imposed by the Government. e.g. Taxes, Fine charges, etc.\nDeductions – Concessions in nature of discounts, allowances, etc. (if any) imposed by the Government.",
    q403: "Is there any additional costs charged, apart from the above?",
    a403: "Yes, a convenient fee (% of Licence fee) charged by the respective Bank (for Online payment) and delivery/ postal fee will be added.",
    q404: "What are the important things I should ensure during the payment?",
    a404: "Make sure your Mobile phone is with you to get the OTP and you should complete the payment process within the ‘time’ specified on the respective screen to avoid a ‘session timeout’.",
    q405: "Revenue Licence is not renewed, though the payment is deducted and can I get the Licence renewed or refund the payment already made?",
    a405: "Yes, refund will be arranged by the relevant Provincial Department of Motor Traffic, if the license is not renewed and payment will be refunded by the respective Bank.",
    q406: "Whom should I contact regarding the delay, if I have not received my refund yet?",
    a406: "You should contact the respective Bank or relevant Provincial Department of Motor Traffic, of the vehicle is registered to. You can find the details in Contact Us or1919",
    q501: "Whom should I contact for any assistance regarding the eRL Service?",
    a501: "You may contact 1919 (voice) or info@erl2.gov.lk (email).",
    q502: "How can I know if I do not receive my original Revenue License on time or to whom should I inform, if it is not received by post?",
    a502: "You can check your Revenue Licence status by clicking ‘Check Status’ on the Home page of eRL Service and shall contact the relevant Provincial Department of Motor Traffic, which the vehicle is registered. You can find the details in Contact Us.\nFurther, you can contact Sri Lanka Post on:\n011 2328 301-3, 011 2328 112 (Speed Post)\ninfo@slpost.lk/ pmg@slpost.lk\nhttps://slpost.gov.lk/contact-us/",
    q503: "How can I collect my Original Revenue Licence, if it is returned without delivering?",
    a503: "You should visit the Provincial Department of Motor Traffic, relevant to the owner’s address ((with Temporary receipt/ original CR/ NIC/ Driving licence), which the vehicle is registered.",
    q504: "Can I get service notifications from the eRL Service?",
    a504: "Yes, you can get the service notifications on your Mobile no. if the correct no. is given in your User Profile.",
    q505: "What are the exclusions in obtaining the Online Revenue Licence?",
    a505: "You cannot obtain your Revenue Licence Online for following scenarios;\n1).	If your vehicle Revenue Licence is not in ‘Renewable’ status.\n2).	If your vehicle Revenue Licence is not in ‘Expired’ status.\n3).	If the vehicle is ‘Ineligible’ or ‘Archive’ status.\n4).	If the vehicle is ‘Black Listed’ status – temporary banned by the Provincial Department of Motor Traffic for a valid reason.\n5).	If you have converted the ‘Fuel Type’ of your vehicle.",
    q506: "What does it mean by the Support Services?",
    a506: "Service that support you to get the Revenue Licence; e.g. Insurance, Emission (VET) and Fitness (VFT), which are essential as per your vehicle category.",
  },
  si: {
    title: "නිතර යොමු කරන ප්‍රශ්න (FAQs)",
    general: "සාමාන්‍ය",
    login: "පිවිසෙන්න/පූරණය වන්න",
    eRLService: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව",
    payment: "ගෙවීම්",
    assistance: "සහාය",
    q101: "විද්‍යුත් ආදායම් බලපත්‍ර සේවාව (eRL) යනු කුමක් ද?",
    a101: "ඔබට ලබා ගත හැකි විද්‍යුත් ආදායම් බලපත්‍ර (eRL)  සේවා පහසුකම් ; \n1). මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුවේ ලියාපදිංචිවීමෙන් අනතුරුව ප්‍රථම වරට ආදායම් බලපත්‍රය ලබා ගැනීම,\n 2). දැනට භාවිතා කරනු ලබන ආදායම් බලපත්‍රය අලුත් කිරීම සහ \n3). ඉල්ලුම්කර ඇති ආදායම් බලපත්‍රවල වර්තමාන තත්ත්වය පිළිබඳව මාර්ගගතව පරීක්ෂා කිරීම.",
    q102: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව හරහා ආදායම් බලපත්‍ර ලබා ගත හැක්කේ කාහට ද?",
    a102: "මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුවේ ලියාපදිංචි කර ඇති වාහනයක (අර්ථ කථනය කර ඇති වාහන කාණ්ඩ අතුරින්*) හිමිකරුවකු වන ඕනෑම පුද්ගලයකුට ලෝකයේ ඕනෑම ස්ථානයක සිට  විද්‍යුත් ආදායම් බලපත්‍ර (eRL)  සේවාව හරහා ආදායම් බලපත්‍ර ලබා ගැනීමේ  හැකියාව තිබේ.",
    q103: "සෑම වාහනයක් සඳහාම මාර්ගගත ආදායම් බලපත්‍ර ලබා ගත හැකි ද?",
    a103: "පහත සඳහන් වාහන වර්ග*  සඳහා ආදායම් බලපත්‍රය මාර්ගගතව ලබා ගත හැක; මෝටර් කාර්, මෝටර් කාර් A-Z, මෝටර් සයිකල්, ද්විත්ව කාර්ය වාහන, ඉඩම් වාහන, ඉඩම් වාහන ටේලර්, ත්‍රිරෝද රථ, මෝටර් ලොරි, මෝටර් ලොරි ටේලර්, මෝටර් ට්‍රයිසිකල් වෑන්, පුද්ගලික වාහන, මෝටර් හෝම්, විශේෂ කාර්ය වාහන, ක්වඩ්‍රික් - සයිකල්,  ගිලන්රථ- සහ අවමගුල් රථ.",
    q104: "ආදායම් බලපත්‍රය මාර්ගගතව ලබා ගැනීම සඳහා මා විසින් යම් ලියකියවිලි ඉදිරිපත් කිරීම අවශ්‍ය වේද ?",
    a104: "නැත. ආදායම් බලපත්‍රය මාර්ගගතව ලබා ගැනීම සඳහා ඔබ විසින් ලියකියවිලි කිසිවක් ඉදිරිපත් කිරීම අවශ්‍ය නොවේ.",
    q105: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව ලබා ගැනීම සඳහා ජංගම දුරකථන යෙදුමක් සපයා තිබේ ද? ",
    a105: "නැත. එහෙත් ඔබගේ ජංගම උපාංගය  හරහා මෙම සේවාවට ප්‍රවේශ විය හැක.",
    q106: "මාර්ගගත තාවකාලික ආදායම් බලපත්‍රය වලංගු සේවාවක් ලෙස පිළිගනු ලබයි ද? එමෙන්ම එහි වලංගුභාවය සනාථ කරන්නේ කෙසේ ද?",
    a106: "ඔව්. රජයේ නීති රීතිවලට අනුව මාර්ගගත තාවකාලික ආදායම් බලපත්‍රය වලංගු බලපත්‍රයක් වන අතර තාවකාලික රිසිට්පතේ දක්වා ඇති කාලය සඳහා  තාවකාලික රිසිට්පත වලංගු ලේඛනයක් වේ.\n1).ආදායම් බලපත්‍රයේ වලංගුභාවය සනාථ කිරීම සඳහා ඔබ විසින් පහත දැක්වෙන විකල්ප භාවිතා කළ යුතුය. විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාවේ දිස්වන ‘වර්තමාන තත්ත්වය පරීක්ෂා කරන්න’ විකල්පය වෙත යොමුවන්න.\n2).‘DMT RLS <වාහනයේ අංකය> යතුරු ලියනය කර 1919 ට කෙටි පණිවිඩයක් යොමු කරන්න. \n3).මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුවේ මෝටර් රථ තොරතුරු යෙදුමේ දක්වා ඇති ‘ආදායම්  බලපත්‍රයේ වර්තමාන තත්ත්වය’) ක්ලික් කරන්න. \n4).වාහනය ලියාපදිංචි කර ඇති ඔබගේ ලිපිනයට අදාළ පළාත් මෝටර් රථ වාහන දෙපාර්තමේන්තුව හා සම්බන්ධ වන්න. ",
    q201: "පිවිසෙන්න/පූරණය වන්න ",
    a201: "පිවිසෙන්න: සක්‍රිය ගූගල් හෝ මුහුණු පොත් ගිණුමක් ඔස්සේ විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව වෙත පිවිසීම සඳහා ඔබට පහසුකම් සලසනු ලැබේ.\nපූරණය වන්න:  ඔබට ගූගල් හෝ මුහුණු පොත් ගිණුමක් නොමැති නම් ඔබගේ නිශ්චිත විද්‍යුත් ලිපින අනන්‍යතා අංකය හා මුරපදය තුලින්  විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව වෙත පිවිසිම සඳහා ඔබට පහසුකම් සලසනුු ලැබේ.",
    q202: "මා හට පිවිසීමට/ පූරණය විමට  නොහැකි වුවහොත් මා හට කළ හැක්කේ කුමක් ද?",
    a202: "ඔබගේ අන්තර්ජාලය සම්බන්ධතාව පිළිබඳව ඔබ විසින් පරීක්ෂා කළ යුතු අතර අන්තර්ජාල සම්බන්ධතාව නිසි පරිදි ක්‍රියාත්මක වන්නේ නම්, ඔබගේ ගූගල් / මුහුණු පොත් ගිණුම් පිවිසීමේ   හෝ පූරණය විමේ  අංකය/ විද්‍යුත් ලිපින අනන්‍යතා අංකය/ මුරපදය ආදියෙහි වලංගුභාවය පරීක්ෂා කරන්න. ",
    q203: "මගේ මුරපදය මා හට වෙනස් කළ හැකි ද නැතහොත් මාගේ මුරපදය අමතක වී ඇත් නම් මා හට කළ හැක්කේ කුමක් ද?",
    a203: "ඔව්, ඔබගේ මුරපදය අමතක වී ඇත්නම් හෝ එය වෙනස් කිරීමට අවශ්‍ය වන්නේ නම් ඔබට ඔබගේ මුරපදය ප්‍රතිසැකසිය  හැක. පිවිසීමේ/ පූරණය විමේ  මෙනුවේ ඇති ‘මුරපදය අමතක වී ඇත’වෙත යොමු වී එම පියවරෙන් පසුව  විද්‍යුත් ලිපිනයෙහි  දිස්වන   ‘මුරපදය ප්‍රතිසකසන්න’ සම්බන්ධකය ක්ලික් කරන්න.",
    q204: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව තුල මා හට මගේ පරිශිලක තොරතුරු ලබා ගත හැකි ද? ",
    a204: "ඔව්, පරිශීලක තොරතුරු වෙත ප්‍රවේශවීම සඳහා මුල් පිටුවේ ඉහළින්ම ඇති ඔබගේ පිවිසුම් නාමය ඔබ විසින් ක්ලික් කළ යුතුය. සේවා නිවේදන ලබා ගැනීම සඳහා ඔබට  ඔබගේ ජංගම දුරකථන අංකය ලබා දිය  හැක.",
    q301: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව ලබා ගැනීම ආරම්භ කිරීම සඳහා මා සතුව තිබිය යුතු පහසුකම් මොනවා ද?",
    a301: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL)  සේවාව ලබා ගැනීම සඳහා ඔබ සතුව පහත සඳහන් පහසුකම් තිබිය යුතුය.\n1). ගූගල්/ මුහුණු පොත් ගිණුමක් හෝ ඔබගේ විද්‍යුත් ලිපින අනන්‍යතා අංකය/ මුරපදය තුලින් පූරණය වීම.\n2). වලංගු ක්‍රෙඩ්ට් කාඩ්පතක් හෝ QR යෙදුම එක් වරක් පමණක් පෙන්නුම් කරන මුරපදය (OTP) ලබා ගැනීම සඳහා ජංගම දුරකථන අංකය",
    q302: "ආදායම් බලපත්‍ර ලබා ගැනීම සඳහා සපුරා තිබිය යුතු සුදුසුකම් මොනවා ද?",
    a302: "ඔබගේ වාහන වර්ගයට අදාළ වන ආකාරයේ,  වලංගු රක්ෂණ,  වාහන දුම් පරීක්ෂණ (VET) සහ වාහන යෝග්‍යතා (VFT) සහතික ඔබ සතුව තිබිය යුතුය.",
    q303: "මගේ ආදායම් බලපත්‍රය සඳහා මා හට ඉල්ලුම් කළ හැක්කේ කෙසේ ද? ",
    a303: "1).	ඔබගේ ගූගල්/මුහුණු පොත් ගිණුම භාවිතා කරමින් පිවිසෙන්න. නැතහොත් ඔබ සතුව ගූගල් හෝ මුහුණු පොත්  ගිණුමක් නොමැති නම් ඔබගේ  පරිශීලක නම/ මුරපදය භාවිතා කරමින් පූරණය  වන්න.\n2). ඔබගේ වාහනයේ  අංකය සහ වාහනයේ චැසි අංකය ඇතුලත් කරන්න.\n3). වාහනයේ තොරතුරු සනාථ කරන්න - හිමිකාරීත්වය, රක්ෂණ, වාහන දුම් පරීක්ෂණ  (VET)/ වාහන යෝග්‍යතා  පරීක්ෂණ  (VFT) සහතික සහ බලපත්‍ර ගාස්තුව.\n4). බෙදාහැරීමේ ක්‍රමය  හා ගෙවීම් සිදු කරන ආකාරය තෝරා ගන්න.\n5). ගෙවීම සම්පූර්ණ කරන්න.\n6). ඔබගේ විද්‍යූ ලිපින ගිණුමෙහි ඇති  තාවකාලික ආදායම් බලපත්‍රය සඳහා වන රිසිට්පත මුද්‍රණය කර ගන්න නැතහොත් එය ඔබගේ ජංගම උපාංගයෙහි බාගත කර ගන්න.",
    q304: "තාවකාලික ආදායම් බලපත්‍රයක් යනුවෙන් හඳුන්වන්නේ කුමක් ද?",
    a304: "තැපැල මගින් මුල් ආදායම් බලපත්‍රයක් ඔබට වෙත යොමු කරන තුරු ඔබ ළඟ තබා ගැනීම සඳහා විද්‍යුත් ආදායම් බලපත්‍ර  (eRL) සේවාව මගින් තාවකාලික ආදායම් බලපත්‍රයක් නිකුත් කරනු ලබන අතර ඔබගේ තාවකාලික රිසිට්පතේ දක්වා ඇති කාලසීමාව සඳහා එය වලංගු වේ.",
    q305: "මුල් ආදායම් බලපත්‍රය තැපෑල මගින් ලබා ගැනීම සඳහා කොපමණ කාලයක් ගත වේද?",
    a305: "තාවකාලික රිසිට්පතේ දක්වා ඇති කාලය තුල මුල් ආදායම් බලපත්‍රය ඔබට ලැබෙනු ඇත. ",
    q306: "වාහන රක්ෂණ/ වාහන දුම් පරීක්ෂණ  (VET)/ වාහන යෝග්‍යතා පරීක්ෂණ (VFT)  සහතික ‘කල් ඉකුත් වී’  හෝ ‘අවලංගු’ වී තිබේ නම් මා හට කළ හැක්කේ කුමක් ද?",
    a306: "ඔබ විසින් අදාළ සේවා සපයන ආයතනය   සම්බන්ධ කර ගත යුතු අතර ළඟම පිහිටි සේවා ස්ථානය වෙත ගොස් ඔබගේ රක්ෂණ/ වාහන දුම් පරීක්ෂණ (VET)/වාහන යෝග්‍යතා පරීක්ෂණ ( VFT) සහතික අලුත් කර ගත යුතුය.",
    q307: "වාහනය ලියාපදිංචි කර ඇති ලිපිනය වෙනස් වන අවස්ථාවක මා පදිංචිව සිටින ලිපිනය වෙත මුල් ආදායම් බලපත්‍රය ගෙන්වා ගත හැකි ද?",
    a307: "මුල් ආදායම් බලපත්‍රය තැපෑල මගින් යොමු කරනු ලබන්නේ  මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුවේ වාහන ලියාපදිංචි පද්ධතිය වෙත ලබා දී  ඇති ලිපිනයට පමණි.  ඔබ වෙනත් ලිපිනයක පදිංචි වී සිටින්නේ නම් ඔබගේ අදායම් බලපත්‍රය  ලබා ගැනීම සඳහා (තාවකාලික රිසිට්පත/ මුල් CR/ ජා.හැ./රියදුරු බලපත්‍රය රැගෙන) අදාළ පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුව වෙත යා යුතුය.",
    q308: "වාහනයක් සඳහා  ප්‍රථම වරට ආදායම් බලපත්‍රය ලබා ගැනීම සඳහා මා විසින් වාහන දුම් පරීක්ෂණ  (VET) හෝ වාහන යෝග්‍යතා පරීක්ෂණ (VFT) සහතිතකය ලබා ගත යුතුව තිබේ ද?",
    a308: "නැහැ.  නවතම වාහනයක් සඳහා පළමුවරට ආදායම් බලපත්‍රය ලබා ගැනීමේ දී  වාහන දුම් පරීක්ෂණ  (VET) හෝ වාහන යෝග්‍යතා පරීක්ෂණ (VFT) සහතිතකය ලබා ගැනීම අවශ්‍ය නොවේ.",
    q309: "පවරන ලද වාහනයක ප්‍රථම ආදායම් බලපත්‍රය මාර්ගගත විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව හරහා මා හට අළුත් කළ හැකි ද?",
    a309: "ඔව්. පවරනු ලැබූ වාහනයක ප්‍රථම ආදායම් බලපත්‍රය මාර්ගගත විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව හරහා ඔබට අළුත් කළ හැකිය.",
    q401: "මාර්ගගත ගෙවීම් සිදු කළ හැකි ගෙවීම් ක්‍රම මොනවා ද?",
    a401: "ඕනෑම ක්‍රෙඩිට්/ ඩෙබිට් කාඩ් (වීසා/ මාස්ටර්/ ඇමෙක්ස්) පතක් හෝ  QR යෙදුම ඔබට භාවිතා කළ හැක.",
    q402: "ආදායම් බලපත්‍ර වටිනාකම/ගාස්තුව ගණනය කරනු  ලබන්නේ කෙසේ ද?",
    a402: "බලපත්‍රයේ වටිනාකම  = ගාස්තුව + දඩ මුදල + හිඟ මුදල + එකතු කිරීම් -  අඩු කිරීම්\n ගාස්තුව – වාහනයේ වර්ගය අනුව මෙම ගාස්තුව රජය විසින් තීරණය කරනු  ලැබේ.\n දඩ මුදල – බලපත්‍රයේ වර්ගය  හා බලපත්‍රය අලුත් කිරීම සඳහා ප්‍රමාද වූ කාලය සැලකිල්ලට ගනිමින්  දඩ මුදල අය කරනු ලැබේ.\n හිඟ මුදල් – ගෙවිය යුතු හිඟ මුදල (ඇත්නම්)\n එකතු කිරීම් – රජය විසින් පනවනු ලබන අමතර ගාස්තු (ඇත්නම්). උදා. බදු, දඩ ගාස්තු ආදිය.\n අඩු කිරීම් – රජය විසින් නියම කරනු  ලබන වට්ටම්, දීමනා  ස්වභාවයේ සහන ආදිය (ඇත්නම්).",
    q403: "ඉහත සඳහන් වියදම්වලට අමතරව අතිරේක ගාස්තු අය කරනු ලැබේ ද?",
    a403: "ඔව්, අදාළ බැංකුව විසින් අය කරනු ලබන සුදුසු ගාස්තුවක් (බලපත්‍ර ගාස්තුවෙන් %) (මාර්ගගත ගෙවීම් සඳහා) සහ බෙදා හැරීමේ/ තැපැල් ගාස්තු එකතු කරනු ලැබේ.",
    q404: "ගෙවීම් සිදු කිරීමේ දී මා විසින් තහවුරු කර ගත යුතු වැදගත් කරුණු මොනවා ද?",
    a404: "එක් වරක් පමණක් පෙන්නුම් කරන මුරපදය (OTP) ලබා ගැනීම සඳහා ඔබගේ ජංගම දුරකථනය ඔබ ළඟ තිබෙන බවට ඔබ විසින් වග බලා ගත යුතු අතර  'සැසි කල් ඉකුත්වීම' වළක්වා ගැනීම සඳහා අදාළ තිරයේ දක්වා ඇති 'කාලය' තුළ ගෙවීම් ක්‍රියාවලිය සම්පූර්ණ කළ යුතුය.",
    q405: "ගෙවන ලද ලද මුදල අඩු කළද ආදායම් බලපත්‍රය අලුත් කරනු නොලබන අතර මා හට බලපත්‍රය අලුත් කර ගැනීම  හෝ දැනටමත් ගෙවා ඇති මුදල ආපසු ලබා ගැනීමට හැකිද?",
    a405: "ඔව්. බලපත්‍රය අලුත් කර නොමැති නම් සහ අදාළ බැංකුව විසින් ගෙවීම් ආපසු ලබා දෙන්නේ නම්, අදාළ පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුව විසින් මුදල් ආපසු ලබා දීම සඳහා අවශ්‍ය කටයුතු සම්පාදනය කරනු ලැබේ.",
    q406: "මා හට මේ වන තුරුත්  මගේ මුදල් ආපසු නොලැබුණි නම්, ප්‍රමාදය සම්බන්ධයෙන් දැනුම්දීම සඳහා මා සම්බන්ධ කර ගත යුත්තේ කවුරුන් ද?",
    a406: "වාහනය ලියාපදිංචි කර ඇති අදාළ බැංකුව හෝ අදාළ පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුව ඔබ විසින් සම්බන්ධ කර ගත යුතුය. අප වෙත  හෝ 1919 අංකය වෙත ඇමතුමක් ලබා දීම මගින් ඔබට තොරුතුරු ලබා ගත හැක.",
    q501: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL)  සේවාව සම්බන්ධයෙන් අවශ්‍ය සහයෝගය ලබා ගැනීම සඳහා මා විසින් සම්බන්ධ කර ගත යුත්තේ කවුරුන් ද?",
    a501: "1919 අංකයට (කටහඩ) දුරකථන ඇමතුමක් ලබා දීමේ  හෝ info@erl2.gov.lk  වෙත විද්‍යූත් ලිපියක් යොමු කිරීමේ හැකියාව ඔබට තිබේ.",
    q502: "මගේ මුල් ආදායම් බලපත්‍රය නියමිත වේලාවට ලබා ගැනීමට හැකි වේද යන්න පිළිබඳව මා දැන ගන්නේ කෙසේ ද? නැතහොත් තැපෑල මගින් එය මා හට නොලැබුණි නම් මා විසින් ඒ පිළිබඳව  දැනුම් දිය යුත්තේ කාහට ද?",
    a502: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාවේ මුල් පිටුවේ  ‘වර්තමාන තත්ත්වය පරීක්ෂා කරන්න’ ක්ලික් කිරීම මගින් හෝ ඔබගේ වාහනය ලියාපදිංචි කර ඇති පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුවට දුරකථන ඇමතුමක් ලබා දීම මගින් ඔබට ඔබගේ ආදායම් බලපත්‍රයේ තත්ත්වය පිළිබඳව පරීක්ෂා කළ හැකිය.  අප වෙත දුරකථන ඇමතුමක් ලබා දීම මගින් ද තොරතුරු ලබා ගත හැක. තවද පහත සඳහන් අංක ඔස්සේ  011 2328 301-3, 011 2328 112 (සීඝ්‍ර සේවා  තැපෑල)  info@slpost.lk / pmg@slpost.lk  https://slpost.gov.lk/contact-us/ ශ්‍රී ලංකා තැපැල් සේවාව හා සම්බන්ධ වීමට ද ඔබට හැකියාව තිබේ.",
    q503: "තැපෑල මගින් මගේ මුල් ආදායම් බලපත්‍රය මා වෙත යොමු නොකර ආපසු යවන්නේ නම් එය  මා හට නැවත ලබා ගත හැක්කේ කෙසේ ද?",
    a503: "වාහනය ලියාපදිංචි කර ඇති, වාහන අයිතිකරුගේ ලිපිනයට අදාළ පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුව වෙත ඔබ (තාවකාලික රිසිට්පත/ මුල් CR/ ජා.හැ./ රිය පැදවීමේ බලපත්‍රය රැගෙන)  පැමිණිය  යුතුය.",
    q504: "විද්‍යුත් ආදායම් බලපත්‍ර (eRL) සේවාව තුලින් මා හට සේවා නිවේදන ලබා ගත හැකි ද?",
    a504: "ඔව්, ඔබගේ පරිශීලක තොරතුරුවලට  ඔබගේ නිවැරදි අංකය ඇතුලත් කර  ඇත්නම් ඔබගේ ජංගම දුරකථන අංකය වෙත සේවා තොරතුරු ලබා ගත හැක.",
    q505: "මාර්ගගත ආදායම් බලපත්‍ර ලබා ගැනීමේ දී කළ හැකි බැහැර කිරීම් මොනවා ද?",
    a505: "පහත සඳහන් අවස්ථාවල දී ඔබට මාර්ගගත ආදායම් බලපත්‍ර ලබා ගැනීමට නොහැකි වේ.\n1). ඔබගේ වාහන ආදායම් බලපත්‍රය ‘අලුත් කිරීමේ’ තත්ත්වයේ නොපවතී නම් \n2). ඔබගේ වාහන ආදායම් බලපත්‍රය ‘කල් ඉකුත්වී’ නොමැති තත්ත්වයේ පවතී නම්.\n3). වාහනය ‘නුසුදුසු’ හෝ ‘සංරක්ෂිත’ තත්ත්වයේ පවතී නම්.\n4). වාහනය ‘අසාධු ලේඛනගත’ තත්ත්වයේ පවතී නම්  - වලංගු හේතුවක් මත පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුව විසින් තාවකාලිකව තහනම් කර තිබේ නම්.\n5). ඔබගේ වාහනයේ ‘ඉන්ධන වර්ගය’ වෙනස් කර තිබේ නම්  ",
    q506: "සහායක සේවා  යනුවෙන් අදහස් කරන්නේ කුමක් ද?",
    a506: "ආදායම් බලපත්‍රය ලබාගැනීම සඳහා ඔබට අවශ්‍ය සහාය ලබා දෙන සේවාව. එනම්, ඔබගේ වාහන වර්ගය අනුව අවශ්‍යවන රක්ෂණ, වාහන දුම් පරීක්ෂණ (VET) සහ වාහන යෝග්‍යතා පරීක්ෂණ (VFT) සහතික ලබා දෙන ආයතන.",
  },
  ta: {
    title: "Frequently Asked Questions",
    general: "பொது",
    login: "உள்நுழைவு/பதிவு செய்தல்",
    eRLService: "இ-வருமான அனுமதிப் பத்திர சேவை",
    payment: "கொடுப்பனவு",
    assistance: "உதவி",
    q101: "இ- வருமான அனுமதிப் பத்திர சேவை (eRL) என்றால் என்ன?",
    a101: "இ- வருமான அனுமதிப் பத்திர சேவை (eRL) ஆனது உங்களுக்கு, \n 1) மோட்டார் போக்குவரத்து திணைக்களத்தில்  உங்களது முதலாவது வருமான அனுமதிப்பத்திரத்த்தினைப் பெற்றுக் கொள்வதற்கும்.\n 2). தற்போதைய வருமான அனுமதிப்பத்திரத்தினைப் புதுப்பிப்பதற்கும் ,\n 3). இணைய வழிமூலமாக கோரிய வருமான அனுமதிப்பத்திரத்தின் செயன்முறையின் நிலைப்பாட்டினை அறிந்து கொள்வதற்குமான வசதியினை அளிக்கின்றது.",
    q102: "இ-வருமான அனுமதிப்பத்திர சேவையினூடாக வருமான அனுமதிப்பத்திரத்தினை யார் யார் பெற்றுக் கொள்ள முடியும்?",
    a102: "மோட்டார் போக்குவரத்துத் திணைக்களத்தில் பதுவ செய்து கொண்டுள்ள வாகனமொன்றின் உரிமையாளர் எவரேனும் (வாகன வகையீடுகளில் வரையறை செய்யப்பட்ட) உலகின் எப்பாகத்திலிருந்தும் இ-வருமான அனுமதிப் பத்திர சேவையினூடாக பெற்றுக் கொள்ள முடியும்)",
    q103: "இணையவழி மூலமாக அனைத்து வாகனங்களுக்கமான வருமான அனுமதிப்பத்திரத்தினைப் பெற்றுக் கொள்ள முடியுமா?",
    a103: "பின்வரும் வாகன வகையீடுகளிலுள்ள வாகனங்களுக்கு இணையவழி மூலம் வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்ள முடியும் ; மோட்டார் லொறி, மோட்டார் லொறி இழுவை வண்டி, மோட்டார் முச்சக்கர வான், தனியார் பேரூந்து, வீட்டுப் பாவனைக்கான மோட்டார் வண்டி, விசேட தேவைக்குரிய வாகனம், நான்கு சக்கர துவிச்சக்கர வண்டி, நோயாளர் காவு வண்டி மற்றும் அமரர் ஊர்தி.",
    q104: "இணைய வழி மூலமாக வருமான அனுமதிப் பத்திரத்தித்தினைப் பெற்றுக் கொள்வதற்கு, ஏதேனும் ஆவணங்களைச் சமர்ப்பிக்க வேண்டுமா?",
    a104: "இல்லை, இழையவழி மூலமான வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்கு, நீங்கள் எவ்வித ஆவணங்களையும் சமர்ப்பிக்கத் தேவையில்லை",
    q105: "இ-வருமான அனுமதிப் பத்திரத்திர சேவைக்காக கையடக்க செயலி ஏதேனும் உள்ளதா?",
    a105: "இல்லை, ஆனால் நீங்கள் உங்களது கையடக்க சாதனத்தினூடாக சேவையினைப் பெற்றுக் கொள்ள முடியும்.",
    q106: "இணைய வழி மூலமான தற்காலிக வருமான அனுமதிப் பத்திரம் செல்லுபடியானதா என்பதுடன் அதன் செல்லுபடித் தன்மையினை எவ்வாறு நிரூபிப்பது?",
    a106: "ஆம், அரசாங்க சட்டங்களின்படி, இணைய வழி மூலமான தற்காலிக அனுமதிப் பத்திரம் செல்லுபடியானதாகும் என்பதுடன் பற்றுச்சீட்டில் குறிப்பிடப்பட்ட காலம் வரையில் தற்காலிக பற்றுச்சீட்டானது செல்லுபடியாகும். வருமான அனுமதிப்பத்திரத்தின் செல்லுபடித் தன்மையினை நிரூபிப்பதற்கு பின்வரும் தெரிவுகளினை நீங்கள் பயன்படுத்தலாம். இ-வருமான அனுமதிப்பத்திர சேவையில் உள்ள “தகுதிநிலை செவ்வை பார்த்தல்” இனைப் பார்க்கவும். “DMT RLS என ரைப் செய்து  <வாகன இல.>’ 1919 இற்கு குறுஞ் செய்தி அனுப்பவும். மோட்டார் வாகன திணைக்களத்தின் செயலியில் உள்ள மோட்டார் வாகன தகவலில் உள்ள “வருமான அனுமதிப்பத்திர தகுதிநிலையினை” செவ்வை பார்க்குக.  உங்களின் வாகனம் பதிவு செய்யப்பட்டுள்ள உங்களின் முகவரிக்குரிய, மாகாண மோட்டார் போக்குவரத்துத்  திணைக்களத்துடன் தொடர்பு கொள்ளவும்.",
    q201: "இ-வருமான அனுமதிப்பத்திர பயனர் உள்நுழைவு / பதிவு செய்தல் என்றால் என்ன?",
    a201: "உள்நுழைதல் ;  தற்போதுள்ள கூகுள் அல்லது  முகநூல் கணக்கினூடாக இ-வருமான அனுமதிப் பத்திரத்திர சேவையினை அணுகுவதற்கான வசதியினை ஏற்படுத்திக் கொள்ளுங்கள். பதிவு செய்தல்;உங்களிடம் கூகுள் அல்லது முகநூல் கணக்கு இல்லாவிடின், உங்களின் மின்னஞ்சல் முகவரி மற்றும் கடவுச்சொல் என்பவற்றினைப் பயன்படுத்தி இ- வருமான அனுமதிப் பத்திரத்திர சேவையினை அணுகுவதற்கான வசதியினை ஏற்படுத்திக் கொள்ளுங்கள்.",
    q202: "உள்நுழைய / பதவி செய்ய முடியாவிடின், காரணம் என்னவாக இருக்கும்?",
    a202: "நீங்கள்உங்களது இணைய இணைப்பினை செவ்வை பார்ப்பதுடன் அது நன்றாக இயங்குமிடத்து , உங்களின் கூகுள்/ முகநூல் கணக்கின் வலிதினை செவ்வை பார்த்து மின்னஞ்சல் முகவரி / கடவுச் சொல்லுடன் உள் நுழைக அல்லது பதிவு செய்க.",
    q203: "எனது கடவுச் சொல்லினை மாற்றம் செய்ய முடியுமா? எனது கடவுச் சொல்லினை மறந்தால் என்ன செய்வது?",
    a203: "ஆம் , கடவுச் சொல்லினை நீங்கள் மறந்திருப்பின் அல்லது மாற்ற வேண்டிய தேவை ஏற்படின், உங்களின் கடவுச் சொல்லினை மீளமைத்துக் கொள்ளலாம். விபரப்பட்டியலில் உள்ள உள்நுழைதல் / பதிவு செய்தலில் உள்ள “மறந்து போன கடவுச்சொல்” இனைப் பயன்படுத்தி மேற்குறித்த படிநிலையின் பின்னர் நீங்கள்பெறும் “கடவுச் சொல் மீளமைத்தல்” எனும் இணைப்பினை கிளிக் செய்க..",
    q204: "இ-வருமான அனுமதிப்பத்திர சேவையில் எனது பயனர் விபரத்தினைப் பெற முடியுமா?",
    a204: "ஆம், நீங்கள் பயனர் விபரத்தினை அணுகுவதற்கு முகப்பு பக்கத்தின் மேலேயுள்ள உங்களின் பெயரினைக் கிளிக் செய்தல் வேண்டும். சேவை அறிவித்தல்களைப் பெற்றுக் கொள்வதற்கு, உங்களின் கையடக்க தொலைபேசி இலக்கத்தினைச் சமர்ப்பிக்கலாம்.",
    q301: "இ-வருமான அனுமதிப்பத்திர சேவையினை ஆரம்பிப்பதற்கு என்னென்ன வசதிகள் தேவை?",
    a301: "இ-வருமான அனுமதிப் பத்திர சேவையினைப் பெற்றுக் கொள்வதற்கு பின்வரும் வசதிகள் உங்களுக்குத் தேவைப்படுகின்றதா? கூகுள் / முகநூல் கணக்கு அல்லது உங்களின் மின்னஞ்சல் முகவரி / கடவுச் சொல்லினைப் பயன்படுத்தி பதிவு செய்க. செல்லுபடியாகும் கடனட்டை அல்லது QR செயலி.",
    q302: "வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்கு தேவைப்படுபவை யாவை?",
    a302: "உங்களின் வாகன வகைக்கேற்றதாக, காப்புறுதி சான்றிதழ், புகைப் பரிசோதனைச் சான்றிதழ் (VET), வாகன  தகுதி நிலைச் சான்றிதழ் (VFT) என்பன தேவைப்படும்.",
    q303: "எனது வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்கு நான் கோரிக்கை விடுப்பது எவ்வாறு?",
    a303: "1).	கூகுள் அல்லது முகநூல் கணக்கினைப் பயன்படுத்தி உள்நுழைக, உங்களிடம் கூகுள் அல்லது முகநூல் கணக்கு இல்லாவிடின், உங்களின் மின்னஞ்சல் முகவரி மற்றும் கடவுச்சொல் என்பவற்றினைப் பயன்படுத்தி கணக்கொன்றினைப் பதிவு செய்க.\n2).	உங்கள் வாகனத்தின் வாகன இலக்கம் மற்றும் அடிச்சட்ட இலக்கம் (இறுதி 6 இலங்கங்கள்) என்பவ்ற்றினைச் சமர்ப்பிக்குக.\n3). வாகன விபரங்களை உறுதி செய்க – உரித்துடமை, காப்புறுதி / புகை பரிசோதனை (VET)/ மற்றும் வாகன தகுதிநிலை (VFT) சான்றுப்படுத்தல் மற்றும் உரிம கட்டணம்.\n4). விநியோக முறை மற்றும் கொடுப்பனவு முறையினைத் தெரிவு செய்க.\n5).	கொடுப்பனவினைப் பூர்த்தி செய்தல்.\n6). உங்களின் மின்னஞ்சலிலிருந்து  அல்லது உங்களின் சாதனத்தில் பதவிறக்கம் செய்வதன் மூலம் தற்காலிக வருமான அனுமதிப் பத்திரத்துக்கான பற்றுச்சீட்டினைப் பெற்றுக் கொள்ளலாம்.",
    q304: "தற்காலிக வருமான அனுமதிப் பத்திரம் என்றால் என்ன?",
    a304: "இ-வருமான அனுமதிப்பத்திர சேவையினால் வழங்கப்படும் தற்காலிக வருமான அனுமதிப்பத்திரத்தினை தற்காலிக பற்றுச்சீட்டில் குறிப்பிடப்பட்டவாறான காலப்பகுதி வரையில், மூல வருமான அனுமதிப் பத்திரம் தபால் மூலம் கிடைக்கப் பெறும் வரையில் உங்களுடன் வைத்திருத்தல் வேண்டும்.",
    q305: "தபால் மூலம் மூல வருமான அனுமதிப்பத்திரத்தினைப் பெற்றுக கொள்வதற்கு எவ்வளவு காலம் எடுக்கும்?",
    a305: "நீங்கள், தற்காலிக பற்றுச் சீட்டில் குறிப்பிடப்பட்டுள்ள காலப்பகுதியினுள் மூல வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வீர்கள்.",
    q306: "“காலாவதியான” அல்லது “செல்லுபடியற்றது” என காட்டப்படுகின்ற வாகன காப்புறுதி  / புகை பரிசோதனை சான்றிதழ்களாயின், நான் என்ன செய்ய வேண்டும்?",
    a306: "நீங்கள் உரிய சேவை வழங்குனருடன் தொடர்பு கொண்டு அண்மையிலுள்ள சேவை வழங்குமிடத்துக்குச் சென்று காப்புறுதி சான்றிதழ் / புகைப் பரிசோதனைச் சான்றிதழ் (VET) /வாகன  தகுதி நிலைச் சான்றிதழ் (VFT) என்பனவற்றினைப் புதுப்பிக்கலாம்.",
    q307: "வாகனத்தின் பதிவு செய்யப்பட்ட முகவரியானது வேறுபட்டிருப்பின், எனது வதிவிட முகவரிக்கு மூல வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்ள முடியுமா?",
    a307: "மூல வருமான அனுமதிப் பத்திரமானது மாகாண மோட்டார் போக்குவரத்துத் திணைக்களத்தின் வாகன பதிவு செய்தல் முறைமைக்கு வழங்கப்பட்டுள்ள முகவரிக்கு மாத்திரம் தபாலில் அனுப்பி வைக்கப்படும்.  முகவரியானது வேறுபட்டிருப்பின், மாகாண மோட்டார் போக்குவரத்துத் திணைக்களத்துக்கு (தற்காலிக பற்றுச்சீட்டு / மூல CR /தே.அ.அ/ வாகன சாரதி அனுமதிப்பத்திரம் என்பவற்றுடன்) சென்று பெற்றுக் கொள்ளலாம். மோட்டார் போக்குவரத்துத் திணைக்களத்திற்கு செல்லும்போது சரியான முகவரியினை மாற்றிக் கொள்ளுமாறு பரிந்துரை செய்யப்படுகின்றது.",
    q308: "வாகனத்தின் முதலாவது வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்கு புகை பரிசோதனை சான்றிதழ் (VET) அல்லது உடலுறுதி சான்றிதழினைப் (VFT) பெற்றுக் கொள்ள வேண்டுமா?",
    a308: "புத்தம் புதிய வாகனமொன்றுக்கு முதலாவது வருமான அனுமதிப்பத்திரத்தினைப் பெற்றுக் கொள்வதற்கு புகை பரிசோதனை சான்றிதழ் (VET) அல்லது வாகன தகுதிநிலைச்  சான்றிதழினைப் (VFT) பெற்றுக் கொள்ளத் தேவையில்லை.",
    q309: "இ-வருமான அனுமதிப் பத்திர சேவையினூடாக உரிமை மாற்றம் செய்ய்ப்பட்ட முதலாவது வருமான அனுமதிப் பத்திரத்தினைப் புதுப்பிக்க  முடியுமா?",
    a309: "இ-வருமான அனுமதிப்பத்திர சேவையினூடாக உரிமை மாற்றம் செய்யப்பட்ட வாகனத்தின் முதலாவது வருமான அனுமதிப்பத்திரத்தினை புதுப்பித்துக் கொள்ளலாம்.",
    q401: "இழைய வழி மூலமான கொடுப்பனவுகளினை மேற்கொள்வதற்கு உள்ள கொடுப்பனவு முறைகள் யாவை?",
    a401: "நீங்கள் கடன் / வரவு அட்டை(VISA/ Master/ AMEX) அல்லது QR செயலியைப் பயன்படுத்தி உங்கள் கொடுப்பனவினை மேற்கொள்ளலாம்.",
    q402: "வருமான அனுமதிப் பத்திர தொகை / கட்டணம் எவ்வாறு கணிப்பீடு செய்யப்படுகின்றது?",
    a402: "அனுமதிப் பத்திர கட்ட்டண தொகை = கட்டணம் + நிலுவைகள் + சேர்க்கைகள் – கழிப்பனவுகள் கட்டணம் – அரசாங்கத்தினால், வாகன வகையீட்டின்படி தீர்மானிக்கப்பட்ட வீதம். குற்றப்பணம் – அனுமதிப் பத்திர வகை மற்றும் அனுமதிப் பத்திரத்தினை புதுப்பிப்பதில் ஏற்பட்ட தாமத காலப்பகுதி என்பவற்றின் அடிப்படையில் அறவிடப்படும் தண்டப் பணம். நிலுவைகள் – காலங் கடந்து செலுத்த வேண்டிய தொகை (ஏதேனும்)  சேர்க்கைகள் – அரசாங்கத்தினால் விதிக்கப்படும் மேலதிக கட்டணங்கள் (ஏதேனும்) உதாரணம்- வரிகள், குற்றப்பணங்கள் போன்றவை. கழிப்பனவுகள் – கழிவுகள், கொடுப்பனவுகள் போன்றவற்றின் தன்மையின் அடிப்படையிலான சலுகைகள்.",
    q403: "மேற்குறித்த கட்டணங்களுக்கு மேலதிகமாக, வேறு ஏதேனும் மேலதிக கட்டணங்கள் அறவீடு செய்யப்படுகின்றதா?",
    a403: "ஆம், உரிய வங்கியினால் செகளரியமான கட்டணமொன்று (அனுமதிப் பத்திர கட்டணத்தின் 0%) அறவீடு செய்யப்படுகின்றதுடன் விநியோக / தபால் கட்டணம் சேர்க்கப்படும்.",
    q404: "கொடுப்பனவின் போது நான் உறுதிப்படுத்திக் கொள்ள வேண்டிய முக்கியமான விடயங்கள் யாவை?",
    a404: "உங்களின் கையடக்கத் தொலைபேசி மூலமாக OTP இலகத்கதினைப் பெற்றுக் கொள்ள முடியுமா என்பதனை உறுதிப்படுத்திக் கொள்வதுடன் நீங்கள் குறித்த திரையில் தோன்றும் “அமர்வு நிறைவடையும்” விதித்துரைக்கப்பட்ட நேரத்தினுள் கொடுப்பனவு செயல்முறையினைப் பூர்த்தி செய்தல் வேண்டும்.",
    q405: "கொடுப்பனவானது கழிப்பனவு செய்யப்பட்ட போதிலும், வருமான அனுமதிப் பத்திரமானது இன்னமும் புதுப்பிக்கப்படா விடின், புதுப்பிக்கப்பட்ட அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்ள முடியுமா அல்லது ஏற்கனவே மேற்கொள்ளப்பட்ட கொடுப்பனவினை மீளப் பெற்றுக் கொள்ள முடியுமா?",
    a405: "ஆம், மீள்நிதியிடலானது உரிய மாகாண மோட்டார் போக்குவரத்துத்  திணைக்களத்தினால் மேற்கொள்ளப்படும். வருமான அனுமதிப்பத்திரமானது புதுப்பிக்கப்பட்டுக்கா விடின், கொடுப்பனவானது உரிய வங்கியினால் மீளளிப்புச் செய்யப்படும்.",
    q406: "எனது மீள்நிதியிடலானது இன்னமும் கிடைக்கப் பெற்றிருக்கா விடின், தாமதம் குறித்தி யாருடன் தொடர்பு கொள்ள வேண்டும்.",
    a406: "நீங்கள், வாகனம் பதிவு செய்யப்பட்டுள்ள முகவரிக்குரிய மாகாண மோட்டார் போக்குவரத்துத் திணைக்களம் அல்லது உரிய வங்கியுடன் தொடர்பு கொள்ளுதல் வேண்டும். நீங்கள் எம்முடன் அல்லது 1919 உடன் தொடர்பு கொண்டு விபரங்களைப் பெற்றுக் கொள்ளலாம்.",
    q501: "இ-வருமான அனுமதிப்பத்திர சேவை தொடர்பில் ஏதேனும் உதவிக்காக யாருடன் நான் தொடர்பு கொள்ள வேண்டும்?",
    a501: "நீங்கள் 1919 (குரல்) அல்லது info@erl2.gov.lk (மின்னஞ்சல்) இனூடாக தொடர்பு கொள்ளலாம்.",
    q502: "எனது வருமான அனுமதிப் பத்திரமானது உரிய நேரத்தில் கிடைக்கப் பெறாவிடின், அதனை நான் எவ்வாறு அறிந்து கொள்ள முடியும் அல்லது தபால் மூலமாக அது கிடைக்கப் பெறாவிடின், அது தொடர்பில் யாருக்கு அறிவித்தல் வேண்டும்?",
    a502: "இ-வருமான அனுமதிப்பத்திர சேவையின் முகப்புப் பக்கத்திலுள்ள “தகுதிநிலையினை செவ்வை பார்த்தல்”  இனைக் கிளிக் செய்வதன் மூலம் உங்களின் வருமான அனுமதிப் பத்திர தகுதிநிலையினை செவ்வை பார்க்கலாம். அத்துடன் வாகனம் பதிவு செய்யப்பட்டுள்ள உரிய மாகாண மோட்டார் போக்குவரத்து திணைக்க்ளத்துடன் தொடர்பு கொள்ளுதல் வேண்டும். எம்முடன் தொடர்பு கொள்ளவும் எனும் தலைப்பின் கீழ் நீங்கள் விபரங்களைப் பெற்றுக் கொள்ளலாம். மேலும், நீங்கள் இலங்கைத் தபாலுடன் கீழ்க்காணும் இலக்கங்களுடன் தொடர்பு கொள்ளலாம். 011 2328 301-3, 011 2328 112 (விரைவு  தபால்) info@slpost.lk/ pmg@slpost.lk https://slpost.gov.lk/contact-us/",
    q503: "எனது மூல வருமான அனுமதிப் பதிரமானது உரிய முகவரியில் சேர்ப்பிக்காமல் திரும்பி செல்லுமாயின், அதனை நான் எவ்வாறு பெற்றுக் கொள்வது?",
    a503: "வாகனம் பதிவு செய்யப்பட்டுள்ள உரிமையாளரின் முகவரிக்குரிய (தற்காலிக பற்றுச்சீட்டு / மூல CR /தே.அ.அ/ வாகன சாரதி அனுமதிப்பத்திரம்) மாகாண மோட்டார் போக்குவரத்துத் திணைக்களத்திற்கு சென்று பெற்றுக் கொள்ளலாம்.",
    q504: "இ-வருமான அனுமதிப் பத்திர சேவையிலிருந்து சேவை அறிவித்தல்களைப் பெற்றுக் கொள்ள முடியுமா?",
    a504: "ஆம், நீங்கள் உங்களுடைய பயனர் விபரத்தில் சரியான தொலைபேசி இலக்கத்தினைக் கொடுத்திருப்பின், உங்களின் கையடக்கத் தொலைபேசி இலக்கத்தினூடாக சேவை அறிவித்தல்களினைப பெற்றுக் கொள்ளலாம்.",
    q505: "இணையவழி வருமான அனுமதிப்பத்திரத்தினைப் பெற்றுக் கொள்வதில் காணப்படும் விதிவிலக்குகள் என்ன?",
    a505: "பின்வரும் காரணங்களினால் உங்களுடைய வருமான அனுமதிப்பத்திரத்தினைப் பெற்றுக் கொள்ள முடியாது போகலாம் ;\n1). உங்களுடைய வருமான அனுமதிப்பத்திரம் “புதுப்பிக்கத்தக்க” தகுதிநிலையினைக் கொண்டிருக்காவிடின்.\n2). உங்களின் வாகன அனுமதிப்பத்திரமானது “காலாவதி” ஆன நிலையினைக் கொண்டிருக்காவிடின்.\n3). வாகனமானது “தகைமையற்ற” அல்லது மிகவும் “”பழைமையான” நிலையில் இருத்தல்.\n4). வாகனமானது “கறுப்புப் பட்டியல்” நிலையில் இருத்தல் – வலிதான காரணமொன்றிற்காக மாகாண மோட்டார் போக்குவரத்து திணைக்களத்தினால் தற்காலிகமாக தடை செய்யப்பட்டிருத்தல்.\n5). நீங்கள் உங்கள் வாகனத்தின் “எரிபொருள் வகை” இனை மாற்றம் செய்திருப்பின்.",
    q506: "துணை சேவைகள் என்பதன் மூலம் கருதப்படுவது என்ன?",
    a506: "வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்கு தங்களுக்கு ஆதரவளிக்கும் சேவை: தங்களின் வாகன வகையீட்டின்படி, உதாரணம். காப்புறுதி, புகை பரிசோதனை (VET) மற்றும் உடல் தகுதி (VFT) சான்றிதழ்கள் அவசியமாகும்.",
  },
};

const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

const pageTransition = {
  duration: 2,
};

const Faq = () => {
  function linesBreak(messages) {
    return messages
      .map((message) =>
        typeof message === "string"
          ? message.split("\n").map((messages) => <p>{messages}</p>)
          : message
      )
      .reduce((messages, message) => messages.concat(message), []);
  }

  const locale = useRecoilValue(LanguageFilter);
  const [vehicleNumberState, setVehicleNumber] = useRecoilState(VehicleNumber);
  const [chassisNumberState, setChassisNumber] = useRecoilState(ChassisNumber);

  useEffect(() => {
    setVehicleNumber("");
    setChassisNumber("");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Template activeHeader="faq" selectedLan={locale}>
      <MetaTags>
        <meta name="description" content="Provincial Department of Motor Traffic (PDMT) and Divisional Secretariat (DS) Offices issue the Vehicle Revenue Licence Island wide. There are 330 + DS Offices throughout all 9 Provinces and the Offices open from 08:30 a.m. to 03:30 p.m. from Monday to Friday, except Public, Bank and Mercantile holidays for issuance of Licences." />
        <meta name="keywords" content="faqs, faq, q&a" />
      </MetaTags>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <motion.div
        // initial="out"
        // animate="in"
        // exit="out"
        // variants={pageVariants}
        // transition={pageTransition}
        >
          <UICard
            title={
              <FormattedMessage
                id="title"
                value={{ locale }}
              ></FormattedMessage>
            }
            titlePlacement={cardTitlePlacement.LEFT}
            cardSize={cardSize.LARGE}
            titleStyle="mainTxtHeading "
            borderLess={true}
          >
            <AccordionWrapper>
              <Accordion
                heading={
                  <FormattedMessage
                    id="general"
                    value={{ locale }}
                  ></FormattedMessage>
                }
                content={""}
              >
                <AccordionWrapper>
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q101"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a101" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q102"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a102" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q103"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a103"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q104"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a104"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q105"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a105"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q106"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a106" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                </AccordionWrapper>
              </Accordion>
              <Accordion
                heading={
                  <FormattedMessage
                    id="login"
                    value={{ locale }}
                  ></FormattedMessage>
                }
                content={""}
              >
                <AccordionWrapper>
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q201"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a201" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q202"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a202"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q203"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a203"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q204"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a204"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                </AccordionWrapper>
              </Accordion>
              <Accordion
                heading={
                  <FormattedMessage
                    id="eRLService"
                    value={{ locale }}
                  ></FormattedMessage>
                }
                content={""}
              >
                <AccordionWrapper>
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q301"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a301" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q302"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a302"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q303"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a303" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q304"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a304"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q305"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a305"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q306"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a306"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q307"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a307"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q308"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a308"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q309"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a309"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                </AccordionWrapper>
              </Accordion>
              <Accordion
                heading={
                  <FormattedMessage
                    id="payment"
                    value={{ locale }}
                  ></FormattedMessage>
                }
                content={""}
              >
                <AccordionWrapper>
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q401"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a401"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage id="q402" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a402" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q403"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a403"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q404"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a404"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q405"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a405"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q406"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a406"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                </AccordionWrapper>
              </Accordion>
              <Accordion
                heading={
                  <FormattedMessage
                    id="assistance"
                    value={{ locale }}
                  ></FormattedMessage>
                }
                content={""}
              >
                <AccordionWrapper>
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q501"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a501"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q502"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a502" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q503"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a503"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q504"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a504"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q505"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage id="a505" value={{ locale }}>
                        {linesBreak}
                      </FormattedMessage>
                    }
                  />
                  <Accordion
                    heading={
                      <FormattedMessage
                        id="q506"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                    content={
                      <FormattedMessage
                        id="a506"
                        value={{ locale }}
                      ></FormattedMessage>
                    }
                  />
                </AccordionWrapper>
              </Accordion>
            </AccordionWrapper>
          </UICard>
        </motion.div>
      </IntlProvider>
    </Template>
  );
};

export default Faq;
