import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { LanguageFilter } from "../../atom";
import { statusCheckAPI } from "../../config/apiUrl.config";

import { callApi } from "../../helpers/common-helpers/callApi.helpers";

export const useStatusCheckHook = () => {
  const locale = useRecoilValue(LanguageFilter);

  const [statusInfo, setStatusInfo] = useState();


  const getAllStatus = useCallback(async () => {
    callApi(`${statusCheckAPI.url}`)
      .method("get")
      .headers(true)
      .send((error, result) => {
        if (result) {
          setStatusInfo(result.data.data);
        }
      });
  }, []);

  const getStatusByID =  useCallback(async (type, vehicleNum) => {
    callApi(`${statusCheckAPI.url}?${type}=${vehicleNum?.trim()}`)
      .method("get")
      .headers(true)
      .send((error, result) => {
        if (result) {
          setStatusInfo(result.data.data);
        }
      });
  },[statusInfo]);

  useEffect(() => {
    getAllStatus();
  }, []);


  return {
    statusInfo,
    getStatusByID,
    getAllStatus,
  };
};
