import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useProfileInfoHook } from "../../../hooks/useProfileHook";
import Template from "../../../ui-components/templates/Template";
import {
  cardSize,
  UICard,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import { CheckBox } from "../../../ui-components/ui-elements/form/CheckBox";
import { Label } from "../../../ui-components/ui-elements/form/Label";
import Dropdown from "react-bootstrap/Dropdown";
import { Card } from "@material-ui/core";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter, userData } from "../../../../atom";
import { useUserDataHook } from "../../../hooks/useUserDataHook";
import { setToLocalStorage } from "../../../../helpers/common-helpers/manageStorage.helpers";
import { userProfileStorageKey } from "../../../../config/core.config";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";

const UserProfile = () => {
  const locale = useRecoilValue(LanguageFilter);
  const { profileInfo } = useUserDataHook();
  const [user, setUser] = useRecoilState(userData);
  const [contactNum, setContactNum] = useState("");
  const [eMail, setEmail] = useState("");
  const [smsNotify, setSMSNotify] = useState(false);

  useEffect(() => {
    setUser(profileInfo);
    setToLocalStorage(userProfileStorageKey, profileInfo);
  }, [profileInfo]);

  useEffect(() => {
    if (profileInfo?.phone !== undefined && profileInfo?.phone !== null) {
      let contact = profileInfo?.phone;

      if (profileInfo?.phone.charAt("0") === "0") {
        contact = "+94 " + profileInfo?.phone.substring(1);
      } else {
        contact = "+94 " + profileInfo?.phone;
      }

      setContactNum(contact);
    } else {
      setContactNum("");
    }

    if (profileInfo?.email !== null) {
      setEmail(profileInfo?.email);
    } else {
      setEmail("");
    }
  }, [profileInfo]);

  useEffect(() => {
    if (profileInfo?.user?.sms_notification === 1) {
      setSMSNotify(true);
    } else {
      setSMSNotify(false);
    }
  }, [profileInfo?.user?.sms_notification]);

  return (
    <Template activeHeader="userProfile" selectedLan={locale}>
      <div className="uiCardWrapper">
        <UICard
          cardSize={cardSize.LARGE}
          title={
            locale === "en"
              ? "User Profile"
              : locale === "si"
                ? "පරිශීලක තොරතුරු"
                : "பயனர் சுயவிவரம்"
          }
          //   imageSrc=""
          //  imageAlt="userPicture"
          titleStyle="mainTxtHeading "
        >
          <br></br>
          <div
            className="card bg-primary text-white"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30%",
              width: "8%",
              borderRadius: "20%",
            }}
          >
            <div className="card-body">
              <h3 className="card-text">
                {profileInfo !== undefined
                  ? profileInfo.display_name.charAt(0).toUpperCase()
                  : "N/A"}
              </h3>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-2">
              <Label
                labelText={
                  locale === "en"
                    ? "Display Name "
                    : locale === "si"
                      ? "ප්‍රදර්ශිත නාමය"
                      : "பெயரினை வெளிக்காட்டு"
                }
              />
            </div>
            <div>:</div>
            <div className="col-md-6 col-sm-6">
              {profileInfo !== undefined ? profileInfo.display_name : "N/A"}
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-2 col-sm-3">
              <Label
                labelText={
                  locale === "en"
                    ? "Mobile Number "
                    : locale === "si"
                      ? "ජංගම දුරකථන අංකය"
                      : "கையடக்கத் தொலைபேசி இலக்கம்"
                }
              />
            </div>
            <div>:</div>
            <div className="col-md-6 col-sm-6">
              {contactNum !== "" ? contactNum : "N/A"}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2 col-sm-2"></div>
            <div className="col-md-6 col-sm-6">
              <div>
                {/* <CheckBox
                  isDisabled={true}
                  labelPlacement={labelPositions.START}
                  inputValue={smsNotify}
                  inputName={smsNotify}
                  labelText={
                    locale === "en"
                      ? "Send SMS Alerts "
                      : locale === "si"
                        ? "කෙටි පණිවිඩ ඇඟවීම් යවන්න"
                        : "குறுந் தகவல் விழிப்பூட்டலடகளை அனுப்புக"
                  }
                /> */}
                <label>
                  <input
                    isDisabled={true}
                    type="checkbox"
                    checked={smsNotify}
                  />{" "}
                  {locale === "en"
                    ? "Send SMS Alerts "
                    : locale === "si"
                      ? "කෙටි පණිවිඩ ඇඟවීම් යවන්න"
                      : "குறுந்தகவல் விழிப்பூட்டல்களை அனுப்புதல்"}
                </label>
                {/* <label>
                  <input type="checkbox" checked={smsNotify} />{" "}
                  {locale === "en"
                    ? "Send SMS Alerts "
                    : locale === "si"
                    ? "කෙටි පණිවිඩ ඇඟවීම් යවන්න"
                    : "குறுந் தகவல் விழிப்பூட்டலடகளை அனுப்புக"}
                </label> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2 col-sm-3">
              <Label
                labelText={
                  locale === "en"
                    ? "Email Address"
                    : locale === "si"
                      ? "විද්‍යුත් ලිපිනය"
                      : " மின்னஞ்சல் முகவரி"
                }
              />
            </div>
            <div style={{ paddingTop: "5px", fontSize: "15px" }}>:</div>
            <div className="col-md-6 col-sm-6" style={{ paddingTop: "5px", fontSize: "15px" }}>
              {eMail !== "" ? eMail : "N/A"}
            </div>
          </div>
          <br></br>
          <div className="col-md-12 col-sm-12">
            <div className="row">
              <Link to="user-edit">
                <Button
                  btnName={"editProfile"}
                  btnText={
                    locale === "en"
                      ? "Update Profile"
                      : locale === "si"
                        ? "තොරතුරු සංකස්කරණය කරන්න"
                        : "சுயவிபரத்தை தொகு"
                  }
                  btnType={"button"}
                  btnStyle={"btnPrimary"}
                />
              </Link>
            </div>
          </div>
        </UICard>
      </div>
    </Template>
  );
};

export { UserProfile };
