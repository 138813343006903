/*
 * @Author: Binara Medawatta 
 * @Date: 2021-02-11 15:19:35 
 * @Last Modified by:   Binara Medawatta 
 * @Last Modified time: 2021-02-11 15:19:35 
 */
import { store } from "react-notifications-component";

/**
 * Notification
 *
 * @param {Object} props - Notification props
 * @param {string} props.title - Notification title
 * @param {string} props.message - Notification content
 * @param {string=} props.notificationType - Notification type (success, danger, info, default, warning)
 * @param {string=} props.position - Notification position (top, bottom, etc)
 * @param {string=} props.placement - Notification placement (top-left, top-right, top-center, center, bottom-left, bottom-right, bottom-center)
 * @param {Array=} props.animationIn - Start animation
 * @param {Array=} props.animationOut - End animation
 * @param {boolean=} props.isDismiss - Auto clear notification
 * @param {number=} props.dismissDuration - Time to live
 * @param {boolean=} props.dismissDurationDisplay - Show time bar
 * @param {boolean=} props.dismissPauseOnHover - Pause dismiss on hover
 */

const getNotification = (props) => {
  
  const notification = {
    title: props.title,
    message: props.message,
    type: props.notificationType || "success",
    insert: props.position || "top",
    container: props.placement || "top-right",
    animationIn: props.animationIn || ["animate__animated animate__fadeIn"],
    animationOut: props.animationOut || ["animate__animated animate__fadeOut"],
  };

  const dismiss = props.isDismiss && {
    dismiss: {
      duration: props.dismissDuration || 5000,
      onScreen: props.dismissDurationDisplay || true,
      pauseOnHover: props.dismissPauseOnHover || true,
    },
  };

  store.addNotification({
    ...notification,
    ...dismiss,
  });
};

export { getNotification };
