/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:23:37
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-08 00:56:33
 */
import { Form } from "../../../../ui-components/ui-elements/form/FormWrapper";
import { InputBox } from "../../../../ui-components/ui-elements/form/InputBox";
import { Button } from "../../../../ui-components/ui-elements/form/Button";
import { labelPositions } from "../../../../ui-components/ui-elements/form/InputLabel";
import { useProfileInfoHook } from "../../../../hooks/useProfileHook";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { LanguageFilter } from "../../../../../atom";
import { toast, ToastContainer } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { IntlProvider, FormattedMessage } from "react-intl";
import React from "react";

const VerifyMobileNumberPopUp = ({
  mobileNumber,
  verifyFunc,
  handleClose,
  setIsVerified,
}) => {
  const { sendOtp, verifyOtp, otpVerifyResult, profileInfoError, errorInfo } =
    useProfileInfoHook();

  const [isOtpMsg, setIsOtpMsg] = useState();
  const [otp, setOtp] = useState("");
  const [errorOTP, setErrorOTP] = useState();
  const [disable, setDisable] = useState();
  const [disableResend, setDisableResend] = useState(false);
  const locale = useRecoilValue(LanguageFilter);
  const [counter, setCounter] = React.useState(120);
  const messages = {
    en: {
      passwordMsg:
        "One time password (OTP) has been sent to your mobile. Please enter it below",
      otp: "OTP ",
      verify: "Verify",
      remaining: "Remaining seconds - ",
      notReceive: "Haven’t received the OTP yet?",
      resend: "Resend",
    },
    si: {
      passwordMsg:
        "එක් වරක් පමණක් පෙන්නුම් කරනු ලබන මුරපදය (OTP) ඔබගේ ජංගම දුරකථනය වෙත එවා ඇත. කරුණාකර එය පහතින් ඇතුලත් කරන්න.",
      otp: "එක් වරක් පමණක් පෙන්නුම් කරනු ලබන මුරපදය ",
      verify: "සනාථ කරන්න",
      remaining: "ඉතිරි තත්ත්පර ගණන - ",
      notReceive:
        "එක් වරක් පමණක් පෙන්නුම් කරනු ලබන මුරපදය තවමත් ලැබී නොමැති ද?",
      resend: "නැවත එවන්න.",
    },
    ta: {
      passwordMsg: "ஒரு தடவை பயன்படுத்தும் கடவுச் சொல்",
      otp: "ஓரு தடவை பயன்படுத்தும் கடவுச்சொல் ",
      verify: "உறுதிப்படுத்து",
      remaining: "எஞ்சியுள்ள வினாடிகள் - ",
      notReceive: "ஓரிபி இன்னமும் கிடைக்கப் பெறவில்லையா?",
      resend: "மீள அனுப்பு",
    },
  };

  useEffect(() => {
    const timer2 =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer2);
    };
  }, [counter]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisableResend(false);
      setDisable(true);
    }, 180000);
    return () => clearTimeout(timer);
  }, []);

  let otpInput = "";

  const handleOtpInput = (text) => {
    otpInput = text.value;
    setOtp(otpInput);
  };

  const otpValidation = () => {
    const regex = /^\d{6}$/;
    if (!otp || regex.test(otp) === false) {
      return false;
    }

    return true;
  };

  const onVeifyClicked = () => {
    if (otp.length === 6) {
      if (otpValidation()) {
        verifyOtp({
          otp: otp,
        });
      } else {
        failNotify(
          locale === "en"
            ? "OTP entered is Incorrect. Please enter a valid OTP."
            : locale === "si"
            ? "ඇතුලත් කර ඇති එක් වරක් පමණක් පෙන්නුම් කරන මුරපදය වැරදිය. කරුණාකර එක් වරක් පමණක් පෙන්නුම් කරන වලංගු මුරපදය ඇතුලත් කරන්න."
            : "OTP தவறாகப் பதிவு செய்யப்பட்டுள்ளது. தயவு செய்து வலிதான OTP இனைப் பதிவு செய்யவும்."
        );
      }
    } else if (otp.length === 0) {
      setIsOtpMsg(
        locale === "en"
          ? "OTP is required."
          : locale === "si"
          ? "OTP අවශ්‍ය වේ."
          : "OTP தேவைப்படுகின்றது."
        // OTP காலியாக இருக்கக்கூடாது.
        // OTP හිස් විය නොහැක.
        // "<ගොනු කර ඇති නම > අවශ්‍ය වේ."
        //"<களப் பெயர்> தேவைப்படுகின்றது."
      );
    } else {
      setIsOtpMsg(
        locale === "en"
          ? "OTP entered is Incorrect. Please enter a valid OTP."
          : locale === "si"
          ? "ඇතුලත් කර ඇති එක් වරක් පමණක් \nපෙන්නුම් කරන මුරපදය වැරදිය\n. කරුණාකර එක් වරක් පමණක් පෙන්නුම් කරන වලංගු මුරපදය ඇතුලත් කරන්න."
          : "OTP தவறாகப் பதிவு செய்யப்பட்டுள்ளது. தயவு செய்து வலிதான OTP இனைப் பதிவு செய்யவும்."
      );
    }
  };

  const successNotify = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const failNotify = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onResendClicked = () => {
    sendOtp({
      phone_number: mobileNumber,
    });

    setDisable(false);
    setDisableResend(true);
    setCounter(120);

    const timer = setTimeout(() => {
      setDisable(true);
      setDisableResend(false);
    }, 120000);

    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    if (otpVerifyResult !== undefined) {
      verifyFunc(true);
      handleClose(false);
      successNotify(
        locale === "en"
          ? "Mobile number successfully verified"
          : locale === "si"
          ? "ජංගම දුරකථන අංකය සාර්ථකව තහවුරු කර ඇත"
          : "மொபைல் எண் வெற்றிகரமாகச் சரிபார்க்கப்பட்டது"
      );

      setIsOtpMsg("");
      setIsVerified(true);
    } else {
      setIsVerified(false);
      verifyFunc(false);

      if (profileInfoError !== "Invalid OTP.") {
        failNotify(profileInfoError);
      }

      failNotify(errorInfo);

      if (profileInfoError === "Invalid OTP.") {
        failNotify(
          locale === "en"
            ? "OTP entered is Incorrect. Please enter a valid OTP."
            : locale === "si"
            ? "ඇතුලත් කර ඇති එක් වරක් පමණක් පෙන්නුම් කරන මුරපදය වැරදිය. කරුණාකර එක් වරක් පමණක් පෙන්නුම් කරන වලංගු මුරපදය ඇතුලත් කරන්න."
            : "OTP தவறாகப் பதிவு செய்யப்பட்டுள்ளது. தயவு செய்து வலிதான OTP இனைப் பதிவு செய்யவும்."
        );
      }
    }
  }, [otpVerifyResult, profileInfoError, errorInfo]);

  function linesBreak(isOtpMsg) {
    return isOtpMsg
      .map((isOtpMsgs) =>
        typeof isOtpMsgs === "string"
          ? isOtpMsgs.split("\n").map((isOtpMsg) => <p>{isOtpMsg}</p>)
          : isOtpMsgs
      )
      .reduce((isOtpMsg, isOtpMsgs) => isOtpMsg.concat(isOtpMsgs), []);
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Form className="">
        <p className="row pl-5">
          <FormattedMessage
            id="passwordMsg"
            value={{ locale }}
          ></FormattedMessage>
        </p>
        <br></br>
        <div className="row" style={{ marginLeft: "9%" }}>
          <div
            className="text-dark mr-1"
            style={{ width: "23.5%", marginTop: "1.5%" }}
          >
            <div className="row">
              <FormattedMessage id="otp" value={{ locale }}></FormattedMessage>
              <div className="text-danger">&nbsp; *</div>
            </div>
          </div>
          <div className="col">
            <div className="text-danger">
              <InputBox
                id="txtOTP"
                inputId="otp"
                maxCharLength={6}
                inputName="otp"
                helperText=""
                inputError={isOtpMsg}
                // isRequired={true}
                labelPlacement={labelPositions.START}
                isDisabled={false}
                onChange={(text) => handleOtpInput(text)}
                closeOnClick={false}
              />
            </div>
          </div>
          <div className="col-md-3" style={{ marginTop: "1.8%" }}>
            <Button
              btnName={"verify"}
              btnText={
                locale === "en"
                  ? "Verify"
                  : locale === "si"
                  ? "සනාථ කරන්න"
                  : "உறுதிப்படுத்து"
              }
              btnType={"button"}
              isFullWidth={false}
              isDisabled={disable === true ? true : false}
              onClick={() => onVeifyClicked()}
              closeOnClick={false}
            />
            <ToastContainer
              position="bottom-right"
              autoClose={15000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
        <br />
        <div className="row ml-4 pl-3">
          <div className="row col-md-6">
            <div>
              <FormattedMessage
                id="remaining"
                value={{ locale }}
              ></FormattedMessage>{" "}
            </div>
            <div>{counter}</div>
          </div>
        </div>
        <div className="row ml-3 pl-2">
          <div className="col-md-6">
            <p>
              <FormattedMessage
                id="notReceive"
                value={{ locale }}
              ></FormattedMessage>
              {"  "}
              {disableResend === false ? (
                <a href="#" onClick={() => onResendClicked()}>
                  {/* <FormattedMessage
                    id="resend"
                    value={{ locale }}
                  ></FormattedMessage> */}
                  {locale === "en"
                    ? "Resend"
                    : locale === "si"
                    ? "නැවත එවන්න."
                    : "மீள அனுப்பு"}
                </a>
              ) : (
                // <FormattedMessage
                //   id="resend"
                //   value={{ locale }}
                // ></FormattedMessage>
                <a href="#">
                  {locale === "en"
                    ? "Resend"
                    : locale === "si"
                    ? "නැවත එවන්න."
                    : "மீள அனுப்பு"}
                </a>
              )}
            </p>
          </div>
          {/* <div className="col-md-3">
          <Button
            btnName={"resendOtp"}
            btnText={"Resend"}
            btnType={"button"}
            isFullWidth={true}
            onClick={() => onResendClicked()}
          />
        </div> */}
        </div>
      </Form>
    </IntlProvider>
  );
};

export default VerifyMobileNumberPopUp;
