import { useState, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { LanguageFilter } from "../../atom";
import { callApi } from "../../helpers/common-helpers/callApi.helpers";
import { newsAPI } from "../../config/apiUrl.config";

export const useHomeHook = () => {
  const locale = useRecoilValue(LanguageFilter);

  const [news, setNews] = useState();

  const getNewsData = useCallback(async () => {
    callApi(`${newsAPI.url}?language=${locale}`)
      .method("get")
      .send((error, result) => {
        if (result) {
          setNews((prev) => ({
            ...result.data,
            prev: result.data,
          }));
        }
      });
  }, [locale]);

  useEffect(() => {
    getNewsData();
  }, [getNewsData, locale]);

  return { news };
};
