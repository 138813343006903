/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-27 00:06:15
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:34:01
 */
import React, { useState } from "react";
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import PasswordPolicyPopUp from "./includes/PasswordPolicyPopUp";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [open, setOpen] = useState(false);

  return (
    <Template>
      <UICard
        title={"Reset Password"}
        titlePlacement={cardTitlePlacement.CENTER}
        cardSize={cardSize.MEDIUM}
        isCardCenter={true}
      >
        <div className="row authFormWrapper">
          <div className="col-md-6 columnBoder">
            <InputBox
              labelText="Password"
              inputId="password"
              inputName="password"
              inputType="password"
              labelPlacement={labelPositions.TOP}
              inputError=""
              isRequired={true}
              isDisabled={false}
              endIcon={<i className="mdi mdi-help" />}
              endIconOnClickFn={() => setOpen(true)}
            />
            {open && (
              <Popup
                title={"Password Policy"}
                content={<PasswordPolicyPopUp />}
                isOpen={open}
                handleClose={() => setOpen(false)}
              />
            )}

            <InputBox
              labelText="Confirm Password"
              inputId="confirm"
              inputName="confirm"
              inputType="password"
              labelPlacement={labelPositions.TOP}
              inputError=""
              isRequired={true}
              isDisabled={false}
            />
            <div className="contentCenter">
              <div className="floatRight">
                <Button btnText={"Submit"} btnType={"submit"} />
              </div>
            </div>
          </div>
          <div className="col-md-6 contentCenter">
            <div className="marginTopContent">
              <p>
                Reset your password by entering the new password and confirming
                the password by entering it again
              </p>
              <div className="textCenter">
                <p>OR</p>
                <Link to="login">
                  <Button
                    btnName={"login"}
                    btnText={"Back to Login Page"}
                    btnType={"button"}
                    btnStyle={"emailSignin"}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </UICard>
    </Template>
  );
};

export default ResetPassword;
