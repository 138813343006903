/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:23:37
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:35:10
 */
import { Form } from "../../../../ui-components/ui-elements/form/FormWrapper";
import { Button } from "../../../../ui-components/ui-elements/form/Button";
import { InputBox } from "../../../../ui-components/ui-elements/form/InputBox";
import { labelPositions } from "../../../../ui-components/ui-elements/form/InputLabel";

const ConfirmYourEmailPopUp = () => {
  return (
    <Form className="">
    <div className="floatLeft">

      <p >
        The verification code has been sent to the entered Email address. Please
        check enter the sent verification code to continue.
      </p>
    </div>
      <br></br>
      <div className="row">
        <div className="col-md-9">
          <InputBox
            inputId="verificationCode"
            inputName="verificationCode"
            labelText="Verification Code"
            helperText=""
            labelPlacement={labelPositions.START}
            isDisabled={false}
          />
        </div>
        <div className="col-md-3">
          <Button
            btnName={"confirmEmail"}
            btnText={"Confirm"}
            btnType={"submit"}
            isFullWidth={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <p>Havent received verification Code yet?</p>
        </div>
        <div className="col-md-3">
          <Button
            btnName={"resendEmail"}
            btnText={"Resend"}
            btnType={"button"}
            isFullWidth={true}
          />
        </div>
      </div>
    </Form>
  );
};

export default ConfirmYourEmailPopUp;
