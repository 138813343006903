/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:23:37
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-05 16:09:42
 */
import { Image } from "../../../../ui-components/ui-elements/common/BaseElements";
import { Label } from "../../../../ui-components/ui-elements/form/Label";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../../atom";
const ChassisNumberDisplayPopUp = () => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <div style={{height:200}}>
      <ul>
        <li>
          {locale==="en"?"You can find the chassis number in the vehicle registration book/ form.":locale==="si"?"වාහන ලියාපදිංචි පොතෙහි/ පෝරමයෙහි චැසි අංකය දක්වා ඇත.":"நீங்கள் வாகனப் பதிவுப் புத்தகம் / படிவத்தில் அடிச்சட்ட இலக்கத்தினை கண்டு பிடிக்கலாம்."}
        </li>
        <li>
        {locale==="en"?"Chassis number is required to ensure that you are the owner/ valid representative of this vehicle.":locale==="si"?"මෙම වාහනයේ හිමිකරු/ වලංගු නියෝජිතයා ඔබ බව තහවුරු කර ගැනීම සඳහා චැසි අංකය අවශ්‍ය වේ.":"இந்த வாகனத்தின்  உரிமையாளர் / வலிதான பிரதிநிதி நீங்களா என்பதனை உறுதிப்படுத்துவதற்கு அடிச்சட்ட இலக்கம் தேவைப்படுகின்றது."}{""}
        </li>
      </ul>
      <center>
        <div>
          <p className="font-weight-bold">{locale==="en"?"Chassis Number":locale==="si"?"චැසි අංකය":"சேஸ் எண்"}</p>
          <Image src="images/Capture.PNG" />
        </div>
      </center>
      <br></br>
    </div>
  );
};

export default ChassisNumberDisplayPopUp;
