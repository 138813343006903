/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-11 15:54:56
 * @Last Modified by:   Binara Medawatta
 * @Last Modified time: 2021-02-11 15:54:56
 */
import React, { Fragment } from "react";

import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import Faq from "./faq/Faq";
import ContactUs from "./contact-us/ContactUs";
import LocateUs from "./locate-us/LocateUs";
import Profile from "./profile/Profile";
import PrivacyPolicy from "./privacy-policy/PrivacyPolicy";
import { TermsAndConditions } from "./terms-and-conditions/TermsAndConditions";
import SupportOrganizations from "./support-organizations/SupportOrganizations";
import authorized from "../../hoc-middlewares/Authorized";
import { UserProfile } from "../user-profile/pages/UserProfile";
import StatusCheck from "../status-check/pages/StatusCheck";

const QuickLinkRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/contact-us"
        routeKey={"contactus"}
        component={guest(ContactUs)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/Faq"
        routeKey={"Faq"}
        component={guest(Faq)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/locate-us"
        routeKey={"locateus"}
        component={guest(LocateUs)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/profile"
        routeKey={"profile"}
        component={authorized(UserProfile)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/privacy-policy"
        routeKey={"privacy-policy"}
        component={guest(PrivacyPolicy)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/supported-companies"
        routeKey={"supported-companies"}
        component={guest(SupportOrganizations)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/TnC"
        routeKey={"TnC"}
        component={guest(TermsAndConditions)}
        routePermissions={permissions.NONE.permissions}
      />
      {/* <Navigate
        exact={true}
        path="/status-check"
        routeKey={"statuscheck"}
        component={authorized(StatusCheck)}
        routePermissions={permissions.NONE.permissions}
      /> */}
    </Fragment>
  );
};

export default QuickLinkRoutes;
