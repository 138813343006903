import { useState, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { callApi } from "../../helpers/common-helpers/callApi.helpers";
import {
  supInsCompaniesAPI,
  vetCompaniesAPI,
  vftComapniesAPI,
} from "../../config/apiUrl.config";
import { LanguageFilter } from "../../atom";

const initProvice = {
  id: 1,
  value: "select",
  name: "Select",
};

export const useSupportOrganizationHook = () => {
  const locale = useRecoilValue(LanguageFilter);

  const [insCompanies, setInsCompanies] = useState();
  const [vetCompanies, setVetCompanies] = useState();
  const [vftCompanies, setVftCompanies] = useState();
  const [provinceCode, setProvinceCode] = useState(initProvice);

  const getInsData = useCallback(() => {
    callApi(`${supInsCompaniesAPI.url}?sort_by=name|asc&language=${locale}`)
      .method("get")
      .send((error, result) => {
        if (result) {
          setInsCompanies((prev) => ({
            ...result,
            prev: result,
          }));
        }
      });
  }, [locale]);

  const getVetData = useCallback(() => {
    callApi(`${vetCompaniesAPI.url}?sort_by=name|asc&language=${locale}`)
      .method("get")
      .send((error, result) => {
        if (result) {
          setVetCompanies((prev) => ({
            ...result,
            prev: result,
          }));
        }
      });
  }, [locale]);

  const getVftData = useCallback(() => {
    callApi(
      `${vftComapniesAPI.url}?sort_by=name|asc&language=${locale}&province_code=${provinceCode.value}`
    )
      .method("get")
      .send((error, result) => {
        if (result) {
          setVftCompanies((prev) => ({
            ...result,
            prev: result,
          }));
        }
      });
  }, [locale, provinceCode]);

  useEffect(() => {
    if (provinceCode?.value === "null") {
      setVftCompanies([]);
    }
  }, [provinceCode]);

  useEffect(() => {
    getInsData();
    getVetData();
    getVftData();
  }, [getInsData, getVetData, getVftData, locale]);

  return { insCompanies, vetCompanies, vftCompanies, setProvinceCode };
};
