/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-26 09:22:43
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-15 14:27:16
 */
import { Fragment, useState } from "react";

const Accordion = ({
  id = "",
  heading = "",
  content = "",
  children = null,
}) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div>
      <div className={`accordion`}>
        <div
          className={`accordionHeader ${
            collapse ? "activeAccordion" : "defaultAccordion"
          }`}
          id={id}
          onClick={() => setCollapse(!collapse)}
        >
          <div className="row">
            <div className="headingWrapper col-lg-10 col-md-8 col-sm-6">
              <h5 className="defaultH3">{heading}</h5>
            </div>

            <div className="iconWrapper col-lg-2 col-md-4 col-sm-6">
             {collapse ? <i className="mdi mdi-chevron-up"></i>: <i className="mdi mdi-chevron-down"></i>}
            </div>
          </div>
        </div>
      </div>
      {collapse && (
        <Fragment>
          {content && (
            <div id={id}>
              <div className="card-body">{content}</div>
            </div>
          )}
          <div className="secondaryAccordion">{children}</div>
        </Fragment>
      )}
    </div>
  );
};

const AccordionWrapper = ({ 
  children = {},
  accordionWrapperStyle=""
}) => {
  return <div id="accordion" className={`baseAccordionWrapperStyle ${accordionWrapperStyle} `}>{children}</div>;
};

export { Accordion, AccordionWrapper };
