/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-26 16:02:38
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:29:54
 */
import React from "react";
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import { Link, Redirect } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

const pageTransition = {
  duration: 2,
};

const Login = () => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <Redirect to={"/"} />
    // <Template selectedLan={locale}>
    //   <motion.div initial="out" animate="in" exit="out" variants= {pageVariants} transition = {pageTransition}>
    //   <UICard
    //     title={"Login"}
    //     titlePlacement={cardTitlePlacement.LEFT}
    //     cardSize={cardSize.LARGE}
    //    // isContentCenter={true}
    //     titleStyle="mainTxtHeading "
    //   >
    //     <div className="row authFormWrapper">
    //       <div className="col-md-6 columnBoder">
    //         <InputBox
    //           labelText="Email *"
    //           inputId="email"
    //           inputName="email"
    //           inputType="text"
    //           labelPlacement={labelPositions.TOP}
    //           inputError=""
    //           isRequired={true}
    //           isDisabled={false}
    //         />
    //         <InputBox
    //           labelText={"Password *"}
    //           inputId="password"
    //           inputName="password"
    //           inputType="password"
    //           labelPlacement={labelPositions.TOP}
    //           inputError=""
    //           isRequired={true}
    //           isDisabled={false}
    //         />
    //         <div className="floatRight row contentCenter">
    //         <div>
    //               <Link to="/">
    //                 <Button btnText={"Login"} btnName={"login"} />
    //               </Link>

    //           </div>
    //           <div style={{marginLeft:10,marginTop:10}}>
    //             <Link to="forgot-password">
    //               Forgot your password?
    //             </Link>
    //           </div>

    //         </div>
    //       </div>
    //       <div className="col-md-6 contentCenter">
    //         <div className="textCenter">
    //           <Link to="">
    //             <Button
    //               btnName={"facebook"}
    //               btnText={"Login with Facebook"}
    //               btnType={"button"}
    //               btnStyle={"facebookSignin"}
    //             />
    //           </Link>
    //           <Link to="">
    //             <Button
    //               btnName={"google"}
    //               btnText={"Login with Google"}
    //               btnType={"button"}
    //               btnStyle={"googleSignin"}
    //             />
    //           </Link>
    //           <p>OR</p>
    //           <Link to="register">
    //             <Button
    //               btnName={"signup"}
    //               btnText={"Login with your email"}
    //               btnType={"button"}
    //               btnStyle={"emailSignin"}
    //             />
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   </UICard>
    //   </motion.div>
    // </Template>
  );
};

export default Login;
