
import { Button } from "../../../../ui-components/ui-elements/form/Button";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../../atom";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";


const CancelUpdatePopUp = ({closePopup}) => {
    const locale = useRecoilValue(LanguageFilter);
    const history = useHistory();

    
   
  return (
    <div>
        <form id="cancelform">
            <div className="pl-1">{
                    locale==="en"
                    ?"Are you sure you want to cancel?"
                    : locale === "si" 
                    ?"ඔබට අවලංගු කිරීමට අවශ්‍ය බව විශ්වාසද?"
                    :"நீங்கள் நிச்சயமாக ரத்து செய்ய விரும்புகிறீர்களா?"
                }</div>
            <br></br>
            <div className="row pl-3">
                <Button
                btnName={"Yes"}
                btnText={
                    locale==="en"
                    ?"Yes"
                    : locale === "si" 
                    ?"ඔව්"
                    :"ஆம்"
                }
                btnType={"button"}
                onClick={() => history.goBack()}
                />
             
             <div className="ml-4">
                    <Button
                        btnName={"No"}
                        btnText={
                        locale==="en"
                        ?"No"
                        : locale === "si" 
                        ?"නැත"
                        :"இல்லை"
                    }
                 btnType={"button"}
                 onClick={()=>closePopup(false)}
                 
                />
                
                </div>
              
            </div>
        </form>
    </div>
  )
}

export default CancelUpdatePopUp