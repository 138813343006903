/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:23:37
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-02 13:31:49
 */

import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../../atom";
const PasswordPolicyPopUp = () => {
  const locale = useRecoilValue(LanguageFilter);

  return (
    <div>
      <p className="font-weight-bold text-dark">
        {locale==="en"?"Please make sure that your password is a strong password. It should meet the following requirements.":locale==="si"?"ඔබගේ මුරපදය ශක්තිමත් මුරපදයක් බව කරුණාකර තහවුරු කර ගන්න. එය පහත දැක්වෙන අවශ්‍යතා සපුරා තිබිය යුතුයි.":"கடவுச் சொல்லானது மிகவும் உறுதியாதென்பதனை உறுதி செய்க. அது பின்வரும் தேவைப்பாடுகளை நிறைவேற்றவதாக இருத்தல் வேண்டும்."}
        
      </p>
      <br></br>
      <ul>
        <li className="text-dark">{locale==="en"?"Password should be at least 8 characters long":locale==="si"?" මුරපදය අවම වශයෙන් අක්ෂර 8 කින් සමන්විත විය යුතුය.":"கடவுச்சொல்லானது குறைந்தது 8 எழுத்துக்களைக் கொண்ட நீளமானதாக இருத்தல் வேண்டும்."}</li>
        <li className="text-dark">
          {locale==="en"?"Should be a combination of alphanumeric (A-Z, a-z, 0-9)":locale==="si"?"එය අක්ෂර හා සංඛ්‍යාවලින් (A-Z, a-z, 0-9) සමන්විත සංකලනයක් විය යුතුය.":"அகர வரிசை (A-Z, a-z, 0-9) எழுத்துக்களின் சேர்க்கையாக இருத்தல் வேண்டும்."}.
        </li>
        <li className="text-dark">{locale==="en"?"Should include at least one special character.":locale==="si"?" එයට එක් විශේෂ අක්ෂරයක් ඇතුලත් විය යුතුය.":"குறைந்தது ஒரு விசேட எழுத்தையேனும் உள்ள்ளடக்கியிருத்தல் வேண்டும்."}</li>
      </ul>
    </div>
  );
};

export default PasswordPolicyPopUp;
