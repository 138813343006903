import React, { Fragment, useEffect, useState } from "react";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { SelectBox } from "../../../ui-components/ui-elements/form/SelectBox";
import { ConfirmDetails } from "./ConfirmDetails";
import Template from "../../../ui-components/templates/Template";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import {
  Button,
  RoundedIconButton,
} from "../../../ui-components/ui-elements/form/Button";
import ChassisNumberDisplayPopUp from "./includes/ChassisNumberDisplayPopUp";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import { AnimatePresence, motion } from "framer-motion";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  LanguageFilter,
  VehicleNumber,
  ChassisNumber,
  VehicleInfo,
} from "../../../../atom";
import { useRevenueLicenseHook } from "../../../hooks/useRevenueLicenseHook";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useScreenType } from "../../../hooks/common-hooks/useScreenStatus.hook";

const messages = {
  en: {
    title: "Vehicle Information Validation",
    VehicleRegistrationNumber: "Vehicle Registration Number ",
    LastSixCharactersOfChassisNumber: "Chassis Number (Last Six Characters)",
    InsuranceCompany: "Insurance Company",
    PleaseSelectTheCurrentInsuranceCompany:
      "Please Select The Current Insurance Company",
  },
  si: {
    title: "වාහනය පිළිබඳ තොරතුරු",
    VehicleRegistrationNumber: "වාහන ලියාපදිංචි අංකය",
    LastSixCharactersOfChassisNumber: "චැසි අංකයේ අවසන් ඉලක්කම් හය",
    InsuranceCompany: "රක්ෂණ සමාගම",
    PleaseSelectTheCurrentInsuranceCompany:
      "කරුණාකර වත්මන් රක්‍ෂණ සමාගම තෝරන්න",
  },
  ta: {
    title: "வாகன தகவல்",
    VehicleRegistrationNumber: "வாகன பதிவு இலக்கம்",
    LastSixCharactersOfChassisNumber:
      "அடிச்சட்ட இலக்கத்தின் இறுதி ஆறு இலக்கங்கள்",
    InsuranceCompany: "காப்பீட்டு நிறுவனம்",
    PleaseSelectTheCurrentInsuranceCompany:
      "தற்போதைய காப்பீட்டு நிறுவனத்தைத் தேர்ந்தெடுக்கவும்",
  },
};

const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

const pageTransition = {
  duration: 2,
};

const RevenueLicence = () => {
  const locale = useRecoilValue(LanguageFilter);
  const [vehicleNumberState, setVehicleNumber] = useRecoilState(VehicleNumber);
  const [chassisNumberState, setChassisNumber] = useRecoilState(ChassisNumber);
  const [vehicleDetails, setVehicleDetails] = useRecoilState(VehicleInfo);

  const [open, setOpen] = useState(false);
  const [showBottomContainer, setShowBottomContainer] = useState(false);

  const { vehicleInfo, getVehicleInfo, errorInfo, setErrorInfo } =
    useRevenueLicenseHook();
  const [errorVehicle, setErrorVehicle] = useState();
  const [errorChassis, setErrorChassis] = useState();
  const [inputVehicleNumber, setInputVehicleNumber] = useState(vehicleNumberState);
  const [inputChassisNumber, setInputChassisNumber] = useState(chassisNumberState);

  const vehicleNumberValidation = () => {
    const regex = /^([A-Z]{2,3}|((?!0*-)[0-9]{1,3}))-[0-9]{4}$(?<!0{4})/;
    if (!inputVehicleNumber || regex.test(inputVehicleNumber) === false) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (vehicleNumberState != "" && chassisNumberState != "") {
      onOkClicked();
    }
  }, [vehicleNumberState, chassisNumberState])

  const notify = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onOkClicked = (event) => {
    // event.preventDefault();
    setShowBottomContainer(false);
    if (inputVehicleNumber.length !== 0 && inputChassisNumber.length !== 0) {
      if (vehicleNumberValidation() && inputChassisNumber.length === 6) {
        setChassisNumber(inputChassisNumber);
        setVehicleNumber(inputVehicleNumber);
        getVehicleInfo(inputVehicleNumber, inputChassisNumber);
        setErrorChassis("");
        setErrorVehicle("");
        //  document.getElementById("revenueLicenceForm").reset();
      } else if (
        !vehicleNumberValidation() &&
        inputChassisNumber.length === 6
      ) {
        setErrorVehicle("Incorrect format of Vehicle Registration Number.");
        // setErrorChassis("Chassis No is 6 characters long");
        setErrorChassis("");
      } else if (vehicleNumberValidation() && inputChassisNumber.length !== 6) {
        setErrorVehicle("");
        setErrorChassis("Chassis No is 6 characters long");
      } else {
        setErrorVehicle("Incorrect format of Vehicle Registration Number.");
        setErrorChassis("Chassis No is 6 characters long");
      }
    } else if (
      inputVehicleNumber.length !== 0 &&
      inputChassisNumber.length === 0
    ) {
      setErrorVehicle("");
      setErrorChassis(
        locale === "en"
          ? "Chassis Number is required"
          : locale === "si"
            ? "වාහන චැසි අංකය අවශ්‍ය වේ "
            : "வாகனத்தின் சேஸ் எண் தேவை"
      );
    } else if (
      inputVehicleNumber.length === 0 &&
      inputChassisNumber.length !== 0
    ) {
      setErrorChassis("");
      setErrorVehicle(
        locale === "en"
          ? "Vehicle Registration Number is required"
          : locale === "si"
            ? "වාහන ලියාපදිංචි අංකය අවශ්‍ය වේ"
            : "அடிச்சட்ட இலக்கம் தேவைப்படுகின்றது."
      );
    } else {
      setErrorChassis(
        locale === "en"
          ? "Chassis Number is required"
          : locale === "si"
            ? "වාහන චැසි අංකය අවශ්‍ය වේ "
            : "வாகனத்தின் சேஸ் எண் தேவை"
      );
      setErrorVehicle(
        locale === "en"
          ? "Vehicle Registration Number is required"
          : locale === "si"
            ? "වාහන ලියාපදිංචි අංකය අවශ්‍ය වේ"
            : "அடிச்சட்ட இலக்கம் தேவைப்படுகின்றது."
      );
    }
  };

  const onResetClicked = () => {
    toast.dismiss();

    setInputVehicleNumber("");
    setInputChassisNumber("");
    setErrorVehicle("");
    setErrorChassis("");

    document.getElementById("revenueLicenceForm").reset();
    setShowBottomContainer(false);
  };

  const handleVehicleNum = (text) => {
    document
      .getElementById("vehicleNum")
      .addEventListener("keyup", function () {
        if (this.value.length <= 8) {
          setInputVehicleNumber(this.value);
        } else {
          this.value = this.value.slice(0, 8);
          setInputVehicleNumber(this.value);
        }
      });
  };

  const handleChassiNum = (text) => {
    document
      .getElementById("chassisNum")
      .addEventListener("keyup", function () {
        if (this.value.length <= 6) {
          setInputChassisNumber(this.value);
        } else {
          this.value = this.value.slice(0, 6);
          setInputChassisNumber(this.value);
        }
      });
  };
  let screenType = useScreenType()
  useEffect(() => {
    console.log("Check : ", errorInfo);

    if (vehicleInfo !== undefined) {
      setShowBottomContainer(true);
      setVehicleDetails(vehicleInfo);
    } else {
      setShowBottomContainer(false);

      if (errorInfo !== "") {
        if (errorInfo === "vehicle not found") {
          notify(
            locale === "en"
              ? "Vehicle registration number or chassis number invalid"
              : locale === "si"
                ? "වාහන ලියාපදිංචි අංකය හෝ චැසි අංකය වලංගු නැත"
                : "வாகன பதிவு எண் அல்லது சேஸி எண் தவறானது"
          );
          setErrorInfo("");
        } else {
          notify(errorInfo);
          setErrorInfo("");
        }
      }
    }
  }, [errorInfo, vehicleInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onConfrimDetailsCancelClicked = () => {
    onResetClicked();
    window.scrollTo(0, 0);
  };

  console.log("Input Vehicle Number", vehicleNumberState);

  return (
    <Template activeHeader="revenueLicence" selectedLan={locale}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <motion.div
        // initial="out"
        // animate="in"
        // exit="out"
        // variants={pageVariants}
        // transition={pageTransition}
        >
          <div className="uiCardWrapper-new">
            <UICard
              title={
                <div className=" pl-1" s>
                  <FormattedMessage
                    id="title"
                    value={{ locale }}
                  ></FormattedMessage>
                </div>
              }
              borderLess={false}
              titleStyle="mainTxtHeading"
            >
              <div className="col-md-12 col-sm-12 mt-5">
                <form id="revenueLicenceForm">
                  <div className="row pl-2 pr-2">
                    <div className="col-md-12 col-sm-12 text-danger">
                      <InputBox
                        helperText={"e.g 7-652/CAH-9891"}
                        maxCharLength={8}
                        labelText={
                          <div className="text-dark mr-1">
                            <FormattedMessage
                              id="VehicleRegistrationNumber"
                              value={{ locale }}
                            ></FormattedMessage>
                          </div>
                        }
                        inputId="vehicleNum"
                        labelColSize={4}
                        inputError={errorVehicle}
                        isRequired={true}
                        isRightAlignLabel={false}
                        defaultValue={vehicleNumberState}
                        // inputName={vehicleNumber}
                        value={inputVehicleNumber}
                        onChange={(e) => handleVehicleNum()}
                      // onChange={(text) => handleVehicleNum(text.value)}
                      />
                    </div>
                  </div>
                  <div className="row pl-2 pr-2">
                    <div className="col-md-12 col-sm-12">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 text-danger">
                          <InputBox
                            labelText={
                              <div className="text-dark mr-1">
                                <FormattedMessage
                                  id="LastSixCharactersOfChassisNumber"
                                  value={{ locale }}
                                ></FormattedMessage>
                              </div>
                            }
                            maxCharLength={6}
                            inputId="chassisNum"
                            labelColSize={4}
                            inputType={"help"}
                            inputError={errorChassis}
                            isRequired={true}
                            isRightAlignLabel={false}
                            defaultValue={chassisNumberState}
                            //endIcon={<i className="mdi mdi-help" />}
                            endIconOnClickFn={() => setOpen(true)}
                            endIconTooltip={"Chassis Number"}
                            value={inputChassisNumber}
                            onChange={(e) => handleChassiNum()}
                          // inputName={chassiNumber}
                          // onChange={(text) => handleChassiNum(text.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="row">
                  {/* <div className="col-md-10 col-sm-12">
                    <SelectBox
                      helperText={
                        <FormattedMessage
                          id="PleaseSelectTheCurrentInsuranceCompany"
                          value={{ locale }}
                        ></FormattedMessage>
                      }
                      labelText={
                        <FormattedMessage
                          id="InsuranceCompany"
                          value={{ locale }}
                        ></FormattedMessage>
                      }
                      labelColSize={5}
                      isRightAlignLabel={true}
                    />
                  </div> */}
                </div>
              </div>
              <div className="col-md-12 col-sm-12 p-3">
                <div className={`btnWrapper  ${screenType[0] !== "MOBILE" ? "floatRight " : ""} `}>
                  
                    <Button
                      btnStyle={`btnPrimary   marginRight` }
                      btnName="ok"
                      btnText={
                        locale === "en"
                          ? "OK"
                          : locale === "si"
                            ? "නිවැරදියි"
                            : "சரி"
                      }
                      onClick={(event) => onOkClicked(event)}
                    //onClick={notify}
                    />
                 
                    <Button
                      btnStyle="btnOutlinePrimary"
                      btnName="reset"
                      btnText={
                        locale === "en"
                          ? "Reset"
                          : locale === "si"
                            ? "ප්‍රතිසකසන්න"
                            : "மீளமைத்தல்"
                      }
                      onClick={() => onResetClicked()}
                    />
                 
                  <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </div>
              </div>
            </UICard>
          </div>
        </motion.div>
        {open && (
          <Popup
            title={
              locale === "en"
                ? "How to find the Chassis Number?"
                : locale === "si"
                  ? "චැසි අංකය සොයා ගන්නේ කෙසේ ද?"
                  : "அடிச்சட்ட இலக்கத்தை எவ்வாறு கண்டு பிடிப்பது?"
            }
            content={<ChassisNumberDisplayPopUp className="modal fade" />}
            isOpen={open}
            handleClose={() => setOpen(false)}
          />
        )}

        {showBottomContainer ? (
          <div className="seperateBox">
            <ConfirmDetails
              vehicleInfo={vehicleInfo}
              onCancelClicked={onConfrimDetailsCancelClicked}
            />
          </div>
        ) : (
          <></>
        )}
      </IntlProvider>
    </Template>
  );
};

export { RevenueLicence };
