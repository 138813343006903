/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2021-01-18 11:28:45
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-10 13:53:09
 */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Template from "../../../ui-components/templates/Template";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import { AnimatePresence, motion } from "framer-motion";
import { IntlProvider, FormattedMessage } from "react-intl";

import { Carousel } from "react-bootstrap";
import MetaTags from 'react-meta-tags';
// import homeImage from "../../../../style/img/home.jpeg";
import BannerOne from "../../../../style/img/banner_1.jpg";
import BannerTwo from "../../../../style/img/banner_2.jpg";
import BannerThree from "../../../../style/img/banner_3.jpg";
import ImageOne from "../../../../style/img/image1.jpg";
import ImageTwo from "../../../../style/img/image2new.jpg";
import ImageThree from "../../../../style/img/image3.jpg";
import ImageFour from "../../../../style/img/image4.jpg";
import ImageFive from "../../../../style/img/image5.jpg";
import ImageSix from "../../../../style/img/image6.png";

import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";

import { newsAPI } from "../../../../config/apiUrl.config";
import NewsBanner from "../../../ui-components/ui-elements/news/NewsBanner";

import { useHomeHook } from "../../../hooks/useHomeHook";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { VehicleNumber, ChassisNumber } from "../../../../atom";

const messages = {
  en: {
    title: "Welcome",
    checkStatus: "Check Status",
    checkStatusDes: "Checks 'Status' of your Online Revenue Licence Request",
    renewLicence: "First / Renew Licence",
    renewLicenceDes:
      "Get your First Revenue License or Renew your Existing Revenue Licence",
    locateUs: "Locate Us",
    locateUsDes: "Find details of Revenue Licence Issuance Centres Island wide",
    eRevenueLicenseService: "e-Revenue Licence Service",
    eRevenueLicenseServiceDes:
      "eRL Service facilitates to obtain your vehicle Revenue Licence for vehicle categories of; Motor Cars, Motor Cars A-Z, Motor Cycles, Dual Purpose Vehicles, Land Vehicles, Land Vehicle Tailor, Three Wheelers, Motor Lorry, Motor Lorry Tailor, Motor Tricycle Van, Private Coach, Motor Home, Special Purpose Vehicle, Quadric-cycle, Ambulance and Hearse. \n\nPlease ensure that you have a valid Insurance Certificate/ Vehicle Emission Test Certificate/ Vehicle Fitness Certificate (as applicable to respective vehicle category) from respective organization, before you process your request. \n\nYou may visit any Divisional Secretariat Office, convenient for you to obtain your revenue licence for all other vehicles.",
    HowtoApply: "How to get your Revenue Licence?",
    eRevenueLicenseServiceDes2:
      "You should have a valid Insurance Certificate/ Vehicle Emision Test Certificate/ Vehicle Fitness Certificate (as applicable to your Vehicle category) from respective issuance organization, a valid Credit Card or QR App with sufficient credit and own mobile phone to validate the OTP before you process your request.",
    eRevenueLicenseItem1:
      "Login using your Google/ Facebook account or Sign up with your User name/ Password, if you do not have either Google or Facebook account.",
    eRevenueLicenseItem2:
      "Submit the Vehicle No. and the Chassis No. (Last 6 digits) of your vehicle.",
    eRevenueLicenseItem3:
      "Confirm the vehicle details - Ownership, Insurance/ Emission (VET)/ Fitness (VFT) certification and Licence Fee.",
    eRevenueLicenseItem4: "Choose the Delivery method and Payment method.",
    eRevenueLicenseItem5: "Complete the payment.",
    eRevenueLicenseItem6:
      "Print the receipt for Temporary Revenue Licence from your email or by download on your device.",

    WhatareSupportOrganizations: "What are Support Organizations?",

    WhatareSupportOrganizationsPara1:
      "These Organizations provide required services, which essentially required to obtain the Revenue Licence and to run the vehicle on roads as well.",
    WhatareSupportOrganizationsItem1:
      "Insurance Companies: Insurance companies facilitate to issue general insurance covers to your vehicles, which is mandatory as suited for your requirements.",
    WhatareSupportOrganizationsItem2:
      "VET Companies: Vehicle Emission Test (VET) Companies facilitate to issue emission test certificates to your vehicles, which required to obtain the Revenue Licence.",
    WhatareSupportOrganizationsItem3:
      "VFT Issuance Centres: Vehicle Fitness Test (VFT) Issuance Centres facilitate to issue fitness test certificate to your vehicle (only for the required vehicle categories).",

    WhatareSupportOrganizationsPara2:
      "You can view the details of all Insurance/ VET/ VFT organizations under Support Organizations Menu.",

    Whatshouldyouknow: "What should you know?",
    Whatshouldyouknowpara:
      "Refer FAQs Section to get any further information about the eRevenueLicence Service. e.g. How to make the payments?, What is QR App payment?, What should do, if I have not received the Licence by post?, etc.",
  },
  si: {
    title: "සාදරයෙන් පිළිගනිමු",
    checkStatus: "වර්තමාන තත්ත්වය පරීක්ෂා කරන්න",
    checkStatusDes:
      "ඔබගේ මාර්ගගත ආදායම් බලපත්‍ර ඉල්ලීමේ ‘වර්තමාන තත්ත්වය’ පරීක්ෂා කරන්න",
    renewLicence: "ප්‍රථම බලපත්‍රය/ආදායම් බලපත්‍රය අලුත් කිරීම",
    renewLicenceDes:
      "ඔබගේ ප්‍රථම ආදායම් බලපත්‍රය ලබා ගන්න නැතහොත් ඔබගේ පවතින ආදායම් බලපත්‍රය අලුත් කර ගන්න",
    locateUs: "අප ආයතනයේ පිහිටීම",
    locateUsDes:
      "දීප ව්‍යාප්ත ආදායම් බලපත්‍ර නිකුත් කිරීමේ මධ්‍යස්ථාන පිළිබඳ තොරතුරු සොයා ගන්න",
    eRevenueLicenseService: "විද්‍යුත් ආදායම් බලපත්‍ර සේවාව",
    eRevenueLicenseServiceDes:
      "පහත සඳහන් වාහන වර්ග  සඳහා ඔබට ආදායම් බලපත්‍රය සේවාව ලබා ගත හැක; \n\nමෝටර් කාර්, මෝටර් කාර් A-Z, මෝටර් සයිකල්, ද්විත්ව කාර්ය වාහන, ඉඩම් වාහන, ඉඩම් වාහන ටේලර්, ත්‍රිරෝද රථ, මෝටර් ලොරි, මෝටර් ලොරි ටේලර්, මෝටර් ට්‍රයිසිකල් වෑන්, පුද්ගලික වාහන, මෝටර් හෝම්, විශේෂ කාර්ය වාහන, ක්වඩ්‍රික් - සයිකල්, ගිලන්රථ- සහ අවමගුල් රථ. \n\nඔබගේ ඉල්ලීම ක්‍රියාත්මක කිරීමට පෙර , (වාහන වර්ගයට අදාළ වන ආකාරයේ)  වලංගු රක්ෂණ සහතිකය/වාහන දුම් පරීක්ෂණ සහතිකය (VET) / වාහන යෝග්‍යතා සහතිකය (VFT) අදාළ ආයතනවලින් ලබා ගෙන ඇති බව කරුණාකර තහවුරු කර ගන්න. \n\nඅනෙකුත් සියළු වාහන සඳහා ඔබගේ ආදායම් බලපත්‍රය පහසුවෙන් ලබා ගැනීම සඳහා ඔබට ඕනෑම ප්‍රාදේශීය ලේකම් කාර්යාලයක්  වෙත යා  හැකිය.",
    HowtoApply: "ඔබගේ ආදායම් බලපත්‍රය ලබා ගන්නේ කෙසේ ද?",
    eRevenueLicenseServiceDes2:
      "ඔබගේ ඉල්ලීම ක්‍රියාත්මක කිරීමට පෙර අදාළ ආයතනයෙන් නිකුත් කරනු ලබන වලංගු රක්ෂණ සහතිකයක්/ වාහන දුම් පරීක්ෂණ සහිතකයක්/ වාහන යෝග්‍යතා සහතිකක්ය (ඔබගේ වාහන වර්ගයට උචිත වන පරිදි)  ඔබ විසින් ලබා ගෙන තිබිය යුතුය. නැතහොත් එක් වරක් පමණක් පෙන්නුම් කරන මුරපදය (OTP) වලංගුභාවයට පත් කර ගැනීම සඳහා වලංගු ක්‍රෙඩිට් කාඩ්පතක් නැතහොත් ප්‍රමාණවත් ණය ශේෂයක් සහිත QR යෙදුමක් සහ ඔබගේම ජංගම දුරකථනයක් ඔබ සතුව තිබිය යුතුය.",
    eRevenueLicenseItem1:
      "ඔබගේ ගූගල්/මුහුණු පොත් ගිණුම  භාවිතා කරමින් පිවිසෙන්න. නැතහොත් ඔබ සතුව ගූගල් හෝ මුහුණු පොත් ගිණුමක් නොමැති නම් ඔබගේ විද්‍යුත් ලිපිනය/ මුරපදය යොදා ගනිමින් පූරණය වන්න.",
    eRevenueLicenseItem2:
      "ඔබගේ වාහනයේ වාහන අංකය සහ චැසි අංකය (අවසන් ඉලක්කම් 6)  ඇතුලත් කරන්න.",
    eRevenueLicenseItem3:
      "වාහනයේ තොරතුරු - හිමිකාරීත්වය, රක්ෂණය/ වාහන දුම් පරීක්ෂණ (VET) / වාහන යෝග්‍යතා (VFT) සහතිකකරණය සහ බලපත්‍ර ගාස්තුව ආදිය තහවුරු කරන්න.",
    eRevenueLicenseItem4:
      "ලබා දීමේ ක්‍රමය සහ ගෙවීම් සිදු කිරීමේ ක්‍රමය තෝරන්න.",
    eRevenueLicenseItem5: "ගෙවීම සම්පූර්ණ කරන්න.",
    eRevenueLicenseItem6:
      "තාවකාලික ආදායම් බලපත්‍රය සඳහා වන රිසිට් පත ඔබගේ විද්‍යුත් ලිපිනය මගින් මුද්‍රණය කර ගන්න. නැතහොත් ඔබගේ ජංගම උපාංගයට  බාගත කර ගන්න.",
    eRevenueLicenseItem7:
      "දැනට මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුවේ ලියාපදිංචි වී ඇති වාහනයේ සඳහන් ලිපිනයට ආදායම් බලපත්‍රය අවම වශයෙන් දින 14ක් ඇතුළත තැපැල් කරනු ලැබේ.",

    WhatareSupportOrganizations: "සහායක ආයතන යනුවෙන් අදහස් කරන්නේ මොනවා ද?",

    WhatareSupportOrganizationsPara1:
      "ආදායම් බලපත්‍රය ලබා ගැනීම සඳහා මෙන්ම මාර්ගවල වාහන ධාවනය කිරීම සඳහාත් අවශ්‍ය වන ලිපි ලේඛන ලබා ගැනීම සඳහා අවශ්‍ය සේවාව මෙම ආයතන මගින් සපයනු ලැබේ.",
    WhatareSupportOrganizationsItem1:
      "රක්ෂණ සමාගම්: ඔබගේ අවශ්‍යතාවන්ට ගැළපෙන ආකාරයට ඔබගේ වාහනය සඳහා ලබා ගත යුතු බවට  අනිවාර්ය කර ඇති පොදු රක්ෂණාවරණය  ලබා දීම සඳහා රක්ෂණ සමාගම් විසින් පහසුකම් සලසනු ලැබේ.",
    WhatareSupportOrganizationsItem2:
      "වාහන දුම් පරීක්ෂණ (VET) සමාගම්: ආදායම් බලපත්‍රයක් ලබා ගැනීම සඳහා ඔබගේ වාහනය සඳහා අවශ්‍ය වන වාහන දුම් පරීක්ෂණ සහතික නිකුත් කිරීම සඳහා වාහන දුම් පරීක්ෂණ (VET) සමාගම් විසින් පහසුකම් සලසනු ලැබේ.",
    WhatareSupportOrganizationsItem3:
      "වාහන යෝග්‍යතා පරීක්ෂණ (VFT) සහතික නිකුත්  කිරීමේ මධ්‍යස්ථාන: ඔබගේ වාහනය (අවශ්‍ය වාහන වර්ග සඳහා පමණි) සඳහා වාහන යෝග්‍යතා පරීක්ෂණ සහතික නිකුත් කිරීම සඳහා අවශ්‍ය පහසුකම් වාහන යෝග්‍යතා පරීක්ෂණ (VFT) සහතික නිකුත්  කිරීමේ මධ්‍යස්ථාන මගින් සපයනු ලැබේ.",

    WhatareSupportOrganizationsPara2:
      "රක්ෂණ/වාහන දුම් පරීක්ෂණ/වාහන යෝග්‍යතා සහතික නිකුත් කිරීමේ ආයතන පිළිබඳ සියළුම තොරතුරු සහායක ආයතන මෙනුව යටතේ ඔබට ලබා ගත  හැකිය.",

    Whatshouldyouknow: "ඔබ දැන සිටිය යුතු කරුණු මොනවා ද?",
    Whatshouldyouknowpara:
      "විද්‍යුත් ආදායම් බලපත්‍රය පිළිබඳව වැඩිදුර තොරතුරු ලබා ගැනීම සඳහා නීතර යොමු කරන ප්‍රශ්න වෙත යොමු වන්න. එනම්, මා විසින් ගෙවීම් සිදු කරන්නේ කෙසේ ද? QR යෙදුම හරහා ගෙවීම් සිදු කිරීම යනු කුමක් ද? බලපත්‍රය තැපෑල මගින් මා වෙත නොලැබුණහොත් මා විසින් කළ යුත්තේ කුමක් ද? යනාදී ප්‍රශ්න.",
  },
  ta: {
    title: "தலைப்பு",
    checkStatus: "செவ்வை பார்த்தல் தகுதிநிலை",
    checkStatusDes:
      "வலை போர்ட்டலில் உள்நுழைவதன் மூலம் உங்கள் ஆன்லைன் விண்ணப்ப நிலையை சரிபார்க்கவும்",
    renewLicence: "முதல் / உரிமத்தை புதுப்பிக்கவும்",
    renewLicenceDes:
      "உங்கள் வருவாய் உரிமத்தை முதல் முறையாகப் பெறுங்கள் அல்லது உங்கள் வருவாய் உரிமத்தை இங்கே புதுப்பிக்கவும்",
    locateUs: "எங்களைக் கண்டுபிடி",
    locateUsDes:
      "நாடு முழுவதும் பரவலாகப் பரவியுள்ள எங்கள் வருவாய் உரிமம் வழங்கும் மையங்களின் இருப்பிடங்களைக் கண்டறியவும்",
    eRevenueLicenseService: "மின் வருவாய் உரிம சேவை",
    eRevenueLicenseServiceDes:
      "மோட்டார் கார்கள் A-Z, மோட்டார் சைக்கிள்கள், இரட்டை பயன்ப்படுட்டைய வாகனங்கள், தரை வாகனங்கள், தரை வாகன இழுவை வண்டிகள், முச்சக்கர வண்டிகள், மோட்டார் லொறி, மோட்டார் லொறி இழுவை வண்டி, மோட்டார் முச்சக்கர வான், தனியார் பேரூந்து, வீட்டு பாவனை வண்டிகள், விசேட தேவைக்குரிய வண்டிகள், இருபட்டி துவிச்சக்கர வண்டிகள், நோயாளர்காவு வண்டிகள் மற்றும் அமரர் ஊர்த்தி ஆகிய வகைகளினைச் சார்ந்த வாகனங்களுக்கான உங்களின் வாகன அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்கான இ – வருமான அனுமதிப் பத்திர சேவை.\n\nநீங்கள் உங்களின் கோரிக்கயினை முன்வைப்பதற்கு முன்னதாக, நீங்கள் உரிய நிறுவனத்திடமிருந்து பெற்றுக்கொள்ளப்பட்ட நீங்கள் செல்லுபடியாகும் காப்புறுதிச் சான்றிதழ் / வாகன புகை பரிசோதனை.",
    HowtoApply:
      "உங்களின் வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வது எவ்வாறு?",
    eRevenueLicenseServiceDes2:
      "நீங்கள் உரிய நிறுவனங்களிடமிருந்து பெற்றுக்  கொள்ள்ப்பட்ட செல்லுபடியாகும் காப்புறுதிச் சான்றிதழ் / வாகன புகை பரிசோதனை சான்றிதழ் / வாகன உடலுறுதி சான்றிதழ் (உங்களின் வாகன வகைக்கேற்ப)  என்பவற்றினை வைத்திருத்தல் வேண்டும். உங்களின் கோரிக்கயினை செயற்படுத்த முன்னர் செல்லுபடியாகும் கடன் அட்டை அல்லது QR App மற்றும் உங்களின் சொந்த கையடக்கத் தொலைபேசி என்பவற்றினூடாக OTP இனைச் செல்லுபடியானதாக்குதல் வேண்டும்.",
    eRevenueLicenseItem1:
      "உங்களிடம் கூகுள் அல்லது முகநூல் கணக்கு இல்லாதவிடத்து, உங்களின் மின்னஞ்சல்முகவரி  / கடவுச்சொல்லினைக் கொண்டு  பதிவு செய்யப்பட்ட கூகுள் / முகநூலினைப் பயன்படுத்தி உள்நுழையுங்கள்.",
    eRevenueLicenseItem2:
      "உங்களின் வாகனத்தின் இலக்கம் மற்றும் அடிச்சட்ட இலக்கம் (இறுதி 6 இலக்கங்கள்) என்பவற்றினைச் சமர்ப்பிக்குக.",
    eRevenueLicenseItem3:
      "வாகன விபரங்களை உறுதிப்படுத்துக – உரித்துடமை, காப்புறுதி / புகை பரிசோதனை சான்றிதழ் (VET), உடலுறுதி (VFT) சான்றிதழ் மற்றும் உரிம கட்டணம்.",
    eRevenueLicenseItem4: "விநியோக மற்றும் கொடுப்பனவு முறையினை தெரிவு செய்க.",
    eRevenueLicenseItem5: "கொடுப்பனவினைப் பூர்த்தி செய்க.",
    eRevenueLicenseItem6:
      "தற்காலிக வருமான அஅனுமதிப் பத்திரத்திற்கான பற்றுச்சீட்டினை  உங்களின் மின்னசலினூடாக அல்லது உங்களின் சாதனத்தில் பதிவிறக்கம் செய்வதனூடாக அச்சிட்டுப் பெற்றுக் கொள்ளலாம்.",
    eRevenueLicenseItem7:
      "குறைந்தபட்சம் 14 நாட்களுக்குள், தற்போது மோட்டார் போக்குவரத்துத் திணைக்களத்தில் வாகனம் எவ்வாறு பதிவு செய்யப்பட்டுள்ளது என குறிப்பிடப்பட்ட முகவரிக்கு வருவாய் உரிமம் இடுகையிடப்படும்.",

    WhatareSupportOrganizations: "துணை நிறுவனங்கள் எவை?",

    WhatareSupportOrganizationsPara1:
      "இத்தகைய நிறுவனங்கள்வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்கும் அவ்வாறே அவற்றினை வீதிகளில் செலுத்திச் செல்லவதற்கும் தேவையான அனைத்துச் சேவைகளினையும் வழங்கி வருகின்றன.",
    WhatareSupportOrganizationsItem1:
      "காப்புறுதிக் கம்பனிகள் ;உங்களின் தேவைப்பாடுகளுக்குப் பொருத்தமானவையாக இருக்கின்ற உங்களின் வாகனங்களுக்கான  லபொதுக் காப்புறுதிகளினை வழங்குவதற்கான வசதிகளினை ஏற்படுத்திக் கொடுக்கின்ற காப்புறுதிக் கம்பனிகள்.",
    WhatareSupportOrganizationsItem2:
      "VET கம்பனிகள்: வாகன புகை பரிசோதனை  (VET) கம்பனிகள் உங்களின் வாகனங்களுக்கான வருமான அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்குத் தேவையான வாகன புகை பரிசோதனைக்கான சான்றிதழ்களினை வழங்குகின்றன.",
    WhatareSupportOrganizationsItem3:
      "VFT சான்றிதழ் வழங்கும் நிலையங்கள்: உங்கள் வாகனங்களின் உடலுறுதியினை சான்றுப்படுத்தி சான்றிதழ் வழங்கும் நிலையங்கள். (தேவைப்படுத்தப்படும் வாகன வகையீடுகளுக்கு மாத்திரம்).",

    WhatareSupportOrganizationsPara2:
      "துணை நிறுவனங்களின் விபர உள்ளடக்கத்தில் காப்புறுதி / வாகன புகை பரிசோதனை / வாகன உடல் உறுதி  சான்றிதழ்களினை  வழங்கும் அனைத்து நிறுவனங்களினதும் விபரங்களைப் பார்வையிடலாம்.",

    Whatshouldyouknow: "நீங்கள் தெரிந்து கொள்ள வேண்டியது என்ன?",
    Whatshouldyouknowpara:
      "இ- வருமான அனுமதிப் பத்திரத்திர சேவையினைப் பற்றிய ஏதேனும் மேலதிக தகவலினைப் பெற்றுக் கொள்வதற்கு  அடிக்கடி கேட்கப்படும் வினாக்கள் பிரிவினைப் பார்க்கவும். உதாரணம் – கொடுப்பனவினை எவ்வாறு மேற்கொள்வது?, QR App கொடுப்பனவு என்றால் என்ன? வருமான அனுமதிப் பத்திரத்தினை தபால் மூலமாக பெற்றுக் கொள்ள முடிந்திருக்காவிடின், என்ன செயதல் வேண்டும்? போன்றைவை.",
  },
};

const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

const pageVariantsCaro = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: "-10vw",
  },
};

const pageTransition = {
  duration: 2,
};

const Home = () => {
  const locale = useRecoilValue(LanguageFilter);
  const [vehicleNumberState, setVehicleNumber] = useRecoilState(VehicleNumber);
  const [chassisNumberState, setChassisNumber] = useRecoilState(ChassisNumber);

  useEffect(() => {
    setVehicleNumber("");
    setChassisNumber("");
  }, []);

  function linesBreak(messages) {
    return messages
      .map((message) =>
        typeof message === "string"
          ? message.split("\n").map((messages) => <p>{messages}</p>)
          : message
      )
      .reduce((messages, message) => messages.concat(message), []);
  }

  const { news } = useHomeHook();

  let isNewsEmpty = true;

  if (news === undefined) {
    isNewsEmpty = true;
  } else {
    isNewsEmpty = false;
  }

  // console.log("Check : ", news.data);

  return (
    <Template activeHeader="home" selectedLan={locale}>
      <MetaTags>
        <meta name="description" content="Welcome to the eRevenue Licence Service.You may obtain your revenue licence for Motor Cars, Motor Cars A-Z, Motor Cycles, Dual Purpose Vehicles, Land Vehicles, Land Vehicle Tailor, Three Wheelers, Motor Lorry, Motor Lorry Tailor, Motor Tricycle Van, Private Coach, Motor Home, Special Purpose Vehicle, Quadric-cycle, Ambulance and Hearse using this Service.You may visit any Divisional Secretariat Office, convenient for you to obtain your revenue licence for all other vehicles.Please ensure that you have a valid Insurance Certificate/ Vehicle Emission Test Certificate/ Vehicle Fitness Certificate (as applicable to vehicle category) from respective organization, before you process your request." />
        <meta name="keywords" content="eRevenue licence service, Revenue license service, revenue license, revenue licence, online license, online licence, online revenue license, online revenue licence, vehicle revenue license, vehicel revenue licence" />
      </MetaTags>
      <div className="homePageWrapper">
        <motion.div
          class="image-stack"
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariantsCaro}
          transition={pageTransition}
        >
          <div class="image-stack__item image-stack__item--bottom">
            <Carousel>
              <Carousel.Item
                style={{ height: "600px", backgroundColor: "white" }}
              >
                <img
                  className="d-block w-100"
                  src={ImageOne}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item
                style={{ height: "600px", backgroundColor: "white" }}
              >
                <img
                  className="d-block w-100"
                  src={ImageTwo}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item
                style={{ height: "600px", backgroundColor: "white" }}
              >
                <img
                  className="d-block w-100"
                  src={ImageThree}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item
                style={{ height: "600px", backgroundColor: "white" }}
              >
                <img
                  className="d-block w-100"
                  src={ImageFour}
                  alt="Fourth slide"
                />
              </Carousel.Item>
              {/* <Carousel.Item style={{ height: "600px" }}>
                <img
                  className="d-block w-100"
                  src={ImageFive}
                  alt="Fifth slide"
                />
              </Carousel.Item>
              <Carousel.Item style={{ height: "600px" }}>
                <img
                  className="d-block w-100"
                  src={ImageSix}
                  alt="Sixth slide"
                />
              </Carousel.Item> */}
            </Carousel>
          </div>
          <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <div class="image-stack__item image-stack__item--top">
              {!isNewsEmpty ? <NewsBanner data={news} /> : <div></div>}
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <div className="erlContent erlMainIntroWrapper">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 marginBottom">
                <Link to="status-check">
                  <div className="introCardWrapper whiteBG">
                    <div className="iconWrapper">
                      <i className="mdi mdi-bell-outline"></i>
                    </div>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                      <h3>
                        <FormattedMessage
                          id="checkStatus"
                          value={{ locale }}
                        ></FormattedMessage>
                      </h3>
                    </IntlProvider>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                      <h6>
                        <FormattedMessage
                          id="checkStatusDes"
                          value={{ locale }}
                        ></FormattedMessage>
                      </h6>
                    </IntlProvider>
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 marginBottom">
                <Link to="revenue-licence">
                  <div className="introCardWrapper lightBlueBG">
                    <div className="iconWrapper">
                      <i className="mdi mdi-autorenew"></i>
                    </div>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                      <h3>
                        <FormattedMessage
                          id="renewLicence"
                          value={{ locale }}
                        ></FormattedMessage>
                      </h3>
                    </IntlProvider>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                      <h6>
                        <FormattedMessage
                          id="renewLicenceDes"
                          value={{ locale }}
                        ></FormattedMessage>
                      </h6>
                    </IntlProvider>
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 marginBottom">
                <Link to="locate-us">
                  <div className="introCardWrapper whiteBG">
                    <div className="iconWrapper">
                      <i className="mdi mdi-map-marker"></i>
                    </div>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                      <h3>
                        <FormattedMessage
                          id="locateUs"
                          value={{ locale }}
                        ></FormattedMessage>
                      </h3>
                    </IntlProvider>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                      <h6>
                        <FormattedMessage
                          id="locateUsDes"
                          value={{ locale }}
                        ></FormattedMessage>
                      </h6>
                    </IntlProvider>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="erlContent erlIntroduction">
            <IntlProvider locale={locale} messages={messages[locale]}>
              <h1>
                <FormattedMessage
                  id="eRevenueLicenseService"
                  value={{ locale }}
                ></FormattedMessage>
              </h1>
            </IntlProvider>
            <IntlProvider locale={locale} messages={messages[locale]}>
              <p align="justify">
                <FormattedMessage
                  id="eRevenueLicenseServiceDes"
                  value={{ locale }}
                >
                  {linesBreak}
                </FormattedMessage>
              </p>
            </IntlProvider>

            <div className="btnWrapper">
              <Link to="contact-us">
                <Button
                  btnStyle="btnPrimary marginRight"
                  btnName="contactUs"
                  btnText={
                    locale === "en"
                      ? "Contact Us"
                      : locale === "si"
                        ? "අපව අමතන්න"
                        : "எம்முடன் தொடர்பு கொள்ள"
                  }
                  to
                />
              </Link>
              <Link to="locate-us">
                <Button
                  btnStyle="btnOutlinePrimary"
                  btnName="locateUs"
                  btnText={
                    locale === "en"
                      ? "Locate Us"
                      : locale === "si"
                        ? "අප ආයතනයේ පිහිටීම"
                        : "எங்களைக் கண்டறிய"
                  }
                />
              </Link>
            </div>
          </div>

          <div className="erlProcessFlowWrapper">
            <div className="erlContent erlProcessFlowWrapper">
              <IntlProvider locale={locale} messages={messages[locale]}>
                <h1>
                  <FormattedMessage
                    id="HowtoApply"
                    value={{ locale }}
                  ></FormattedMessage>
                </h1>
              </IntlProvider>
              <IntlProvider locale={locale} messages={messages[locale]}>
                <p align="justify">
                  <FormattedMessage
                    id="eRevenueLicenseServiceDes2"
                    value={{ locale }}
                  ></FormattedMessage>
                </p>
              </IntlProvider>

              <ul>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="eRevenueLicenseItem1"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="eRevenueLicenseItem2"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="eRevenueLicenseItem3"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="eRevenueLicenseItem4"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="eRevenueLicenseItem5"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="eRevenueLicenseItem6"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>
                {/* <IntlProvider locale={locale} messages={messages[locale]}>
                  <li>
                    <FormattedMessage
                      id="eRevenueLicenseItem7"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider> */}
              </ul>

              <div className="btnWrapper">
                <Link to="/revenue-licence">
                  <Button
                    btnStyle="btnPrimary"
                    btnName="signInToContinue"
                    btnText={
                      locale === "en"
                        ? "First / Renew Licence"
                        : locale === "si"
                          ? "පළමු / බලපත්‍රය අලුත් කරන්න"
                          : "முதல் / உரிமத்தை புதுப்பிக்கவும்"
                    }
                  />
                </Link>
              </div>
              <br />
            </div>
          </div>

          <div className="erlProcessFlowWrapper">
            <div className="erlContent erlProcessFlowWrapper">
              {/* <div> */}

              <IntlProvider locale={locale} messages={messages[locale]}>
                <h1>
                  <FormattedMessage
                    id="WhatareSupportOrganizations"
                    value={{ locale }}
                  ></FormattedMessage>
                </h1>
              </IntlProvider>

              <IntlProvider locale={locale} messages={messages[locale]}>
                <p align="justify">
                  <FormattedMessage
                    id="WhatareSupportOrganizationsPara1"
                    value={{ locale }}
                  ></FormattedMessage>
                </p>
              </IntlProvider>
              <ul>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="WhatareSupportOrganizationsItem1"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>

                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="WhatareSupportOrganizationsItem2"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>

                <IntlProvider locale={locale} messages={messages[locale]}>
                  <li align="justify">
                    <FormattedMessage
                      id="WhatareSupportOrganizationsItem3"
                      value={{ locale }}
                    ></FormattedMessage>
                  </li>
                </IntlProvider>
              </ul>
              <IntlProvider locale={locale} messages={messages[locale]}>
                <p align="justify">
                  <FormattedMessage
                    id="WhatareSupportOrganizationsPara2"
                    value={{ locale }}
                  ></FormattedMessage>
                </p>
              </IntlProvider>
              <br />
            </div>
          </div>
          <div className="erlProcessFlowWrapper">
            <div className="erlContent erlProcessFlowWrapper">
              <IntlProvider locale={locale} messages={messages[locale]}>
                <h1>
                  <FormattedMessage
                    id="Whatshouldyouknow"
                    value={{ locale }}
                  ></FormattedMessage>
                </h1>
              </IntlProvider>

              <IntlProvider locale={locale} messages={messages[locale]}>
                <p align="justify">
                  <FormattedMessage
                    id="Whatshouldyouknowpara"
                    value={{ locale }}
                  ></FormattedMessage>
                </p>
              </IntlProvider>

              <br />
            </div>
          </div>
        </motion.div>
      </div>
    </Template>
  );
};

export default Home;
