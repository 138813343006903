/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 14:45:04
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-02 17:10:10
 */
import { Form } from "../../../../ui-components/ui-elements/form/FormWrapper";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../../atom";

const CurrentAddressPopUp = () => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <Form className="">
      <ul>
        <li>
        {locale==="en"?"Revenue Licence will be posted to the displayed address which is currently registered with the Department of Motor Traffic (DMT)":locale==="si"?"මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුවේ (DMT) දැනට ලියාපදිංචි ලිපිනය ලෙස දැක්වෙන ලිපිනයට ආදායම් බලපත්‍රය තැපැල මගින් යවනු ලැබේ.":"வருமான அனுமதிப் பத்திரமானது மோட்டார் போக்குவரத்துத் திணைக்களத்தில் (DMT) தற்போது பதிவு செய்யப்பட்டுள்ள வெளிக்காட்டும் முகவரிக்கு அனுப்பி வைக்கப்படும்."}
        </li>
        <br></br>
        <li>
        {locale==="en"?"If the mentioned address is not your postal address, please visit the nearest Revenue Licence Issuing Center to obtain the Revenue Licence.":locale==="si"?"එහි සඳහන්  ලිපිනය ඔබගේ තැපැල් ලිපිනය නොවන්නේ නම්, ඔබගේ ආදායම් බලපත්‍රය ලබා ගැනීම සඳහා කරුණාකර ළඟම පිහිටි මෝටර් රථ ප්‍රවාහන බලපත්‍ර නිකුත් කිරීමේ මධ්‍යස්ථානය වෙත යන්න. ":"குறிப்பிடப்பட்டுள்ள முகவரியானது உங்களின் அஞ்சல் முகவரியாக இல்லாதவிடத்து, வருமான அனுமதிப் பததிரத்தினை பெற்றுக் கொள்வதற்கு அண்மையிலுள்ள வருமான அனுமதிப் பத்திரம் வழங்குகின்ற வருமான அனுமதிப் பத்திரத்தினை வழங்கும் "}
        </li>
      </ul>
    </Form>
  );
};

export default CurrentAddressPopUp;
