/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-26 16:03:01
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:13:30
 */
import React, { memo, useEffect, useState } from "react";
import { Button, RoundedIconButton } from "./Button";
import {
  FormControlWrapper,
  FormHelperTextWrapper,
} from "./form-includes/FormCoreIncludes";
import { InputLabelWrapper, labelPositions } from "./InputLabel";
import PropTypes from "prop-types";
import { formCacheLevel } from "../../../../config/template.config";
import { useBasicInput } from "../../../hooks/common-hooks/useFormInputState.hook";
import { Tooltip } from "react-bootstrap";

const emptyFn = (...para) => undefined;

const Input = ({
  inputId = "",
  inputName = "",
  inputType = "",
  inputValue = undefined,
  placeholder = "",
  className = "",
  onChangeTxtFn = emptyFn,
  onClickFn = emptyFn,
  isRequired = false,
  isDisabled = false,
  maxCharacter = 35,
  defaultValue = "",
}) => {
  return (
    <input
      id={inputId}
      name={inputName}
      className={className}
      type={inputType}
      maxLength={maxCharacter}
      defaultChecked={inputValue}
      onChange={(event) =>
        onChangeTxtFn({
          name: inputName,
          value: event.target.value,
          eventInfo: event,
        })
      }
      onClick={(event) =>
        onClickFn({
          name: inputName,
          eventInfo: event,
        })
      }
      value={inputValue}
      placeholder={placeholder}
      required={isRequired}
      disabled={isDisabled}
      defaultValue={defaultValue}
      onKeyPress={(event) => {
        event.key === "Enter" && event.preventDefault();
      }}
    />
  );
};

const InputBoxWrapper = ({
  inputId = "",
  inputName = "",
  inputValue = "",
  inputType = "",
  placeholder = "",
  isRequired = false,
  isDisabled = false,
  onChange = emptyFn,
  onClick = emptyFn,
  endIcon = null,
  endIconOnClickFn = emptyFn,
  startText = null,
  endIconTooltip = "",
  maxCharLength = 35,
  defaultValue = "",
}) => {
  const [password, setPassword] = useState(true);
  const type = inputType;
  return (
    <div className="input-group">
      {startText && (
        <div class="input-group-prepend">
          <span class="input-group-text">{startText}</span>
        </div>
      )}
      <Input
        maxCharacter={maxCharLength}
        className={`form-control ${
          inputType === "password" && "input-box-with-icon"
        }`}
        inputId={inputId}
        inputName={inputName}
        value={inputValue}
        placeholder={placeholder}
        inputType={
          inputType === "password" && password
            ? "password"
            : inputType === "password-help" && password
            ? "password"
            : "text"
        }
        isRequired={isRequired}
        isDisabled={isDisabled}
        onChangeTxtFn={onChange}
        onClickFn={onClick}
        defaultValue={defaultValue}
      />

      {type === "password" && (
        <div className="input-group-append">
          <div className="input-group-text">
            {password ? (
              <i
                title={endIconTooltip}
                className="mdi mdi-eye-off"
                onClick={() => setPassword(!password)}
              />
            ) : (
              <i
                title={endIconTooltip}
                className="mdi mdi-eye"
                onClick={() => setPassword(!password)}
              />
            )}
          </div>
        </div>
      )}
      {type === "help" && (
        <div className="input-group-append">
          <div className="input-group-text">
            <i
              title={endIconTooltip}
              className="mdi mdi-help"
              onClick={() => endIconOnClickFn()}
            />
          </div>
        </div>
      )}
      {type === "password-help" && (
        <div className="input-group-append">
          <div className="input-group-text">
            <i
              title={endIconTooltip}
              className="mdi mdi-help"
              onClick={() => endIconOnClickFn()}
            />
          </div>
          <div className="input-group-text">
            {password ? (
              <i
                title={endIconTooltip}
                className="mdi mdi-eye-off"
                onClick={() => setPassword(!password)}
              />
            ) : (
              <i
                title={endIconTooltip}
                className="mdi mdi-eye"
                onClick={() => setPassword(!password)}
              />
            )}
          </div>
        </div>
      )}
      {endIcon && (
        <div className="input-box-outside-icon">
          <RoundedIconButton
            icon={endIcon}
            onClickFn={endIconOnClickFn}
            tooltip={endIconTooltip}
          />
        </div>
      )}
    </div>
  );
};

const InputBox = ({
  labelText = "",
  inputId = "",
  inputName = "",
  inputValue = "",
  inputType = "",
  placeholder = "",
  inputError = "",
  helperText = "",
  isRequired = false,
  isDisabled = false,
  onChange = emptyFn,
  onClick = emptyFn,
  labelPlacement = labelPositions.START,
  labelColSize,
  inputColSize,
  isRightAlignLabel = false,
  endIcon = null,
  endIconOnClickFn = emptyFn,
  startText = null,
  endIconTooltip = "",
  maxCharLength = 35,
  defaultValue="",
}) => {
  return (
    <FormControlWrapper
      inputError={inputError}
      helperText={helperText}
      className={"form-group"}
    >
      <InputLabelWrapper
        inputName={inputName}
        isRequired={isRequired}
        labelPlacement={labelPlacement}
        labelText={labelText}
        labelColSize={labelColSize}
        inputColSize={inputColSize}
        isRightAlignLabel={isRightAlignLabel}
        endIcon={endIcon}
        endIconOnClickFn={endIconOnClickFn}
        endIconTooltip={endIconTooltip}
      >
        <InputBoxWrapper
          className={"form-control"}
          inputId={inputId}
          inputName={inputName}
          value={inputValue}
          placeholder={placeholder}
          inputType={inputType}
          isRequired={isRequired}
          isDisabled={isDisabled}
          onChangeTxtFn={onChange}
          maxCharLength={maxCharLength}
          onChange={onChange}
          onClick={onClick}
          endIcon={endIcon}
          endIconOnClickFn={endIconOnClickFn}
          startText={startText}
          endIconTooltip={endIconTooltip}
          defaultValue={defaultValue}
        />
        <FormHelperTextWrapper
          helperText={helperText}
          inputError={inputError}
        />
      </InputLabelWrapper>
    </FormControlWrapper>
  );
};

InputBox.propTypes = {
  /** is set full width or not */
  isFullWidth: PropTypes.bool,
  /** lable text */
  labelText: PropTypes.string,
  /**  input field type */
  inputType: PropTypes.oneOf([
    "text",
    "password",
    "number",
    "email",
    "help",
    "password-help",
  ]),
  /**  input field type */
  inputValue: PropTypes.string,
  /**  input field name */
  inputName: PropTypes.string,
  /**  input field error message */
  inputError: PropTypes.string,
  /**  input field helper text */
  helperText: PropTypes.string,
  /** is auto Focus or not */
  isAutoFocus: PropTypes.bool,
  /** input field placeholder text */
  inputPlaceholder: PropTypes.string,
  /** enable/disabled field */
  isDisabled: PropTypes.bool,
  /** set required * mark */
  isRequired: PropTypes.bool,
  /** onchange text event Function */
  onChange: PropTypes.func,
  /** onclick element Function */
  onClick: PropTypes.func,
  /** placeholder **/
  placeholder: PropTypes.string,
  /** label placement **/
  labelPlacement: PropTypes.string,
  /** label column size **/
  labelColSize: PropTypes.number,
  /** end icon **/
  endIcon: PropTypes.object,
  /** end icon click function **/
  endIconOnClickFn: PropTypes.func,
  /** is right aligned label **/
  isRightAlignLabel: PropTypes.bool,
  defaultValue: PropTypes.string,
};

/**
 * memo render
 * @param {Object} prevProps
 * @param {Object} nextProps
 */
const areEqual = (prevProps, nextProps) => {
  if (nextProps.cacheLevel === formCacheLevel.none) {
    return false;
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnFormGroupChange) {
    return (
      prevProps.isFullWidth === nextProps.isFullWidth &&
      prevProps.labelText === nextProps.labelText &&
      prevProps.inputType === nextProps.inputType &&
      prevProps.inputError === nextProps.inputError &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isRequired === nextProps.isRequired &&
      prevProps.inputValue === nextProps.inputValue &&
      prevProps.updateStatus === nextProps.updateStatus &&
      prevProps.placeholder === nextProps.placeholder &&
      prevProps.labelPlacement === nextProps.labelPlacement &&
      prevProps.labelColSize === nextProps.labelColSize &&
      prevProps.endIcon === nextProps.endIcon &&
      prevProps.isRightAlignLabel === nextProps.isRightAlignLabel
    );
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnIndividual) {
    return (
      prevProps.isFullWidth === nextProps.isFullWidth &&
      prevProps.labelText === nextProps.labelText &&
      prevProps.inputType === nextProps.inputType &&
      prevProps.inputError === nextProps.inputError &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isRequired === nextProps.isRequired &&
      prevProps.inputValue === nextProps.inputValue &&
      prevProps.updateStatus === nextProps.updateStatus &&
      prevProps.placeholder === nextProps.placeholder &&
      prevProps.labelPlacement === nextProps.labelPlacement &&
      prevProps.labelColSize === nextProps.labelColSize &&
      prevProps.endIcon === nextProps.endIcon &&
      prevProps.isRightAlignLabel === nextProps.isRightAlignLabel
    );
  }
};

const InputBoxMemo = memo(InputBox, areEqual);

const InputBoxWithState = ({
  isFullWidth = true,
  labelText = "",
  inputType = "text",
  inputValue = "",
  inputName = "",
  inputError = "",
  helperText = "",
  // isAutoFocus = false,
  placeholder = "",
  isDisabled = false,
  isRequired = false,
  formGroupName = "",
  inputStatePath = "",
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  onChangeTxtFn = emptyFn,
  onClick = emptyFn,
  labelPlacement = labelPositions.START,
  labelColSize = 3,
  endIcon = null,
  endIconOnClickFn = emptyFn,
  isRightAlignLabel = false,
}) => {
  const [
    currentValue,
    currentError,
    updateStatus,
    handleOnChangeFn,
    handleOnClickFn,
  ] = useBasicInput(
    inputStatePath,
    formGroupName,
    inputName,
    inputValue,
    onChangeTxtFn,
    onClick
  );

  return (
    <InputBoxMemo
      isFullWidth={isFullWidth}
      labelText={labelText}
      inputType={inputType}
      inputValue={currentValue}
      inputName={inputName}
      inputError={currentError}
      helperText={helperText}
      // isAutoFocus={isAutoFocus}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isRequired={isRequired}
      onChangeTxtFn={(eventData) => handleOnChangeFn(eventData)}
      onClick={handleOnClickFn}
      updateStatus={updateStatus}
      cacheLevel={cacheLevel}
      labelPlacement={labelPlacement}
      labelColSize={labelColSize}
      endIcon={endIcon}
      endIconOnClickFn={endIconOnClickFn}
      isRightAlignLabel={isRightAlignLabel}
    />
  );
};

InputBoxWithState.propTypes = {
  /** element Wrapper css class */
  elementWrapperStyle: PropTypes.string,
  /** Button element css class */
  elementStyle: PropTypes.string,
  /** is set full width or not */
  isFullWidth: PropTypes.bool,
  /** lable text */
  labelText: PropTypes.string,
  /**  input field type */
  inputType: PropTypes.oneOf(["text", "password", "number", "email"]),
  /**  input field type */
  inputValue: PropTypes.string,
  /**  input field name */
  inputName: PropTypes.string,
  /**  input field error message */
  inputError: PropTypes.string,
  /**  input field helper text */
  helperText: PropTypes.string,
  /** is auto Focus or not */
  // isAutoFocus: PropTypes.bool,
  /** input field placeholder text */
  placeholder: PropTypes.string,
  /** enable/disabled field */
  isDisabled: PropTypes.bool,
  /** set required * mark */
  isRequired: PropTypes.bool,
  /** form group name */
  formGroupName: PropTypes.string,
  /** input element state avilable path(use for manage complex objects) */
  inputStatePath: PropTypes.string,
  /** onchange text event Function */
  onChangeTxtFn: PropTypes.func,
  /** onclick element Function */
  onClick: PropTypes.func,
  /** label placement **/
  labelPlacement: PropTypes.string,
  /** label column size **/
  labelColSize: PropTypes.number,
  /** end icon **/
  endIcon: PropTypes.object,
  /** end icon click function **/
  endIconOnClickFn: PropTypes.func,
  /** is right aligned label **/
  isRightAlignLabel: PropTypes.bool,
};

export { Input, InputBox, InputBoxWithState };
