/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:09:37
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-15 14:36:46
 */
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { DataTable } from "../../../ui-components/ui-elements/data-table/DataTable";
import LocateUsFilterComponent from "./includes/SearchComponent";
import { AnimatePresence, motion } from "framer-motion";

import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { authorityAPI } from "../../../../config/apiUrl.config";
import CompanyListTable from "../../../ui-components/ui-elements/table/CompanyListTable";
import { FilterWrapper } from "../../../ui-components/ui-elements/data-table/FilterWrapper";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useLocateUsHook } from "../../../hooks/useLocateUsHook";
import { proviceCode } from "../../../../config/ProvinceCode.config";
import { VehicleNumber, ChassisNumber } from "../../../../atom";

const messages = {
  en: {
    title: "Locate Us",
  },
  si: {
    title: "අප ආයතනයේ පිහිටීම",
  },
  ta: {
    title: "எங்களைக் கண்டறிய",
  },
};

const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

const pageTransition = {
  duration: 2,
};

const LocateUsTableHeadEnglish = [
  {
    displayName: "Revenue Licence Issuing Centre",
    key: "name",
  },
  {
    displayName: "Address",
    key: "address",
  },
  {
    displayName: "Contact Number",
    key: "contactNumber",
  },
];

const LocateUsTableHeadTamil = [
  {
    displayName: "வருமான உரிமம் வழங்கும் நிலையம்",
    key: "name",
  },
  {
    displayName: "முகவரி",
    key: "address",
  },
  {
    displayName: "தொடர்பு இலக்கம்",
    key: "contactNumber",
  },
];

const LocateUsTableHeadSinhala = [
  {
    displayName: "අදායම් බලපත්‍ර නිකුත් කිරීමේ මධ්‍යස්ථානය",
    key: "name",
  },
  {
    displayName: "ලිපිනය",
    key: "address",
  },
  {
    displayName: "සම්බන්ධ කර ගත හැකි දුරකථන අංකය",
    key: "contactNumber",
  },
];

const LocateUsTableRows = [
  {
    issuingCentre: "Department of Motor Traffic - Province A",
    address: "Department of Motor Traffic, Province A",
    contactNumber: "94117000001",
  },
  {
    issuingCentre: "Department of Motor Traffic - Province B",
    address: "Department of Motor Traffic, Province B",
    contactNumber: "94117000002",
  },
];

const LocateUs = () => {
  const locale = useRecoilValue(LanguageFilter);

  const [authorityList, setAuthorityList] = useState([]);

  const [vehicleNumberState, setVehicleNumber] = useRecoilState(VehicleNumber);
  const [chassisNumberState, setChassisNumber] = useRecoilState(ChassisNumber);

  useEffect(() => {
    setVehicleNumber("");
    setChassisNumber("");
  }, []);

  const {
    authorities,
    province,
    district,
    setProvince,
    setDistrict,
    districtList,
    provinceList,
  } = useLocateUsHook();

  useEffect(() => {
    const arry = [];
    let obj = {};

    setAuthorityList(arry);

    authorities &&
      authorities.map((data) => {
        obj = {
          name: data.name,
          address:
            data.addresses.length > 0
              ? data?.addresses[0]?.line1 + ", " + data?.addresses[0]?.line2 + ", " + data?.addresses[0]?.city
              : "N/A",
          contactNumber:
            data.contacts.length > 0 ? "+94" + data?.contacts[0]?.contact : "N/A",
        };

        arry.push(obj);
      });

    setAuthorityList(arry);
  }, [authorities]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Template activeHeader="locateUs" selectedLan={locale}>
        <MetaTags>
      
      <meta name="description"  content= "Provincial Department of Motor Traffic (PDMT) and Divisional Secretariat (DS) Offices issue the Vehicle Revenue Licence Island wide. There are 330 + DS Offices throughout all 9 Provinces and the Offices open from 08:30 a.m. to 03:30 p.m. from Monday to Friday, except Public, Bank and Mercantile holidays for issuance of Licences." />
       <meta name="keywords" content="licence issuance centres, licence issuance" />
    </MetaTags>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <motion.div
          // initial="out"
          // animate="in"
          // exit="out"
          // variants={pageVariants}
          // transition={pageTransition}
        >
          <div className="">
            <UICard
              title={
                <div className="">
                  <FormattedMessage
                    id="title"
                    value={{ locale }}
                  ></FormattedMessage>
                </div>
              }
              titlePlacement={cardTitlePlacement.LEFT}
              cardSize={cardSize.LARGE}
              titleStyle="mainTxtHeading "
            >
               
              <DataTable
                dataTableWrapperStyle={"mt-5 mb-5 ml-1"}
                filterComponent={
                  <LocateUsFilterComponent
                    provinceData={provinceList}
                    districtData={districtList}
                    onProvinceSelected={setProvince}
                    onDistrictSelected={setDistrict}
                    isDistrictDisabled={
                      province.name === "Select" || province.value === "null"
                        ? true
                        : false
                    }
                  />
                }
                isSetSearch={false}
                tableHeaderList={
                  locale === "en"
                    ? LocateUsTableHeadEnglish
                    : locale === "si"
                    ? LocateUsTableHeadSinhala
                    : LocateUsTableHeadTamil
                }
                tableBodyList={authorityList?.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
              />
             
            </UICard>
          </div>
        </motion.div>
      </IntlProvider>
    </Template>
  );
};

export default LocateUs;
