import { useEffect, useState } from "react";
import { profileInfoAPI } from "../../config/apiUrl.config";
import { callApi } from "../../helpers/common-helpers/callApi.helpers";

export const useUserDataHook = () => {
  const [profileInfo, setProfileInfo] = useState();

  const getProfileInfo = async () => {
    callApi(`${profileInfoAPI.url}/public-user`)
      .method("get")
      .headers(true)
      .send((error, result) => {
        if (result) {
          setProfileInfo(result.data.data);
        }
      });
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  return {
    profileInfo,
  };
};
