/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-21 14:52:50
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-08 13:14:41
 */
import { Label } from "./Label";
const labelPositions = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
  START: "START",
  END: "END",
};

const CheckLabelWrapper = ({
  uiType = "",
  inputName = "",
  isRequired = false,
  labelText = "",
  className = "",
  forId = "",
  labelPlacement = labelPositions.START,
  children = {},
}) => {
  return labelPlacement === labelPositions.TOP ? (
    <div className={"row"}>
      <div>
        <Label
          className={className}
          forId={forId}
          labelText={labelText}
          lablePosition={labelPlacement}
        />
      </div>
      <div className={"col-sm-1"}>{children}</div>
    </div>
  ) : labelPlacement === labelPositions.END ? (
    <div className={"row col-sm-9 floatRight"}>
      <div className={""}>{children}</div>
      <div>
        <Label
          className={className}
          forId={forId}
          labelText={labelText}
          lablePosition={labelPlacement}
        />
      </div>
    </div>
  ) : labelPlacement === labelPositions.BOTTOM ? (
    <div className={"row"}>
      <div className={"col-sm-1"}>{children}</div>
      <div>
        <Label
          className={className}
          forId={forId}
          labelText={labelText}
          lablePosition={labelPlacement}
        />
      </div>
    </div>
  ) : (
    // default Start
    <div className={"row"}>
      <div className={"col-sm-1"}>{children}</div>
      <div>
        <Label
          className={className}
          forId={forId}
          labelText={labelText}
          lablePosition={labelPlacement}
        />
      </div>
    </div>
  );
};

export { CheckLabelWrapper, labelPositions };
