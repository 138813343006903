/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-01 16:37:58
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-09 00:21:40
 */

import { Button } from "../form/Button";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";

const FilterWrapper = ({
  filterElements = null,
  isSetSearch = false,
  isSetReset = false,
  handleSearch,
  handleReset,
}) => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <div className="">
      <div className="">{filterElements}</div>
      
      <div className="ChechStausButtons">
        <div className="row floatRight paddingRightExtend" >
          {isSetSearch && (
            <Button
              btnName="Search"
              btnText={
                locale === "si" ? "සොයන්න" : locale === "en" ? "Search" : "தேடு"
              }
              onClick={handleSearch}
              btnStyle="btnPrimary marginRight marginBottom"
            />
          )}
          {isSetReset && (
            <Button
              btnName="reset"
              btnText={locale === "si" ? "ප්‍රතිසකසන්න" : locale === "en" ? "Reset" : "மீளமைக்குக"}
              btnStyle="btnOutlinePrimary"
              onClick={handleReset}
            />
          )}
          </div>
        </div>
        </div>
    
        
    
   
  );
};

export { FilterWrapper };
