/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2021-01-18 11:28:45
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-02-11 10:34:36
 */
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import ERLLogo from "../../../../style/img/logo/logo_8.png";
import contact1919 from "../../../../style/img/1919.png";

import { FacebookButton, TwitterButton } from "react-social";

const messages = {
  en: {
    content:
      "eRL Service facilitates to obtain your vehicle Revenue Licence for vehicle categories of; Motor Cars, Motor Cars A-Z, Motor Cycles, Dual Purpose Vehicles, Land Vehicles, Land Vehicle Tailor, Three Wheelers, Motor Lorry, Motor Lorry Tailor, Motor Tricycle Van, Private Coach, Motor Home, Special Purpose Vehicle, Quadric-cycle, Ambulance and Hearse. \n\nPlease ensure that you have a valid Insurance Certificate/ Vehicle Emission Test Certificate/ Vehicle Fitness Certificate (as applicable to respective vehicle category) from respective organization, before you process your request. \n\nYou may visit any Divisional Secretariat Office, convenient for you to obtain your revenue licence for all other vehicles.",
    shareOn: "Share On",
    quickLinks: "Quick Links",
    supportOrganization: "Support organizations",
    locateUs: "Locate Us",
    faq: "FAQs",
    terms: "Terms and Conditions",
    privacyPolicy: "Privacy Policy",
    call1919: "Call 1919",
    contactUs: "Contact Us",
    address: "Address",
    addressLine1: " ICT Agency of Sri Lanka,",
    addressLine2: "490, R. A. de Mel Mawatha,",
    addressLine3: "Colombo 03.",
    phone: "Phone",
    phoneNo: "011 2369 099",
    fax: "Fax",
    faxNo: "011 2369 091",
    e_mail_title: "Email",
    copyright:
      "Copyright © Provincial Department of Motor Traffic, All Rights Reserved.",
    inquiries: "Inquiries",
  },
  si: {
    content:
      "පහත සඳහන් වාහන වර්ග  සඳහා ඔබට ආදායම් බලපත්‍රය සේවාව ලබා ගත හැක; මෝටර් කාර්, මෝටර් කාර් A-Z, මෝටර් සයිකල්, ද්විත්ව කාර්ය වාහන, ඉඩම් වාහන, ඉඩම් වාහන ටේලර්, ත්‍රිරෝද රථ, මෝටර් ලොරි, මෝටර් ලොරි ටේලර්, මෝටර් ට්‍රයිසිකල් වෑන්, පුද්ගලික වාහන, මෝටර් හෝම්, විශේෂ කාර්ය වාහන, ක්වඩ්‍රික් - සයිකල්,   ගිලන්රථ- සහ අවමගුල් රථ. ඔබගේ ඉල්ලීම ක්‍රියාත්මක කිරීමට පෙර , (වාහන වර්ගයට අදාළ වන ආකාරයේ)  වලංගු රක්ෂණ සහතිකය/වාහන දුම් පරීක්ෂණ සහතිකය (VET) / වාහන යෝග්‍යතා සහතිකය (VFT) අදාළ ආයතනවලින් ලබා ගෙන ඇති බව කරුණාකර තහවුරු කර ගන්න. අනෙකුත් සියළු වාහන සඳහා ඔබගේ ආදායම් බලපත්‍රය පහසුවෙන් ලබා ගැනීම සඳහා ඔබට ඕනෑම ප්‍රාදේශීය ලේකම් කාර්යාලයක්  වෙත යා  හැකිය.",
    shareOn: "හුවමාරු කරන්න",
    quickLinks: "ක්ෂණික සබැඳි",
    supportOrganization: "සහායක ආයතන",
    locateUs: "අප ආයතනයේ පිහිටීම",
    faq: "නිතර යොමු කරන ප්‍රශ්න",
    terms: "නියමයන්  හා කොන්දේසි",
    privacyPolicy: "රහස්‍යතා ප්‍රතිපත්තිය",
    call1919: "1919 අමතන්න",
    contactUs: "අපව අමතන්න",
    address: "ලිපිනය",
    addressLine1: "ශ්‍රී ලංකා තොරතුරු හා සන්නිවේදන තාක්ෂණ ආයතනය,",
    addressLine2: "490, ආර්.ඒ. ද මෙල් මාවත,",
    addressLine3: "කොළඹ 03.",
    phone: "දුරකථන අංකය",
    phoneNo: "011 2369 099",
    fax: "ෆැක්ස්",
    faxNo: "011 2369 091",
    e_mail_title: "විද්‍යුත් ලිපිනය ",
    copyright:
      "පිටපත් අයිතිය පළාත් මෝටර් රථ ප්‍රවාහන දෙපාර්තමේන්තුව සතුය.  සියළුම හිමිකම් ඇවිරිණි. ",
    inquiries: "විමසීම්",
  },
  ta: {
    content:
      "மோட்டார் கார்கள் A-Z, மோட்டார் சைக்கிள்கள், இரட்டை பயன்ப்படுட்டைய வாகனங்கள், தரை வாகனங்கள், தரை வாகன இழுவை வண்டிகள், முச்சக்கர வண்டிகள், மோட்டார் லொறி, மோட்டார் லொறி இழுவை வண்டி, மோட்டார் முச்சக்கர வான், தனியார் பேரூந்து, வீட்டு பாவனை வண்டிகள், விசேட தேவைக்குரிய வண்டிகள், இருபட்டி துவிச்சக்கர வண்டிகள், நோயாளர்காவு வண்டிகள் மற்றும் அமரர் ஊர்த்தி ஆகிய வகைகளினைச் சார்ந்த வாகனங்களுக்கான உங்களின் வாகன அனுமதிப் பத்திரத்தினைப் பெற்றுக் கொள்வதற்கான இ – வருமான அனுமதிப் பத்திர சேவை. நீங்கள் உங்களின் கோரிக்கயினை முன்வைப்பதற்கு முன்னதாக, நீங்கள் உரிய நிறுவனத்திடமிருந்து பெற்றுக்கொள்ளப்பட்ட   நீங்கள் செல்லுபடியாகும் காப்புறுதிச் சான்றிதழ் / வாகன புகை பரிசோதனை ",
    shareOn: "பகிர்ந்து கொள்ள",
    quickLinks: "துரித இணைப்புக்கள்",
    supportOrganization: "துணை நிறுவனங்கள்",
    locateUs: "எம்மைக் கண்டறிக",
    faq: "அடிக்கடிகேட்கப்படும் வினாக்கள்",
    terms: "நியதிக்ள மற்றும நிபந்தனைகள்",
    privacyPolicy: "பிரத்தியேக கொள்கை",
    call1919: "1919 இனை அழைக்கவும்",
    contactUs: "எம்முடன் தொடர்பு கொள்ள",
    address: "முகவரி",
    addressLine1: "இலங்கை ஐசிரி முகவராண்மை,",
    addressLine2: "490, ஆர்.ஏ.டீ.மெல் மாவத்தை,",
    addressLine3: "கொழும்பு 03.",
    phone: "தொலை\nபேசி",
    phoneNo: "011 2369 099",
    fax: "தொலைந\nகல்",
    faxNo: "011 2369 091",
    e_mail_title: "மின்னஞ்\nசல்",
    copyright:
      "பதிப்புரிமை © மாகாண மோட்டார் போக்குவரத்துத் திணைக்களம், அனைத்து உரிமைகளும் பதிக்கப்பெற்றவை.",
    inquiries: "விசாரணைகள்",
  },
};

const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 1,
    x: 0,
  },
};

const pageTransition = {
  duration: 2,
};

const Footer = () => {
  const locale = useRecoilValue(LanguageFilter);

  let facebookURL = "https://web.dev.erl2.lgcc.gov.lk/";

  function linesBreak(messages) {
    return messages
      .map((message) =>
        typeof message === "string"
          ? message.split("\n").map((messages) => <p>{messages}</p>)
          : message
      )
      .reduce((messages, message) => messages.concat(message), []);
  }

  return (
    <motion.div
      className="erlWebFooterMainWrapper paddingTop"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="erlContent">
        <div className="row">
          <div className="col-md-4 col-sm-12 paddingBottom">
            {/* <h5>eRL</h5> */}
            <img src={ERLLogo} alt="Logo" style={{ height: 50 }} />
            <IntlProvider locale={locale} messages={messages[locale]}>
              <p align="justify" className="paddingBottom">
                <FormattedMessage id="content" value={{ locale }}>
                  {linesBreak}
                </FormattedMessage>
              </p>
            </IntlProvider>

            <p>
              <IntlProvider locale={locale} messages={messages[locale]}>
                <FormattedMessage id="shareOn" value={{ locale }} />
              </IntlProvider>{" "}
              <FacebookButton url={facebookURL} appId={179389394251544}>
                <i className="mdi mdi-facebook"></i>
              </FacebookButton>{" "}
              <TwitterButton url={facebookURL} appId={179389394251544}>
                <i className="mdi mdi-twitter"></i>
              </TwitterButton>
            </p>
          </div>
          <div className="col-md-4 col-sm-12 paddingBottom quickLinkWrapper">
            <h5>
              <IntlProvider locale={locale} messages={messages[locale]}>
                <FormattedMessage id="quickLinks" value={{ locale }} />
              </IntlProvider>
            </h5>
            <ul>
              <li>
                -
                <Link to="supported-companies">
                  {" "}
                  <IntlProvider locale={locale} messages={messages[locale]}>
                    <FormattedMessage
                      id="supportOrganization"
                      value={{ locale }}
                    />
                  </IntlProvider>
                </Link>
              </li>
              <li>
                <li>
                  -
                  <Link to="contact-us">
                    {" "}
                    <IntlProvider locale={locale} messages={messages[locale]}>
                      <FormattedMessage id="contactUs" value={{ locale }} />
                    </IntlProvider>
                  </Link>
                </li>
                -
                <Link to="locate-us">
                  {" "}
                  <IntlProvider locale={locale} messages={messages[locale]}>
                    <FormattedMessage id="locateUs" value={{ locale }} />
                  </IntlProvider>
                </Link>
              </li>

              {/* <li>
                -{" "}
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <FormattedMessage id="call1919" value={{ locale }} />
                </IntlProvider>
              </li> */}

              <li>
                -
                <Link to="faq">
                  {" "}
                  <IntlProvider locale={locale} messages={messages[locale]}>
                    <FormattedMessage id="faq" value={{ locale }} />
                  </IntlProvider>
                </Link>
              </li>

              <li>
                -
                <Link to="tnc">
                  {" "}
                  <IntlProvider locale={locale} messages={messages[locale]}>
                    <FormattedMessage id="terms" value={{ locale }} />
                  </IntlProvider>
                </Link>
              </li>

              <li>
                -
                <Link to="privacy-policy">
                  {" "}
                  <IntlProvider locale={locale} messages={messages[locale]}>
                    <FormattedMessage id="privacyPolicy" value={{ locale }} />
                  </IntlProvider>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 paddingBottom quickLinkWrapper">
            <h5>
              <IntlProvider locale={locale} messages={messages[locale]}>
                <FormattedMessage id="inquiries" value={{ locale }} />
              </IntlProvider>
            </h5>
            <ul class="alignMe">
              <li>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <div className="row ml-2">
                    {/* <div className="col-md-2"> */}
                    {/* <b><FormattedMessage id={"address"} value={{ locale }} /></b> */}
                    <Link to="contact-us">
                      {" "}
                      <IntlProvider locale={locale} messages={messages[locale]}>
                        <FormattedMessage
                          id="Provincial Department of Motor Traffic"
                          value={{ locale }}
                        />
                      </IntlProvider>
                    </Link>
                    {/* </div> */}

                    {/* <div className="col-md-1 pl-4 ml-3">:</div> */}
                    {/* <FormattedMessage
                        id={"addressLine1"}
                        value={{ locale }}
                      />
                      <br></br>
                      <FormattedMessage
                          id={"addressLine2"}
                          value={{ locale }}
                        />
                      <br></br>
                      <FormattedMessage
                          id={"addressLine3"}
                          value={{ locale }}
                        /> */}
                  </div>
                </IntlProvider>
              </li>
              <li>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <div className="row">
                    {/* <div className="col-md-2"> */}
                    {/* <FormattedMessage id="phone" value={{ locale }} /> */}
                    {/* </div> */}
                    {/* <div className="col-md-1 pl-4 ml-3">:</div> */}
                    {/* <div className="col-md-5"> */}
                    {/* {" "} */}
                    {/* <FormattedMessage id="phoneNo" value={{ locale }} /> */}
                    {/* </div> */}
                    <b>
                      <FormattedMessage id="phone" value={{ locale }} />{" "}
                    </b>{" "}
                    <img src={contact1919} alt="Logo" style={{ height: 30 }} />
                  </div>
                </IntlProvider>
              </li>
              <li>
                {" "}
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <div className="row">
                    {/* <b><FormattedMessage id="fax" value={{ locale }} /> </b> <FormattedMessage id="faxNo" value={{ locale }} /> */}
                  </div>
                </IntlProvider>
              </li>
              <li>
                <IntlProvider locale={locale} messages={messages[locale]}>
                  <div className="row">
                    {/* <div className="col-md-2"> */}
                    {/* <FormattedMessage id="e_mail_title" value={{ locale }} /> */}
                    {/* </div> */}

                    {/* <div className="col-md-1 pl-4 ml-3">:</div> */}
                    <b>
                      <FormattedMessage id="e_mail_title" value={{ locale }} />{" "}
                    </b>
                    {/* <div className=""> */}
                    <a href={"mailto:" + "info@erl2.gov.lk"}>
                      info@erl2.gov.lk
                    </a>
                    <br></br>
                    <b>
                      <FormattedMessage id=" " value={{ locale }} />{" "}
                    </b>
                    <a href={"mailto:" + "info@icta.lk"}>info@icta.lk</a>
                    {/* </div> */}
                  </div>
                </IntlProvider>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 floatLeft">
            <hr />
            <IntlProvider locale={locale} messages={messages[locale]}>
              <FormattedMessage id="copyright" value={{ locale }} />
            </IntlProvider>
            Version - 1.9.79
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export { Footer };
