/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-13 11:50:32
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 17:48:44
 */
import React, { useState } from "react";
import { SelectBox } from "../../../ui-components/ui-elements/form/SelectBox";
import {
  CheckBox,
  CheckBoxWithState,
} from "../../../ui-components/ui-elements/form/CheckBox";
import { Form } from "../../../ui-components/ui-elements/form/FormWrapper";
import {
  Button,
  ButtonWithState,
  RoundedIconButton,
  toolTipPositions,
} from "../../../ui-components/ui-elements/form/Button";
import {
  InputBox,
  InputBoxWithState,
} from "../../../ui-components/ui-elements/form/InputBox";
import { RadioButton } from "../../../ui-components/ui-elements/form/RadioButton";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import { Badge } from "../../../ui-components/ui-elements/form/Badge";
import {
  Accordion,
  AccordionWrapper,
} from "../../../ui-components/ui-elements/accordion/Accordion";
import {
  UICard,
  cardTitlePlacement,
  cardSize,
  Image,
} from "../../../ui-components/ui-elements/common/BaseElements";
import {
  applicationStatus,
  DataTable,
} from "../../../ui-components/ui-elements/data-table/DataTable";
import {
  Popup,
  MenuPopup,
} from "../../../ui-components/ui-elements/Popup/Popup";
import { TestPopupContent } from "./TestPopupContent";
import {
  OnlineAppStatusFilterComponent,
  VftFilterComponent,
  LocateUsFilterComponent,
} from "./TestFilterComponent";
import { store } from "react-notifications-component";
import {
  getNotification,
  notificationData,
} from "../../../ui-components/ui-elements/notification/notification";
import { CircleLoaderElement, LoadingScreen } from "../../../ui-components/ui-elements/circle-loader-element/CircleLoaderElement";

const inputData = [
  {
    id: 1,
    value: "option 1",
  },
  {
    id: 2,
    value: "option 2",
  },
  {
    id: 3,
    value: "option 3",
  },
];

const OnlineAppStatusHead = [
  {
    displayName: "Requested At",
    key: "requestedAt",
  },
  {
    displayName: "Vehicle Reg. No.",
    key: "vehicleRegNo",
  },
  {
    displayName: "Reference No.",
    key: "referenceNo",
  },
  {
    displayName: "Application Status",
    key: "badge",
  },
];

const OnlineAppStatusBody = [
  {
    requestedAt: "10/08/2020",
    vehicleRegNo: "CAD-5555",
    referenceNo: "1254657687",
    badge: applicationStatus.PENDING,
  },
  {
    requestedAt: "01/05/2020",
    vehicleRegNo: "BAA-0978",
    referenceNo: "1212409458",
    badge: applicationStatus.ISSUED,
  },
];

const VftTableHead = [
  {
    displayName: "VFT Company Name",
    key: "companyName",
  },
  {
    displayName: "Contact Number",
    key: "contactNumber",
  },
  {
    displayName: "Email",
    key: "email",
  },
];

const VftTableRows = [
  {
    companyName: "GSK Motors (PVT) Ltd",
    contactNumber: "94117000001",
    email: "gsk@gmail.com",
  },
  {
    companyName: "Amarasinghe Motor Way (PVT) Ltd",
    contactNumber: "94117000002",
    email: "info@amw.lk",
  },
];

const VetTableHead = [
  {
    displayName: "VET Company Name",
    key: "companyName",
  },
  {
    displayName: "Contact Number",
    key: "contactNumber",
  },
  {
    displayName: "Email",
    key: "email",
  },
];

const VetTableRows = [
  {
    companyName: "LAUGFS Eco Sri (PVT) Ltd",
    contactNumber: "94117000001",
    email: "info@let.com",
  },
  {
    companyName: "Clean Co Lanka (PVT) Ltd",
    contactNumber: "94117000002",
    email: "info@gglk.lk",
  },
];

const InsuranceTableHead = [
  {
    displayName: "Insurance Company Name",
    key: "companyName",
  },
  {
    displayName: "Contact Number",
    key: "contactNumber",
  },
  {
    displayName: "Email",
    key: "email",
  },
];

const InsuranceTableRows = [
  {
    companyName: "Amana Takaful PLC",
    contactNumber: "94117000001",
    email: "info@ama.com",
  },
  {
    companyName: "AIA General Lanka Limited",
    contactNumber: "94117000002",
    email: "info@aia.lk",
  },
];

const ContactUsTableHead = [
  {
    displayName: "Revenue Licence Issuing Centre",
    key: "issuingCentre",
  },
  {
    displayName: "Address",
    key: "address",
  },
  {
    displayName: "Contact Number",
    key: "contactNumber",
  },
  {
    displayName: "Email",
    key: "email",
  },
];

const ContactUsTableRows = [
  {
    issuingCentre: "Department of Motor Traffic - Province A",
    address: "Department of Motor Traffic, Province A",
    contactNumber: "94117000001",
    email: "info@a.province.com",
  },
  {
    issuingCentre: "Department of Motor Traffic - Province B",
    address: "Department of Motor Traffic, Province B",
    contactNumber: "94117000002",
    email: "info@b.province.lk",
  },
];

const LocateUsTableHead = [
  {
    displayName: "Revenue Licence Issuing Centre",
    key: "issuingCentre",
  },
  {
    displayName: "Address",
    key: "address",
  },
  {
    displayName: "Contact Number",
    key: "contactNumber",
  },
];

const LocateUsTableRows = [
  {
    issuingCentre: "Department of Motor Traffic - Province A",
    address: "Department of Motor Traffic, Province A",
    contactNumber: "94117000001",
  },
  {
    issuingCentre: "Department of Motor Traffic - Province B",
    address: "Department of Motor Traffic, Province B",
    contactNumber: "94117000002",
  },
];

const TestComponent = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const notificationData = {
    title: "Test title",
    message: "Test mesasge",
    // notificationType: "success",
    // position: "top", // insert
    // placement: "top-right", // container
    // animationIn: ["animate__animated", "animate__fadeIn"],
    // animationOut: ["animate__animated", "animate__fadeOut"],
    isDismiss: true,
    dismissDuration: 5000,
    dismissDurationDisplay: true, // onScreen
    dismissPauseOnHover: true,
  };

  const getNotificationFn = () => {
    getNotification(notificationData);
  };

  return (
    <UICard
      title={"Test"}
      titlePlacement={cardTitlePlacement.LEFT}
      cardSize={cardSize.LARGE}
      imageSrc=""
      imageAlt="Card image cap"
      cardBorder={true}
      // isContentCenter={true}
    >
        <CircleLoaderElement />
      <Form className="">

        <div className="input-group">
          <Button
            btnText={"Open Notification"}
            onClick={getNotificationFn}
            btnStyle={"btnOutlinePrimary"}
          />

          <Button
            btnText={"Menu"}
            onClick={() => (open3 ? setOpen3(false) : setOpen3(true))}
            btnStyle={"btnOutlinePrimary"}
          />
          {open3 && (
            <MenuPopup
              title={"Test Title"}
              content={
                <ul>
                  <li>Change Password</li>
                  <li>Status Check</li>
                  <li>Logout</li>
                </ul>
              }
              isOpen={open3}
              handleClose={() => setOpen3(false)}
            />
          )}
        </div>
        <Image src="images/Capture.PNG" />
        <InputBoxWithState
          // uiType={templateTypes.bootstrap}
          formGroupName={"loginFromKey"}
          inputName={"username"}
          labelText="Username"
          isRequired={true}
        />
        <InputBox
          inputId=""
          inputName="test"
          inputType="text"
          placeholder="Enter value..."
          labelText="input box"
          helperText="helper"
          labelPlacement={labelPositions.TOP}
          isDisabled={true}
        />
        <InputBox
          inputId=""
          inputName="test"
          inputType="password"
          placeholder="Enter value..."
          labelText="input box"
          helperText="helper"
          labelPlacement={labelPositions.TOP}
          endIcon={<i className="mdi mdi-help" />}
          endIconOnClickFn={() => console.log("test")}
        />
        <SelectBox
          labelText="select box label"
          inputName="testSelectBox"
          dataList={inputData}
          inputError=""
          helperText="helper"
          horizontalLabel={true}
          labelPlacement={labelPositions.START}
          isRequired={true}
        />
        <InputBox
          labelText="input box label"
          inputId="testInputBox"
          inputName="testInputBox"
          labelPlacement={labelPositions.START}
          isRightAlignLabel={true}
          inputError=""
          helperText="helper"
          isRequired={false}
          isDisabled={false}
        />
        <CheckBoxWithState
          labelText="Check box"
          inputName="checkBox1"
          labelPlacement={labelPositions.TOP}
          inputError=""
          helperText="helper"
          id="testCheckBox"
        />
        <CheckBox
          labelText="Check box"
          inputName="checkBox3"
          labelPlacement={labelPositions.START}
          inputError=""
          helperText="helper"
          id="testCheckBox"
        />
        <CheckBox
          labelText="Check box"
          inputName="checkBox2"
          labelPlacement={labelPositions.BOTTOM}
          inputError=""
          helperText="helper"
          id="testCheckBox"
        />
        <CheckBox
          labelText="Check box"
          inputName="checkBox4"
          labelPlacement={labelPositions.END}
          inputError=""
          helperText="helper"
          id="testCheckBox"
        />
        google Signin
        <Button btnText={"Google Signin"} btnStyle={"googleSignin"} />
        facebook Signin
        <Button btnText={"Facebook Signin"} btnStyle={"facebookSignin"} />
        email Signin
        <Button btnText={"Email Signin"} btnStyle={"emailSignin"} />
        primary button
        <Button
          btnText={"Test"}
          btnType={"submit"}
          onClick={() => alert("test")}
          btnStyle={"btnPrimary"}
          isDisabled={true}
        />
        disabled outline button
        <Button
          btnText={"Test"}
          btnType={"submit"}
          onClick={() => alert("test")}
          isActive={true}
          setToolTip={true}
          toolTipTitle={"test"}
          toolTipPosition={toolTipPositions.bottom}
          isDisabled={true}
          btnStyle={"btnOutlinePrimary"}
        />
        full width outline button
        <ButtonWithState
          btnText={"Popup"}
          onClick={() => setOpen(true)}
          isFullWidth={true}
          startIcon={<i className="mdi mdi-help"></i>}
          endIcon={<i className="mdi mdi-help"></i>}
          btnStyle={"btnOutlinePrimary"}
        />
        {open && (
          <Popup
            title={"Test Title"}
            content={<TestPopupContent />}
            isOpen={open}
            handleClose={() => setOpen(false)}
          />
        )}
        <h1>Radio</h1>
        <RadioButton
          inputName="radio"
          inputValue="option1"
          inputId="1"
          // onChangeFn={() => {
          //   console.log("checked");
          // }}
          isChecked={false}
          labelText="test 1"
          labelPlacement={labelPositions.END}
        />
        <RadioButton
          inputName="radio"
          inputValue="option1"
          inputId="2"
          labelText="test 2"
          labelPlacement={labelPositions.END}
        />
        Badges
        <Badge text="test" style={"warningBadge"} />
        <Badge text="test" style={"primaryBadge"} />
        <Badge text="test" style={"defaultBadge"} />
        <Badge text="test" style={"successBadge"} />
        {/* Online Application Status table */}
        <DataTable
          filterComponent={<OnlineAppStatusFilterComponent />}
          isSetSearch={true}
          isSetReset={true}
          tableHeaderList={OnlineAppStatusHead}
          tableBodyList={OnlineAppStatusBody}
          isSetStatus={true}
          statusIcon={
            <RoundedIconButton
              icon={<i className="mdi mdi-help"></i>}
              tooltip={"test"}
              onClickFn={() => setOpen2(true)}
            />
          }
        />
        {open2 && (
          <Popup
            title={"Test Title"}
            content={<TestPopupContent />}
            isOpen={open2}
            handleClose={() => setOpen2(false)}
          />
        )}
        {/* VFT company table */}
        <DataTable
          filterComponent={<VftFilterComponent />}
          tableHeaderList={VftTableHead}
          tableBodyList={VftTableRows}
        />
        {/* Locate us table */}
        <DataTable
          filterComponent={<LocateUsFilterComponent />}
          isSetSearch={true}
          tableHeaderList={LocateUsTableHead}
          tableBodyList={LocateUsTableRows}
        />
        {/* Contact us table */}
        <DataTable
          tableHeaderList={ContactUsTableHead}
          tableBodyList={ContactUsTableRows}
        />
        {/* VET company table */}
        <DataTable
          tableHeaderList={VetTableHead}
          tableBodyList={VetTableRows}
        />
        {/* Insurance company table */}
        <DataTable
          tableHeaderList={InsuranceTableHead}
          tableBodyList={InsuranceTableRows}
        />
        <RoundedIconButton
          icon={<i className="mdi mdi-help"></i>}
          tooltip={"test"}
        />
      </Form>
      Accordion
      <AccordionWrapper>
        <Accordion
          heading={"test"}
          content={
            "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt."
          }
        >
          <AccordionWrapper>
            <Accordion
              heading={"test"}
              content={
                "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt."
              }
            />
            <Accordion
              heading={"test"}
              content={
                "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt."
              }
            />
            <Accordion
              heading={"test"}
              content={
                "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt."
              }
            />
          </AccordionWrapper>
        </Accordion>
        <Accordion
          heading={"test"}
          content={
            "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt."
          }
        />
      </AccordionWrapper>
    </UICard>
  );
};

export default TestComponent;
