/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-11 15:19:21
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:31:08
 */

import React, { Fragment } from "react";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import authorized from "../../hoc-middlewares/Authorized";
import Login from "./login/Login";
import ChangePassword from "./change-password/ChangePassword";
import ResetPassword from "./reset-password/ResetPassword";
import ForgotPassword from "./forgot-password/ForgotPassword";
import Register from "./sign-up/Register";
import Home from "../home/pages/Home";

const AuthRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/login"
        routeKey={"login"}
        component={authorized(Login)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/change-password"
        routeKey={"change-password"}
        component={authorized(ChangePassword)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/forgot-password"
        routeKey={"forgot-password"}
        component={authorized(ForgotPassword)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/reset-password"
        routeKey={"reset-password"}
        component={authorized(ResetPassword)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/register"
        routeKey={"register"}
        component={guest(Register)}
        routePermissions={permissions.NONE.permissions}
      />
    </Fragment>
  );
};

export default AuthRoutes;
