import React, { useEffect, useState } from "react";
import Template from "../../../ui-components/templates/Template";
import {
  cardSize,
  UICard,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import { CheckBox } from "../../../ui-components/ui-elements/form/CheckBox";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { Label } from "../../../ui-components/ui-elements/form/Label";

import { useRecoilState, useRecoilValue } from "recoil";
import { LanguageFilter, userData } from "../../../../atom";
import { useProfileInfoHook } from "../../../hooks/useProfileHook";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import ChassisNumberDisplayPopUp from "../../revenue-licence/pages/includes/ChassisNumberDisplayPopUp";
import VerifyMobileNumberPopUp from "./includes/VerifyMobileNumberPopUp";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../../../helpers/common-helpers/manageStorage.helpers";
import {
  authTokenStorageKey,
  userProfileStorageKey,
} from "../../../../config/core.config";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import _ from "lodash";
import { ToggleButton } from "react-bootstrap";
import CancelUpdatePopUp from "./includes/CancelUpdatePopUp";

const UserProfileEdit = () => {
  const locale = useRecoilValue(LanguageFilter);
  const userProfile = useRecoilValue(userData);
  const history = useHistory();

  const {
    updateProfileInfo,
    sendOtp,
    otpResult,
    errorInfo,
    profileInfoError,
    profileInfo,
    isVerifyResult,
    otpVerifyResult
  } = useProfileInfoHook();

  const [isVerified, setIsVerified] = useState(false);
  const [verifyButton, setVerifyButton] = useState(false);
  const [contactChamged, setContactChanged] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [contactNum, setContactNum] = useState("");
  // const [sms, setSMS] = useState("");
  const [eMail, setEMail] = useState("");
  const [enteredContact, setEnteredContact] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const [errorDisplayName, setErrorDisplayName] = useState("");
  const [showCancelScreen, setShowCancelScreen] = useState(false);
  const [isOtpVerifyResult, setIsOtpVerifyResult] = useState("");
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-z A-Z]/;

  useEffect(() => {
    setDisplayName(getFromLocalStorage(userProfileStorageKey)?.display_name);
  }, [getFromLocalStorage(userProfileStorageKey)?.display_name]);

  useEffect(() => {
    if (
      getFromLocalStorage(userProfileStorageKey)?.user?.sms_notification === 1
    ) {
      setSendSMS(true);
    } else {
      setSendSMS(false);
    }
  }, [getFromLocalStorage(userProfileStorageKey)?.user?.sms_notification]);

  useEffect(() => {
    let phoneNum = getFromLocalStorage(userProfileStorageKey)?.phone
      ? getFromLocalStorage(userProfileStorageKey)?.phone
      : null;
    let eMail = getFromLocalStorage(userProfileStorageKey)?.email
      ? getFromLocalStorage(userProfileStorageKey)?.email
      : null;

    if (phoneNum !== null) {
      if (phoneNum?.charAt(0) === "0") {
        setContactNum(phoneNum?.slice(1));
        setEnteredContact(phoneNum?.slice(1));
      } else {
        setContactNum(phoneNum);
        setEnteredContact(phoneNum);
      }

      setContactChanged(true);
      setIsVerified(true);
    } else {
      setContactChanged(false);
    }

    setIsVerified(true);

    if (eMail !== null) {
      setEMail(eMail);
    }
  }, [getFromLocalStorage(userProfileStorageKey)?.phone]);

  const handleDisplayName = (text) => {
    document.getElementById("txtName").addEventListener("keyup", function () {
      setDisplayName(this.value);
      setErrorDisplayName("");
    });
  };

  const handleMobileNumber = (text) => {

    document.getElementById("txtMobile").addEventListener("keyup", function () {
      if (this.value !== contactNum) {
        setContactChanged(false);

      } else {
        setContactChanged(true);

      }

      if (
        this.value !== 0 &&
        this.value.length === 9 &&
        this.value !== contactNum
      ) {
        setVerifyButton(true);
      } else {
        setVerifyButton(false);
      }

      if (this.value !== 0 && this.value.charAt(0) === "0") {
        this.value = this.value.slice(1);
        setEnteredContact(this.value);

      } else {
        setEnteredContact(this.value);


      }


    });
  };

  const onUpdateClicked = () => {
    console.log("aaaaa", contactChamged, enteredContact, isVerifyResult, profileInfo);
    // if (document.getElementById("txtName").value !== "") {
    //   if (document.getElementById("txtMobile").value !== "") {
    //     if(contactChamged){
    //       if (!format.test(enteredContact)) {
    //         updateProfileInfo({
    //           display_name: displayName,
    //           sms_notification: sendSMS,
    //           contact_number: contactChamged ? enteredContact : contactNum,
    //         });
    //         setErrorMobile("");
    //         setToLocalStorage(userProfileStorageKey, profileInfo);
    //       } else {
    //         // failNotify("Phone No should not contain special characters or letters");
    //         setErrorMobile(
    //           locale === "en"
    //             ? "Invalid Mobile Number. Please enter a valid Mobile Number. Ex: 7XXXXXXXX"
    //             : locale === "si"
    //               ? "ලබා දි ඇති දුරකථන අංකය වලංගු දුරකථන අංකයක් නොවේ. කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න. උදා: 7XXXXXXXX"
    //               : "செல்லுபடியற்ற கையடக்கத் தொலைபேசி இலக்கம். தயவு செய்து வலிதான கையடக்க தொலைபேசி இலக்கத்தினை பதிவு செய்க. உதாரணம் – 7XXXXXXXX"
    //         );
    //       }
    //     }else{
    //       if(isVerifyResult){
    //         if (!format.test(enteredContact)) {
    //           updateProfileInfo({
    //             display_name: displayName,
    //             sms_notification: sendSMS,
    //             contact_number: contactChamged ? enteredContact : contactNum,
    //           });
    //           setErrorMobile("");
    //           setToLocalStorage(userProfileStorageKey, profileInfo);
    //         } else {
    //           // failNotify("Phone No should not contain special characters or letters");
    //           setErrorMobile(
    //             locale === "en"
    //               ? "Invalid Mobile Number. Please enter a valid Mobile Number. Ex: 7XXXXXXXX"
    //               : locale === "si"
    //                 ? "ලබා දි ඇති දුරකථන අංකය වලංගු දුරකථන අංකයක් නොවේ. කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න. උදා: 7XXXXXXXX"
    //                 : "செல்லுபடியற்ற கையடக்கத் தொலைபேசி இலக்கம். தயவு செய்து வலிதான கையடக்க தொலைபேசி இலக்கத்தினை பதிவு செய்க. உதாரணம் – 7XXXXXXXX"
    //           );
    //         }
    //       }
    //       else{
    //         setErrorMobile(
    //           locale === "en"
    //             ? "Mobile number should be verified."
    //             : locale === "si"
    //               ? "ජංගම දුරකථන අංකය අවශ්‍යයි."
    //               : "மொபைல் எண் தேவை."
    //         );
    //       }

    //     }

    //   } else {
    //     setErrorMobile(
    //       locale === "en"
    //         ? "Mobile number is required."
    //         : locale === "si"
    //           ? "ජංගම දුරකථන අංකය අවශ්‍යයි."
    //           : "மொபைல் எண் தேவை."
    //     );
    //   }
    // } else {
    //   setErrorDisplayName(
    //     locale === "en"
    //       ? "Display Name is required"
    //       : //"Display name cannot be empty."
    //       locale === "si"
    //         ? "ප්‍රදර්ශිත නාමය අවශ්‍ය වේ." //"< ගොනු කර ඇති නම > අවශ්‍ය වේ."
    //         : "பெயரினை வெளிக்காட்டுக தேவைப்படுகின்றது" //"< களப் பெயர் > தேவைப்படுகின்றது."
    //   );
    // }


    console.log("aaaaa", contactChamged, enteredContact, isVerifyResult, profileInfoError);
    if (contactChamged) {
      if (document.getElementById("txtName").value !== "") {
        if (
          document.getElementById("txtMobile").value === "" &&
          sendSMS === false
        ) {
          if (!format.test(enteredContact)) {
            updateProfileInfo({
              display_name: displayName,
              sms_notification: sendSMS,
              contact_number: contactChamged ? enteredContact : contactNum,
            });
            setErrorMobile("");
            setToLocalStorage(userProfileStorageKey, profileInfo);
          } else {
            // failNotify("Phone No should not contain special characters or letters");
            setErrorMobile(
              locale === "en"
                ? "Invalid Mobile Number. Please enter a valid Mobile Number. Ex: 7XXXXXXXX"
                : locale === "si"
                ? "ලබා දි ඇති දුරකථන අංකය වලංගු දුරකථන අංකයක් නොවේ. කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න. උදා: 7XXXXXXXX"
                : "செல்லுபடியற்ற கையடக்கத் தொலைபேசி இலக்கம். தயவு செய்து வலிதான கையடக்க தொலைபேசி இலக்கத்தினை பதிவு செய்க. உதாரணம் – 7XXXXXXXX"
            );
          }
        } else if (
          document.getElementById("txtMobile").value !== "" &&
          sendSMS === true
        ) {
          if (!format.test(enteredContact)) {
            updateProfileInfo({
              display_name: displayName,
              sms_notification: sendSMS,
              contact_number: contactChamged ? enteredContact : contactNum,
            });
            setErrorMobile("");
            setToLocalStorage(userProfileStorageKey, profileInfo);
          } else {
            // failNotify("Phone No should not contain special characters or letters");
            setErrorMobile(
              locale === "en"
                ? "Invalid Mobile Number. Please enter a valid Mobile Number. Ex: 7XXXXXXXX"
                : locale === "si"
                ? "ලබා දි ඇති දුරකථන අංකය වලංගු දුරකථන අංකයක් නොවේ. කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න. උදා: 7XXXXXXXX"
                : "செல்லுபடியற்ற கையடக்கத் தொலைபேசி இலக்கம். தயவு செய்து வலிதான கையடக்க தொலைபேசி இலக்கத்தினை பதிவு செய்க. உதாரணம் – 7XXXXXXXX"
            );
          }
        } else if (
          document.getElementById("txtMobile").value !== "" &&
          sendSMS === false
        ) {
          if (!format.test(enteredContact)) {
            updateProfileInfo({
              display_name: displayName,
              sms_notification: sendSMS,
              contact_number: contactChamged ? enteredContact : contactNum,
            });
            setErrorMobile("");
            setToLocalStorage(userProfileStorageKey, profileInfo);
          } else {
            // failNotify("Phone No should not contain special characters or letters");
            setErrorMobile(
              locale === "en"
                ? "Invalid Mobile Number. Please enter a valid Mobile Number. Ex: 7XXXXXXXX"
                : locale === "si"
                ? "ලබා දි ඇති දුරකථන අංකය වලංගු දුරකථන අංකයක් නොවේ. කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න. උදා: 7XXXXXXXX"
                : "செல்லுபடியற்ற கையடக்கத் தொலைபேசி இலக்கம். தயவு செய்து வலிதான கையடக்க தொலைபேசி இலக்கத்தினை பதிவு செய்க. உதாரணம் – 7XXXXXXXX"
            );
          }
        } else {
          setErrorMobile(
            locale === "en"
              ? "Mobile number is required."
              : locale === "si"
              ? "ජංගම දුරකථන අංකය අවශ්‍යයි."
              : "மொபைல் எண் தேவை."
          );
        }
      } else {
        setErrorDisplayName(
          locale === "en"
            ? "Display Name is required"
            : //"Display name cannot be empty."
            locale === "si"
            ? "ප්‍රදර්ශිත නාමය අවශ්‍ය වේ." //"< ගොනු කර ඇති නම > අවශ්‍ය වේ."
            : "பெயரினை வெளிக்காட்டுக தேவைப்படுகின்றது" //"< களப் பெயர் > தேவைப்படுகின்றது."
        );
      }
    } else {
      if (document.getElementById("txtName").value !== "") {
        if (
          document.getElementById("txtMobile").value === "" &&
          sendSMS === false
        ) {
          if (!format.test(enteredContact)) {
            updateProfileInfo({
              display_name: displayName,
              sms_notification: sendSMS,
              contact_number: contactChamged ? enteredContact : contactNum,
            });
            setToLocalStorage(userProfileStorageKey, profileInfo);
          } else {
            setErrorMobile(
              locale === "en"
                ? "Invalid Mobile Number. Please enter a valid Mobile Number. Ex: 7XXXXXXXX"
                : locale === "si"
                ? "ලබා දි ඇති දුරකථන අංකය වලංගු දුරකථන අංකයක් නොවේ. කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න. උදා: 7XXXXXXXX"
                : "செல்லுபடியற்ற கையடக்கத் தொலைபேசி இலக்கம். தயவு செய்து வலிதான கையடக்க தொலைபேசி இலக்கத்தினை பதிவு செய்க. உதாரணம் – 7XXXXXXXX"
            );
          }
        } else {
          setErrorMobile(
            locale === "en"
              ? "Mobile number is required."
              : locale === "si"
              ? "ජංගම දුරකථන අංකය අවශ්‍යයි."
              : "மொபைல் எண் தேவை."
          );
        }
      } else {
        setErrorDisplayName(
          locale === "en"
            ? "Display Name is required"
            : locale === "si"
            ? "ප්‍රදර්ශිත නාමය අවශ්‍ය වේ."
            : "பெயரினை வெளிக்காட்டுக தேவைப்படுகின்றது"
        );
      }
    }



  };

  const onVerifyClicked = () => {
    if (!format.test(enteredContact)) {
      if (enteredContact.length === 9) {
        setShowOtpScreen(true);

        sendOtp({
          phone_number: enteredContact,
        });
        setErrorMobile("");
      } else {
        failNotify(
          locale === "en"
            ? "Phone No should be 9 digits."
            : locale === "si"
              ? "දුරකථන අංකය ඉලක්කම් 9ක් විය යුතුය."
              : "தொலைபேசி எண் 9 இலக்கங்களாக இருக்க வேண்டும்."
        );
      }
    } else {
      // failNotify("Phone No should not contain special characters or letters");
      setErrorMobile(
        locale === "en"
          ? "Invalid Mobile Number. Please enter a valid Mobile Number. Ex: 7XXXXXXXX"
          : locale === "si"
            ? "ලබා දි ඇති දුරකථන අංකය වලංගු දුරකථන අංකයක් නොවේ. කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න. උදා: 7XXXXXXXX"
            : "செல்லுபடியற்ற கையடக்கத் தொலைபேசி இலக்கம். தயவு செய்து வலிதான கையடக்க தொலைபேசி இலக்கத்தினை பதிவு செய்க. உதாரணம் – 7XXXXXXXX"
      );
    }
  };

  useEffect(() => {
    failNotify(errorInfo);
  }, [errorInfo]);

  useEffect(() => {
    if (profileInfo !== undefined) {
      successNotify(
        locale === "en"
          ? "User Profile updated successfully"
          : locale === "si"
            ? "පරිශීලක තොරතුරු සාර්ථකව යාවත්කාලීන කරන ලදි"
            : "பயனர் விபரம் வெற்றிகரமாக இற்றைப்படுத்தப்பட்டுள்ளது"
      );

      setTimeout(() => {
        history.goBack();
      }, 1000);
    } else {
      failNotify(profileInfoError);
    }
  }, [profileInfoError, profileInfo]);

  const handleSendSMSCheck = () => {
    if (sendSMS === true) {
      setSendSMS(false);
    } else {
      setSendSMS(true);
    }
  };

  const successNotify = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const failNotify = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onCancelClicked = () => {

    setShowCancelScreen(true)

  }
  return (
    <Template activeHeader="userProfileEdit" selectedLan={locale}>
      <div className="uiCardWrapper">
        <UICard
          cardSize={cardSize.LARGE}
          title={
            locale === "en"
              ? "Edit User Profile"
              : locale === "si"
                ? "පරිශීලක තොරතුරු සංස්කරණය කිරීම"
                : "பயனர் சுயவிவரத்தைத் திருத்தவும்"
          }
          // imageSrc=""
          // imageAlt="userPicture"
          titleStyle="mainTxtHeading "
        >
          <br></br>
          <form id="profileForm">
            <div
              className="ml-1 card bg-primary text-white"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30%",
                width: "8%",
                borderRadius: "20%",
              }}
            >
              <div className="card-body">
                <h3 className="card-text">
                  {displayName ? displayName.charAt(0).toUpperCase() : "N/A"}
                </h3>
              </div>
            </div>
            <br></br>

            <div className="row">
              <div style={{ marginLeft: "4.5%", width: "30%" }}>
                <div className="row">
                  <div className="text-dark">
                    {locale === "en"
                      ? "Display Name "
                      : locale === "si"
                        ? "ප්‍රදර්ශිත නාමය "
                        : "பெயரினை வெளிக்காட்டுக "}
                  </div>

                  <div className="text-danger"> &nbsp; *</div>
                </div>
              </div>

              <div>
                <input
                  id="txtName"
                  className="form-control form-group"
                  maxLength={100}
                  style={{ width: "128%", height: 32 }}
                  defaultValue={displayName ? displayName : ""}
                  onChange={(text) => handleDisplayName(text)}
                />

                <small style={{ alignContent: "left" }} className="text-danger">
                  {errorDisplayName}
                </small>
              </div>
            </div>

            <br />

            <div className="row">
              <div style={{ marginLeft: "2.5%", width: "32%" }}>
                <div className="d-flex flex-row">

                  <div className="text-dark">
                    {locale === "en"
                      ? "Mobile Number "
                      : locale === "si"
                        ? "ජංගම දුරකථන අංකය"
                        : "கையடக்க தொலைபேசி இலக்கம்"}{" "}
                  </div>
                  <div className="text-danger"> &nbsp; *</div>

                </div>
              </div>

              <div className="input-group-prepend">
                <span
                  class="input-group-text "
                  style={{ height: 32, marginLeft: "0%" }}
                >
                  {"+94"}
                </span>

                <div>
                  <input
                    style={{ width: "100%", height: 32 }}
                    className="form-control form-group"
                    id="txtMobile"
                    onError={errorMobile}
                    maxLength={9}
                    type="text"
                    name={
                      <div className="text-dark">
                        {locale === "en"
                          ? "Mobile Number "
                          : locale === "si"
                            ? "ජංගම දුරකථන අංකය"
                            : "கையடக்க தொலைபேசி இலக்கம்"}{" "}
                      </div>
                    }
                    defaultValue={contactNum === "" ? "" : contactNum}
                    onChange={(text) => handleMobileNumber()}
                    // onInput={() => handleMobileNumber()}
                    pattern="[0-9]*"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>

              <br />

              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />

              {/* <div className="col-md-3 col-sm-3"> */}
              <div style={{ marginLeft: "5%" }}>
                <Button
                  btnStyle={"btnPrimary"}
                  isDisabled={verifyButton === true ? false : true}
                  btnText={
                    locale === "en"
                      ? "Verify "
                      : locale === "si"
                        ? "සත්‍යක්ෂණය කරන්න"
                        : "உறுதிப்படுத்து"
                  }
                  onClick={() => onVerifyClicked()}
                />

                {showOtpScreen && (
                  <Popup
                    title={
                      locale === "en"
                        ? "Verify Your Mobile"
                        : locale === "si"
                          ? "ඔබගේ ජංගම දුරකථන අංකය සත්‍යක්ෂණය කරන්න"
                          : "உங்கள் மொபைல் எண்ணை உறுதிசெய்யுங்கள்"
                    }
                    content={
                      <VerifyMobileNumberPopUp
                        mobileNumber={enteredContact}
                        verifyFunc={setContactChanged}
                        handleClose={setShowOtpScreen}
                        className="modal fade"
                        setIsVerified={setIsVerified}
                      />
                    }
                    isOpen={showOtpScreen}
                    handleClose={() => setShowOtpScreen(false)}
                  />
                )}
              </div>
            </div>
          </form>
          <div style={{ marginLeft: "34%", width: "80%" }}>
            <small
              // className="col-md-12 col-sm-4 text-danger"
              style={{ color: "red" }}
            >
              {errorMobile}
            </small>
          </div>
          <div style={{ marginLeft: "34%" }}>
            {/* <CheckBox
              labelText={
                locale === "en"
                  ? "Send SMS Alerts "
                  : locale === "si"
                  ? "කෙටි පණිවිඩ ඇඟවීම් යවන්න"
                  : "குறுந்தகவல் விழிப்பூட்டல்களை அனுப்புதல்"
              }
              inputValue={sendSMS === 1 ? true : false}
              onChangeFn={(obj) => handleSendSMSCheck(obj)}
            /> */}

            <label>
              <input
                type="checkbox"
                checked={sendSMS}
                onChange={() => handleSendSMSCheck()}
              />{" "}
              {locale === "en"
                ? "Send SMS Alerts "
                : locale === "si"
                  ? "කෙටි පණිවිඩ ඇඟවීම් යවන්න"
                  : "குறுந்தகவல் விழிப்பூட்டல்களை அனுப்புதல்"}
            </label>
          </div>

          <div className="row">
            <div style={{ marginLeft: "2%", width: "30%" }}>
              <Label
                labelText={
                  locale === "en"
                    ? "Email Address"
                    : locale === "si"
                      ? "විද්‍යුත් ලිපිනය"
                      : " மின்னஞ்சல் முகவரி"
                }
              />
            </div>
            <div className="ml-3" style={{ paddingTop: "5px", fontSize: "15px", paddingLeft: "5px" }}>{eMail ? eMail : "N/A"}</div>
          </div>

          <div className="row float-left mr-5 pr-5">
            <div className="btnWrapper" style={{ marginLeft: "6%" }}>
              <Button
                btnStyle="btnPrimary marginRight"
                btnName="update"
                isDisabled={isVerified === true ? false : true}
                btnText={
                  locale === "en"
                    ? "Update"
                    : locale === "si"
                      ? "යාවත්කාලීන කරන්න"
                      : " இற்றைப்படுத்து"
                }
                onClick={() => onUpdateClicked()}
              />
              <div className="mr-2 pr-2" style={{ width: 160 }}>
                <Button
                  btnStyle="btnOutlinePrimary"
                  btnName="cancel"
                  btnText={
                    locale === "en"
                      ? "Cancel"
                      : locale === "si"
                        ? "අවලංගු කරන්න"
                        : " இரத்துச் செய்"
                  }
                  onClick={() => onCancelClicked()}
                />
                {showCancelScreen && (
                  <Popup
                    title={
                      locale === "en"
                        ? "Confirm Cancellation"
                        : locale === "si"
                          ? "අවලංගු කිරීම තහවුරු කරන්න"
                          : "ரத்து செய்வதை உறுதிப்படுத்தவும்"
                    }
                    content={
                      <CancelUpdatePopUp
                        closePopup={setShowCancelScreen}
                      />

                    }
                    isOpen={showCancelScreen}
                    handleClose={() => setShowCancelScreen(false)}

                  />
                )}
              </div>

              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </div>
          <br></br>
        </UICard>
      </div>
    </Template>
  );
};

export { UserProfileEdit };
