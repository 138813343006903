import { atom } from "recoil";

export const LanguageFilter = atom({
  key: "languageFilter",
  default: "en",
});

export const VehicleNumber = atom({
  key: "vehicleNumber",
  default: "",
});

export const ChassisNumber = atom({
  key: "chassisNumber",
  default: "",
});

export const MobileNumber = atom({
  key: "mobileNumber",
  default: "",
});

export const VehicleInfo = atom({
  key: "vehicleInfo",
  default: {},
});

export const userData = atom({
  key: "user",
  default: {},
});

export const paymentCompleteUrl = atom({
  key: "paymentCompleteUrl",
  default: {},
});

export const paymentCompleteRequest = atom({
  key: "paymentCompleteRequest",
  default: {},
});

export const paymentCompleteResponse = atom({
  key: "paymentCompleteResponse",
  default: {},
});

export const paymentConfirmFlag = atom({
  key: "paymentConfirmFlag",
  default: {},
});