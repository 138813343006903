/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 15:09:52
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-09 10:05:28
 */
import { Badge } from "../../../../ui-components/ui-elements/form/Badge";
import { Label } from "../../../../ui-components/ui-elements/form/Label";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../../atom";

const CurrentLicenceStatusPopUp = () => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <div className="">
      <div className="row mt-4">
        <div className="col-md-3 col-sm-12 ">
          <p className="text-danger float-right font-weight-bold">
            {locale === "en"
              ? "Expired"
              : locale === "si"
              ? "කල් ඉකුත් වී ඇත"
              : "காலாவதியாகி விட்டது."}
          </p>
        </div>
        <div className="col-md-8 col-sm-12">
          <p>
            {locale === "en"
              ? "Revenue Licence is expired"
              : locale === "si"
              ? "ආදායම් බලපත්‍රය කල් ඉකුත් වී ඇත"
              : "வருமான அனுமதிப் பத்திரம் காலாவதியாகி விட்டது."}
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-3 col-sm-12">
          <p className="text-primary float-right font-weight-bold">
            {locale === "en"
              ? "Renewable"
              : locale === "si"
              ? "අලුත් කළ හැකිය"
              : "புதுப்பிக்கத்தக்கது"}
          </p>
        </div>
        <div className="col-md-8 col-sm-12">
          <p>
            {locale === "en"
              ? "Revenue Licence is within the renewable period"
              : locale === "si"
              ? "ආදායම් බලපත්‍රය අලුත් කළ හැකි කාල සීමාව තුල තිබේ."
              : "அனுமதிப்பத்திரமானது புதுப்பிக்கத்தக்க காலப்பகுதியினுள் உள்ளது."}
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-3 col-sm-12">
          <p className="text-success float-right font-weight-bold">
            {locale === "en"
              ? "Valid"
              : locale === "si"
              ? "වලංගු වේ"
              : "செல்லுபடியானது"}
          </p>
        </div>
        <div className="col-md-8 col-sm-12">
          <p>
            {locale === "en"
              ? "Valid Revenue Licence"
              : locale === "si"
              ? "වලංගු ආදායම් බලපත්‍රය"
              : "செல்லுபடியாகும் வருமான அனுமதிப்பத்திரம்"}
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-3 col-sm-12">
          <p className="text-dark float-right font-weight-bold">
            {locale === "en"
              ? "No Current Licence"
              : locale === "si"
              ? "දැනට බලපත්‍රයක් නොමැත"
              : "தற்போதைய அனுமதிப் பத்திரம் இல்லை."}
          </p>
        </div>
        <div className="col-md-8 col-sm-12">
          <p>
            {locale === "en"
              ? "No current Revenue Licence record exists"
              : locale === "si"
              ? "වර්තමාන බලපත්‍ර වාර්තා නොමැත"
              : "தற்போதைய அனுமதிப்பத்திர பதிவு இல்லை."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurrentLicenceStatusPopUp;
