/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-01-27 13:28:58
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:35:44
 */
import React, { useEffect, useState } from "react";
import Template from "../../../ui-components/templates/Template";
import {
  UICard,
  cardSize,
} from "../../../ui-components/ui-elements/common/BaseElements";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { Button, RoundedIconButton } from "../../../ui-components/ui-elements/form/Button";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import PasswordPolicyPopUp from "../reset-password/includes/PasswordPolicyPopUp";
import { useProfileInfoHook } from "../../../hooks/useProfileHook";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Row } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";
import { IntlProvider, FormattedMessage } from "react-intl";

const ChangePassword = () => {
  const messages = {
    en: {
      title: "Change Password",
      currentPass: "Current Password" + "\t",
      newPass: "New Password",
      confirmPass: "Confirm Password",
    },
    si: {
      title: "මුරපදය වෙනස් කරන්න",
      currentPass: "වර්තමාන මුරපදය",
      newPass: "නව මුරපදය",
      confirmPass: "මුරපදය තහවුරු කරන්න",
      confirm: "තහවුරු කරන්න",
    },
    ta: {
      title: "கடவுச்சொல் மாற்றம்",
      currentPass: "தற்போதைய கடவுச்சொல்",
      newPass: "புதிய கடவுச்சொல்",
      confirmPass: "கடவுச்சொல்லை உறுதிப்படுத்து",
      confirm: "கடவுச்சொல்லை",
    },
  };
  const locale = useRecoilValue(LanguageFilter);
  var buttonClickLocale;

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [cuPassError, setCuPassError] = useState();
  const [coPassError, setCoPassError] = useState();
  const [nPassError, setNPassError] = useState();
  const [currentPwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [reEnteredPwd, setReEnteredPwd] = useState("");

  const { changePassword, profileInfo, profileInfoError } =
    useProfileInfoHook();

  // let currentPwd = "";
  // let newPwd = "";
  // let reEnteredPwd = "";

  const handleCurrentPwd = () => {
    document.getElementById("current").addEventListener("keyup", function () {
      if (this.value.length <= 20) {
        setCurrentPwd(this.value);
        //currentPwd = this.value
      } else {
        this.value = this.value.slice(0, 20);
        setCurrentPwd(this.value);
        // currentPwd = this.value
      }
    });
  };

  const handleNewPwd = () => {
    document.getElementById("new").addEventListener("keyup", function () {
      if (this.value.length <= 20 && this.value !== currentPwd) {
        setNewPwd(this.value);
        setNPassError("");
        // newPwd = this.value;
      } else if (this.value.length > 20 && this.value !== currentPwd) {
        this.value = this.value.slice(0, 20);
        setNewPwd(this.value);
        setNPassError("");
        // newPwd = this.value;
      } else if (this.value.length <= 20 && this.value === currentPwd) {
        setNPassError(locale==="en"? "New Password cannot be the Current Password."
        :locale==="si"?"නව මුරපදය පවත්නා මුරපදය විය නොහැක.":
        "புதிய கடவுச் சொல்லானது நடைமுறை கடவுச் சொல்லாக மாட்டாது.");
      } else if (this.value.length > 20 && this.value === currentPwd) {
        setNPassError(locale==="en"? "New Password cannot be the Current Password."
        :locale==="si"?"නව මුරපදය පවත්නා මුරපදය විය නොහැක.":
        "புதிய கடவுச் சொல்லானது நடைமுறை கடவுச் சொல்லாக மாட்டாது.");
        this.value = this.value.slice(0, 20);
        setNewPwd(this.value);
      }
    });
  };

  const handleReEnteredPwd = (text) => {
    document.getElementById("confirm").addEventListener("keyup", function () {
      if (this.value.length <= 20) {
        setReEnteredPwd(this.value);
        // reEnteredPwd = this.value;
      } else {
        this.value = this.value.slice(0, 20);
        setReEnteredPwd(this.value);
        //reEnteredPwd = this.value;
      }
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onConfirmClicked = () => {
    buttonClickLocale = locale;
    if (currentPwd === "" || newPwd === "" || reEnteredPwd === "") {
      if (currentPwd === "" && newPwd !== "" && reEnteredPwd !== "") {
        setCuPassError(locale ==="en"?"Current Password is required.":
        locale==="si"?"වර්තමාන මුරපදය අවශ්‍ය වේ.":
        "தற்போதைய கடவுச்சொல் தேவைப்படுகின்றது.");
        setCoPassError("");
        setNPassError("");
      } else if (newPwd === "" && currentPwd !== "" && reEnteredPwd !== "") {
        setCoPassError("");
        setCuPassError("");
        setNPassError(locale==="en"?"New Password is required.":
        locale==="si"?"නව මුරපදය අවශ්‍ය වේ.":
        "புதிய கடவுச்சொல் தேவைப்படுகின்றது.");
      } else if (reEnteredPwd === "" && newPwd !== "" && currentPwd !== "") {
        setNPassError("");
        setCuPassError("");
        setCoPassError(locale==="en"?"Confirm Password is required.":
        locale==="si"?"මුරපදය තහවුරු කරන්න අවශ්‍ය වේ.":
        "கடவுச்சொல்லை உறுதிப்படுத்து தேவைப்படுகின்றது.");
      } else if (reEnteredPwd === "" && newPwd === "" && currentPwd !== "") {
        setCuPassError("");
        setCoPassError(locale==="en"?"Confirm Password is required.":
        locale==="si"?"මුරපදය තහවුරු කරන්න අවශ්‍ය වේ.":
        "கடவுச்சொல்லை உறுதிப்படுத்து தேவைப்படுகின்றது.");
        setNPassError(locale==="en"?"New Password is required.":
        locale==="si"?"නව මුරපදය අවශ්‍ය වේ.":
        "புதிய கடவுச்சொல் தேவைப்படுகின்றது.");
        // notifyError("Required fields cannot be empty.");
      } else if (reEnteredPwd === "" && newPwd !== "" && currentPwd === "") {
        setCuPassError(locale ==="en"?"Current Password is required.":
        locale==="si"?"වර්තමාන මුරපදය අවශ්‍ය වේ.":
        "தற்போதைய கடவுச்சொல் தேவைப்படுகின்றது.");
        setCoPassError(locale==="en"?"Confirm Password is required.":
        locale==="si"?"මුරපදය තහවුරු කරන්න අවශ්‍ය වේ.":
        "கடவுச்சொல்லை உறுதிப்படுத்து தேவைப்படுகின்றது.");
        setNPassError("");
      } else if (reEnteredPwd !== "" && newPwd === "" && currentPwd === "") {
        setCuPassError(locale ==="en"?"Current Password is required.":
        locale==="si"?"වර්තමාන මුරපදය අවශ්‍ය වේ.":
        "தற்போதைய கடவுச்சொல் தேவைப்படுகின்றது.");
        setCoPassError("");
        setNPassError(locale==="en"?"New Password is required.":
        locale==="si"?"නව මුරපදය අවශ්‍ය වේ.":
        "புதிய கடவுச்சொல் தேவைப்படுகின்றது.");
      } else if (reEnteredPwd === "" && newPwd === "" && currentPwd === "") {
        setCuPassError(locale ==="en"?"Current Password is required.":
        locale==="si"?"වර්තමාන මුරපදය අවශ්‍ය වේ.":
        "தற்போதைய கடவுச்சொல் தேவைப்படுகின்றது.");
        setCoPassError(locale==="en"?"Confirm Password is required.":
        locale==="si"?"මුරපදය තහවුරු කරන්න අවශ්‍ය වේ.":
        "கடவுச்சொல்லை உறுதிப்படுத்து தேவைப்படுகின்றது.");
        setNPassError(locale==="en"?"New Password is required.":
        locale==="si"?"නව මුරපදය අවශ්‍ය වේ.":
        "புதிய கடவுச்சொல் தேவைப்படுகின்றது.");
        // notifyError("Required fields cannot be empty.");
      }
    } else if (newPwd.length < 8 && reEnteredPwd.length < 8) {
      setNPassError(locale === "en"
      ? "The password entered does not meet the password policy" : 
      locale === "si" ? "ඇතුලත් කර ඇති මුරපදය මුරපද ප්‍රතිපත්තියට අනුකූල නොවේ." : 
      "பதிவு செய்யப்பட்ட கடவுச்சொல்லானது கடவுச் சொல் கொள்கையினை நிறைவேற்றவில்லை.");
      setCoPassError(locale === "en"
      ? "The password entered does not meet the password policy" : 
      locale === "si" ? "ඇතුලත් කර ඇති මුරපදය මුරපද ප්‍රතිපත්තියට අනුකූල නොවේ." : 
      "பதிவு செய்யப்பட்ட கடவுச்சொல்லானது கடவுச் சொல் கொள்கையினை நிறைவேற்றவில்லை.");
    } else if (newPwd.length >= 8 && reEnteredPwd.length < 8) {
      setNPassError("");
      setCoPassError(locale === "en"
      ? "The password entered does not meet the password policy" : 
      locale === "si" ? "ඇතුලත් කර ඇති මුරපදය මුරපද ප්‍රතිපත්තියට අනුකූල නොවේ." : 
      "பதிவு செய்யப்பட்ட கடவுச்சொல்லானது கடவுச் சொல் கொள்கையினை நிறைவேற்றவில்லை.");
    } else if (newPwd.length < 8 && reEnteredPwd.length >= 8) {
      setNPassError(locale === "en"
      ? "The password entered does not meet the password policy" : 
      locale === "si" ? "ඇතුලත් කර ඇති මුරපදය මුරපද ප්‍රතිපත්තියට අනුකූල නොවේ." : 
      "பதிவு செய்யப்பட்ட கடவுச்சொல்லானது கடவுச் சொல் கொள்கையினை நிறைவேற்றவில்லை.");
      setCoPassError("");
    } else {
      setCoPassError("");
      setCuPassError("");
      setNPassError("");

      if (reEnteredPwd === currentPwd) {
        setCoPassError(locale === "en" ? "New Password cannot be the Current Password." : locale === "si" ? 
        "නව මුරපදය පවත්නා මුරපදය විය නොහැක.": "புதிய கடவுச் சொல்லானது நடைமுறை கடவுச் சொல்லாக மாட்டாது.");
      } else {
        if (newPwd === reEnteredPwd) {
          setNPassError("");
          setCoPassError("");
          setCuPassError("");
          changePassword({
            current_password: currentPwd,
            new_password: newPwd,
            confirmation_password: reEnteredPwd,
          });
          // history.goBack();
        } else {
          // alert("Password mismatch");
          setCoPassError(locale === "en" ? "Confirm password does not match with new password" : locale === "si" ?
          "‘තහවුරු කරන ලද මුරපදය’ ‘නව මුරපදය’ හා නොගැළපේ." : " கடவுச்சொல்லை உறுதிப்படுத்து” என்பது “புதிய கடவுச்சொல்” உடன் இணங்கவில்லை. " );
        }
      }
    }
  };

  useEffect(() => {
      if(buttonClickLocale !== locale){
        onConfirmClicked();
      }else{
        console.log("buttonClickLocale" , buttonClickLocale)
      }
  })

  useEffect(() => {
    if (profileInfo !== undefined) {
      if (profileInfo !== null) {
        notifySuccess(locale ==="en" ? "Password successfully changed." : locale === "si" ? "මුරපදය සාර්ථකව වෙනස් කරන ලදි."
        : "கடவுச்சொல்லானது வெற்றிகரமாக மாற்றப்பட்டது.");
        document.getElementById("changePasswordForm").reset();
        const timer = setTimeout(() => {
          history.push("/");
        }, 1000);
        return () => clearTimeout(timer);
      }
    } else {
      if (
        profileInfoError === "Invalid password. Please enter the valid password"
      ) {
        setCuPassError(locale === "en"
        ? "Invalid password. Please enter the valid password" : locale === "si" ? "වලංගු නොවන මුරපදයක්. කරුණාකර වලංගු මුරපදය ඇතුළත් කරන්න." 
        : "தவறான கடவுச்சொல். சரியான கடவுச்சொல்லை உள்ளிடவும்.");
      } else {
        notifyError(profileInfoError);
      }
    }
    // else{
    //   alert("Something went wrong");
    // }
  }, [profileInfo, profileInfoError]);

  return (
    <Template activeHeader="changePassword" selectedLan={locale}>
      <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="uiCardWrapper" >
          <UICard
            title={
              <div style={{marginLeft:"-2%"}}>
              <FormattedMessage
                id="title"
                value={{ locale }}
              ></FormattedMessage>
              </div>
            }
            titleStyle="mainTxtHeading"
            cardSize={cardSize.LARGE}
          >
            <br></br>
            <div className="ml-2 pl-1">
              <div className="row">
                <div className="col-md-12 col-sm-12 text-danger">
                  <form id="changePasswordForm">
                    <InputBox
                      labelText={
                        <div className="row" style={{ marginTop:"8%",paddingBottom:"10%"}}>
                        <div className="text-dark mr-1">
                          <FormattedMessage
                            id="currentPass"
                            value={{ locale }}
                          ></FormattedMessage>
                        </div>
                        <div className="mr-1">*</div>
                        </div>
                      }
                      inputId="current"
                      inputName="current"
                      inputType="password"
                      labelPlacement={labelPositions.START}
                      inputError={cuPassError}
                      maxCharLength={20}
                      // isRequired={true}
                      isDisabled={false}
                      onChange={(text) => handleCurrentPwd(text)}
                    />

                    <InputBox
                      labelText={
                        <Row className="pl-3 ">
                        <div className="text-dark mr-1 " style={{ marginLeft:"-11%",paddingBottom:"8%"}}>
                          <FormattedMessage
                            id="newPass"
                            value={{ locale }}
                          ></FormattedMessage>
                          
                        </div>
                        <div className="mr-1">*</div>
                       
                        <div >
                          <RoundedIconButton
                            icon={"?"}
                            tooltip={"New Password Help"}
                            onClickFn={() => setOpen(true)}
                          />
                          </div>
                        </Row>
                      }
                      inputId="new"
                      inputName="new"
                      maxCharLength={20}
                      inputType="password"
                      labelPlacement={labelPositions.START}
                      inputError={nPassError}
                      isRequired={false}
                      isDisabled={false}
                      // endIcon={<i className="mdi mdi-help" />}
                      onChange={(text) => handleNewPwd(text)}
                      endIconOnClickFn={() => setOpen(true)}
                    />
                    {open && (
                      <Popup
                        title={
                          locale === "en"
                            ? "Password Policy"
                            : locale === "si"
                            ? "මුරපද ප්‍රතිපත්තිය"
                            : "கடவுச்சொல் கொள்கை"
                        }
                        content={<PasswordPolicyPopUp />}
                        isOpen={open}
                        handleClose={() => setOpen(false)}
                      />
                    )}
                    <InputBox
                   
                      labelText={
                        <div className="row" style={{ marginTop:"8%",paddingBottom:"12%"}}>
                        <div className="text-dark mr-1">
                          <FormattedMessage
                            id="confirmPass"
                            value={{ locale }}
                          ></FormattedMessage>
                        </div>
                        <div className="mr-1">*</div>
                        </div>
                      }
                      inputId="confirm"
                      inputName="confirm"
                      inputType="password"
                      labelPlacement={labelPositions.START}
                      onChange={(text) => handleReEnteredPwd(text)}
                      inputError={coPassError}
                      maxCharLength={20}
                      // isRequired={true}
                      isDisabled={false}
                    />
                    
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 ml-2"></div>
              <div className="col-md-5">
                <Button
                  btnText={
                    locale === "si"
                      ? "තහවුරු කරන්න"
                      : locale === "en"
                      ? "Confirm"
                      : "கடவுச்சொல்லை"
                  }
                  btnType={"submit"}
                  onClick={() => onConfirmClicked()}
                />
              </div>
            </div>
          </UICard>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </IntlProvider>
    </Template>
  );
};

export default ChangePassword;
