/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-13 11:59:57
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 09:55:45
 */
import React from "react";
import ReactDOM from "react-dom";
import App from "./bootstrap/App";
import "./style/app.scss";
import "react-notifications-component/dist/theme.css";
import * as serviceWorker from "./serviceWorker";
import { ReactNotifications } from "react-notifications-component";

ReactDOM.render(
  <div className="app-container">
    {/* <ReactNotifications /> */}
    <App />
  </div>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
