/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:23:37
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:53:59
 */
import { SelectBox } from "../../../../ui-components/ui-elements/form/SelectBox";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../../atom";

// const inputData = [
//   {
//     id: 1,
//     value: "option 1",
//   },
//   {
//     id: 2,
//     value: "option 2",
//   },
//   {
//     id: 3,
//     value: "option 3",
//   },
// ];

const VehicleFitnessTestingCompaniesFilterComponent = ({
  inputData,
  onProviceChanged,
}) => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <div className="row">
      <div className="col-md-11 ml-5">
        <SelectBox
          labelText={
            locale === "en" ? "Province" : locale === "si" ? "පළාත" : "மாகாணம்"
          }
          dataList={inputData}
          emptySelectOptionTxt={
            locale === "en" ? "Select" : locale === "si" ? "තෝරන්න" : "நிரப்பு"
          }
          onChangeFn={onProviceChanged}
          keyName="code"
          valueName="name"
        />
      </div>
    </div>
  );
};

export default VehicleFitnessTestingCompaniesFilterComponent;
