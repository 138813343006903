/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-21 17:15:29
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:14:46
 */
import React from "react";
import { RoundedIconButton } from "./Button";

const emptyFn = () => undefined;

const labelPositions = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
  START: "START",
  END: "END",
};

const Label = ({
  className = "",
  forId = "",
  labelText = "",
  isBold = false,
  endIcon = null,
  endIconOnClickFn = emptyFn,
  endIconTooltip = "",
  required = false,
  lablePosition = labelPositions.START,
}) => {
  const formatedLabel = isBold ? (
    <div className="row">
      <strong>{labelText} </strong> {required ? "*" : " "}
    </div>
  ) : (
    <div className="row">
      {labelText} {required ? "*" : " "}
    </div>
  );
  return (
    <div
      className="row"
      style={{
        marginRight: "5px",
        marginLeft: "20px",
        alignItems: "center",
        // marginTop: "5px",
        fontSize: "15px"
      }}
    >
      <label className={className} htmlFor={forId}>
        {formatedLabel}
      </label>
      {endIcon && (
        <div className="label-outside-icon">
          <RoundedIconButton
            icon={endIcon}
            onClickFn={endIconOnClickFn}
            tooltip={endIconTooltip}
          />
        </div>
      )}
    </div>
  );
};

export { Label };
