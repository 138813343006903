/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-21 12:56:28
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-11 15:14:22
 */

import { Fragment } from "react";
import { Label } from "./Label";

const emptyFn = () => undefined;

const labelPositions = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
  START: "START",
  END: "END",
  LEFT: "LEFT",
};

const InputLabelWrapper = ({
  uiType = "",
  inputName = "",
  isRequired = false,
  labelText = "",
  className = "",
  forId = "",
  labelPlacement = labelPositions.START,
  children = {},
  screenSize = "sm",
  labelColSize = labelText ? 3 : 0,
  inputColSize = 0,
  isRightAlignLabel = false,
  endIcon = null,
  endIconOnClickFn = emptyFn,
  endIconTooltip = "",
}) => {
  return labelPlacement === labelPositions.TOP ? (
    <Fragment>
      <div>
        <Label
          className={className}
          forId={forId}
          labelText={labelText}
          endIcon={endIcon}
          endIconOnClickFn={endIconOnClickFn}
          endIconTooltip={endIconTooltip}
        />
      </div>{" "}
      <div>{children}</div>
    </Fragment>
  ) : labelPlacement === labelPositions.END ? (
    <div className="row">
      <div
        className={
          "col-" +
          screenSize +
          "-" +
          (inputColSize ? inputColSize : 12 - labelColSize).toString()
        }
      >
        {children}
      </div>{" "}
      <div className={"col-" + screenSize + "-" + labelColSize.toString()}>
        <div className={isRightAlignLabel ? "rightAlignLabel" : ""}>
          <Label
            className={className}
            forId={forId}
            labelText={labelText}
            endIcon={endIcon}
            endIconOnClickFn={endIconOnClickFn}
            endIconTooltip={endIconTooltip}
          />
        </div>
      </div>
    </div>
  ) : labelPlacement === labelPositions.BOTTOM ? (
    <Fragment>
      <div>{children}</div>{" "}
      <div>
        <Label
          className={className}
          forId={forId}
          labelText={labelText}
          endIcon={endIcon}
          endIconOnClickFn={endIconOnClickFn}
          endIconTooltip={endIconTooltip}
        />
      </div>
    </Fragment>
  ) : (
    // default Start
    <div className="row" style={{ alignItems: "center" }}>
      <div className={"col-" + screenSize + "-" + labelColSize.toString()}>
        <div className={isRightAlignLabel ? "rightAlignLabel" : ""}>
          <Label
            className={className}
            forId={forId}
            required={isRequired}
            labelText={labelText}
            endIcon={endIcon}
            endIconOnClickFn={endIconOnClickFn}
            endIconTooltip={endIconTooltip}
          />
        </div>
      </div>
      <div
        className={
          "col-" +
          screenSize +
          "-" +
          (inputColSize ? inputColSize : 12 - labelColSize).toString()
        }
      >
        {children}
      </div>
    </div>
  );
};

export { InputLabelWrapper, labelPositions };
