/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2019-10-08 14:09:14
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 14:51:46
 */
import Keycloak from "keycloak-js";

import {
  checkUserINLocalStorage,
  setToLocalStorage,
} from "../../../helpers/common-helpers/manageStorage.helpers";
import {
  authServiceClient,
  authServiceClientSecret,
  authTokenStorageKey,
} from "../../../config/core.config";
import { axiosWrapper } from "../../../helpers/common-helpers/callApi.helpers";
import { _get } from "../../../helpers/common-helpers/lodash.wrappers";
import { userAuthServiceAPI, userInfoAPI } from "../../../config/apiUrl.config";

/**
 * @author: Nisal Madusanka(EruliaF)
 * @description : check user authorized in globally
 * @param {Object} authStatus
 * @param {Object} authAction
 * @param {Function} cb callback function
 */
const isAuthorizedINGlobalState = (authStatus, authAction, cb) => {
  if (
    authStatus.isAuthenticated === true &&
    authStatus.accessToken !== null &&
    authStatus.refreshToken !== null
  ) {
    cb(null, true);
  } else {
    connecToLocalStorage(authStatus, authAction, cb);
  }
};

/**
 * @author: Nisal Madusanka(EruliaF)
 * @description : check user data avilable or not in Local Storage
 * @param {Object} authStatus
 * @param {Object} authAction
 * @param {Function} cb callback function
 */
const connecToLocalStorage = (authStatus, authAction, cb) => {
  const userObject = checkUserINLocalStorage();
  if (userObject.status === true) {
    authAction.setTokensFn(userObject.result);
    cb(null, true);
  } else {
    setUserInfo(authStatus, authAction, cb);
  }
};

const logout = () => {};

const setUserInfo = (authStatus, authAction, cb) => {
  const config = {
    realm: "erl-web-user",
    url: `${userAuthServiceAPI.url}`,
    "ssl-required": "external",
    clientId: `${authServiceClient}`,
    clientSecret: `${authServiceClientSecret}`,
    onLoad: "login-required",
    "enable-cors": true,
    grantType: "password",
    "confidential-port": 0,
  };

  const keycloak = new Keycloak(config);

  keycloak
    .init({ onLoad: "login-required", promiseType: "native" })
    .then((authenticated) => {
      if (authenticated === true) {
        let tokens = {
          accessToken: keycloak.token,
          refreshToken: keycloak.refreshToken,
          keycloakObject: keycloak,
        };

        axiosWrapper(
          userInfoAPI.url,
          "get",
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${keycloak.token}`,
            },
          },
          {},
          false,
          (error, result) => {
            if (error) {
              cb(null, false);
            }

            tokens["id"] = _get(result, "data.sub", null);
            tokens["name"] = _get(result, "data.user_name", null);
            tokens["display_name"] = _get(result, "data.display_name", null);
            tokens["email"] = _get(result, "data.email", null);
            tokens["phone"] = _get(result, "data.phone", null);
            tokens["social_login"] = _get(result, "data.social_login", null);

            tokens["keycloak"] = keycloak;

            setToLocalStorage(authTokenStorageKey, tokens);
            authAction.setTokensFn(tokens);

            cb(null, true);
          }
        );
      } else {
        cb(null, false);
      }
    })
    .catch((error) => {
      cb(null, false);
    });
};

export { isAuthorizedINGlobalState, setUserInfo };
