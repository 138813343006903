import React, { Fragment } from "react";
import { permissions } from "../../../config/permission.config";
import authorized from "../../hoc-middlewares/Authorized";
import guest from "../../hoc-middlewares/Guest";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { UserProfile } from "./pages/UserProfile";
import { UserProfileEdit } from "./pages/UserProfileEdit";

const UserProfileRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/user-profile"
        routeKey={"user"}
        component={authorized(UserProfile)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/user-edit"
        routeKey={"userEdit"}
        component={authorized(UserProfileEdit)}
        routePermissions={permissions.NONE.permissions}
      />
    </Fragment>
  );
};

export default UserProfileRoutes;
