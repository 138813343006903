/*
 * @Author: Jayani Nawarathna
 * @Date: 2021-02-02 11:09:32
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-02-02 15:13:17
 */
import React from "react";

import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import StatusCheck from "./pages/StatusCheck";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import authorized from "../../hoc-middlewares/Authorized";

const StatusCheckRoutes = () => {
  return (
    <Navigate
      exact={true}
      path="/status-check"
      routeKey={"statuscheck"}
      component={authorized(StatusCheck)}
      routePermissions={permissions.NONE.permissions}
    />
  );
};

export default StatusCheckRoutes;
