/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-21 15:02:30
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-07-01 11:40:53
 */

import { apiBaseURL, mockApiBaseURL, adminBffBaseURL } from "./core.config";

export const userAuthServiceAPI = {
  url: `${apiBaseURL}auth`,
  key: "userAuthServiceAPIKey",
};

export const userInfoAPI = {
  url: `${apiBaseURL}api/bff-web/user-service/users/user-login`,
  key: "userInfoAPIKEY",
};

export const refreshTokenAPI = {
  url: `${apiBaseURL}api/bff-web/user-service/users/token-refresh`,
  key: "refreshTokenAPIKey",
};

export const paginAPI = {
  url: `${mockApiBaseURL}api/master/dummies/paging`,
  key: "paginResultKEY",
};

export const supInsCompaniesAPI = {
  url: `${apiBaseURL}api/bff-web/organization-service/insurance-companies/web`,
  key: "supInsCompaniesResultKEY",
};

export const vetCompaniesAPI = {
  url: `${apiBaseURL}api/bff-web/organization-service/vet-companies/web`,
  key: "vetCompanisResultKEY",
};

export const vftComapniesAPI = {
  url: `${apiBaseURL}api/bff-web/organization-service/vft-companies/web`,
  key: "vftCompaniesResultKEY",
};

export const newsAPI = {
  url: `${apiBaseURL}api/bff-web/web-service/web-portal-news/web`,
  key: "newsResultKEY",
};

export const authorityAPI = {
  url: `${apiBaseURL}api/bff-web/organization-service/authorities`,
  key: "authorityResultKEY",
};

export const provinceAPI = {
  url: `${apiBaseURL}api/bff-web/organization-service/provinces/list`,
  key: "districtResultKEY",
};

export const districtsAPI = {
  url: `${apiBaseURL}api/bff-web/organization-service/districts/list`,
  key: "districtResultKEY",
};

export const contactUsAPI = {
  url: `${apiBaseURL}api/bff-web/organization-service/province-offices/list`,
  key: "authorityResultKEY",
};

export const vehicleInfoAPI = {
  url: `${apiBaseURL}api/bff-web/licence-service/vehicle`,
  key: "vehicleInfoResultKEY",
};

export const profileInfoAPI = {
  url: `${apiBaseURL}api/bff-web/user-service/users`,
  key: "profileInfoResultKEY",
};

export const statusCheckAPI = {
  url: `${apiBaseURL}api/bff-web/licence-service/status-check/list`,
  key: "statusCheckResultKEy",
};

export const logoutAPI = {
  url: `${apiBaseURL}api/bff-web/user-service/users`,
  key: "logoutResultKEy",
};
