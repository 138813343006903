/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-21 13:04:27
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-05 17:08:38
 */
import React, { memo } from "react";
import PropTypes from "prop-types";
import { labelPositions } from "./InputLabel";
import { Input } from "./InputBox";
import { CheckLabelWrapper } from "./CheckLabel";
import { FormControlWrapper } from "./form-includes/FormCoreIncludes";
import { formCacheLevel } from "../../../../config/template.config";
import { useAPIBaseInput } from "../../../hooks/common-hooks/useFormInputState.hook";

const emptyFn = () => undefined;

const RadioButton = ({
  inputError = "",
  helperText = "",
  isRequired = false,
  isDisabled = false,
  inputId = "",
  inputName = "",
  inputValue = "",
  labelText = "",
  onChangeFn = emptyFn,
  isChecked = false,
  screenSize,
  labelPlacement = labelPositions.START,
}) => {
  return (
    <FormControlWrapper
      inputError={inputError}
      helperText={helperText}
      className={"form-check"}
    >
      <CheckLabelWrapper
        inputName={inputName}
        isRequired={isRequired}
        labelPlacement={labelPlacement}
        labelText={labelText}
        screenSize={screenSize}
      >
        <Input
          className={"form-check-input"}
          inputId={inputId}
          inputName={inputName}
          inputType="radio"
          inputValue={inputValue}
          isDisabled={isDisabled}
          isRequired={isRequired}
          onChangeTxtFn={onChangeFn}
          isChecked={isChecked}
        />
      </CheckLabelWrapper>
    </FormControlWrapper>
  );
};

RadioButton.propTypes = {
  /** Is full width set or not */
  // isFullWidth: PropTypes.bool,
  /** In out value */
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** In out value name */
  inputName: PropTypes.string,
  /** Input field error message */
  inputError: PropTypes.string,
  /** Input field helper text */
  helperText: PropTypes.string,
  /** Lable text */
  lableText: PropTypes.string,
  /** Array item key name */
  keyName: PropTypes.string,
  /** Array item value name */
  valueName: PropTypes.string,
  /** Radio buttons data */
  dataList: PropTypes.array,
  /** Tooltip description */
  fieldDescribe: PropTypes.string,
  /** Is inline or not */
  isInlineTrue: PropTypes.bool,
  /** Is required or not */
  isRequired: PropTypes.bool,
  /** Onclick event function */
  onChangeFn: PropTypes.func,
  /** Is disabled or not */
  isDisabled: PropTypes.bool,
  /** Input Id */
  inputId: PropTypes.string,
  /** Is checked or not */
  isChecked: PropTypes.bool,
  /** Screen size */
  screenSize: PropTypes.string,
  /** Label placement */
  labelPlacement: PropTypes.string,
};

/**
 * memo render
 * @param {Object} prevProps
 * @param {Object} nextProps
 */
const areEqual = (prevProps, nextProps) => {
  if (
    nextProps.cacheLevel === formCacheLevel.none ||
    nextProps.setCache === false
  ) {
    return false;
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnFormGroupChange) {
    return (
      // prevProps.isFullWidth === nextProps.isFullWidth &&
      prevProps.labelText === nextProps.labelText &&
      prevProps.responseUpdateStatus === nextProps.responseUpdateStatus &&
      prevProps.inputError === nextProps.inputError &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isRequired === nextProps.isRequired &&
      prevProps.inputValue === nextProps.inputValue &&
      prevProps.updateStatus === nextProps.updateStatus &&
      prevProps.dataList.length === nextProps.dataList.length
    );
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnIndividual) {
    return (
      // prevProps.isFullWidth === nextProps.isFullWidth &&
      prevProps.inputError === nextProps.inputError &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isRequired === nextProps.isRequired &&
      prevProps.labelText === nextProps.labelText &&
      prevProps.inputValue === nextProps.inputValue &&
      prevProps.responseUpdateStatus === nextProps.responseUpdateStatus &&
      prevProps.dataList.length === nextProps.dataList.length
    );
  }
};

const RadioButtonMemo = memo(RadioButton, areEqual);

const RadioButtonWithState = ({
  // isFullWidth = false,
  inputValue = "",
  inputName = "",
  labelText = "",
  inputError = "",
  helperText = "",
  keyName = "value",
  valueName = "label",
  isInlineTrue = true,
  isRequired = false,
  fieldDescribe = "",
  dataList = [],
  formGroupName = "",
  inputStatePath = "",
  apiUrl = "",
  apiStateKey = "",
  setCache = true,
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  onChangeFn = emptyFn,
  isDisabled = false,
  inputId = "",
  isChecked = false,
  screenSize,
  labelPlacement,
}) => {
  const [
    currentValue,
    currentError,
    updateStatus,
    responseUpdateStatus,
    optionList,
    handleOnChangeFn,
  ] = useAPIBaseInput(
    apiUrl,
    apiStateKey,
    dataList,
    inputStatePath,
    formGroupName,
    inputName,
    inputValue,
    onChangeFn
  );

  return (
    <RadioButtonMemo
      // isFullWidth={isFullWidth}
      inputName={inputName}
      inputValue={currentValue}
      labelText={labelText}
      inputError={currentError}
      helperText={helperText}
      keyName={keyName}
      valueName={valueName}
      isInlineTrue={isInlineTrue}
      isRequired={isRequired}
      fieldDescribe={fieldDescribe}
      dataList={optionList}
      onChangeFn={(eventData) => {
        handleOnChangeFn(eventData);
      }}
      cacheLevel={cacheLevel}
      updateStatus={updateStatus}
      responseUpdateStatus={responseUpdateStatus}
      setCache={setCache}
      isDisabled={isDisabled}
      labelPlacement={labelPlacement}
    />
  );
};

RadioButtonWithState.propTypes = {
  /** Is full width set or not */
  // isFullWidth: PropTypes.bool,
  /** In out value */
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** In out value name */
  inputName: PropTypes.string,
  /** Input field error message */
  inputError: PropTypes.string,
  /** Input field helper text */
  helperText: PropTypes.string,
  /** Lable text */
  lableText: PropTypes.string,
  /** Array item key name */
  keyName: PropTypes.string,
  /** Array item value name */
  valueName: PropTypes.string,
  /** Radio buttons data */
  dataList: PropTypes.array,
  /** Tooltip description */
  fieldDescribe: PropTypes.string,
  /** Is inline or not */
  isInlineTrue: PropTypes.bool,
  /** Is required or not */
  isRequired: PropTypes.bool,
  /** Form group name */
  formGroupName: PropTypes.string,
  /** Input element state avilable path(use for manage complex objects) */
  inputStatePath: PropTypes.string,
  /** Onclick event function */
  onChangeFn: PropTypes.func,
  /** Is disabled or not */
  isDisabled: PropTypes.bool,
  /** Input Id */
  inputId: PropTypes.string,
  /** Is checked or not */
  isChecked: PropTypes.bool,
  /** Screen size */
  screenSize: PropTypes.string,
  /** Label placement */
  labelPlacement: PropTypes.string,
};

export { RadioButton, RadioButtonWithState };
