import { useCallback, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import _ from "lodash";

import {
  LanguageFilter,
  paymentCompleteUrl,
  paymentCompleteRequest,
  paymentCompleteResponse,
  paymentConfirmFlag,
} from "../../atom";

import { vehicleInfoAPI } from "../../config/apiUrl.config";
import { chargeInfoAPI } from "../../config/apiUrl.config";
import { callApi } from "../../helpers/common-helpers/callApi.helpers";

export const useRevenueLicenseHook = () => {
  const locale = useRecoilValue(LanguageFilter);
  const history = useHistory();

  const [vehicleInfo, setVehicleInfo] = useState();
  const [chargeInfo, setChargeInfo] = useState();
  const [deliveryInfo, setDeliveryInfo] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [makePaymentInfo, setMakePaymentInfo] = useState();
  const [errorInfo, setErrorInfo] = useState();
  // const [paymentUrl, setPaymentUrl] = useState();
  const [PaymentUrl, setPaymentUrl] = useRecoilState(paymentCompleteUrl);
  const [PaymentRequest, setPaymentRequest] = useRecoilState(
    paymentCompleteRequest
  );
  const [PaymentRespose, setPaymentRespose] = useRecoilState(
    paymentCompleteResponse
  );
  const [PaymentConfirmFlag, setPaymentConfirmFlag] =
    useRecoilState(paymentConfirmFlag);

  const getVehicleInfo = async (vehicleNumber, chassiNumber) => {
    callApi(
      `${vehicleInfoAPI.url}?vehicle_no=${vehicleNumber}&chassis_no_last_six=${chassiNumber}&language=${locale}`
    )
      .method("get")
      .headers(true)
      .send((error, result) => {
        if (result) {
          setVehicleInfo(result.data.data);
        } else if (error) {
          if (_.isEmpty(error?.data?.errors?.details)) {
            setErrorInfo("Something went wrong");
          } else {
            setErrorInfo(error?.data?.errors?.details[0]?.message);
            setVehicleInfo();
          }
        }
      });
  };

  const getChargeInfo = async (vehicleNum, refNum) => {
    callApi(
      `${vehicleInfoAPI.url}/charge?vehicle_no=${vehicleNum}&temp_licence_reference=${refNum}&language=${locale}`
    )
      .method("get")
      .headers(true)
      .send((error, result) => {
        if (result) {
          setChargeInfo(result.data.data);
          setDeliveryInfo(result.data.data.delivery_methods);
          setPaymentInfo(result.data.data.payment_methods);
        }
      });
  };

  const makePayment = async (body, paymentMethod) => {
    setPaymentRequest(body);

    callApi(`${vehicleInfoAPI.url}/confirm-pay`)
      .method("post")
      .headers(true)
      .body(body)
      .send((error, result) => {
        if (result) {
          setPaymentRespose(result);
          setMakePaymentInfo(result);
          if (paymentMethod === "SMV") {
            setPaymentConfirmFlag(true);
            setPaymentUrl(
              result.data.data.url +
                "&MD=" +
                encodeURIComponent(result.data.data.md) +
                "&PID=" +
                encodeURIComponent(result.data.data.pid) +
                "&PRN=" +
                encodeURIComponent(result.data.data.prn) +
                "&AMT=" +
                encodeURIComponent(result.data.data.amt) +
                "&CRN=" +
                encodeURIComponent(result.data.data.crn) +
                "&RU=" +
                encodeURIComponent(result.data.data.ru)
            );
            // setPaymentUrl(result.data.data.url);
          } else if (paymentMethod === "QR") {
            setPaymentUrl(result.data.data.url);
          } else if (paymentMethod === "LGP") {
            console.log("New URI", encodeURIComponent(result.data.data.client_payment_request));
            setPaymentUrl(
              result.data.data.url +
                "?clientPaymentRequest=" +
                encodeURIComponent(result.data.data.client_payment_request)
            );
          }
          // if(paymentMethod === "LGP"){setPaymentUrl(result.data.data.url+"?clientPaymentRequest=1tW1xpZCSeJa5pyFBIlFHrMC5qq7LWdMPkA6S5BnEuP//p12YXeM2ejGsJQIiHWpQ4jc8WMKpcpEKtL3Q0+d2xIJrH4LS0aR4M22zZ/f0NhUFHD0P4eXPSoZcsYH1qo3ER5uxevUpfUOA8bNR39XxD44+ROHzRIC8mRPH5knSVKb++2jbc2pfLc3iQVCVe3aHO6AO8K4mI5i/8h0wuwgh0AIcfHUcvZj741pFvDvnSPRNjjzmjgu77eI+4Cwo5MlNPHi7PPNCXOKFCCQ/Xo4iBGfCS9WlP0g5AMz9/5I2umNg5GDvDp5CxVPKvR5ohKJAjTxwVnA95tJjddsIj4hBa0pgEv1+apEaoYmANKidXw5v2weurHlXrz8AcQME7g5QXKzWRloKBzxqfPwEJ7kiB9WF71TXBNBP9kptHQBdrKtAiwgHNjlxC9YqQ1eBGmSMjSw2V/xJiKZpal4ScWPI7ZblrqQvtdJUQDubWoLsYTr5VHg5bSBhyuu3RF3M/fKdrtrkFMMpu2gLHV2VS+8nC5fczV8I6Hn7h8kY1FaA+SWF0lx1reeqUNzsuutNfY7TCtIAqe1xvni4ghaEzEThdP2U0Z3/efrpBidGXiy5Mat9+13Qofdka79+JDBBShF4uTuUtJxY62jqd+5ybnkxACxLaRi7SFpJ7F31l3Hfoku1HVn0Es949YlZk2Fy32C687VmaX6nyYbxxCFa0XWIPKDArlHoCDZnyAKp6/83o13j4DRUSUnDmGJbrzsz+PMu7Pa60jQrhBiFW6wbk/6HZHpnaljNZNJPcXtWZfb6Ev0BByVoCVRKKH4bLgQ/zOrDUqOfKbdUNMW7f0jJlC/HzVHlXG4fQRxzmQk+uB44iQqgnCATPSyD4g==Dq4Guz6tXJooUQX8adqIzg82OqvMBc6F9RhLhMFnJx6xgTphkYV3rVtp1JxwpZTEKIZHR4QOrH/3Uf2Pzn8PE3f/QST+0q9vhJd0FvdACc3c8oksuN8+osc7ji5/4PyPUNzZN4r5dylG9EOg68KhtrOJHWWJBTyNUC0X5672KE7KWfZylrCh7LUIDKZ/SgGoaowycepBRJ+2MogvD1s+ZvcpvP5Pc/8MzsBvsbNHVa9jr5YPD0ACO7l8EBtRRaH8OjNabGi/XB2dOWnzpoHGsJvMv/yV73l+tJKzmrJTH5+bkCo/tVRGEvBd/kCyfnfk2ixAxFAFICZ8chxJxZU17A==");}
          // setcipheredRequest(result.data.data.url)
        }
      });
  };

  return {
    chargeInfo,
    deliveryInfo,
    paymentInfo,
    vehicleInfo,
    errorInfo,
    setErrorInfo,
    getVehicleInfo,
    getChargeInfo,
    makePayment,
    makePaymentInfo,
  };
};
