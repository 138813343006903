/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-13 11:50:35
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-01-29 16:21:48
 */
import React, { Fragment } from "react";

import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import TestComponent from "./pages/TestComponent";
import PopUpComponent from "./pages/PopUpComponent";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";

const TestComponentRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/test-component"
        routeKey={"test"}
        component={guest(TestComponent)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/test-popup"
        routeKey={"popup"}
        component={guest(PopUpComponent)}
        routePermissions={permissions.NONE.permissions}
      />
    </Fragment>
  );
};

export default TestComponentRoutes;
