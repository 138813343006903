/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-01 16:37:50
 * @Last Modified by: Nisal Madusanka(EruliaF)EruliaF)
 * @Last Modified time: 2021-02-15 14:35:13
 */

import { FilterWrapper } from "./FilterWrapper";
import { TableWrapper } from "./TableWrapper";

const applicationStatus = {
  PENDING: "Pending",
  ISSUED: "Issued",
};

const DataTable = ({
  tableHeaderList = [],
  tableBodyList = [],
  filterComponent = null,
  isSetSearch = false,
  isSetReset = false,
  isSetStatus = false,
  statusIcon = "",
  dataTableWrapperStyle = "",
  onSearchClicked,
  onResetClicked,
}) => {
  // console.log("Data :", tableBodyList);

  return (
    <div className={`baseDataTableWrapper ${dataTableWrapperStyle}`}>
      <FilterWrapper
        isSetSearch={isSetSearch}
        isSetReset={isSetReset}
        handleSearch={onSearchClicked}
        handleReset={onResetClicked}
        filterElements={filterComponent}
      />
      <br></br>
      <br></br>
      <br></br>
      <TableWrapper
        tableHeaderList={tableHeaderList}
        tableBodyList={tableBodyList}
        isSetStatus={isSetStatus}
        statusIcon={statusIcon}
      />
    </div>
  );
};

export { DataTable, applicationStatus };
