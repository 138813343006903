/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 16:56:11
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-01-13 17:06:46
 */

import React from "react";

import { ContextProvider } from "./Providers";
import Routes from "./Routers";

import { RecoilRoot } from "recoil";
import DocumentMeta from "react-document-meta";

const App = () => {
  return (
    <RecoilRoot>
      <ContextProvider>
        <Routes />
      </ContextProvider>
    </RecoilRoot>
  );
};

export default App;
