import { useCallback, useContext, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { LanguageFilter } from "../../atom";

import {
  profileInfoAPI,
  logoutAPI,
  userInfoAPI,
} from "../../config/apiUrl.config";
import { chargeInfoAPI } from "../../config/apiUrl.config";
import {
  axiosWrapper,
  callApi,
} from "../../helpers/common-helpers/callApi.helpers";
import Keycloak from "keycloak-js";
import {
  getFromLocalStorage,
  reomveFromLocalStorage,
  setToLocalStorage,
} from "../../helpers/common-helpers/manageStorage.helpers";
import { authTokenStorageKey } from "../../config/core.config";
import { AuthContext } from "../modules/core/context-providers/AuthContext.provider";
import { _get } from "../../helpers/common-helpers/lodash.wrappers";
import _ from "lodash";

export const useProfileInfoHook = () => {
  const locale = useRecoilValue(LanguageFilter);

  const [profileInfo, setProfileInfo] = useState();
  const [authAction] = useContext(AuthContext);
  const [otpResult, setOtpResult] = useState();
  const [otpVerifyResult, setOtpVerifyResult] = useState();
  const [isVerifyResult, setIsVerifyResult] = useState(false);
  const [errorInfo, setErrorInfo] = useState();
  const [profileInfoError, setprofileInfoError] = useState();

  const getProfileInfo = async () => {
    callApi(`${profileInfoAPI.url}/public-user`)
      .method("get")
      .headers(true)
      .send((error, result) => {
        if (result) {
          setProfileInfo(result.data.data);
        }
      });
  };

  const updateProfileInfo = async (requestBody) => {
    callApi(`${profileInfoAPI.url}/public-user`)
      .method("put")
      .headers(true)
      .body(requestBody)
      .send((error, result) => {
        if (result) {
          setProfileInfo(result.data.data);

          let tokens = {
            accessToken: getFromLocalStorage(authTokenStorageKey)?.accessToken,
            refreshToken:
              getFromLocalStorage(authTokenStorageKey)?.refreshToken,
            keycloakObject:
              getFromLocalStorage(authTokenStorageKey)?.keycloakObject,
          };

          axiosWrapper(
            userInfoAPI.url,
            "get",
            {
              headers: {
                accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${
                  getFromLocalStorage(authTokenStorageKey)?.accessToken
                }`,
              },
            },
            {},
            false,
            (error, result) => {
              tokens["id"] = _get(result, "data.sub", null);
              tokens["name"] = _get(result, "data.user_name", null);
              tokens["display_name"] = _get(result, "data.display_name", null);
              tokens["email"] = _get(result, "data.email", null);
              tokens["phone"] = _get(result, "data.phone", null);
              tokens["social_login"] = _get(result, "data.social_login", null);

              reomveFromLocalStorage(authTokenStorageKey);
              setToLocalStorage(authTokenStorageKey, tokens);
            }
          );
        } else {
          setprofileInfoError(error.data.errors.details[0].message);
        }
      });
  };

  const changePassword = async (requestBody) => {
    callApi(`${profileInfoAPI.url}/change-password`)
      .method("put")
      .headers(true)
      .body(requestBody)
      .send((error, result) => {
        if (result) {
          setProfileInfo(result.data.data);
        } else {
          if (_.isEmpty(error?.data?.errors?.details)) {
            setprofileInfoError("Something went wrong");
          } else {
            setprofileInfoError(error?.data?.errors?.details[0]?.message);
          }
        }
      });
  };

  const sendOtp = async (requestBody) => {
    callApi(`${profileInfoAPI.url}/send-otp`)
      .method("post")
      .headers(true)
      .body(requestBody)
      .send((error, result) => {
        if (result) {
          setOtpResult(result.data.data);
        } else {
          setErrorInfo(error.data.errors.details[0].message);
        }
      });
  };

  const verifyOtp = async (requestBody) => {
    callApi(`${profileInfoAPI.url}/verify-otp`)
      .method("put")
      .headers(true)
      .body(requestBody)
      .send((error, result) => {
        if (result) {
          setOtpVerifyResult(result.data.data);
          setIsVerifyResult(true)
        } else {
          setprofileInfoError(error.data.errors.details[0].message);
          setIsVerifyResult(false)
        }
      });
  };

  return {
    profileInfo,
    getProfileInfo,
    updateProfileInfo,
    profileInfoError,
    changePassword,
    sendOtp,
    otpResult,
    verifyOtp,
    otpVerifyResult,
    errorInfo,
    isVerifyResult,
  };
};
