/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-19 14:20:09
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-08 12:56:27
 */
import React, { memo } from "react";
import {
  FormControlWrapper,
  FormHelperTextWrapper,
} from "./form-includes/FormCoreIncludes";
import { InputLabelWrapper, labelPositions } from "./InputLabel";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import PropTypes from "prop-types";
import {
  defaultTemplateType,
  formCacheLevel,
} from "../../../../config/template.config";
import { useAPIBaseInput } from "../../../hooks/common-hooks/useFormInputState.hook";
import { useRecoilValue } from "recoil";
import { LanguageFilter } from "../../../../atom";

const emptyFn = (...para) => undefined;

const SelectBox = ({
  labelText = "",
  inputName = "",
  // inputValue = "",
  isRightAlignLabel = false,
  dataList = [],
  labelPlacement = labelPositions.LEFT,
  inputError = "",
  helperText = "",
  isRequired = false,
  isDisabled = false,
  onChangeFn = emptyFn,
  onClickFn = emptyFn,
  emptySelectOptionTxt = "",
  keyName = "id",
  valueName = "value",
  screenSize,
  labelColSize,
  isEmptySelection = true,
}) => {
  const locale = useRecoilValue(LanguageFilter);
  return (
    <FormControlWrapper
      inputError={inputError}
      helperText={helperText}
      className={"form-group"}
    >
      <InputLabelWrapper
        inputName={inputName}
        isRequired={isRequired}
        labelPlacement={labelPlacement}
        labelText={labelText}
        screenSize={screenSize}
        labelColSize={labelColSize}
        isRightAlignLabel={isRightAlignLabel}
      >
        <select
          className="form-control"
          required={isRequired}
          disabled={isDisabled}
          defaultValue={"null"}
          // value={inputValue}
          onChange={(event) =>
            onChangeFn({
              name: inputName,
              value: event.target.value,
              eventInfo: event,
            })
          }
          onClick={(event) =>
            onClickFn({
              name: inputName,
              eventInfo: event,
            })
          }
        >
          {isEmptySelection == true && <option value={"null"}>{emptySelectOptionTxt}</option>}
          {dataList.map((row, index) => (
            <option key={index} value={`${_get(row, keyName, "null")}`}>
              {`${_get(row, valueName, "")}`}
            </option>
          ))}
        </select>
        <FormHelperTextWrapper
          helperText={helperText}
          inputError={inputError}
        />
      </InputLabelWrapper>
    </FormControlWrapper>
  );
};

SelectBox.propTypes = {
  /** Is full width set or not */
  // isFullWidth: PropTypes.bool,
  /** Lable text */
  labelText: PropTypes.string,
  /** In out value */
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** In out value name */
  inputName: PropTypes.string,
  /** Input field error message */
  inputError: PropTypes.string,
  /** Input field helper text */
  helperText: PropTypes.string,
  /** Dropdown data */
  dataList: PropTypes.array,
  /** Array item key name */
  keyName: PropTypes.string,
  /** Array item value name */
  valueName: PropTypes.string,
  /** Is select disable or not */
  isDisabled: PropTypes.bool,
  /** Is required or not */
  isRequired: PropTypes.bool,
  /** Onchane event function */
  onChangeFn: PropTypes.func,
  /** Onclick event function */
  onClickFn: PropTypes.func,
  /** Label placement **/
  labelPlacement: PropTypes.string,
  /** Empty select option text **/
  emptySelectOptionTxt: PropTypes.string,
  /** Screen size **/
  screenSize: PropTypes.string,
  /** Label column size **/
  labelColSize: PropTypes.number,
  /** Label alignment */
  isRightAlignLabel: PropTypes.bool,
};

/**
 * memo render
 * @param {Object} prevProps
 * @param {Object} nextProps
 */
const areEqual = (prevProps, nextProps) => {
  if (
    nextProps.cacheLevel === formCacheLevel.none ||
    nextProps.setCache === false
  ) {
    return false;
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnFormGroupChange) {
    return (
      // prevProps.isFullWidth === nextProps.isFullWidth &&
      prevProps.labelText === nextProps.labelText &&
      prevProps.responseUpdateStatus === nextProps.responseUpdateStatus &&
      prevProps.inputError === nextProps.inputError &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isRequired === nextProps.isRequired &&
      prevProps.inputValue === nextProps.inputValue &&
      prevProps.updateStatus === nextProps.updateStatus &&
      prevProps.dataList.length === nextProps.dataList.length
    );
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnIndividual) {
    return (
      // prevProps.isFullWidth === nextProps.isFullWidth &&
      prevProps.inputError === nextProps.inputError &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isRequired === nextProps.isRequired &&
      prevProps.labelText === nextProps.labelText &&
      prevProps.inputValue === nextProps.inputValue &&
      prevProps.responseUpdateStatus === nextProps.responseUpdateStatus &&
      prevProps.dataList.length === nextProps.dataList.length
    );
  }
};

const SelectBoxMemo = memo(SelectBox, areEqual);

const SelectBoxWithState = ({
  uiType = defaultTemplateType,
  elementWrapperStyle = "",
  elementStyle = "",
  isFullWidth = true,
  labelText = "",
  inputValue = "",
  inputName = "",
  inputError = "",
  dataList = [],
  helperText = "",
  keyName = "id",
  valueName = "value",
  isDisabled = false,
  isRequired = false,
  formGroupName = "",
  inputStatePath = "",
  apiUrl = "",
  apiStateKey = "",
  setCache = true,
  emptySelectOptionTxt = "--Select an option--",
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  onChangeFn = emptyFn,
  onClickFn = emptyFn,
  labelPlacement = labelPositions.START,
  screenSize,
  labelColSize,
}) => {
  const [
    currentValue,
    currentError,
    updateStatus,
    responseUpdateStatus,
    optionList,
    handleOnChangeFn,
    handleOnClickFn,
  ] = useAPIBaseInput(
    apiUrl,
    apiStateKey,
    dataList,
    inputStatePath,
    formGroupName,
    inputName,
    inputValue,
    onChangeFn,
    onClickFn
  );

  return (
    <SelectBoxMemo
      uiType={uiType}
      elementWrapperStyle={elementWrapperStyle}
      elementStyle={elementStyle}
      isFullWidth={isFullWidth}
      labelText={labelText}
      inputName={inputName}
      inputError={currentError}
      helperText={helperText}
      dataList={optionList}
      keyName={keyName}
      valueName={valueName}
      inputValue={currentValue}
      isDisabled={isDisabled}
      emptySelectOptionTxt={emptySelectOptionTxt}
      isRequired={isRequired}
      onChangeFn={(eventData) => {
        handleOnChangeFn(eventData);
      }}
      onClickFn={(event) => handleOnClickFn(event)}
      updateStatus={updateStatus}
      cacheLevel={cacheLevel}
      responseUpdateStatus={responseUpdateStatus}
      setCache={setCache}
      labelPlacement={labelPlacement}
      screenSize={screenSize}
      labelColSize={labelColSize}
    />
  );
};

export { SelectBox, SelectBoxWithState };
