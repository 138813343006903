import React, { Fragment, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { CheckBox } from "../../../ui-components/ui-elements/form/CheckBox";
import { InputBox } from "../../../ui-components/ui-elements/form/InputBox";
import { SelectBox } from "../../../ui-components/ui-elements/form/SelectBox";
import { Button } from "../../../ui-components/ui-elements/form/Button";
import { labelPositions } from "../../../ui-components/ui-elements/form/InputLabel";
import Template from "../../../ui-components/templates/Template";
import { Popup } from "../../../ui-components/ui-elements/Popup/Popup";
import { TermsAndConditionsPopUp } from "../../quick-links/terms-and-conditions/TermsAndConditions";
import { Link } from "react-router-dom";
import { Label } from "../../../ui-components/ui-elements/form/Label";

import { useRecoilState, useRecoilValue } from "recoil";
import { ChassisNumber, VehicleNumber, VehicleInfo } from "../../../../atom";
import { useRevenueLicenseHook } from "../../../hooks/useRevenueLicenseHook";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  LanguageFilter,
  paymentCompleteUrl,
  paymentCompleteRequest,
  paymentCompleteResponse,
  paymentConfirmFlag,
} from "../../../../atom";
import { usePaymentCompleteHook } from "../../../hooks/usePaymentCompleteHook";
import dateFormat, { masks } from "dateformat";

const PaymentComplete = () => {
  const paymentUrl = useRecoilValue(paymentCompleteUrl);
  // const paymentUrl = "https://www.abcs.com?asd=ass&zxc=qwert";
  const paymentRequest = useRecoilValue(paymentCompleteRequest);
  const paymentResponse = useRecoilValue(paymentCompleteResponse);
  const paymentConfirmation = useRecoilValue(paymentConfirmFlag);
  const [PaymentConfirmFlag, setPaymentConfirmFlag] =
    useRecoilState(paymentConfirmFlag);

  const vehicleNum = useRecoilValue(VehicleNumber);
  const vehicleDetails = useRecoilValue(VehicleInfo);

  const { printLicense, licenseData, errorInfo } = usePaymentCompleteHook();
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);

  // const [imageData, setImageData] = useState("");

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const getQueryVariable = () => {
    let query = window.location.search;
    // let query = "https://web.dev.erl2.lgcc.gov.lk/payment-complete?request_reference=ERLIWP000000000188&conf=first&PAID=Y&BID=8543069&PRN=ERLIWP000000000188";
    console.log(query); //"app=article&act=news_content&aid=160990"

    let vars = query.split("&");
    console.log("Query Params: ", vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]

    let conf = null;
    let paid = null;
    let bid = null;
    let prn = null;
    let payment_response = null;

    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=")[1];
      let pair2 = vars[i].split("=")[0];
      console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      console.log(pair2);
      // if(pair[0] == variable){return pair[1];}
      if(pair2 == "conf"){
        conf = pair;
      }
      if(pair2 == "PAID"){
        paid = pair;
      }
      if(pair2 == "BID"){
        bid = pair;
      }
      if(pair2 == "PRN"){
        prn = pair;
      }
      if(pair2 == "lgpsPaymentResponse"){
        payment_response = decodeURIComponent(pair);
      }
    }
    // console.log(paymentConfirmFlag);
    // if(!paymentConfirmFlag){
      console.log("Paymnet Confirmation");
      console.log('COMPLETE_RESPONSE-request_reference_no', vars[0].split("=")[1]);
      console.log('COMPLETE_RESPONSE-lgpsPaymentResponse', payment_response);
      // console.log('COMPLETE_RESPONSE', vars);


      if(conf !== null || payment_response !== null){
        setIsPaymentCompleted(true);
        console.log("conf " + conf + " Payment Response " + payment_response + "isCompleted" + isPaymentCompleted);
        printLicense({
        request_reference_no: vars[0].split("=")[1],
        pg_response:{
          conf: conf,
          paid: paid,
          bid: bid,
          prn: prn,
          payment_response: payment_response,
          // lgpsPaymentResponse: decodeURIComponent(vars[1] !== undefined? vars[1].split("=")[1] : ""),
        }
        // request_reference_no: "96f98bbc-8c15-4355-8571-1b8c0bbaa44d",
        // pg_response:{
        //   lgpsPaymentResponse: decodeURIComponent("86IPT6hWgqRAlqmsgLXpKGLIP9KC3TLEyn6m1UW01QR0uSwNj%2BczFbygDVupnpvA10T6X9AgDlex%0D%0AQyRthmcLzNcPdtuGdlRkwJblH9HbxCjg2mgw%2FR784sCW5R%2FR28Qo8ZXNVqgtfb4m01Ag7Q%2B7kI3Y%0D%0A2vEuUWLH%2FoFo8kZnMTYsglpFi1VI5lvn%2BxzBzZSr0g6eqD5s44k7fxiF3iKgb7BKqyTPAO%2BUBbvH%0D%0A86NsiMvEwuftbg%2BAaKhymHgRqR4dCMtKJsWlIellg%2BAGuGptCetykvBjc2ZQyKy%2Bllp2bsIYqcNE%0D%0AIeTU%2F4V%2BIPveeLTsFuoiq6z1fSmIqy3s4y0RnjMAgD9rLmsfLACj7ASIeApO3oczCxNFTb4W90oX%0D%0ARKZuBfQvitnpz1KCGpOIDMLvxOJAJA17OKHyM6YNQPGxWTBX4v6kqWwmfFOhei%2BzYEsSN6i%2BTU2J%0D%0A786%2B1IyaGM3oY5r3QKMeRgPbd3w2eM4y%2FRsTKhUxCG0XZiRzgwNx%2BEVBCqSzAPhDMvgta4ZDZRax%0D%0A0pPvGAOvqiwadkMpv2s9cWTWrf3L9yqgh875geMkmaDWlkOVytgOUb6AP2yBkJ6FeQUH1J99dlv2%0D%0A8%2FNCtYI%2B2JVZePFY9uHdH6aDiVisY2ZNPHwpWe9ifhChkDPl%2FZEj08AzdVppW5hNh4v67Um%2BuxxT%0D%0AXE3WzP8QfEaUb7pPKIhZ%2FdilfL4dA%2F8GkVO4btHHYjeAuEXvFv4NN2PQO9n2vBIbJVcexSGmEBmT%0D%0AFkj50l9dkHw1OWenUGBQHzS9KYaY69p5JK8IfOP6qhh3KlS9wV49K9IG4lZexjM4unFZH5OwL4rU%0D%0AtQI%2BbFuU9SslIgFx0ful%2FwLNdmplax7OVbzptoHL93mk4EysbP%2B9dDIOReGIfwbFkcx20Yxqh8xj%0D%0AVOUXlQat%2BEXZU0XqBb2%2BZp3ym%2BDQbcqBNvOzvFcBPWVN86BWcSSViEWyUmk%3D"),
        // }
      }) ;}
    // }
    
  };

  const onConfirmedClick = () =>{
    window.location.href = licenseData?.image;
    const linkSource = licenseData?.image;
     const downloadLink = document.createElement("a");
     downloadLink.href = linkSource;
     downloadLink.download = "Temporary Licence";
     downloadLink.click();
  }

  useEffect(() => {
    getQueryVariable();
  }, []);

  // useEffect(() => {
  //   if (licenseData !== undefined) {
  //     console.log("LicenseData : ", licenseData);
  //     setImageData(licenseData);
  //   }
  // }, [licenseData]);

  const ImageView = ({ data }) => (
    data !== undefined ? <img src={`data:image/jpeg;base64,${data}`} alt="License" />: <div></div>
  );


  if (!paymentUrl.length == 0 && paymentConfirmation) {
    console.log("PaymentCompleteUrl:", paymentUrl);
    console.log("confirm-pay request:", paymentCompleteRequest);
    console.log("confirm-pay response:", paymentCompleteResponse);

    return (
      <Route
        component={() => {
          window.location.href = paymentUrl;
          setPaymentConfirmFlag(false);
          return null;
        }}
      />
    );
  }

  console.log("Payment Complete Error:", errorInfo);
    return (
      isPaymentCompleted ? licenseData !== undefined ? 
      <Template>
        {licenseData !== undefined ? <div  className="successMessage">
          <b>Success ! </b> Your Payment has been processed. You will receive your licence within {licenseData?.web_temp_valid_days} working days. Thank you for using the eRL service.
        </div> : <></>}
        {licenseData !== undefined ? <div className="uiCardWrapper">
        <UICard
        title="Licence Details"
        borderLess={true}
        titleStyle="mainTxtHeading "
      >
        <div className="col-md-9 col-sm-12 mt-5">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Reference No."
              />
            </div>
            <div className="col-7">
              {licenseData !== undefined ? licenseData?.licence_details?.reference_number : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Vehicle No."
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.licence_details?.vehicle_no : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Revenue Licence No."
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.licence_details?.revenue_license_no : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Licence Duration"
              />
            </div>
            <div className="col-7">
              {licenseData !== undefined ? dateFormat(licenseData?.licence_details?.valid_from, "dd/mm/yyyy") : "-"}{" To "}{licenseData !== undefined ? dateFormat(licenseData?.licence_details?.valid_to, "dd/mm/yyyy") : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Validity Period"
              />
            </div>
            <div className="col-2">
              {licenseData?.web_temp_valid_days}
            </div>
            <div className="col-5">
            <p style={{ color: "red" }}>* Temporary licence validity period</p>
            </div>
          </div>
        </div>
      </UICard>
      </div> : <></>}
      {licenseData !== undefined ? <div className="uiCardWrapper">
      <UICard
        title="Owner Details"
        borderLess={true}
        titleStyle="mainTxtHeading "
      >
        {/* <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Reference No."
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.licence_details?.reference_number : "-"}
            </div>
          </div>
        </div> */}
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Name of the Owner"
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.owner_details? licenseData.owner_details.name_of_the_owner : "-" : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Delivery Address of the Owner"
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.owner_details? licenseData.owner_details.delivery_address_of_the_owner : "-" : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Contact No."
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.owner_details? licenseData.owner_details.contact_no : "-" : "-"}
            </div>
          </div>
        </div>
      </UICard>
      </div>:<></>}
      {licenseData !== undefined ? <div className="uiCardWrapper">
      <UICard
        title="Payment Details"
        borderLess={true}
        titleStyle="mainTxtHeading "
      >
        {/* <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Reference No."
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.licence_details?.reference_number : "-"}
            </div>
          </div>
        </div> */}
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Total Payment Amount(LKR)"
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? numberWithCommas(licenseData?.payment_details?.total_payment_amount) : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Approval Code"
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.payment_details?.approval_code !== "" && licenseData?.payment_details?.approval_code !== null && licenseData?.payment_details?.approval_code.isEmpty ? licenseData?.payment_details?.approval_code : "-" : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Payment Type"
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? licenseData?.payment_details?.payment_type : "-"}
            </div>
          </div>
        </div><div className="col-md-9 col-sm-12 mt-4">
          <div className="row">
            <div className="col-5">
              <Label
                className="floatRight"
                labelText="Transction Date"
              />
            </div>
            <div className="col-7">
            {licenseData !== undefined ? dateFormat(licenseData?.payment_details?.transaction_date, "dd/mm/yyyy") : "-"}
            </div>
          </div>
        </div>
        <div className="col-7 ml-4">
            <div className="btnWrapper floatRight">
              {/* <Link to="/payment-licence"> */}
                <Button
                  btnStyle="btnPrimary marginRight"
                  btnName="confirm"
                  btnText="Print Temporary Licence"
                  onClick={() => onConfirmedClick()}
                />
                </div>
                </div>
      </UICard>
    </div>:<></>}
      </Template> : errorInfo !== undefined ? <Template><div  className="errorMessage">
      <b>Failed ! </b> Something went wrong. Please try again later. Thank you for using the eRL service.
    </div></Template>: <></> : <></>
    );
  }


export default PaymentComplete;
