/*
 * @Author: Binara Medawatta
 * @Date: 2021-02-01 16:38:03
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2021-02-01 17:29:03
 */

import { Badge } from "../form/Badge";
import { applicationStatus } from "./DataTable";

const TableBody = ({
  tableHeaderList = [],
  tableBodyList = [],
  isSetStatus = false,
}) => {
  const tableBody = tableBodyList.map((data) => {
    return (
      <tr>
        {tableHeaderList.map((headData) => {
          if (isSetStatus && headData.key === "status")
            return (
              <td style={{ textAlign: "left" }}>
                <Badge
                  text={data[headData.key]}
                  style={
                    data[headData.key] === applicationStatus.PENDING
                      ? "defaultBadge"
                      : "successBadge"
                  }
                />
              </td>
            );
          else
            return <td style={{ textAlign: "left" }}>{data[headData.key]}</td>;
        })}
      </tr>
    );
  });
  return <tbody>{tableBody}</tbody>;
};

export { TableBody };
